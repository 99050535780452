import React from "react";
import { Link } from "react-router-dom";



const DfeUserDetails = () => {
    return (
        <>
            <section>
                <div className="container my-3 border bg-white">
                    <div className="row p-lg-5 p-0">
                        <div className="col-4 text-center">
                            <h3>Hi Jhon Doe,</h3>
                            <h5>Plan id : SDDFE-CNK7ND</h5>
                        </div>
                        <div className="col-5 text-center">
                            <h3>Installment 4 of 11 paid</h3>
                            <h6>Next Installment Due on : October-12-2022</h6>
                        </div>
                        <div className="col-3 px-lg-5">
                            <Link
                                to={`${process.env.PUBLIC_URL}/dfe-active-plan`}
                                data-lng="en"
                                className="btn btn-solid "
                            >
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DfeUserDetails