import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { actUserLogout, actMyOrderList, actOrderTracking } from "../../actions";
import Accordion from "react-bootstrap/Accordion";
import TimeOut from "../../sessions/timeOut";


class TrackDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "GUEST",
      open: false,
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  logoutSubmit = () => {
    this.props.actUserLogout();
  };

  render() {
    const { order_track } = this.props;
    return (
      <div>
        <Breadcrumb title={"Track details"} />
        <section className="section-b-space  blog-page">
          <TimeOut logout={this.logoutSubmit} msg={null} />

          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 px-0">
                <div className="dashboard-right mt-2">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2 className="text-center">Track Details</h2>
                    </div>
                    {order_track ? (
                      <Accordion defaultActiveKey="1" alwaysOpen={true}>
                        <Accordion.Item>
                          <Accordion.Header>
                            {
                              <div className="d-flex justify-content-between w-100 px-4 mt-1 row">
                                <h4 className="accordian-question">
                                  Order Id: {order_track.order_id}
                                </h4>
                                <h4 className="accordian-question text-right pr-2">
                                  Status: {order_track.status}
                                </h4>
                              </div>
                            }
                          </Accordion.Header>
                          <Accordion.Body className="dark-accordian">
                            <table className="table table-striped mb-0">
                              <tbody>
                                <tr className="d-none d-md-block">
                                  <th>Order date</th>
                                  <td>{order_track.order_date}</td>

                                  <th>Order Status</th>
                                  <td>{order_track.status}</td>
                                </tr>
                                <tr className="d-md-none d-block">
                                  <th>Order date</th>
                                  <td>{order_track.order_date}</td>
                                </tr>
                                <tr className="d-md-none d-block mb-3">
                                  <th>Order Status</th>
                                  <td>{order_track.status}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              className="table table-bordered d-none d-md-block"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th><h5>Sl</h5></th>
                                  <th><h5>Name</h5></th>
                                  <th><h5>SKU</h5></th>
                                  <th><h5>Quantity</h5></th>
                                  <th><h5>Price</h5></th>
                                  <th><h5>Amount</h5></th>
                                </tr>
                              </thead>
                              <tbody>

                                {order_track.items && order_track.items.map((items, i) => (
                                  <tr key={i}>
                                    <td><h6>{i + 1}</h6></td>
                                    <td><h6>{items.name}</h6></td>
                                    <td><h6>{items.sku}</h6></td>
                                    <td><h6>{items.qty}</h6></td>
                                    <td><h6>{items.price}</h6></td>
                                    <td><h6>{items.qty * items.price}</h6></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="container table-responsive px-0">
                              {order_track.items && order_track.items.map((items, i) => (
                                <table
                                  className="table table-bordered d-md-none d-block "
                                  style={{ width: "100%" }}
                                >
                                  <h6>{items.name}</h6>
                                  <tbody>
                                    <tr>
                                      <th><h5>SKU</h5></th>
                                      <td><h6>{items.sku}</h6></td>
                                    </tr>
                                    <tr>
                                      <th><h5>Qty</h5></th>
                                      <td><h6>{items.qty}</h6></td>
                                    </tr>
                                    <tr>
                                      <th><h5>Price</h5></th>
                                      <td><h6>{items.price}</h6></td>
                                    </tr>
                                    <tr>
                                      <th><h5>Amount</h5></th>
                                      <td><h6>{items.qty * items.price}</h6></td>
                                    </tr>
                                  </tbody>
                                </table>
                              ))}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <div className="row">
                        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                          <h3>
                            Sorry! Couldn't find the Orders you were looking
                            For!!!
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order_track: state.data.order_track,
});

export default connect(
  mapStateToProps,
  { actUserLogout, actMyOrderList, actOrderTracking }
)(TrackDetails);
