/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Modal from "react-responsive-modal";

import FairPrice from "./fair-price";
import PdpImages from "./pdp-images";
import CarouselSlider from "./pdp-carousel";
import PdpOfferCoupon from "./offer-coupon";
import VariantOptions from "./variant-options";


class DetailsWithPrice extends Component {
  constructor(props) {
    super(props);
    //this.loading = false;
    this.state = {
      open: false,
      quantity: 1,
      quickQty: 1,
      inStock: "",
      nav3: null,
      pincode: "",
      pinStatus: "",
      pincode_loading: false,
      price: "",
      offerprice: "",
      images: [],
      variSize: '',
      variants: [
        {
          color: "",
          price: "",
          offerprice: "",
          images: []
        }
      ]
    };
  }

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    this.setState({ pinStatus: "" });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {

    this.setState({
      nav3: this.slider3,
      // variants: this.props.item.variants,
      // price: this.props.item.variants[0].price,
      // offerprice: this.props.item.variants[0].offerprice,
      // images: this.props.item.variants[0].image.split(","),

      variants: this.props.item.variants[0],
      price: this.props.item.variants[0].price,
      offerprice: this.props.item.variants[0].offerprice,
      images: this.props.item.variants[0].image.split(","),
      inStock:
        this.props.item.made_to_order == "true" ? "Made to order" : "In Stock"
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartItems != prevProps.cartItems) {
      this.props.addCartToServer(this.props.cartItems)
    }
  }

  minusQty = () => {
    if (this.state.quantity > 1 && this.state.quickQty > 1) {
      this.setState({
        quickQty: this.state.quickQty - 1,
        quantity: this.state.quantity - 1
      });
    }
    else {
      this.setState({
        quantity: 1,
        quickQty: 1
      });
    }
  };

  plusQty = (item) => {

    if (item.made_to_order == "false") {
      if (item.stock > this.state.quantity) {
        this.setState({
          quickQty: this.state.quickQty + 1,
          quantity: this.state.quantity + 1
        });
      } else {
        this.setState({ inStock: "Out of Stock !" });
      }
    } else {
      this.setState({
        quickQty: this.state.quickQty + 1,
        quantity: this.state.quantity + 1
      })
    };
    // window.dataLayer.push({ ecommerce: null });
    // window.dataLayer.push({
    //   'event': 'addToCart',
    //   'ecommerce': {
    //     'currencyCode': 'INR',
    //     'add': {
    //       'products': [{
    //         'name': this.props.item.name,
    //         'id': this.props.item.sku,
    //         'price': this.props.item.variants && this.props.item.variants[0].offerprice,
    //         "brand": "Sunny Diamonds",
    //         "category": this.props.item.category,
    //         'variant': this.props.item.variants && this.props.item.variants[0].color,
    //         'quantity': this.state.quantity
    //       }]
    //     }
    //   },
    // });

  };

  addToCart = item => {
    this.props.addToCartClicked(item, this.state.quickQty, item.selectedVariant);
    this.props.addCartToServer(this.props.cartItems)
    this.setState({ quickQty: 1 });
  };

  changeVariant = vari => {
    // console.log(vari)
    this.setState({
      // offerprice: vari.offerprice, price: vari.price,
      variants: vari,
      offerprice: vari.offerprice,
      price: vari.price,
      images: vari.image.split(","),
    })
    this.props.setVariant(vari);
  }

  checkPin = event => {
    event.preventDefault();
    if (this.state.pincode_loading) return;
    this.setState({ pincode_loading: true });
    // if (this.props.item.variants[0].offerprice * (this.state.quickQty) <= 49000) {
    if (this.props.item.variants[0].offerprice * (this.state.quickQty) <= 49000) {
      this.checkPinAPI(event)
    }
    else {
      this.setState({ pinStatus: "COD not available for this product" });
      this.setState({ pincode_loading: false });
    }
  };

  checkPinAPI = async event => {
    await axios
      .get("https://api.postalpincode.in/pincode/" + this.state.pincode)
      .then(response => {
        response = response.data
        this.setState({ pincode_loading: false });
        if (response[0].Status == "Success") {
          this.setState({ pinStatus: "COD available" });
        }
        else {
          this.setState({ pinStatus: "COD not available" });
        }
      })
      .catch(error => {
        this.setState({ pincode_loading: false });
        if (error) {
          this.setState({ pinStatus: " Error cannot find. Please try again" });
        }
      });
  }

  render() {
    const {
      item,
      BuynowClicked,
      addToWishlistClicked,
      addToCompareClicked,
      symbol
    } = this.props;
    const { price, offerprice, variants, images } = this.state;

    // console.log(item)
    // console.log(JSON.parse(item.variant))
    // console.log(variants)


    return (
      <div className="row">
        <div className="col-xl-7 col-lg-12 mt-4 d-none d-md-block">
          {variants ? (
            <PdpImages imageArr={images} />
          ) : (
            <PdpImages imageArr={item.pictures} />
          )}
        </div>
        <div className="col-sm-12 mt-4 d-md-none d-flex justify-content-center ">
          {variants ? (
            <CarouselSlider imageArr={images} />
          ) : (
            <CarouselSlider imageArr={item.pictures} />
          )}
        </div>

        <div className="col-xl-5 col-lg-12 mt-4">
          <div className="col-lg-12 text-center text-xl-left">
            <div className="product-right">
              <h2 className="px-4 px-xl-0"> {item.name}</h2>
              <h5>{item.sku.toUpperCase()}</h5>
              {variants ? (
                price != 0 ?
                  <div className="d-flex inline justify-content-lg-start justify-content-center">
                    <h3 className="cart_sum mr-2">
                      {symbol}{offerprice}
                    </h3>
                    {/* <h4 className="cart_sum">
                      <del>
                        {symbol}{price}
                      </del>
                    </h4> */}
                  </div>
                  : ""
              ) : price != 0 ? (
                <div className="d-flex inline justify-content-lg-start justify-content-center">
                  <h4 className="cart_sum mr-2">
                    <del>
                      {symbol}{item.price}
                    </del>
                  </h4>
                  <h3 className="cart_sum">
                    {symbol}{item.offerprice}
                  </h3>
                </div>
              ) : ("")
              }
              {item.variants[0] ? (
                <VariantOptions
                  category={item.category}
                  variants={item.variants}
                  changeVariant={this.changeVariant}
                />
              ) : (
                ""
              )}
              {/* onChange={this.changeVariant()} */}
              {/* {JSON.parse(item.variant).map((vari, i) => {
                  return (
                      <li
                          className={vari.color}
                          key={i}
                          title={vari.color}
                          onClick={() => changeVariant(vari)}
                      />
                  );
              })} */}
              <div className="col-md-12 col-sm-12 mt-4">
                <div className="row py-2 mb-1 mx-2 mx-md-4">
                  <div className="col-6 d-flex justify-content-center">
                    <a
                      href="javascript:void(0)"
                      className="wishlist-btn"
                      // onClick={() => addToWishlistClicked(item)}
                      onClick={() => {
                        item.selectedVariant = variants
                        addToWishlistClicked(item, item.selectedVariant)
                      }}
                    >
                      <i className="fa fa-heart pr-2" />
                      <span className="title-font">WishList</span>
                    </a>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <a
                      href="javascript:void(0)"
                      className="wishlist-btn"
                      // onClick={() => addToCompareClicked(item)}
                      onClick={() => {
                        item.selectedVariant = variants
                        addToCompareClicked(item, item.selectedVariant)
                      }}
                    >
                      <i className="fas fa-refresh pr-2" />
                      <span className="title-font">Compare</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="product-description border-product">

                {/* Offer-Coupon */}
                {/* {this.props.promo_data ? <PdpOfferCoupon promo_data={this.props.promo_data} /> : ""} */}

                {/* Quantity Selector  */}
                <div className="row py-2">
                  <div className="col-12 col-sm-6 py-2 d-flex flex-column align-items-center">
                    <span className="instock-cls">
                      Stock: {this.state.inStock}
                    </span>
                    <h6 className="product-title my-2">Quantity</h6>
                    <div className="qty-box">
                      <div className="input-group">
                        <span className="input-group-prepend">
                          <button
                            type="button"
                            className="quantity-left-minus"
                            onClick={this.minusQty}
                            data-type="minus"
                            data-field=""
                          >
                            <i className="fas fa-minus" />
                          </button>
                        </span>
                        <input
                          type="text"
                          readOnly={true}
                          name="quantity"
                          value={this.state.quickQty}
                          className="form-control input-number"
                        />
                        <span className="input-group-prepend">
                          <button
                            className="quantity-right-plus"
                            onClick={() => this.plusQty(item)}
                            data-type="plus"
                            disabled={
                              item.made_to_order == "false"
                                ? item.qty >= item.stock
                                  ? true
                                  : false
                                : false
                            }
                          >
                            <i className="fas fa-plus" />
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 py-2 d-flex flex-column align-items-center">
                    <span className="instock-cls">
                      Check COD Availability
                    </span>
                    <h6 className="product-title my-2">Available for products Below ₹49k</h6>
                    <div>
                      <div className="d-flex flex-md-row justify-content-center mt-md-2">
                        <input
                          type="tel"
                          className="form-control pin"
                          placeholder="Pin Code"
                          minLength="6"
                          maxLength="6"
                          name="pincode"
                          disabled={this.state.pincode_loading}
                          value={this.state.pincode}
                          onChange={this.setStateFromInput}
                        />
                        <button
                          type="submit"
                          disabled={this.state.pincode_loading}
                          className="btn pin-button"
                          onClick={this.checkPin}
                        >
                          CHECK
                        </button>
                      </div>
                    </div>
                    <p>{this.state.pinStatus}</p>
                  </div>
                </div>
              </div>
              <div className="product-buttons col-md-12 my-4 d-none d-md-block">
                <div className="row">
                  <div className="col-md-6 pl-md-0">
                    <a
                      className="btn btn-solid c-margin-x-0"
                      // onClick={() => this.addToCart(item)}
                      onClick={() => {
                        item.selectedVariant = variants
                        this.addToCart(item)
                      }}
                    >
                      add to cart
                    </a>
                  </div>
                  <div className="col-md-6 mt-3 mt-md-0 pr-md-0">
                    <Link
                      to={`${process.env.PUBLIC_URL}/checkout`}
                      className="btn btn-solid c-margin-x-0"
                      // onClick={() => BuynowClicked(item, this.state.quickQty)}
                      onClick={() => {
                        item.selectedVariant = variants
                        BuynowClicked(item, this.state.quickQty)
                      }}
                    >
                      buy now
                    </Link>
                  </div>
                </div>
              </div>


              {/* For Mobile Device  */}

              <div className="d-block d-md-none">
                <div className="d-flex justify-content-center mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">
                  <div className="col-6 col-sm-4 px-1">
                    <button className="btn btn-dfe3 w-100"
                      onClick={() => this.addToCart(item)}
                    >
                      <div className="mx-3 ">add to cart</div>
                    </button>
                  </div>
                  <div className="col-6 col-sm-4 px-1">
                    <Link className="btn pdp-buy-now w-100"
                      to={`${process.env.PUBLIC_URL}/checkout`}
                      onClick={() => BuynowClicked(item, this.state.quickQty)}
                    >
                      <div className="mx-4">buy now</div>
                    </Link>
                  </div>
                </div>
              </div>


              {price !== 0 ? (
                <div className="mt-3">
                  <FairPrice
                    symbol={symbol}
                    price={offerprice}
                    strike_price={price}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/internally-flawless-diamonds/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/Fluoresence-icon-svg (1).svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="Internally flawless diamonds"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            Internally flawless diamonds
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/100-money-back/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/100pr_Money-back.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="100% money back on diamond value"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            100% money back on diamond value
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/education/loose-diamonds/grading/diamond-certificate`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/certification.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt=" Certifications of Diamonds"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            Certifications of Diamonds
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/hallmarked-jewellery/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/BSI_hall_mark.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt=" BIS Hall Mark for Jewellery"
                        />
                        <div className="card-body px-0">
                          <p className="pdp_feature_text text-center">
                            BIS Hall Mark for Jewellery
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/brand-assured-quality/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/brand_quality.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="Brand assured quality"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            Brand assured quality
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/15day-return-policy/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/15days_return.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="15 days return policy"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            15 days return policy
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/cash-on-delivery/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/24_1.png`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="Cash on delivery"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            Cash on delivery
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <a
                      href={`${process.env.PUBLIC_URL
                        }/why-buy-from-us/free-shipping/`}
                    >
                      <div className="card-deck d-block text-center">
                        <img
                          src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/shipping_truck.svg`}
                          className="pdp_feature-img-top-fill image mx-auto"
                          alt="Pan india free shipping"
                        />
                        <div className="card-body px-0 ">
                          <p className="pdp_feature_text text-center">
                            Pan india free shipping
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3">
                <h4 className="pdp_help">Need Help?</h4>
                <div className="row mt-3 px-4 mx-3">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 pdp_help">
                    <a
                      href="tel:+919961870099"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-phone fa-2x"
                        style={{ transform: "rotateY(180deg)" }}
                      />
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 pdp_help">
                    <a
                      href="mailto:customerservice@sunnydiamonds.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-envelope fa-2x" />

                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 pdp_help">
                    <a
                      href="https://api.whatsapp.com/send?phone=919961870099"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "rgb(114, 34, 87)" }}
                    >
                      <i className="fab fa-whatsapp fa-2x" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Modal open={this.state.open} onClose={this.onCloseModal} center>
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Get Notification</h5>
                  </div>
                  <div className="modal-body text-center">
                    <input
                      type="text"
                      name="username"
                      className="notify"
                      placeholder="  enter your name"
                      required=""
                    />
                    <input
                      type="email"
                      name="email"
                      className="notify my-3"
                      placeholder="  enter your email"
                    />
                    <br />
                    <button className="btn btn-solid">Send</button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  promo_data: state.data.promo_code_name
});

export default connect(mapStateToProps)(DetailsWithPrice);
