import React, { Component } from "react";
import { connect } from "react-redux";

import { getPolicy } from "../../../services";

import DOMPurify from 'dompurify';

import Seo from "../../layouts/common/seo";
import Breadcrumb from "../../common/breadcrumb";
import Sidebar from "../../layouts/common/sidebar";
import Promise from "../../new_home_page/promise";

class ShippingPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { listPolicy } = this.props;

    if (listPolicy.length > 0) {
      return (
        <div>
          {/* seo section  */}
          <Seo type="shipping-head" />
          <Breadcrumb title={listPolicy[0].title} />

          <section className="section-b-space  blog-page">
            <div className="container">
              <div className="row">
                <Sidebar type="shipping-policy" card="card" />

                <div className="col-xl-9 col-lg-8 col-md-7 px-md-0 px-5">
                  <div className="row blog-media px-md-5 px-0">
                    <h1 className="py-3 mt-2 pl-2 tilte-normal">{listPolicy[0].title}</h1>

                    {listPolicy.map((item, i) => (
                      <div key={i} className="col-xl-12 mb-4 policy-para">
                        <h3 className="para-one">
                          <b>{item.name}</b>
                        </h3>
                        <h5>
                          <b>{item.subName}</b>
                        </h5>
                        <p className="normal-para" dangerouslySetInnerHTML = {{__html: DOMPurify.sanitize(item.content)}}></p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Sunny diamonds features section*/}
          <div className="mt-5 pt-2">
            <Promise />
          </div>
          {/* end of sunny diamonds features section */}
        </div>
      );
    } else return 0;
  }
}

const mapStateToProps = state => ({
  listPolicy: getPolicy(state.data.products, "shipping-policy")
});

export default connect(mapStateToProps)(ShippingPolicy);
