import React, { Component } from "react";
import { connect } from "react-redux";
import { getCategory } from "../../../services";

class BangleSize extends Component {
  render() {
    const { listMetal } = this.props;
    return (
      <div>
        <div className="row blog-media mt-4">
          <div className="col-xl-12">
            <h2>
              <b>Bangle and Bracelet size guide</b>
            </h2>
            <p className="normal-para">
              Before making an online purchase for a bangle, you need to know
              the required measurements. To find your correct bangle size, we
              can help you with a simple method you can do at the luxury of your
              home. Before measuring, ensure that your thumb is touching your
              little finger. Then either tie a thread around the knuckles and
              measure the length with a scale or directly measure the length of
              the knuckles with a measuring tape. Use the chart provided below
              to help you find your bangle size for the measurements you have
              taken. The chart is based on the standard international bangle
              size guide.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            {/* video section */}

            <video
              className="blog-video"
              src="/assets/video/sunny/education/How_To_Measure_Bangle_Size_With_Tape-1.mp4#t=0.5"
              loop="loop"
              type="video/mp4"
              preload="metadata"
              controls="controls"
            />
            <center>Measure Your Bangle Size With A Tape</center>
          </div>
          <br />
          <div className="col-md-6">
            <video
              className="blog-video"
              src="/assets/video/sunny/education/How_To_Measure_Bracelet_Size_With_Tape.mp4#t=0.2"
              loop="loop"
              preload="metadata"
              controls="controls"
              playsInline="playsinline"
            />
            <center>Measure Your Bracelet Size With A Tape</center>
          </div>
        </div>
        <h4 className ="py-4">
          <b>Measure your bangle size with a ruler</b>
        </h4>
        <div className="col-md-12 mx-0">
            <video
              className="blog-video"
              src="/assets/video/sunny/education/Bangle_With_Ruler-1.mp4.mp4#t=0.3"
              loop="loop"
              preload="metadata"
              controls="controls"
            />
          </div>
        <div className="row  blog-media">
          <div className="col-md-12">
            <h3 className="mt-4 py-4 normal-para">Bangle and Bracelet Size Chart</h3>
            <table className="uk-table uk-table-striped">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>mm</th>
                  <th>size</th>
                </tr>
              </thead>
              <tbody>
                {listMetal.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.mm}</td>
                    <td>{item.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row ml-2">
          <a
            className="btn btn-solid"
            href="/assets/docs/Bangle_Bracelet_Size.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Chart
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listMetal: getCategory(state.data.products, "bangle-size")
});

export default connect(mapStateToProps)(BangleSize);
