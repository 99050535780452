import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Breadcrumb extends Component {
    render() {
        const { title, parent, head } = this.props;
        return (
            <div className="breadcrumb-section d-none d-md-block">
                <div className="container">
                    <div className="row">

                        <div className="col-12 float-right">
                            <nav aria-label="breadcrumb" className="theme-breadcrumb">
                                <ol className="breadcrumb pt-1 pb-1">
                                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                    {parent ?
                                        <li className="breadcrumb-item" aria-current="page">{parent}</li> : ''}
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Breadcrumb;