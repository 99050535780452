import React, { Component } from "react";
// import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import { Slider1 } from "../../../../services/script";
import { Link } from "react-router-dom";

class CarouselSlider extends Component {
  render() {
    let imgeSrc = [];
    let vidSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];

    this.props.imageArr.forEach(function(img) {
      let ext = img.substring(img.lastIndexOf("."));
      if (exts.includes(ext)) {
        imgeSrc[imgI] = img;
        imgI++;
      } else {
        vidSrc[imgI] = img;
        imgI++;
      }
    });

    if (this.props.imageArr)
    return (
      <section className="ml-1 pdp-carousel">
        <Slider {...Slider1} className="">
        {imgeSrc.map((img, index) => (
              <div className="container_ban mx-auto" key={index}>
              <img
                className="d-md-none d-block mt-3 img-fluid"
                src={`${process.env.PUBLIC_URL}` + img}
                alt="sunny diamonds no like other"
                onError={e => {
                  e.target.src = `${
                    process.env.PUBLIC_URL
                  }/assets/images/sunny/no_image/no_images.webp`;
                }}
              />
            </div>
          ))}
        </Slider>
      </section>
    );
    else return null;

  }
}

export default CarouselSlider;
