import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import './new-trend.css';

const NewTrend = () => {

    const sliderRef = useRef();

    const prev = () => {
        sliderRef.current.slickPrev();
    }

    const next = () => {
        sliderRef.current.slickNext();
    }



    const settingNewTrend = {
        dots: false,
        autoplay: true,
        speed: 900,
        infinite: true,
        autoplaySpeed: 5500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    return (
        <section className="new-trend newsfeed-section my_l">
            <div className="home-container">
                <div className="trend-title">
                    <div className="heading-section">
                        <h2 className="heading-title title-normal">What's New Trend</h2>
                        <div className="heading-icon">
                            <span className="hr-line">&#160;</span>
                            <span className="diamond-icon">&#160;</span>
                            <span className="hr-line">&#160;</span>
                        </div>
                    </div>
                    <div className="nav-content">
                        <p className="para-one">CHECK ALL</p>
                        <div className="custom-arrow">
                            <span className="left-arrow nav-arrow" onClick={prev}>&#160;</span>
                            <span className="right-arrow nav-arrow" onClick={next}>&#160;</span>
                        </div>
                    </div>
                </div>
                <div className="newsfeed-contents">

                    <Slider ref={sliderRef} {...settingNewTrend}>
                        {window.whatNewTrend.map((item, index) => (
                            <div className="item" key={index} style={{ width: "500px", marginRight: "70px" }}>
                                <div className="newsfeed-item">
                                    <div className="newsfeed-img">
                                        <img src={item.image} alt={item.heading} />
                                    </div>
                                    <div className="newsfeed-text">
                                        <p className="normal-para">{item.paragraph}</p>
                                        <h2 className="title-medium">{item.heading}</h2>
                                    </div>
                                    <a href='https://sunnydiamonds.com/blog/' target='_blank'>
                                        <div className="overlay-content">
                                            <p className="normal-para">{item.paragraph}</p>
                                            <h2 className="title-medium">{item.heading}</h2>
                                            <span>&#160;</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default NewTrend;