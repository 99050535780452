import React, { Component } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import store from "../../../../store";
import { getAboutus } from "../../../../services/index";


class CompanyValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (navigator.cookieEnabled) {
      if (localStorage.getItem("themeLayout") === "dark") {
        store.dispatch({ type: "THEME_LAYOUT", themeColor: "dark" });
      } else {
        store.dispatch({ type: "THEME_LAYOUT", themeColor: "light" });
      }
    }
  }


  render() {
    const { themeColor, service_data } = this.props;

    return (

        <div className="container about-cls section-b-space">
        <section className="service small-section-service ">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {service_data.map((data, i) => (
                  <div className="col-md-4 service-block" key={i}>
                    <div className="media">
                      <div className="row">
                        <div className="col-md-12 media-head">
                          <img
                            src={
                              `${process.env.PUBLIC_URL}` +
                              data.image[themeColor].src
                            }
                            alt={data.title}
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="serv-head">{data.title}</h4>
                          <p className="serv-para">{data.content}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        </div>
    )
  }
}



const mapStateToProps = state => ({

  service_data: getAboutus(state.data.products, "about-us-service"),
  themeColor: state.theme.themeColor

});

export default connect(mapStateToProps)(CompanyValues);
