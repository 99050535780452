import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import store from "../store";
import {
    getCategoryCollection,
    getAllCategoryCollection,
    getPdpCollection,
    getTrendingCollection,
    postCustomerDetails,
    getNewProductCollection,
    postCustomerServices,
    postNewCustomerAccount,
    postPromoCheck,
    postCustomizedDetail,
    postUserEmail,
    postLoginData,
    setSessionData,
    serLogoutData,
    destroySessionData,
    serProfile,
    serProfileEdit,
    serForgotPassword,
    serChangePassword,
    emailSubscription,
    postOrderdetails,
    serOrderDetails,
    postZohoSubmit,
    postUniqueCheck,
    postCareerDetails,
    serMyOrderList,
    serUserId,
    serUserLog,
    postCartDetails,
    serReqOtp,
    serVerifyOtp,
    serCms,
    timerService,
    serValidateCheckout,
    serCreateGift,
    serCheckGiftBalance,
    serFetchTemplate,
    serFetchPromoCode,
    serSubmitDfe,
    postCategoryOptions
} from "../services";


import SDClient from "../sd_core/client";

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});

export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
});

export const getAllProducts = () => dispatch => {
    dispatch(fetchProductsBegin());
    shop.getProducts(products => {
        dispatch(receiveProducts(products));
        return products;
    });
};

export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
});

// From API

export function getAllPdps(val) {
    return dispatch => {
        dispatch({
            type: types.FETCH_PDP
        });
        var POST = {
            value: val
        };

        getPdpCollection(POST)
            .then(response => {
                if (response.data) {

                    console.log(response.data.result)

                    if (response.data.success === true) {
                        window.dataLayer = window.dataLayer || [];
                        try {
                            window.dataLayer.push({
                                'event': 'view_item',
                                'ecommerce': {
                                    'detail': {
                                        'actionField': { 'list': 'pdp' },
                                        'products': [{
                                            'name': response.data.result.name,
                                            'id': response.data.result.sku,
                                            'price': response.data.result.variants[0].offerprice,
                                            'brand': 'Sunny Diamonds',
                                            'category': response.data.result.category,
                                            'variant': response.data.result.variants[0].color
                                        }]
                                    }
                                }
                            });
                        }
                        catch (err) {
                            if (window.dataLayer.filter(function (obj) {
                                return obj.errorMsg === err.message;
                            }).length == 0) {
                                window.dataLayer.push({
                                    'event': 'variable error',
                                    'errorMsg': err.message
                                });
                            }
                        }

                        return dispatch({
                            type: types.RECEIVE_PDP,
                            payload: response.data.result
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_PDP
                        });
                    }
                } else {
                    // this.history.push("/404");
                    this.history.push("/");
                    return dispatch({
                        type: types.FAILED_PDP
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_PDP,
                    error: error
                });
            });
    };
}

export function getAllCategories(cat) {
    return dispatch => {
        dispatch({
            type: types.FETCH_CATEGORY
        });
        var POST = {
            category: cat,
        };
        if (cat) {
            getCategoryCollection(POST)
                .then(response => {
                    if (response.data) {
                        if (response.data.success === true) {
                            return dispatch({
                                type: types.RECEIVE_DB_CATEGORY,
                                payload: response.data.result
                            });
                        } else {
                            return dispatch({
                                type: types.FAILED_CATEGORY
                            });
                        }
                    } else {
                        return dispatch({
                            type: types.FAILED_CATEGORY
                        });
                    }
                })
                .catch(error => {
                    return dispatch({
                        type: types.FAILED_CATEGORY,
                        error: error
                    });
                });
        } else {
            getAllCategoryCollection(POST)
                .then(response => {
                    if (response.data) {
                        if (response.data.success === true) {
                            return dispatch({
                                type: types.RECEIVE_DB_CATEGORY,
                                payload: response.data.result
                            });
                        } else {
                            return dispatch({
                                type: types.FAILED_CATEGORY
                            });
                        }
                    } else {
                        return dispatch({
                            type: types.FAILED_CATEGORY
                        });
                    }
                })
                .catch(error => {
                    return dispatch({
                        type: types.FAILED_CATEGORY,
                        error: error
                    });
                });
        }
    };
}


export function getAllTrending() {
    return dispatch => {
        dispatch({
            type: types.FETCH_TRENDING
        });
        var POST = {
            type: "trending"
        };

        getTrendingCollection(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_TRENDING,
                            payload: response.data.result
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_TRENDING
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_TRENDING
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_TRENDING,
                    error: error
                });
            });
    };
}


//it seems that I should probably use this as the basis for "Cart"

export const addToCart = (product, qty, variants, cartItems) => dispatch => {
    dispatch(addToCartUnsafe(product, qty, variants, cartItems));
};


export const addToCartUnsafe = (product, qty, variants, cartItems) => dispatch => {

    window.dataLayer = window.dataLayer || [];
    try {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'INR',
                'add': {
                    'products': [{
                        'name': variants.alt,
                        'id': variants.sku,
                        'price': variants.offerprice,
                        "brand": "Sunny Diamonds",
                        "category": product.category,
                        'variant': variants.color,
                        'size': variants.size,
                        'quantity': qty
                    }]
                }
            },
            'value': variants.offerprice

        });
    }
    catch (err) {
        if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
        }).length == 0) {
            window.dataLayer.push({
                'event': 'variable error',
                'errorMsg': err.message
            });
        }
    }
    dispatch({
        type: types.ADD_TO_CART,
        product,
        qty,
        variants
    });
};

export const addCartToServer = (cartItems) => dispatch => {
    let cart = []
    cartItems.map((item, i) => {
        cart.push({
            sku: item.sku,
            quantity: item.qty,
            variant: item.selectedVariant.id
        })
    })
    dispatch({
        type: types.READY_UPDATE_TO_SERVER,
        // payload: {cart:data.message.cart}
    });
    SDClient.endpoint('cart_update', {
        cart: cart
    }).then((data) => {
        // console.log('EX-CART',data.message)
        if (data.message.action) {
            dispatch({
                type: types.UPDATE_TO_SERVER,
                payload: { cart: data.message.cart }
            });
        }
    });
}


export const incrementQty = (product, qty, variant) => dispatch => {

    dispatch(addToCartUnsafe(product, qty, variant));
};
export const decrementQty = (productId, variantId) => dispatch => {
    dispatch({
        type: types.DECREMENT_QTY,
        productId,
        variantId
    });
};

export const addToCartAndRemoveWishlist = (product, qty, variants) => dispatch => {

    window.dataLayer = window.dataLayer || [];
    try {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'INR',
                'add': {
                    'products': [{
                        'name': product.name,
                        'id': product.sku,
                        'price': product.selectedVariant.offerprice,
                        "brand": "Sunny Diamonds",
                        "category": product.category,
                        'variant': product.selectedVariant.color,
                        'size': product.selectedVariant.size,
                        'quantity': qty
                    }]
                }
            },
        });
    }
    catch (err) {
        if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
        }).length == 0) {
            window.dataLayer.push({
                'event': 'variable error',
                'errorMsg': err.message
            });
        }
    }
    dispatch({
        type: types.WISHLIST_TO_CART,
        product,
        qty,
        variants
    });
    dispatch(removeFromWishlist(product));
};

export const removeFromCart = (product_id, variant_id) => dispatch => {

    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id,
        variant_id
    });
};

//it seems that I should probably use this as the basis for "Wishlist"

export const addToWishlist = (product, variants) => dispatch => {
    dispatch(addToWishlistUnsafe(product, variants));
};

export const addToWishlistUnsafe = (product, variants) => dispatch => {
    dispatch({
        type: types.ADD_TO_WISHLIST,
        product,
        variants
    })
};

export const removeFromWishlist = product_id => dispatch => {
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    });
};

//Compare Products
export const addToCompare = (product, variant) => dispatch => {
    dispatch(addToCompareUnsafe(product, variant));
};
export const addToCompareUnsafe = (product, variant) => ({
    type: types.ADD_TO_COMPARE,
    product,
    variant
});

export const removeFromCompare = product_id => dispatch => {
    toast.error("Item Removed from Compare");
    dispatch({
        type: types.REMOVE_FROM_COMPARE,
        product_id
    });
};

// Filters
export const filterBrand = (brand, placeholder) => ({
    type: types.FILTER_CAT,
    brand,
    placeholder
});
export const filterColor = color => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = value => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = sort_by => ({
    type: types.SORT_BY,
    sort_by
});

// Currency
export const changeCurrency = (symbol, currency_code) => ({
    type: types.CHANGE_CURRENCY,
    payload: {
        symbol: symbol,
        currency_code: currency_code
    }

});

//New Products
export function getAllNewProducts() {
    return dispatch => {
        dispatch({
            type: types.FETCH_NEW_PRODUCTS
        });
        var POST = {
            type: "new"
        };
        getNewProductCollection(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_NEW_PRODUCTS,
                            payload: response.data.result
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_NEW_PRODUCTS
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_NEW_PRODUCTS
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_NEW_PRODUCTS,
                    error: error
                });
            });
    };
}

//Get New User Details
export function postAllNewCustomerAccount(regData) {
    return dispatch => {
        dispatch({
            type: types.POST_NEW_CUSTOMER_DETAIL
        });
        var POST = {
            first_name: regData.first_name,
            last_name: regData.last_name,
            phone: regData.phone,
            email: regData.email,
            subscription: regData.subscription,
            password: window.btoa(regData.password),
            c_password: window.btoa(regData.c_password)
        };
        postNewCustomerAccount(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        setSessionData(response.data.result);
                        toast.success("Account Created Successfully", {
                            autoClose: 1000,
                            hideProgressBar: false,
                            progress: undefined
                        });
                        // this.history.push("/customer/account/success");
                        setTimeout(() => this.history.push("/customer/account/success"), 1000);
                        return dispatch({
                            type: types.RECEIVE_NEW_CUSTOMER_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        toast.warning("Something went wrong");
                        return dispatch({
                            type: types.FAILED_NEW_CUSTOMER_DETAIL
                        });
                    }
                } else {
                    toast.warning("Something went wrong");
                    return dispatch({
                        type: types.FAILED_NEW_CUSTOMER_DETAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CUSTOMER_DETAIL,
                    error: error
                });
            });
    };
}

//Get Login Data
export function actUserLogin(lgData) {
    return dispatch => {
        dispatch({
            type: types.POST_LOGIN_DETAIL
        });

        var POST = {
            email: lgData.email,
            password: window.btoa(lgData.password)
        };

        postLoginData(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Logged In Successfully..!", {
                            autoClose: 1000,
                            hideProgressBar: false,
                            progress: undefined
                        });
                        setSessionData(response.data.result);
                        setTimeout(() => this.history.push("/dashboard"), 1000);
                        return dispatch({
                            type: types.RECEIVE_LOGIN_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        toast.warning("Login Failed..!");
                        return dispatch({
                            type: types.FAILED_LOGIN_DETAIL
                        });
                    }
                } else {
                    toast.warning("Login Failed..!");
                    return dispatch({
                        type: types.FAILED_LOGIN_DETAIL
                    });
                }
            })
            .catch(error => {
                toast.warning("User Login Failed...");
                return dispatch({
                    type: types.FAILED_LOGIN_DETAIL,
                    error: error
                });
            });
    };
}

//Change Password

export function actChangePassword(data) {
    return dispatch => {
        dispatch({
            type: types.POST_FORGOT_PASSWORD
        });
        var POST = {
            user_id: data.user_id,
            email: data.email,
            oldPassword: window.btoa(data.oldPassword),
            newPassword: window.btoa(data.newPassword),
            confirmPassword: window.btoa(data.confirmPassword)
        };
        serChangePassword(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Password Change Successfully");
                        destroySessionData();
                        store.dispatch({ type: "RECEIVE_LOGIN_DETAIL", payload: "" });
                        setTimeout(() => this.history.push("/login"), 1000);
                        return dispatch({
                            type: types.RECEIVE_CHANGE_PASSWORD,
                            payload: response.data
                        });
                    } else {
                        toast.warning("Something went wrong");
                        return dispatch({
                            type: types.FAILED_CHANGE_PASSWORD
                        });
                    }
                } else {
                    toast.warning("Something went wrong");
                    return dispatch({
                        type: types.FAILED_CHANGE_PASSWORD
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CHANGE_PASSWORD,
                    error: error
                });
            });
    };
}

//Email To user

export function postAllUserEmail(emailsend) {
    return dispatch => {
        dispatch({
            type: types.POST_USER_EMAIL
        });

        var POST = {
            recipient: emailsend.Email,
            name: emailsend.First_Name + " " + emailsend.Last_Name
        };

        postUserEmail(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Email Send Successfully");
                        return dispatch({
                            type: types.RECEIVE_USER_EMAIL
                        });
                    } else {
                        toast.warning("Email Send Failed");
                        return dispatch({
                            type: types.FAILED_USER_EMAIL
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_USER_EMAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_USER_EMAIL,
                    error: error
                });
            });
    };
}

//Logout Data
export function actUserLogout() {
    return dispatch => {
        dispatch({
            type: types.POST_LOGOUT_DETAIL
        });

        serLogoutData()
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Logged Out ");
                        destroySessionData();
                        store.dispatch({ type: "RECEIVE_LOGOUT_DETAIL", payload: "" });
                        this.history.push("/login");
                        return dispatch({
                            type: types.RECEIVE_LOGOUT_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        toast.warning("Something went wrong");
                        return dispatch({
                            type: types.FAILED_LOGOUT_DETAIL
                        });
                    }
                } else {
                    toast.warning("Something went wrong");
                    return dispatch({
                        type: types.FAILED_LOGOUT_DETAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_LOGIN_DETAIL,
                    error: error
                });
            });
    };
}

//Customer_Service_details
export function postAllCustomerServices(csData) {
    return dispatch => {
        dispatch({
            type: types.POST_CUSTOMER_DETAIL
        });

        postCustomerServices(csData)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_CUSTOMER_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        toast.danger("Error");
                        return dispatch({
                            type: types.FAILED_CUSTOMER_DETAIL
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_CUSTOMER_DETAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CUSTOMER_DETAIL,
                    error: error
                });
            });
    };
}

//Get Customized Detail
export function postAllCustomizedDetail(formData) {
    return dispatch => {
        dispatch({
            type: types.POST_NEW_CUSTOMER_DETAIL
        });

        postCustomizedDetail(formData)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_NEW_CUSTOMER_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_NEW_CUSTOMER_DETAIL
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_NEW_CUSTOMER_DETAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CUSTOMER_DETAIL,
                    error: error
                });
            });
    };
}

// Customer Detail
export function postAllCareerCustomerDetails(formData) {
    return dispatch => {
        dispatch({
            type: types.POST_CAREER_DETAILS
        });

        postCareerDetails(formData)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Thank you applying. We will get back to !..");
                        return dispatch({
                            type: types.RECEIVE_CAREER_DETAILS,
                            payload: response.data.result
                        });
                    } else {
                        toast.warning("Something Wrong");
                        return dispatch({
                            type: types.FAILED_CAREER_DETAILS
                        });
                    }
                } else {
                    toast.warning("Something Wrong");
                    return dispatch({
                        type: types.FAILED_CAREER_DETAILS
                    });
                }
            })
            .catch(error => {
                toast.warning("Something Wrong");
                return dispatch({
                    type: types.FAILED_CAREER_DETAILS,
                    error: error
                });
            });
    };
}

//Checkout_Customer_details
export function postAllCustomerDetails(fData) {
    return dispatch => {
        dispatch({
            type: types.POST_CUSTOMER_DETAIL
        });

        postCustomerDetails(fData)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_CUSTOMER_DETAIL,
                            payload: response.data.result
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_CUSTOMER_DETAIL
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_CUSTOMER_DETAIL
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CUSTOMER_DETAIL,
                    error: error
                });
            });
    };
}

//subscribe details
export function postAllSubscribe(email) {
    return dispatch => {
        dispatch({
            type: types.POST_SUBSCRIBE
        });

        emailSubscription(email)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        if (response.data.result.user_exists === true) {
                            toast.success("Already Subscribed");
                        } else {
                            toast.success("Successfully Subscribed");
                        }
                        return dispatch({
                            type: types.RECEIVE_SUBSCRIBE,
                            payload: response.data.result
                        });

                    } else {
                        toast.danger("Subscription Failed");
                        return dispatch({
                            type: types.FAILED_SUBSCRIBE
                        });
                    }
                } else {
                    toast.danger("Subscription Failed");
                    return dispatch({
                        type: types.FAILED_SUBSCRIBE
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_SUBSCRIBE,
                    error: error
                });
            });
    };
}

//Order Id

export function postAllOrderdetails(order, items, user, payment_mode) {
    return dispatch => {
        dispatch({
            type: types.POST_ORDER_ID
        });
        var POST = {
            order: order,
            items: items,
            user: user
        };

        postOrderdetails(POST, payment_mode)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_ORDER_ID,
                            payload: response.data.result,
                        });
                    } else {
                        toast.warning("Price mismatch");
                        return dispatch({
                            type: types.FAILED_ORDER_ID,
                        });
                    }
                } else {
                    toast.warning("Something went wrong");
                    return dispatch({
                        type: types.FAILED_ORDER_ID,
                        payload: response.data.result
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_ORDER_ID,
                    error: error
                });
            });
    };
}

//check cart item
export function postCartItems(items) {
    return dispatch => {
        dispatch({
            type: types.POST_CART
        });
        var POST = {
            items: items,
        };

        postCartDetails(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.CART_UPDATED,
                            payload: response.data.items,
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_CART
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_CART,
                        payload: response.data.result
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CART,
                    error: error
                });
            });
    };
}

//Unique check
export function postUniqueCheckDetails(email) {
    return dispatch => {
        dispatch({
            type: types.POST_UNIQUE
        });
        var POST = {
            table: "users",
            field: "email",
            email: email
        };

        postUniqueCheck(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_UNIQUE,
                            payload: response.data.success
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_UNIQUE
                        });
                    }
                } else {
                    return dispatch({
                        type: types.RECEIVE_UNIQUE,
                        payload: "false"
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_UNIQUE,
                    error: error
                });
            });
    };
}

//Promo check
export function postPromoCodeDetails(promo_code) {
    return dispatch => {
        dispatch({
            type: types.POST_PROMO
        });
        var POST = {
            code: promo_code
        };

        postPromoCheck(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_PROMO,
                            payload: response.data.promo
                        });
                    } else {
                        toast.warning("Invalid Promo Code..!");
                        return dispatch({
                            type: types.FAILED_PROMO
                        });
                    }
                } else {
                    toast.warning("Invalid Promo Code..!");
                    return dispatch({
                        type: types.FAILED_PROMO,
                        payload: "false"
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_PROMO,
                    error: error
                });
            });
    };
}

//Zoho Access Submit
export function postZohoDetailsSubmit(data, module) {
    return dispatch => {
        dispatch({
            type: types.POST_ZOHO_ACCESS_TOKEN
        });

        postZohoSubmit(JSON.stringify(data), module)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_ZOHO_SUBMIT,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_ZOHO_SUBMIT
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_ZOHO_SUBMIT
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_ZOHO_SUBMIT,
                    error: error
                });
            });
    };
}

//Order tracking details Submit
export function
    actOrderTracking(data) {
    return dispatch => {
        dispatch({
            type: types.POST_ORDER_TRACKING_SUBMIT
        });

        serOrderDetails(data)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Checkout your track");
                        this.history.push("/track-details");
                        return dispatch({
                            type: types.RECEIVE_ORDER_TRACKING_SUBMIT,
                            payload: response.data.order
                        });
                    } else {
                        toast.warning("Something went wrong");
                        return dispatch({
                            type: types.FAILED_ORDER_TRACKING_SUBMIT
                        });
                    }
                } else {
                    toast.warning("Something went wrong");
                    return dispatch({
                        type: types.FAILED_ORDER_TRACKING_SUBMIT
                    });
                }
            })
            .catch(error => {
                toast.warning("Something went wrong");
                return dispatch({
                    type: types.FAILED_ORDER_TRACKING_SUBMIT,
                    error: error
                });
            });
    };
}

//Order tracking for Login User
export function actOrderTrackingLogin() {
    return dispatch => {
        dispatch({
            type: types.POST_ORDER_TRACKING_SUBMIT
        });

        var POST = {
            user_id: sessionStorage.getItem("user_id"),
            user_type: sessionStorage.getItem("user_type")
        };

        serOrderDetails(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_ORDER_TRACKING_LOGIN,
                            payload: response.data.order
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_ORDER_TRACKING_LOGIN
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_ORDER_TRACKING_LOGIN
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_ORDER_TRACKING_LOGIN,
                    error: error
                });
            });
    };
}

//Products details for admin
export function actOrderProductDetails(order_id) {
    return dispatch => {
        dispatch({
            type: types.POST_ORDER_PRODUCTS
        });
        var POST = {
            user_type: "ADMIN",
            order_id: order_id
        };
        serOrderDetails(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_ORDER_PRODUCTS,
                            payload: response.data.order
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_ORDER_PRODUCTS
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_ORDER_PRODUCTS
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_ORDER_PRODUCTS,
                    error: error
                });
            });
    };
}

//Profile

export function actProfile(data) {
    return dispatch => {
        dispatch({
            type: types.POST_PROFILE
        });
        var POST = {
            user_id: sessionStorage.getItem("user_id")
        };

        serProfile(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_PROFILE,
                            payload: response.data.profile
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_PROFILE
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_PROFILE
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_PROFILE,
                    error: error
                });
            });
    };
}

//Profile Edit

export function actProfileEdit(data) {
    return dispatch => {
        dispatch({
            type: types.POST_PROFILE
        });

        serProfileEdit(data)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Profile Update Successfully")
                        return dispatch({
                            type: types.RECEIVE_PROFILE_EDIT,
                            payload: response.data.profile
                        });
                    } else {
                        toast.warn("Profile Update Failed")
                        return dispatch({
                            type: types.FAILED_PROFILE_EDIT
                        });
                    }
                } else {
                    toast.warn("Profile Update Failed")
                    return dispatch({
                        type: types.FAILED_PROFILE_EDIT
                    });
                }
            })
            .catch(error => {
                toast.warn("Profile Update Failed")
                return dispatch({
                    type: types.FAILED_PROFILE_EDIT,
                    error: error
                });
            });
    };
}

//Forgot Password

export function actForgotPassword(data) {
    return dispatch => {
        dispatch({
            type: types.POST_FORGOT_PASSWORD
        });

        serForgotPassword(data)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        toast.success("Forgot Password sent Successfully");
                        setTimeout(() => this.history.push("/login"), 1500);

                        return dispatch({
                            type: types.RECEIVE_FORGOT_PASSWORD,
                            payload: response.data
                        });
                    } else {
                        toast.warning("Sorry.., The User does not Exist");
                        return dispatch({
                            type: types.FAILED_FORGOT_PASSWORD
                        });
                    }
                } else {
                    toast.warning("Sorry.., The User does not Exist");
                    return dispatch({
                        type: types.FAILED_FORGOT_PASSWORD
                    });
                }
            })
            .catch(error => {
                toast.warn("Something went wrong..")
                return dispatch({
                    type: types.FAILED_FORGOT_PASSWORD,
                    error: error
                });
            });
    };
}



//My Orders list

export function actMyOrderList() {
    return dispatch => {
        dispatch({
            type: types.POST_MY_ORDER
        });
        var POST = {
            user_id: sessionStorage.getItem("user_id"),
            user_type: sessionStorage.getItem("user_type")
        };

        serMyOrderList(POST)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_MY_ORDER,
                            payload: response.data.order
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_MY_ORDER
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_MY_ORDER
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_MY_ORDER,
                    error: error
                });
            });
    };
}

//My Orders list

export function postUserId(id) {
    return dispatch => {
        dispatch({
            type: types.POST_ID
        });

        serUserId(id)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_ID,
                            payload: response.data.order
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_ID
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_ID
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_ID,
                    error: error
                });
            });
    };
}

//My Orders list

export function postUserLog(log) {
    return dispatch => {
        dispatch({
            type: types.POST_LOG
        });

        serUserLog(log)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.SUCCESS_LOG,
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_LOG
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_LOG
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_LOG,
                    error: error
                });
            });
    };
}

//Request-OTP

export function getReqOtp(post) {
    return dispatch => {
        dispatch({
            type: types.POST_REQUEST_OTP
        });
        serReqOtp(post, "verify/cod/number")
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_REQUEST_OTP,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.RECEIVE_REQUEST_OTP,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.ERROR_MSG,
                        payload: "NETWORK_ERROR"
                    })
                }
            })
            .catch(error => {
                console.error(error)
                return dispatch({
                    type: types.ERROR_MSG,
                    payload: "NETWORK_ERROR"
                })
            });
    };
}

//Request-Access OTP

export function getReqAccessOtp(number, credentials) {
    let post = {
        mobile_number: number,
        card_number: credentials
    }
    return dispatch => {
        dispatch({
            type: types.POST_REQUEST_OTP
        });
        serReqOtp(post, "giftcards/access/generate")
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.ACCESS_RECEIVE_REQUEST_OTP,
                            payload: response.data.access
                        });
                    } else {
                        return dispatch({
                            type: types.ACCESS_RECEIVE_REQUEST_OTP,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_REQUEST_OTP
                    });
                }
            })
            .catch(error => {
                console.error(error)
                return dispatch({
                    type: types.ERROR_MSG,
                    payload: "NETWORK_ERROR"
                })
            });
    };
}

//Verify-OTP

export function getVerifyOtp(post, url) {
    return dispatch => {
        dispatch({
            type: types.POST_VERIFY_OTP
        });

        serVerifyOtp(post, url)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_VERIFY_OTP,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.RECEIVE_VERIFY_OTP,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_VERIFY_OTP
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_VERIFY_OTP,
                    error: error
                });
            });
    };
}

//Verify-OTP

export function getAccessVerifyOtp(post, url) {
    return dispatch => {
        dispatch({
            type: types.POST_VERIFY_OTP
        });

        serVerifyOtp(post, url)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.ACCESS_RECEIVE_VERIFY_OTP,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.ACCESS_RECEIVE_VERIFY_OTP,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_VERIFY_OTP
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_VERIFY_OTP,
                    error: error
                });
            });
    };
}

//Aboutus-CMS

export function getCms(post) {
    return dispatch => {
        dispatch({
            type: types.POST_CMS
        });
        serCms(post)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_CMS,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_CMS
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_CMS
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CMS,
                    error: error
                });
            });
    };
}

export function timerAction(otpDetails, type) {
    let mode = "otp"
    let active = true

    if (otpDetails.reason == "WAIT_TIMEOUT") { mode = "number" }
    else { mode = "otp" }

    return dispatch => {
        timerService(active, otpDetails.server_resend_rate)
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: types.DECREMENT_TIME,
                        delay: otpDetails.server_resend_rate,
                        formHandler: mode
                    })
                }
            })
            .catch(error => {
                console.error("ERR: ", error)
            });
    }
}

export function timerAccessAction(otpDetails) {
    let mode = "otp"
    let active = true

    if (otpDetails.reason == "WAIT_TIMEOUT") { mode = "number" }
    else { mode = "otp" }

    return dispatch => {
        timerService(active, otpDetails.timer_rate)
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: types.DECREMENT_TIME,
                        delay: otpDetails.timer_rate,
                        formHandler: mode
                    })
                }
            })
            .catch(error => {
                console.error("ERR: ", error)
            });
    }
}

export function errorMsgAction(actionType, otpDetails = null) {
    return dispatch => {
        if (actionType == types.ERROR_MSG) {
            if (otpDetails.reason)
                dispatch({
                    type: types.ERROR_MSG,
                    payload: otpDetails.reason
                })
        } else
            actionCallbacks[actionType](dispatch)
    }
}

let actionCallbacks = {
    MSG_RESET: (dispatch) => {
        dispatch({
            type: types.ERROR_MSG,
            payload: types.MSG_RESET
        })
    },
    OTP_LOADER: (dispatch) => {
        dispatch({
            type: types.OTP_HANDLER,
            payload: { form: "loader", clearCounter: true }
        })
    },
    RESET_OTP: (dispatch) => {
        dispatch({
            type: types.OTP_HANDLER,
            payload: { form: "number", clearCounter: true }
        })
    },
    FORM_LOADER: (dispatch) => {
        dispatch({
            type: types.OTP_HANDLER,
            payload: { form: "loader", clearCounter: false }
        })
    },
    OTP_UNVERIFIED: (dispatch) => {
        dispatch({
            type: types.ERROR_MSG,
            payload: "OTP_UNVERIFIED"
        })
    },
    // NETWORK_ERROR: (dispatch) => {
    //     dispatch({
    //         type: types.ERROR_MSG,
    //         payload: "NETWORK_ERROR"
    //     })
    // }
}

export function createGiftCard(cardData) {
    return dispatch => {
        dispatch({
            type: types.POST_GIFT_CARD
        });
        serCreateGift(cardData)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECIEVE_GIFTCARD_CREATE_DETAILS,
                            payload: response.data
                        })
                    }
                    else
                        if (response.data.reason === "NO_CARD_FOUND") {
                            toast.warn("Failed to purchase No card found");
                        } else if (response.data.reason === "AMOUNT_RANGE_ERROR") {
                            toast.warn("Amount must be min of ₹1000 and max of ₹500000");
                        }
                        else if (response.data.reason === "VALIDATION_ERROR") {
                            toast.warn("Please re-check your details and try agin");
                        }
                        else {
                            toast.warn("Something went wrong. Please try again or contact our team");
                        }
                    return dispatch({
                        type: types.FAILED_GIFTCARD_CREATE_DETAILS
                    })
                }
                else
                    toast.warn("Failed to purchase");
                return dispatch({
                    type: types.FAILED_GIFTCARD_CREATE_DETAILS
                })
            })
            .catch(error => {
                toast.warn("Failed to purchase");
                return dispatch({
                    type: types.FAILED_GIFTCARD_CREATE_DETAILS,
                    error: error
                })
            })
    }
}

export function checkGiftBalance(cardData, token) {
    let post = {
        card_number: cardData,
        access_token: token
    }
    return dispatch => {
        dispatch({
            type: types.FETCH_GIFT_CARD_BALANCE
        });
        serCheckGiftBalance(post)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECIEVE_GIFTCARD_BALANCE,
                            payload: response.data.card
                        })
                    }
                    else
                        toast.warn("Something wrong in check your balance please re-try");
                    return dispatch({
                        type: types.FAILED_GIFTCARD_BALANCE
                    })
                }
                else
                    toast.warn("Something wrong in check your balance please re-try");
                return dispatch({
                    type: types.FAILED_GIFTCARD_BALANCE
                })
            })
            .catch(error => {
                toast.warn("Something wrong in check your balance please re-try");
                return dispatch({
                    type: types.FAILED_GIFTCARD_BALANCE,
                    error: error
                })
            })
    }
}

export function fetchTemplates() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GIFT_CARD_TEMPLATE
        });
        serFetchTemplate()
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECIEVE_GIFTCARD_TEMPLATE,
                            payload: response.data.templates
                        })
                    }
                    else
                        return dispatch({
                            type: types.FAILED_GIFTCARD_TEMPLATE
                        })
                }
                else
                    return dispatch({
                        type: types.FAILED_GIFTCARD_TEMPLATE
                    })
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_GIFTCARD_TEMPLATE,
                    error: error
                })
            })
    }
}

// validate checkout
export function validateCheckout() {
    return dispatch => {
        dispatch({
            type: types.POST_GIFT_CARD
        });
        serValidateCheckout()
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECIEVE_GIFTCARD_DETAILS,
                            payload: response.data
                        })
                    }
                    else
                        return dispatch({
                            type: types.FAILED_GIFTCARD_DETAILS
                        })
                }
                else
                    return dispatch({
                        type: types.FAILED_GIFTCARD_DETAILS
                    })
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_GIFTCARD_DETAILS,
                    error: error
                })
            })
    }
}

export function errorAccessMsgAction(actionType, otpDetails = null) {

    return dispatch => {
        if (actionType == types.ACCESS_ERROR_MSG) {
            if (otpDetails.reason)
                dispatch({
                    type: types.ACCESS_ERROR_MSG,
                    payload: otpDetails.reason
                })
        } else
            actionAccessCallbacks[actionType](dispatch)
    }
}

let actionAccessCallbacks = {
    ACCESS_MSG_RESET: (dispatch) => {
        dispatch({
            type: types.ACCESS_ERROR_MSG,
            payload: types.ACCESS_MSG_RESET
        })
    },
    ACCESS_OTP_LOADER: (dispatch) => {
        dispatch({
            type: types.ACCESS_OTP_HANDLER,
            payload: { form: "loader", clearCounter: true }
        })
    },
    ACCESS_RESET_OTP: (dispatch) => {
        dispatch({
            type: types.ACCESS_OTP_HANDLER,
            payload: { form: "number", clearCounter: true }
        })
    },
    ACCESS_FORM_LOADER: (dispatch) => {
        dispatch({
            type: types.ACCESS_OTP_HANDLER,
            payload: { form: "loader", clearCounter: false }
        })
    },
    ACCESS_OTP_UNVERIFIED: (dispatch) => {
        dispatch({
            type: types.ACCESS_ERROR_MSG,
            payload: "OTP_UNVERIFIED"
        })
    }
}


//PromoCode in PDP

export function getPromoCode() {
    return dispatch => {
        dispatch({
            type: types.POST_PROMO_CODE
        });

        serFetchPromoCode()
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_PROMO_CODE,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_PROMO_CODE,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_PROMO_CODE
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_PROMO_CODE,
                    error: error
                });
            });
    };
}

export function dfeSubmit(data) {
    return dispatch => {
        dispatch({
            type: types.POST_DFE
        });

        serSubmitDfe(data)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECEIVE_DFE,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_DFE,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_DFE
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_DFE,
                    error: error
                });
            });
    };
}

//customised product category

export function customisedProductCategory(data) {
    return dispatch => {
        dispatch({
            type: types.POST_CATEGORY_OPTIONS
        });

        postCategoryOptions(data)
            .then(response => {
                if (response.data) {
                    if (response.data.success === true) {
                        return dispatch({
                            type: types.RECIEVE_CATEGORY_PRODUCTS,
                            payload: response.data
                        });
                    } else {
                        return dispatch({
                            type: types.FAILED_CATEGORY_PRODUCTS,
                            payload: response.data
                        });
                    }
                } else {
                    return dispatch({
                        type: types.FAILED_CATEGORY_PRODUCTS
                    });
                }
            })
            .catch(error => {
                return dispatch({
                    type: types.FAILED_CATEGORY_PRODUCTS,
                    error: error
                });
            });
    };
}
