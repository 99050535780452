import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  DECREMENT_QTY,
  REMOVE_ALL_CART,
  WISHLIST_TO_CART,
  CART_UPDATED,
  RECIEVE_GIFTCARD_DETAILS,
  RECEIVE_PROMO,
  UPDATE_TO_SERVER,
  READY_UPDATE_TO_SERVER
} from "../constants/ActionTypes";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { addCartToServer } from "../actions";

const filtersReducerDefaultState = {
  cart: [],
  extended_cart: {
    syncing: false,
    list: []
  },
  gift_card: [{ id: 1, giftCardCode: "abcd", amount: 1000, expiry: "30/09/2022" }],
  promo_details: []
};


export default function cartReducer(
  state = filtersReducerDefaultState,
  action
) {
  switch (action.type) {
    case ADD_TO_CART:
      const productSku = action.product.sku;
      const variantId = action.variants.id
      if (state.cart.findIndex(product => product.variant.id === variantId) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.variant && product.variant.id === variantId) {
            if (action.product.made_to_order === "false") {
              if (product.qty < action.product.stock) {
                toast.success("Item Added to Cart");
                cartAcc.push({
                  ...product,
                  qty: product.qty + action.qty,
                  variant: action.variants,
                  sum: action.variants.offerprice * (product.qty + action.qty)
                });

              } else {
                toast.warn("Out of stock");
                cartAcc.push({
                  ...product
                });
              }
            } else {
              toast.success("Item Added to Cart");
              cartAcc.push({
                ...product,
                qty: product.qty + action.qty,
                variant: action.variants,
                sum: action.variants.offerprice * (product.qty + action.qty)
              });
            }
          } else {
            cartAcc.push(product);
          }
          return cartAcc;
        }, []);
        let new_state = { ...state, cart, extended_cart: { list: [], syncing: true } }
        return new_state;
      }
      toast.success("Item Added to Cart");

      return {
        ...state,
        extended_cart: { list: [], syncing: true },
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            variant: action.variants,
            sum: action.variants.offerprice * action.qty
          }
        ]
      };

    case READY_UPDATE_TO_SERVER:
      return {
        ...state, extended_cart: { list: [], syncing: true }
      };


    case UPDATE_TO_SERVER:
      return {
        ...state, extended_cart: { list: action.payload.cart, syncing: false }
      };

    case WISHLIST_TO_CART:
      const product_id = action.product.id;
      const variant_id = action.variants.id;
      if (state.cart.findIndex(product => product.variant.id === variant_id) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.variant.id === variant_id) {
            if (action.product.made_to_order === "false") {
              if (product.qty <= action.product.stock) {
                cartAcc.push({
                  ...product,
                  qty: product.qty + action.qty,
                  variant: action.variants,
                  sum: action.variants.offerprice * (product.qty + action.qty)
                });
              }
            } else {
              cartAcc.push({
                ...product,
                qty: product.qty + action.qty,
                variant: action.variants,
                sum: action.variants.offerprice * (product.qty + action.qty)
              });
            }
          } else {
            cartAcc.push(product);
          }
          return cartAcc;
        }, []);
        return { ...state, cart };
      }
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            variant: action.variants,
            sum: action.variants.offerprice * action.qty
          }
        ]
      };

    case DECREMENT_QTY:
      if (
        state.cart.findIndex(product => product.id === action.productId) !== -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === action.productId && product.qty > 0) {
            toast.error("Item Removed from Cart");
            cartAcc.push({
              ...product,
              qty: product.qty - 1,
              sum: product.selectedVariant.offerprice * (product.qty - 1)
            }); // Decrement qty
          } else {
            cartAcc.push(product);
          }
          if (product.id === action.productId && product.qty === 1) {
            return cartAcc.filter(item => item.id !== action.productId);
          }
          return cartAcc;
        }, []);
        return { ...state, cart };
      }
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: action.product.selectedVariant.offerprice * action.qty
          }
        ]
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(item => item.variant.id !== action.variant_id.id)
      };
    case REMOVE_ALL_CART:
      return {
        ...state,
        cart: []
      };
    case CART_UPDATED:
      return {
        ...state,
        cart: action.payload,
      };
    case RECIEVE_GIFTCARD_DETAILS:
      return {
        ...state,
        gift_card: action.payload
      };
    case RECEIVE_PROMO:
      return {
        ...state,
        promo_details: action.payload
      };
    default:
      return state;
  }
  return state;
}
