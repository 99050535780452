import { combineReducers } from 'redux';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

// Import custom components
import productReducer from './products';
import themeReducer from './theme';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import timerReducer from './timer';
import giftCardReducer from './gift-card'
import otpReducer from './otp'

const rootReducer = combineReducers({
    data: productReducer,
    theme: themeReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    timer: timerReducer,
    gift: giftCardReducer,
    otp: otpReducer,
    Intl
});

export default rootReducer;