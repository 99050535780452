import React from "react";
import useIdle from './useIdle';

const TimeOut = (props) => {
    const {isIdle} = useIdle({onIdle:props.logout,idleTime:300000})

    return (
        <>
            {/* <h4>{ isIdle ? 'Idle' : 'Active'}</h4> */}
            {props.msg !== null ? <h4>{isIdle ? 'Idle' : 'Active'}</h4> : ""}
        </>
    )
}

export default TimeOut;