import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getCareer, getDescription, getDescription1, getDescription2 } from "../../services";
import { connect } from "react-redux";
import DOMPurify from 'dompurify';
import Features from "../layouts/sunny-diamonds/features";
import SimpleReactValidator from "simple-react-validator";
import { postAllCareerCustomerDetails } from "../../actions";
import ReCAPTCHA from "react-google-recaptcha";

class ApplyForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
      country: "India",
      vacancy: "SHOWROOM MANAGER",
      description: "● Reporting and Documentation. <br/>    ● Team Management.  <br/>   ● Customer Service Standards.<br/>   ● Operations planning and Management. <br/>   ● Driving Branch Targets <br/> ",
      description1: "Graduate/ MBA ",
      description2: "● Graduate/MBA with 10-15 years handling a retail outlet independently and responsible for outlet targets.<br/>   ● Experience handling Marketing & Sales function in retail sector, preferably, luxury segment.",
      resume: "",
      portfolio: "",
      photograph: ""
    };
    this._reCaptchaRef = React.createRef();
    this.form = React.createRef(null);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  setStateFromInput = event => {
    var obj = {};
    if (event.target.name === 'resume' || event.target.name === 'portfolio' || event.target.name === 'photograph') {
      obj[event.target.name] = event.target.files[0];
    } else {
      obj[event.target.name] = event.target.value;
    }
    this.setState(obj);
  };


  getVacancy() {
    return this.props.job.map((item, i) => {
      return <option key={i} value={item.name}>{item.name}</option>
    });
  }


  handleDropdownChange(e) {
    this.setState({
      description: getDescription(this.props.job, e.target.value),
      description1: getDescription1(this.props.job, e.target.value),
      description2: getDescription2(this.props.job, e.target.value),
      vacancy: e.target.value
    });
  }

  onRecaptcha = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this._reCaptchaRef.current.executeAsync();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSubmit = (code) => {

    this.form.current.reset();

    const formData = new FormData();
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("address", this.state.address);
    formData.append("pincode", this.state.pincode);
    formData.append("city", this.state.city);
    formData.append("state", this.state.state);
    formData.append("country", this.state.country);
    formData.append("vacancy", this.state.vacancy);
    formData.append("resume", this.state.resume);
    formData.append("portfolio", this.state.portfolio);
    formData.append("photograph", this.state.photograph);

    if (code) {
      this.props.postAllCareerCustomerDetails(formData);
      this.validator.hideMessages();
      // this.props.postZohoDetailsSubmit(details,module);
      this.setState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        country: "India",
        vacancy: "SHOWROOM MANAGER",
        description: "● Reporting and Documentation. <br/>    ● Team Management.  <br/>   ● Customer Service Standards.<br/>   ● Operations planning and Management. <br/>   ● Driving Branch Targets <br/> ",
        description1: "Graduate/ MBA",
        description2: "● Graduate/MBA with 10-15 years handling a retail outlet independently and responsible for outlet targets.<br/>   ● Experience handling Marketing & Sales function in retail sector, preferably, luxury segment.",
      });
      this._reCaptchaRef.current.reset();
    }
  };

  render() {


    const { job } = this.props;
    const { description, description1, description2 } = this.state;
    return (
      <div>
        <Breadcrumb parent={"Careers"} title={"Apply Now"} />

        <div className="container">
          <div className="row border bg-white px-4 my-4">
            <div className="col-lg-12 col-md-12 col-sm-12 border-bottom">
              <h2 className="my-3 text-center">Job Application</h2>
              <p className="text-center">Please complete the form below to apply for a position with us.</p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 mx-2">
              <form
                className="theme-form"
                ref={this.form}
                onSubmit={this.onRecaptcha}
              >
                <h3>
                  <u>Personal Details</u>
                </h3>
                <div className="row pt-2">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="first_name"
                      placeholder="First Name"
                      value={this.state.first_name}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("First Name", this.state.first_name, "required")}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="last_name"
                      placeholder="Last Name"
                      value={this.state.last_name}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("Last Name", this.state.last_name, "required")}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="email"
                      className="form-control mb-2"
                      name="email"
                      placeholder="Email"
                      title="enter a valid mail id"
                      value={this.state.email}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("email", this.state.email, "required|email")}
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      label="please enter correct format"
                      title="Enter a valid phone number(10 digit number)"
                      type="tel"
                      className="form-control mb-2"
                      name="phone"
                      placeholder="Enter your Number"
                      value={this.state.phone}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("phone", this.state.phone, "required|phone|max:10")}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <textarea
                      className="form-control mb-2"
                      name="address"
                      placeholder="Address Line"
                      rows="1"
                      value={this.state.address}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message(
                      "address",
                      this.state.address,
                      "required|max:150"
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="city"
                      placeholder="City"
                      value={this.state.city}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("city", this.state.city, "required")}
                  </div>
                </div>
                <br />
                <br />
                <div className="row">

                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="state"
                      placeholder="State"
                      value={this.state.state}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("state", this.state.state, "required")}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <input
                      type="tel"
                      className="form-control mb-2"
                      placeholder="Pin Code"
                      name="pincode"
                      pattern="[0-9]{6}"
                      title="Six digit zip code"
                      value={this.state.pincode}
                      onChange={this.setStateFromInput}
                    />
                    {this.validator.message("pincode", this.state.pincode, "required|numeric|min:6|max:6")}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <select
                      name="country"
                      className="form-control"
                      value={this.state.country}
                      onChange={this.setStateFromInput}
                    >
                      <option>India</option>
                    </select>
                  </div>
                </div>

                {/* Choose Field */}
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 pt-4">
                    <h3>
                      <u>Choose Field</u>
                    </h3>
                    <select
                      name="vacancy"
                      className="form-control"
                      value={console.log(this.state.vacancy)}
                      onChange={this.handleDropdownChange.bind(this)}
                      // onClick={this.setStateFromInput}
                    >
                      {this.getVacancy()}

                    </select>
                    {this.validator.message(
                      "vacancy",
                      this.state.vacancy,
                      "required"
                    )}
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-lg-4 col-md-12 col-sm-12 pt-4">
                    <h4>Job Description</h4>
                    <p className="px-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}></p>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 pt-4">
                    <h4>Qualification</h4>
                    <p className="px-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description1) }}></p>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 pt-4">
                    <h4>Experience</h4>
                    <p className="px-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description2) }}></p>
                  </div>
                </div>
                <h3 className="mt-3">
                  <u>Attachments</u>
                </h3>
                <div className="row">
                  <div className="col-md-4 col-sm-12 pt-3">
                    <label htmlFor="resume">CV/Resume</label>
                    <input
                      type="file"
                      className="form-control"
                      name="resume"
                      placeholder="Upload file"
                      accept="application/pdf"
                      onChange={this.setStateFromInput}
                      onBlur={() => this.validator.showMessageFor('resume')}
                    />
                    {this.validator.message(
                      "File",
                      this.state.resume,
                      "required"
                    )}
                  </div>
                  <div className="col-md-4 pt-3">
                    <label htmlFor="resume">Portfolio</label>
                    <input
                      type="file"
                      className="form-control"
                      name="portfolio"
                      placeholder="Upload file"
                      accept="application/pdf"
                      // value={this.state.portfolio}
                      onChange={this.setStateFromInput}
                      onBlur={() => this.validator.showMessageFor('portfolio')}
                    />
                    {this.validator.message(
                      "File",
                      this.state.portfolio,
                      "required"
                    )}
                  </div>
                  <div className="col-md-4 pt-3">
                    <label htmlFor="resume">Applicant Photographs(Image size should be max-size: 3mb)</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photograph"
                      placeholder="Upload file"
                      accept="image/png, image/jpeg, image/jpg"
                      // value={this.state.photograph}
                      onChange={this.setStateFromInput}
                      onBlur={() => this.validator.showMessageFor('photograph')}
                    />
                    {this.validator.message(
                      "File",
                      this.state.photograph,
                      "required"
                    )}
                  </div>
                </div>

                <ReCAPTCHA
                  ref={this._reCaptchaRef}
                  size="invisible"
                  // sitekey="6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V"
                  sitekey={global.reCaptcha}
                  onChange={this.handleSubmit}
                />

                <div className="row my-4">
                  <div className="col-md-12 col-sm-12 text-center">
                    <button
                      className="btn btn-solid"
                      type="submit"
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*Sunny diamonds features section*/}
        <div className="mt-5 pt-2">
          <Features />
        </div>
        {/* end of sunny diamonds features section */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  job: getCareer(state.data.products, "job-description")
});

export default connect(
  mapStateToProps,
  { postAllCareerCustomerDetails }
)(ApplyForms);
