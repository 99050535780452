import React, { Component } from "react";

class PdpImages extends Component {
  render() {
    let imgeSrc = [];
    let vidSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];

    this.props.imageArr.forEach(function(img) {
      let ext = img.substring(img.lastIndexOf("."));
      if (exts.includes(ext)) {
        imgeSrc[imgI] = img;
        imgI++;
      } else {
        vidSrc[imgI] = img;
        imgI++;
      }
    });

    if (this.props.imageArr)
      return (
        <div className="row">
          {imgeSrc.map((image, i) => (
            <div className="col-md-6 col-sm-3 pdp_desk_image shadow-sm " key={i}>
              <img
                className="img-fluid blur-up lazyload image_category"
                src={`${process.env.PUBLIC_URL}` + image}
                alt="Ring"
                onError={e => {
                  e.target.src = `${
                    process.env.PUBLIC_URL
                  }/assets/images/sunny/no_image/no_images.webp`;
                }}
              />
            </div>
          ))}
          {vidSrc.map((video, i) =>
            video ? (
              <div className="col-md-6 col-sm-3 video pdp_desk_image shadow-sm" key={i}>
                <video
                  width="100%"
                  height="100%"
                  src={`${process.env.PUBLIC_URL}` + video}
                  loop="loop"
                  preload="none"
                  muted="muted"
                  autoPlay="autoplay"
                  onError={e => {
                    e.target.src = `${
                      process.env.PUBLIC_URL
                    }/assets/images/sunny/no_image/no_images.webp`;
                  }}
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>
      );
    else return null;
  }
}
export default PdpImages;
