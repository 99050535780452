import React, { Component } from "react";
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";

class CompareListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      inStock: "",
      quantity: 1,
      image: ""
    };
  }


  compareToWishlist = item =>{
    this.props.addToWishlist(item, item.selectedVariant)
  }

  addToCartList = item => {

    if (item.made_to_order == "false") {
      if (item.stock >= this.state.quantity) {

        this.setState({ quantity: this.state.quantity + 1 });
        this.props.addToCart(item, 1);
      } else {
        this.setState({ inStock: "Out of Stock !" });
      }
    } else {
      this.setState({ quantity: this.state.quantity + 1 });
      this.props.addToCart(item, 1, item.selectedVariant);
    }
  };

  CompareimgeSource = item => {
    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];
    if (item.selectedVariant.image) {
      let allImages = item.selectedVariant.image;
      images = allImages.split(",");
      images.forEach(function (img) {
        let ext = img.substring(img.lastIndexOf("."));

        if (exts.includes(ext)) {
          imgeSrc[imgI] = img;
          imgI++;
        }
      });
    }
    return imgeSrc[0];
  };

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  render() {
    const { item, index, symbol, removeFromCompare } = this.props;

    return (
      <div key={index}>
        <div className="compare-part bg-white">
          <button
            type="button"
            className="wishlist-btn"
            onClick={() => { this.compareToWishlist(item)}}
          >
            <i className="fa fa-heart text-danger" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="close-btn"
            onClick={() => removeFromCompare(item)}
          >
            <span aria-hidden="true" className="text-dark">
              ×
            </span>
          </button>
          <div className="img-secton">
            <Link to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}>
              <img
                src={`${process.env.PUBLIC_URL}` + this.CompareimgeSource(item)}
                className="img-fluid"
                alt={item.name}
                onError={e => {
                  e.target.src = `${process.env.PUBLIC_URL
                    }/assets/images/sunny/no_image/no_images.webp`;
                }}
              />
              <h4 className="text-center">{item.selectedVariant.alt}</h4>
            </Link>
            <h5 className="cart_sum text-center">
              {symbol}
              {item.selectedVariant.offerprice}

            </h5>
          </div>
          <div className="detail-part">
            <div className="title-detail">
              <h5>Description</h5>
            </div>
            <div className="inner-detail-description">
              <p className="d-flex justify-content-center">{item.selectedVariant.short_description ? item.selectedVariant.short_description : "-"}</p>
            </div>
          </div>
          <div className="detail-part">
            <div className="title-detail">
              <h5>Size</h5>
            </div>
            <div className="inner-detail">
              <p className="text-center">{item.selectedVariant.size ? item.selectedVariant.size : "-"}</p>
            </div>
          </div>
          <div className="detail-part">
            <div className="title-detail">
              <h5>Brand</h5>
            </div>
            <div className="inner-detail ">
              {/* <p className="text-center">{item.brand.replaceAll("_", " ")}</p> */}
             <p className="text-center">Sunny Diamonds</p>
            </div>
          </div>
          <div className="detail-part">
            <div className="title-detail">
              <h5>Color</h5>
            </div>
            <div className="inner-detail">
              <p className="text-center">{item.selectedVariant.color.replaceAll("-", " ")}</p>
            </div>
          </div>
          <div className="detail-part">
            <div className="title-detail">
              <h5>Stock</h5>
            </div>
            <div className="inner-detail">
              <p className="text-center">
                {item.made_to_order == "false" ? item.selectedVariant.stock - (this.state.quantity - 1) : "Made to order"}
              </p>
            </div>
          </div>
          <div className="btn-part">
            <a
              href="javascript:void(0)"
              className="btn btn-solid"
              onClick={() => this.addToCartList(item)}
            >
              add to cart
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default CompareListItem;
