import React, { useRef, useState } from "react";

import SimpleReactValidator from "simple-react-validator";

const PaymentDfe = (props) => {


  const validator = useRef(new SimpleReactValidator())
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const aboveAmount = () => {
    if (props.plan.amount >= 5000) {
      return (
        <div className="text-danger">
          <small>
            your monthly installment amount is ₹ 5,000 & above, PAN card is mandatory
          </small>
        </div>
      )
    }
    else {
      return null
    }
  }

  const handlePayment = () => {
    if (validator.current.allValid()) {
      props.handleSubmit()
    }
    else {
      validator.current.showMessages();
      forceUpdate()
    }
  }

  return (
    <>
      {/* Payment  */}
      < div className="container bg-white p-5 border">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row my-4">
              <h3><strong>Monthly Plan</strong></h3>
            </div>
            <div className="row">
              {/* <div className="col-12 p-0"> */}
              <label className=" form-label d-flex justify-content-between col-12 p-0">
                <span>Enter any amount<span style={{ color: "#EB5406" }}>*</span></span>
                <span>Min(₹1000)</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="amount"
                value={props.plan.amount}
                onChange={(e) => {
                  let amount = Number.parseInt(e.target.value)
                  props.handleAmountData(isNaN(amount) ? "" : amount)
                  validator.current.showMessageFor("amount")
                }} />
              {validator.current.message("amount", props.plan.amount, "required|numeric|min:0,num")}

              {/* </div> */}
            </div>
            <div className="row my-4">
              <label className="form-label my-2">Customers Usually Prefer</label>
              <div className="d-flex flex-row justify-content-around align-items-between mt-md-3 mt-0">
                <button
                  className="btn btn-dfe mx-2 my-lg-0 my-2"
                  onClick={() => { props.handleAmountData(10000) }}
                >
                  10,000
                </button>
                <button
                  className="btn btn-dfe mx-2 my-lg-0 my-2"
                  onClick={() => { props.handleAmountData(20000) }}
                >
                  20,000
                </button>
                <button
                  className="btn btn-dfe mx-2 my-lg-0 my-2"
                  onClick={() => { props.handleAmountData(30000) }}
                >
                  30,000
                </button>
              </div>
            </div>
            <div className="row my-2">
              <label className="form-label">ID Card Number & Photo<span style={{ color: "#EB5406" }}>*</span></label>
              <div className="col-12 p-0">
                <input
                  type="text"
                  className="form-control pb-2"
                  name='pan_card'
                  value={props.plan.pan_card}
                  onChange={props.handlePlanData}
                />
                {validator.current.message("pan_card", props.plan.pan_card, "required")}

              </div>
              <div className="col-12 mt-4 p-0">
                <input
                  type="file"
                  className="form-control"
                  name="pan_image"
                  capture="camera"
                  placeholder="Upload file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={props.handlePlanData}
                />
                {validator.current.message("pan_image", props.plan.pan_image, "required")}

              </div>
              {aboveAmount()}
            </div>
            Note:
            <small className="text-muted">
              Please Upload photo of your Valid PAN Card required for monthly installment of ₹ 5,000 & above
            </small>
            <div className="form-check py-3">
              <input
                className="form-check-input"
                type="checkbox"
                name="automate_payment"
                id="flexCheckDefault"
                onChange={props.handlePlanChange} />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Automate Monthly Payment
              </label>
            </div>

            Note:
            <small className="text-muted">
              By agreeing to this you understand,acknowledge and agree that Sunny Diamonds may engage third party process or gateway service provider to automate recurring payment every month. You may also change to manual payment at any point
            </small>

            <div className="row justify-content-center my-4">
              <small className="text-muted">
                By Clicking Next,I hearby acknowledge that the nominee is above 18 years old.
              </small>
            </div>

            <div className="row justify-content-center d-lg-block d-none">
              <button className="btn btn-dfe2" onClick={handlePayment}>Confirm Plan</button>
            </div>
          </div>


          <div className="mt-3 d-block d-lg-none">
            <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">
              <div className="col-md-12 col-6 px-1">
                <button className="btn btn-dfe2 w-100"
                  onClick={() => { props.handleIndexData(2) }}
                >
                  <div className="mx-3 ">Previous</div>
                </button>
              </div>
              <div className="col-md-12 col-6 px-1">
                <button className="btn btn-dfe3 w-100"
                  onClick={handlePayment}>
                  <div className="mx-4">Confirm Plan</div>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
export default PaymentDfe;