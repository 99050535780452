import React, { Component } from "react";
import { connect } from "react-redux";

import { getPolicy } from "../../services";

import WhyFromUS from "../../api/whyfromus.json";

import DOMPurify from 'dompurify';

import Seo from "../layouts/common/seo";
import Breadcrumb from "../common/breadcrumb";
import Sidebar from "../layouts/common/sidebar";
import Promise from "../new_home_page/promise";

class WhyFromUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { card, listPolicy } = this.props;

    let whyfromus = getPolicy(WhyFromUS, this.props.match.params.id);

    // if (card.length > 0) {
      return (
        <div>
          {/* seo section  */}
          {/* <Seo type={this.props.match.params.id} /> */}
          <Breadcrumb title={whyfromus[0].name} />

          <section className="section-b-space  blog-page">
            <div className="container">
              <div className="row">
                {/* Sidebar section  */}
                <Sidebar type={this.props.match.params.id} card="card-2" />

                <div className="col-xl-9 col-lg-8 col-md-7 ">
                  <div className="row blog-media">
                    <h1 className="pt-4 pl-2 title-normal">Sunny Diamonds Guarantee </h1>

                    {whyfromus.map((item, i) => (
                      <div key={i} className="col-xl-12 mb-4 policy-para">
                        <h2 className="para-one">{item.name}</h2>
                        <h6>
                          <b>{item.subName}</b>
                        </h6>
                        <p className="normal-para" dangerouslySetInnerHTML = {{__html: DOMPurify.sanitize(item.content)}}></p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Sunny diamonds features section*/}
          <div className="mt-5 pt-2">
            <Promise />
          </div>
          {/* end of sunny diamonds features section */}
        </div>
      );
    // } else return 0;
  }
}

// const mapStateToProps = state => ({
//   card: getPolicy(state.data.products, "card-2"),
//   listPolicy: getPolicy(state.data.products, "15-days-return-policy")
// });

// export default connect(mapStateToProps)(WhyFromUs);

export default (WhyFromUs);
