import React from "react";

const AdSpace = (props) => {
    return (
        <div className="select-your-favroute uk-height-medium uk-width-1-1 uk-inline">
            <div
                className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-visible@s"
                data-src={props.bannerImage}>
                <div className="uk-overlay uk-overlay-default uk-position-top-right uk-flex uk-flex-middle">
                    <h2 className="uk-width-1-1">Select Your Favorite <br />Style Of {props.categoryTitle}
                        <p className="like-text">#Like No Other</p>
                    </h2>
                </div>
            </div>
        </div >
    )
}
export default AdSpace