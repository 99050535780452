import React, { useEffect } from "react";

import { connect } from "react-redux";

const PreviewPay = (props) => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        try {
            window.dataLayer.push({
                event: "InitiateCheckout",
                ecommerce: {
                    currencyCode: "INR",
                    impressions: {
                        products: {
                            name: props.occasion.title,
                            price: props.amount,
                            brand: "Sunny Diamonds",
                            category: "Digital Gift Card",
                            quantity: 1,
                        },
                    },
                },
                value: props.amount,
            });
        }
        catch (err) {
            if (window.dataLayer.filter(function (obj) {
                return obj.errorMsg === err.message;
            }).length == 0) {
                window.dataLayer.push({
                    'event': 'variable error',
                    'errorMsg': err.message
                });
            }
        }
    }, []);

    const showDialog = () => {
        return (
            <>

                {props.amount ? (
                    <div>
                        <h4 className="card-preview-title-font text-center">
                            <strong>
                                <span className="text-lowercase"> e </span>-Gift card worth
                            </strong>
                        </h4>
                        <h1 className="card-preview-amount text-center">

                            ₹{props.amount}
                        </h1>
                        <hr className="hr-gift my-1"></hr>
                    </div>
                ) : (
                    ""
                )}
                {props.delivery.first_name ? (
                    <>
                        <h4 className="card-preview-title-font text-center">

                            <strong> For </strong>
                        </h4>
                        <h3 className="card-preview-font text-center mb-0">

                            {props.delivery.first_name}
                        </h3>
                        <h3 className="card-preview-font text-lowercase text-center">

                            {props.delivery.email}
                        </h3>
                    </>
                ) : (
                    ""
                )}
                {props.delivery.message ? (
                    <>
                        <h4 className="card-preview-title-font text-center">
                            <strong> Message </strong>
                        </h4>
                        <h3 className=" card-preview-font text-center mx-3">

                            “{props.delivery.message}”
                        </h3>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    return (
        <div className="container gift-index-active mt-3 mt-lg-0 px-lg-5">
            <div className="row px-lg-5 px-0 ">
                <div className="col-md-6 col-12 d-flex justify-content-center justify-content-md-start p-md-2 p-xl-5">

                    {props.occasion.picture ? (
                        <img
                            src={`${process.env.PUBLIC_URL}${props.occasion.picture}`}
                            className="img-fluid card-type px-2"
                            style={{
                                width: "100%",
                            }}
                        />
                    ) : (
                        <p className="text-center">
                            OOPS!!!Please select a card to preview
                        </p>
                    )}
                </div>
                <div className="col-md-6 col-12 d-flex justify-content-center justify-content-md-start p-md-2 p-xl-5 mt-2 mt-md-0">
                    <div className="maintxt">

                        {props.occasion.picture ? (
                            <>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/sunny/Gift_card/Cards/Back_side.png`}
                                    alt="Sunny diamonds"
                                    className="img-fluid card-type px-2"
                                    style={{
                                        width: "100%",
                                    }}
                                />
                                <div className="overlay-text px-3"> {showDialog()} </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    otp_req: state.data.otp_request,
    otp_verify: state.data.otp_verify,
    verification: state.timer.msgFlag,
});

export default connect(mapStateToProps)(PreviewPay);
