import React, { useState } from "react";
import SDClient from "../../../sd_core/client";
import RedeemGiftCard from "./redeem-giftCard";
import Loader from "../../common/spinner"


const Coupons = (props) => {


  const [discountCode, setDiscountCode] = useState("");
  const [validationTrace, setValidationTrace] = useState({});
  const [applyLoading, setApplyLoading] = useState(false);

  const applyDiscountCode = () => {
    setApplyLoading(true);
    SDClient.endpoint("virtual_order_set_discount_code", { 'code': discountCode }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({
            APPLY_OK: true
          });
          props.updateVO();
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          } else if (data.message.reason == "DISCOUNT_COUPON_NOT_FOUND") {
            setValidationTrace({ DISCOUNT_COUPON_NOT_FOUND: true });
          }
        }
        setApplyLoading(false);
      },
      console.log
    )
  }

  const clearDiscountCode = () => {
    setApplyLoading(true);
    SDClient.endpoint("virtual_order_clear_discount_code").then(
      (data) => {
        setValidationTrace({});
        setApplyLoading(false);
        props.updateVO();
      },
      console.log
    )
  }

  const promoCode = () => {
    return (
      <div className="mb-3">
        <div className="field-label">Promo code</div>
        <div className="d-flex flex-row">
          <input
            type="text"
            name="promocodes"
            className="form-control"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <button
            type="button"
            className="btn btn-promo-code"
            onClick={() => applyDiscountCode()}>
            Apply
          </button>
        </div>
        {validationTrace.DISCOUNT_COUPON_NOT_FOUND ? (<small className="text-uppercase text-danger"> This promocode is not a valid one, please check again. </small>) : null}
        {validationTrace.APPLY_OK ? (<small className="text-uppercase text-success"> Promocode has been successfully applied </small>) : null}
        {validationTrace.code ? (<small className="text-uppercase text-danger"> Please specify a valid promocode. </small>) : null}
      </div>
    )
  }

  const appliedPromoCode = () => {
    if (props.VOData.order.applied_payment_discount) {
      const promoline = () => {
        if (props.VOData.order.applied_payment_discount.virtual_order_discount_type == "PERCENTAGE") {
          return "A discount of " + props.VOData.order.applied_payment_discount.virtual_order_discount_relative_value + "% has been applied."
        } else if (props.VOData.order.applied_payment_discount.virtual_order_discount_type == "FLAT") {
          return "A flat discount of " + props.VOData.order.applied_payment_discount.virtual_order_discount_relative_value + " has been applied."
        } else {
        }
      }
      return (
        <>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <p className="text-secondary text-uppercase">Applied Discount Code</p>
            <div className="mt-1">
              {props.syncing ? (<Loader />) : ""}
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            <div className="m-0">
              {props.VOData.order.applied_payment_discount.virtual_order_discount_name}
              <br />
              <small className="text-success">
                {promoline()}
              </small>
            </div>
            <button className="btn btn-promo-code" onClick={clearDiscountCode}>
              <small>REMOVE</small>
            </button>
          </div>
        </>
      )
    }
  }

  const loadPromocodes = () => {
    if (!props.syncing) {
      return appliedPromoCode();
    } else {
      return (
        <>
          <hr></hr>
          <div className="py-2">
            <p className="placeholder col-9"></p>
            <p className="placeholder col-8"></p>
          </div>
        </>
      )
    }
  }

  const giftCard = () => {
    return (
      <RedeemGiftCard
        handleChange={props.handleChange}
        checkoutSync={props.checkoutSync}
        discountDetails={props.discountDetails}
        symbol={props.symbol}
      />
    )
  }

  return (
    <div className="bg-white border p-4 mb-4">
      {props.discountDetails && props.discountDetails.errors && props.discountDetails.errors
        .map((error, i) => (
          < div className="alert alert-danger" role="alert">
            {error.text}
          </div>
        ))
      }
      <div className="d-flex justify-content-between">
        <p className="text-secondary text-uppercase">Coupon Code</p>
        <div className="mt-1">
          {applyLoading ? (<Loader />) : ""}
        </div>
      </div>
      {promoCode()}

      {loadPromocodes()}
      {/* {giftCard()} */}
      {/* <div className="d-flex justify-content-between ">
                    <p className="mb-0">Giftcard Discount:</p>
                    <p className="mb-0">
                        {props.symbol}0.00
                    </p>
                </div> */}
    </div>
  )
}

export default Coupons

