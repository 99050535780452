import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import Features from "../../layouts/sunny-diamonds/features";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import DOMPurify from 'dompurify';
import edu from "../../../api/education.json";
import RingSizeChart from "./ring-size-chart";
import BangleSizeChart from "./bangle-size-chart";
import EducationSidePanel from "./side-panel";
import './education.css';
import EducationMobileSidePanel from "./mobile-side-panel";
import Promise from "../../new_home_page/promise";
import { element } from "prop-types";
// import UIkit from "uikit";

class Education extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "loose-diamonds",
      mainMenu: "4cs",
      subMenu: "cut",
      seo: []
    };
  }

  componentDidMount() {
    this.setState({
      type: this.props.match.params.id1,
      mainMenu: this.props.match.params.id2,
      subMenu: this.props.match.params.id3
    });

    this.setState({
      seo: edu
        .filter(data => data.category.value === this.state.type)[0]
        .category.menu.filter(data => data.value === this.state.mainMenu)[0].seo
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        type: this.props.match.params.id1,
        mainMenu: this.props.match.params.id2,
        subMenu: this.props.match.params.id3
      });
    }
    // console.log("Educational page update");
    window.UIkit.update()
  }

  onClickTypeHandler = (item) => {
    if (item.category.menu[0].sub) {
      this.setState({
        type: item.category.value,
        mainMenu: item.category.menu[0].value,
        subMenu: item.category.menu[0].submenu[0].value
      });
      this.props.history.push(
        "/education/" +
        item.category.value +
        "/" +
        item.category.menu[0].value +
        "/" +
        item.category.menu[0].submenu[0].value
      );
    } else {
      this.setState({
        type: item.category.value,
        mainMenu: item.category.menu[0].value
      });
      this.props.history.push(
        "/education/" + item.category.value + "/" + item.category.menu[0].value
      );
    }
  }

  onClickmainMenuHandler(item) {
    if (item.sub) {
      this.setState({
        mainMenu: item.value,
        subMenu: item.submenu[0].value
      });
      this.props.history.push(
        "/education/" +
        this.state.type +
        "/" +
        item.value +
        "/" +
        item.submenu[0].value
      );
    } else {
      this.setState({ mainMenu: item.value });
      this.props.history.push(
        "/education/" + this.state.type + "/" + item.value
      );
    }
  }

  onClicksubMenuHandler(item) {
    this.setState({ subMenu: item.value });
    this.props.history.push(
      "/education/" +
      this.state.type +
      "/" +
      this.state.mainMenu +
      "/" +
      item.value
    );
  }

  render() {

    const { seo, type, mainMenu, subMenu } = this.state;

    return (
      <div>
        {/* Seo section  */}

        <div>
          {seo ? (
            <Helmet>
              <title>{seo.title}</title>
              <meta name="description" content={seo.description} />
              <meta name="keywords" content={seo.keyWords} />
              <meta property="og:site_name" content="Sunny Diamonds" />
              <meta property="og:type" content="Summary" />
              <meta property="og:title" content={seo.title} />
              <meta property="og:description" content={seo.description} />
              <meta property="og:url" content={window.location.href} />
              <meta name="twitter:card" content="Summary" />
              <meta name="twitter:site" content="@SunnyDiamond_4c" />
              <meta name="twitter:creator" content="Sunny Diamonds" />
              <meta name="twitter:title" content={seo.title} />
              <meta name="twitter:description" content={seo.description} />
              <meta name="robots" content={seo.robots} />
            </Helmet>
          ) : (
            ""
          )}
        </div>

        <Breadcrumb title={this.state.type} />

        {/* <div className="container">
          <div className="row" />
        </div> */}

        {/*Education Details section*/}

        <section className="section-b-space blog-page">
          <div className="uk-section sd-inner-content uk-padding-small">
            <div className="uk-container uk-container-large">



              <div data-uk-grid>

                <EducationMobileSidePanel
                  edu={edu}
                  type={type}
                  onClickTypeHandler={this.onClickTypeHandler}
                />


                <EducationSidePanel
                  edu={edu}
                  type={type}
                  onClickTypeHandler={this.onClickTypeHandler}
                />

                {/* menu section  */}
                <div className="uk-width-expand@m sd-accordion  side-bar-listing">
                  <div className="uk-margin-medium-top">
                    <ul className="uk-flex-auto" data-uk-tab>
                      {edu
                        .filter(
                          data => data.category.value === this.state.type)[0]
                        .category.menu.map((item, i) => (
                          <li
                            key={i}
                            className={
                              item.value === this.state.mainMenu ?
                                item.value != "birthstones" ? "edu-first-tab-active px-2 cursor-pointer uk-margin-auto" : "edu-first-tab-active px-2 cursor-pointer"
                                : "px-2 cursor-pointer uk-margin-auto"
                            }
                            onClick={() => this.onClickmainMenuHandler(item)}
                          >
                            <p className="normal-para">{item.label}</p>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {edu
                    .filter(data => data.category.value === this.state.type)[0]
                    .category.menu.filter(
                      data => data.value === this.state.mainMenu
                    )[0].sub === true ? (
                    <ul className="uk-flex-auto" data-uk-tab>
                      {edu
                        .filter(
                          data => data.category.value === this.state.type
                        )[0]
                        .category.menu.filter(
                          data => data.value === this.state.mainMenu
                        )[0]
                        .submenu.map((item, i) => (
                          <p
                            key={i}
                            className={
                              item.value === this.state.subMenu
                                ? "edu-second-tab-active px-2 cursor-pointer normal-para uk-margin-auto"
                                : "px-2 cursor-pointer normal-para uk-margin-auto"
                            }
                            onClick={() => this.onClicksubMenuHandler(item)}
                          >
                            {item.label}
                          </p>
                        ))}
                    </ul>
                  ) : (
                    ""
                  )}

                  {/* content section  */}

                  {edu
                    .filter(data => data.category.value === this.state.type)[0]
                    .category.menu.filter(
                      data => data.value === this.state.mainMenu
                    )[0].sub === true ? (
                    <div className="row">
                      {edu
                        .filter(
                          data => data.category.value === this.state.type
                        )[0]
                        .category.menu.filter(
                          data => data.value === this.state.mainMenu
                        )[0]
                        .submenu.filter(data => data.value === this.state.subMenu)
                        .map((item, i) => (
                          <div key={uuidv4()}>
                            {item.layout === "two_col"
                              ? item.data.map((item, i) => (
                                <div key={uuidv4()} className="row blog-media">
                                  <div className="col-xl-4 mt-4">
                                    <div className="uk-cover-container">
                                      {item.media === "Image" ? (
                                        <img
                                          src={
                                            `${process.env.PUBLIC_URL}` +
                                            item.src
                                          }

                                          className={
                                            item.dark === true
                                              ? "img-fluid dark-image"
                                              : "img-fluid"
                                          }
                                          alt="sunny diamonds"
                                        />
                                      ) : item.media === "Video" ? (
                                        <video
                                          width="100%"
                                          height="100%"
                                          src={
                                            `${process.env.PUBLIC_URL}` +
                                            item.src
                                          }
                                          type="video/mp4"
                                          loop="loop"
                                          preload="none"
                                          muted="muted"
                                          autoPlay="autoplay"
                                          playsInline="playsinline"
                                          data-uk-video
                                        />
                                        // <video
                                        //   width="100%"
                                        //   height="100%"
                                        //   loop="loop"
                                        //   preload="none"
                                        //   muted="muted"
                                        //   autoPlay="autoplay"
                                        //   playsInline="playsinline"
                                        //   src={
                                        //     `${process.env.PUBLIC_URL}` +
                                        //     item.src
                                        //   }
                                        // >
                                        //   <source src={
                                        //     `${process.env.PUBLIC_URL}` +
                                        //     item.src
                                        //   }
                                        //   type="video/mp4">
                                        //   </source>
                                        // </video>
                                      ) : item.media === "multi" ? (
                                        <>
                                          <div className="row">
                                            <img
                                              src={
                                                `${process.env.PUBLIC_URL}` +
                                                item.src
                                              }

                                              className={
                                                item.dark === true
                                                  ? "img-fluid dark-image"
                                                  : "img-fluid"
                                              }
                                              alt="sunny diamonds"
                                            />
                                          </div>
                                          <div className="row">
                                            <video
                                              width="100%"
                                              height="100%"
                                              src={
                                                `${process.env.PUBLIC_URL}` +
                                                item.src
                                              }
                                              type="video/mp4"
                                              loop="loop"
                                              muted="muted"
                                              preload="metadata"
                                              autoPlay="autoplay"
                                              playsInline="playsinline"
                                              data-uk-video
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-xl-8 mt-4 ">
                                    <div className="blog-right">
                                      <div>
                                        {/* {React.createElement(
                                          item.tag,
                                          null,
                                          item.heading
                                        )} */}
                                        <h2 className="title-normal  uk-heading-divider">{item.heading}</h2>
                                        {item.content.length > 0 ?
                                          (
                                            <p className="normal-para uk-padding-small" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}></p>
                                          ) : "1"
                                        }
                                        {item.link.length > 0 ? (
                                          <div className="pt-3">
                                            <a href={item.url}>{item.link}</a>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                              : item.layout === "one_col"
                                ? item.data.map((item, i) => (
                                  <div key={uuidv4()} className="row blog-media mb-0">
                                    <div className="col-xl-12 mt-4">
                                      <div className="blog-right">
                                        <div>
                                          {/* {React.createElement(
                                            item.tag,
                                            null,
                                            item.heading
                                          )} */}
                                          <h2 className="title-normal  uk-heading-divider">{item.heading}</h2>
                                          {item.content.length > 0 ? (
                                            <p className="normal-para uk-padding-small" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}></p>
                                          ) : "2"}
                                          {item.link.length > 0 ? (
                                            <div className="pt-3">
                                              <a href={item.url}>{item.link}</a>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                                : ""}
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="row">
                      {edu
                        .filter(
                          data => data.category.value === this.state.type
                        )[0]
                        .category.menu.filter(
                          data => data.value === this.state.mainMenu
                        )
                        .map((item, i) => (
                          <div key={uuidv4()}>
                            {item.layout === "two_col" ? (
                              item.data.map((item, i) => (
                                <div key={uuidv4()} className="row blog-media">
                                  { }
                                  <div className="col-xl-4 mt-4">
                                    <div className="blog-left">
                                      {item.media === "Image" ? (
                                        <img
                                          src={
                                            `${process.env.PUBLIC_URL}` + item.src
                                          }

                                          className={
                                            item.dark === true
                                              ? "img-fluid dark-image"
                                              : "img-fluid"
                                          }
                                          alt="sunny diamonds"
                                        />
                                      ) : item.media === "Video" ? (
                                        <video
                                          width="100%"
                                          height="100%"
                                          src={
                                            `${process.env.PUBLIC_URL}` + item.src
                                          }
                                          type="video/mp4"
                                          loop="loop"
                                          preload="none"
                                          muted="muted"
                                          autoPlay="autoplay"
                                          playsInline="playsinline"
                                          data-uk-video
                                        />
                                      ) : item.media === "multi" ? (
                                        <>
                                          <div className="row">
                                            <img
                                              src={
                                                `${process.env.PUBLIC_URL}` +
                                                item.src
                                              }

                                              className={
                                                item.dark === true
                                                  ? "img-fluid dark-image"
                                                  : "img-fluid"
                                              }
                                              alt="sunny diamonds"
                                            />
                                          </div>
                                          <div className="row">
                                            <video
                                              width="100%"
                                              height="100%"
                                              src={
                                                `${process.env.PUBLIC_URL}` +
                                                item.src
                                              }
                                              type="video/mp4"
                                              loop="loop"
                                              muted="muted"
                                              preload="metadata"
                                              autoPlay="autoplay"
                                              playsInline="playsinline"
                                              data-uk-video
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-xl-8 mt-4">
                                    <div className="blog-right">
                                      <div>
                                        {/* {React.createElement(
                                          item.tag,
                                          null,
                                          item.heading
                                        )} */}
                                        <h2 className="title-normal  uk-heading-divider">{item.heading}</h2>
                                        {item.content.length > 0 ? (
                                          <p className="normal-para uk-padding-small" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}></p>
                                        ) : "3"}
                                        {item.link.length > 0 ? (
                                          <div className="pt-3">
                                            <a href={item.url}>{item.link}</a>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : item.value === "ring-size-guide" ? (
                              <RingSizeChart />
                            ) : item.value ===
                              "bangle-and-bracelet-size-guide" ? (
                              <BangleSizeChart />
                            ) : (
                              item.data.map((item, i) => (
                                <div key={uuidv4()} className="row blog-media">
                                  <div className="col-xl-12 mt-4">
                                    <div className="blog-right">
                                      <div>
                                        {/* {React.createElement(
                                          item.tag,
                                          null,
                                          item.heading
                                        )} */}
                                        <h2 className="title-normal  uk-heading-divider">{item.heading}</h2>
                                        {item.content.length > 0 ? (
                                          <p className="normal-para uk-padding-small" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}></p>
                                        ) : "4"}
                                        {item.link.length > 0 ? (
                                          <div className="pt-3">
                                            <a href={item.url}>{item.link}</a>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section >
        {/*Sunny diamonds features section*/}
        < div className="mt-5 pt-2" >
          <Promise />
        </div >
        {/* end of sunny diamonds features section */}
      </div >
    );
  }
}

export default Education;
