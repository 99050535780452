import React, { Component } from "react";
import { connect } from "react-redux";
import { filterSort } from "../../../actions";
import { getCategoryProducts } from "../../../services";

class FilterBar extends Component {

  // Layout Column View
  LayoutView = colSize => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".infinite-scroll-component .row")
        .childNodes;
      [].forEach.call(elems, function (el) {
        el.className = "";
        el.classList.add("col-lg-" + colSize);
      });
    }

    this.props.onLayoutViewClicked(colSize);
  };

  handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 330) {
      if (window.innerWidth < 576)
        document.getElementById("sticky-filter").classList.remove('fixed');
      else
        document.getElementById("sticky-filter").classList.add('fixed');
    }
    else
      document.getElementById("sticky-filter").classList.remove('fixed');

  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { products } = this.props;
    if (products !== undefined)
      return (
        <div id="sticky-filter" className="product-filter-content sticky-filter">
          <div className="search-count d-none d-md-block">
            <h5>Showing Products 1-{this.props.products.length} Result</h5>
          </div>
          <div className="collection-view">
            <ul>
              <li>
                <i
                  className="fa fa-th grid-layout-view"
                  onClick={this.props.gridLayout}
                />
              </li>
              <li>
                <i
                  className="fa fa-list-ul list-layout-view"
                  onClick={this.props.listLayout}
                />
              </li>
            </ul>
          </div>
          <div className="collection-grid-view">
            <ul>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                  alt="layout6"
                  className="product-2-layout-view"
                  onClick={() => this.LayoutView(6)}
                />
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                  alt="layout4"
                  className="product-3-layout-view"
                  onClick={() => this.LayoutView(4)}
                />
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                  alt="layout3"
                  className="product-4-layout-view"
                  onClick={() => this.LayoutView(3)}
                />
              </li>
              <li>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                  alt="layout2"
                  className="product-6-layout-view"
                  onClick={() => this.LayoutView(2)}
                />
              </li>
            </ul>
          </div>
          <div className="product-page-filter d-none d-md-block">
            <select onChange={e => this.props.filterSort(e.target.value)}>
              <option value="LowToHigh">Price: Low to High</option>
              <option value="HighToLow">Price: High to Low</option>
              <option value="AscOrder">Sort By Name: A To Z</option>
              <option value="DescOrder">Sort By Name: Z To A</option>
              <option value="Newest">Newest Items</option>
            </select>
          </div>
        </div>
      );
    else return ''
  }
}

const mapStateToProps = state => ({
  products: getCategoryProducts(state.data.category.products, state.filters),
});

export default connect(
  mapStateToProps, { filterSort }
)(FilterBar);
