import React, { Component } from "react";

import { getContact, getCategory } from "../../services/index";

import { connect } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";

import Seo from "../layouts/common/seo";
import Breadcrumb from "../common/breadcrumb";
import Banner from "../layouts/common/banner";
import Promise from "../new_home_page/promise";


const StoreLoc = (props) => {

  const handleDropdownChange = (e) => {
    props.history.push("../locations/" + e.target.value);
  }

  return (

    <div>
      {/* seo section  */}
      <Seo type="store-head" />

      <Breadcrumb title={props.head[0].banner.title} />

      <section className=" contact-page career-page">
        <div className="container">
          <div className="row">
            {/* banner section  */}
            <Banner type="store-head" move="false" title_light={true} />

            <div className="col-sm-12 store-locate">
              <div className="row mt-4 pt-4 ">
                <div className="col-md-4  col-sm-12 ">
                  <label htmlFor="subject">Select City</label>
                  <select
                    id="dropdown"
                    className="form-control"
                    onChange={handleDropdownChange}
                  >
                    <option value="city">Select a city</option>
                    <option value="calicut">Calicut</option>
                    <option value="kochi">Kochi</option>
                    <option value="nagercoil">Nagercoil</option>
                    <option value="thrissur">Thrissur</option>
                    <option value="trivandrum">Trivandrum</option>
                  </select>
                </div>
              </div>

              <div className="contact-right">
                <iframe src="https://www.google.com/maps/d/embed?mid=1dWvfs9Ux9xzMAZ9YEqFEcR7iV2PjK8GY&ehbc=2E312F&zoom=50" width="100%" height="700"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/*Sunny diamonds features section*/}
      < div className="mt-5 pt-2" >
        <Promise />
      </div >
      {/* end of sunny diamonds features section */}
    </div >
  );

}

const mapStateToProps = state => ({
  store: getContact(state.data.products, "All"),
  head: getCategory(state.data.products, "store-head")
});

const connector = connect(mapStateToProps)(StoreLoc);
export default GoogleApiWrapper({
  apiKey: ""
})(connector);
