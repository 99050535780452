import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Dropdown from "./dropdown";
import MultiRangeSlider from "./range-slider";

import store from "../../../../store";
import { getMinMaxPrice } from "../../../../services";
import { filterColor, filterPrice, filterBrand, filterSort } from "../../../../actions";

import "./filter.css"

const Filter = (props) => {

    const { prices, filters, filterColor, filterPrice, filterBrand, filterSort } = props

    // Reset all filters

    const resetFilters = () => {
        let phKeys = Object.keys(filters.placeholder);
        phKeys.map((phKey, i) => {
            store.dispatch({ type: "FILTER_CAT_NULL", placeholder: phKey });
        });
        store.dispatch({ type: "FILTER_COLOR", color: "" })
    }

    const urlFiltering = () => {

        const queryParams = new URLSearchParams(window.location.search);
        const urlFilterList = queryParams.keys()
        for (let option of urlFilterList) {

            // price range

            if (option == 'max_price' || option == 'min_price') {
                filterPrice({
                    value: {
                        min: queryParams.get('min_price') && queryParams.get('min_price') < queryParams.get('max_price') ?
                            parseInt(queryParams.get('min_price')) : prices.min,
                        max: queryParams.get('max_price') && queryParams.get('min_price') < queryParams.get('max_price') ?
                            parseInt(queryParams.get('max_price')) : prices.max
                    }
                })
            }

            // metal color

            else if (option == 'metal_color') {
                let list = window.filterOption.filters.filter(item => item.value == "color")[0].list
                    .map((item) => (item.value == queryParams.get('metal_color')))
                if (list.includes(true))
                    filterColor(queryParams.get('metal_color'))
                else {
                    queryParams.delete(option);
                    window.history.replaceState({}, "", window.location.pathname + "?" + queryParams.toString());
                }
            }

            // sorting

            else if (option == 'sort_by') {
                let list = window.filterOption.sortings
                    .map((item) => (item.value == queryParams.get('sort_by')))
                if (list.includes(true))
                    filterSort(queryParams.get('sort_by'))
                else {
                    queryParams.delete(option);
                    window.history.replaceState({}, "", window.location.pathname + "?" + queryParams.toString());
                }
            }

            // checkbox items

            else if (window.filterOption.checkboxList.includes(option)) {
                let list = window.filterOption.filters.filter(item => item.value == option)[0].list
                    .map((item) => (item.value == queryParams.get(option)))
                if (list.includes(true))
                    filterBrand([queryParams.get(option)], option);
                else {
                    queryParams.delete(option);
                    window.history.replaceState({}, "", window.location.pathname + "?" + queryParams.toString());
                }
            }

            // null

            else {
                queryParams.delete(option);
                window.history.replaceState({}, "", window.location.pathname + "?" + queryParams.toString());
            }
        }
    }

    useEffect(() => {
        resetFilters()
        urlFiltering()
    }, [])

    // Filtering list options

    const listHandle = (event, option) => {
        if (event.target.classList.contains("active")) {
            event.target.classList.remove("active");
            filterColor();
            // urlParameterHandler("metal_color", option, "remove")
        } else {
            var elems = document.querySelectorAll(".color-button button");
            [].forEach.call(elems, function (el) {
                el.classList.remove("active");
            });
            event.target.classList.add("active");
            filterColor(option);
            // urlParameterHandler("metal_color", option, "replace")
        }
    }

    // const urlParameterHandler = (parameter, value, mode) => {

    //     const urlString = queryParams.toString()
    //     let updatedString = urlString
    //     if (mode = "replace") {
    //         updatedString = updatedString + ("&" + parameter + "=" + value)
    //         window.location.href = window.location.pathname + '?' + updatedString
    //         // props.history.push(window.location.pathname + '?' + updatedString)
    //     }
    //     else if (mode = "remove")
    //         updatedString = updatedString - ("&" + parameter + "=" + value)
    //     else
    //         updatedString = updatedString.concat("&", parameter, "=", value)
    // }

    // Clear filter inputs

    const clear = () => {
        resetFilters();
        var w = document.getElementsByTagName("input");
        for (var i = 0; i < w.length; i++) {
            if (w[i].type == "checkbox") {
                w[i].checked = false;
            }
        }
        var elems = document.querySelectorAll(".color-button button");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
    }

    const handleListOption = (element, i) => {
        return (
            <li key={i}>
                <a className="uk-accordion-title" href="#">{element.name}</a>
                <div className="uk-accordion-content">
                    <div className="color-button uk-flex uk-display-inline">
                        {element.list.map((list, i) => (
                            <button
                                key={i}
                                className={list.type}
                                onClick={e => listHandle(e, list.value, (i + 1))}
                            />
                        ))}
                    </div>
                </div>
            </li>
        )
    }

    const handleDropdownOption = (element, i) => {
        return (
            <li key={i}>
                <a className="uk-accordion-title" href="#">{element.name}</a>
                <div className="uk-accordion-content">
                    <form>
                        <fieldset className="uk-fieldset">
                            <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <Dropdown
                                    element={element}
                                    placeholder={element.value}
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </li>
        )
    }

    const handleRangeOption = (element, i) => {
        return (
            <li key={i} className="uk-open">
                <a className="uk-accordion-title" href="#">{element.name}</a>
                <div className="uk-accordion-content">
                    <div className="d-flex justify-content-center py-3">
                        <MultiRangeSlider
                            min={prices.min}
                            max={prices.max}
                            value={filters.value}
                            // value={rangeHandler}
                            onChange={value => filterPrice({ value })}
                        />
                    </div>
                    <hr className="uk-margin-large" />
                </div>
            </li>
        )
    }

    return (
        <div>

            {/* Quick links  */}

            <div className="uk-width-1-1 new-arrivals uk-display-block">
                <Link to={`${process.env.PUBLIC_URL}/jewellery/?sort_by=Newest`}>
                    <button className="uk-button uk-width-1-1 uk-button-default">NEW ARRIVALS</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/trending`}>
                    <button className="uk-button uk-width-1-1 uk-button-default">TRENDING</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/jewellery`}>
                    <button className="uk-button uk-width-1-1 uk-button-default">SHOP ALL</button>
                </Link>
                <hr />
            </div>

            {/* Filter  */}

            <div className="uk-width-1-1 new-arrivals uk-display-block">
                <div className="uk-child-width-1-2 uk-text-left" data-uk-grid>
                    <div className="uk-padding-remove">
                        <div className="filter-text uk-margin-medium-top@s"><b>FILTERS</b></div>
                    </div>
                    <div>
                        <div className="clear-all-text">
                            <button className="uk-button-primary" onClick={() => clear()}>
                                <span>CLEAR ALL</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <hr />
            <div className="uk-width-1-1 sd-accordion">
                <ul uk-accordion="transition: ease">
                    {window.filterOption.filters.map((option, i) => {
                        if (option.mode == "list")
                            return (
                                handleListOption(option, i)
                            )
                        else if (option.mode == "dropdown") {
                            // if (!((window.location.pathname != "/jewellery") && option.value == "category"))
                            if (option.value == "category") {
                                if (window.location.pathname == "/jewellery" || option.visibility.includes(window.location.pathname))
                                    return (
                                        handleDropdownOption(option, i)
                                    )
                            }
                            else
                                return (
                                    handleDropdownOption(option, i)
                                )
                        }
                        else if (option.mode == "range")
                            return (
                                handleRangeOption(option, i)
                            )
                    })}
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    prices: getMinMaxPrice(state.data.category.products),
    filters: state.filters
});

export default connect(mapStateToProps, { filterColor, filterPrice, filterBrand, filterSort })(Filter);
