import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";
import { getReqOtp, getVerifyOtp, timerAction, errorMsgAction } from "../../../actions";
import Loader from "../../common/spinner"
import SimpleReactValidator from "simple-react-validator";
import store from "../../../store"

const OtpVerification = (props) => {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator())
    const [resendFlag, setResendFlag] = useState("counter");
    const [requestWait, setWait] = useState(true)
    const [codOtp, setCodOtp] = useState({
        otp: "",
        token: "",
        otpTime: ""
    });
    const [codNumber, setCodNumber] = useState({
        number: props.phone,
        utc_time: new Date().getTime()
    });


    useEffect(() => {
        if (props.otp.server_resend_step > 0) {
            store.dispatch({
                type: "INITIAL_TIME",
                payload: {
                    timeCounter: props.otp.server_resend_step,
                    sendTime: props.otp.server_send_on
                }
            });
            props.errorMsgAction("MSG_RESET");
            setCodOtp({ "token": props.otp.token, "otpTime": props.otp.server_resend_step, "otp": "" })
        }
        if (props.otp.reason) {
            if (props.otp.reason == "WAIT_TIMEOUT") {
                setWait(false)
                setTimeout(() => setWait(true), 1000)
            }
            props.errorMsgAction("ERROR_MSG", props.otp)
        }
    }, [props.otp.server_send_on]);

    // SET RESEND STEP AS 0 FOR INVALID REQUEST ERROR # REMIND NIVED

    useEffect(() => {
        if (props.timer.timeCounter > 0) {
            props.timerAction(props.otp)
        }
        else {
            if (props.timer.msgFlag.flag != "verified")
                props.errorMsgAction("MSG_RESET");
            if (props.timer.timeCounter <= 0 && props.timer.sendTime >= props.otp.server_resend_on) {
                if (props.otp.success) {
                    setResendFlag("resend")
                }
            }
        }
    }, [props.timer.timeCounter]);

    useEffect(() => {
        if (props.otp_verify.token) {
            store.dispatch({ type: "OTP_VERIFIED" });
        }
        else if (props.otp_verify.reason) {
            props.errorMsgAction("ERROR_MSG", props.otp_verify)
            setCodOtp(props.timer.codOtp)
        }
    }, [props.otp_verify]);

    const handleChange = (event) => {
        event.persist()
        setCodNumber(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
        props.handleData && props.handleData(event.target.value)
    }

    const handleChangeOtp = (event) => {
        event.persist()
        setCodOtp(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
    }

    const codRequestOtp = () => {
        if (window.navigator.onLine) {
            if (validator.current.fieldValid("number") && requestWait) {
                props.errorMsgAction("OTP_LOADER")
                props.getReqOtp(codNumber);
                validator.current.hideMessages()
            }
            else {
                validator.current.showMessages();
                forceUpdate()
            }
        }
        else {
            store.dispatch({
                type: "ERROR_MSG",
                payload: "OFFLINE"
            })
        }
    }


    const codVerifyOtp = () => {
        if (window.navigator.onLine) {
            if (validator.current.fieldValid("otp")) {
                store.dispatch({ type: "SET_OTP_DETAILS", payload: codOtp });
                props.errorMsgAction("FORM_LOADER")
                props.getVerifyOtp(codOtp, "verify/cod/otp")
            }
            else {
                validator.current.showMessages();
                forceUpdate()
            }
        } else {
            store.dispatch({
                type: "ERROR_MSG",
                payload: "OFFLINE"
            })
        }
    }

    const resetHandler = () => {
        validator.current.hideMessages()
        props.errorMsgAction("RESET_OTP")
        setResendFlag("counter")
        setCodNumber({
            "number": "",
            "utc_time": new Date().getTime()
        })
    }

    const resendHandler = () => {
        props.errorMsgAction("FORM_LOADER")
        setResendFlag("counter")
        // setCodNumber({ number: props.phone, utc_time: new Date().getTime() })
        props.getReqOtp(codNumber);
    }

    return (
        <div className="container p-0">
            <div className="row">
                {props.timer.msgFlag.form === "number" ?
                    <div className="col-12 mx-md-auto mx-lg-0">
                        <h6 className="product-title">{props.label}</h6>
                        <div className="d-flex flex-md-row justify-content-center mb-2">
                            <input
                                type="tel"
                                className="form-control"
                                placeholder="Mobile number"
                                name="number"
                                value={codNumber.number}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className="btn btn-promo"
                                onClick={() => codRequestOtp()}
                            >
                                Send OTP
                            </button>
                        </div>
                        {validator.current.message(
                            "number",
                            codNumber.number,
                            "required|phone|min:10|max:10"
                        )}
                    </div>
                    :
                    props.timer.msgFlag.form === "loader" ?
                        <div className="col-12 mx-md-auto mx-lg-0">
                            <Loader text="Loading..." />
                        </div> :
                        props.timer.msgFlag.form === "otp" ?
                            <>
                                <div className="col-12 mx-md-auto mx-lg-0">
                                    <div className="d-flex flex-md-row justify-content-between px-4 px-md-0">
                                        <input
                                            type="tel"
                                            className="form-control pin text-center"
                                            placeholder="Enter OTP"
                                            minLength="4"
                                            maxLength="4"
                                            name="otp"
                                            value={codOtp.otp}
                                            onChange={handleChangeOtp}
                                        />
                                        <button
                                            className="btn btn-promo"
                                            onClick={codVerifyOtp}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                    {validator.current.message(
                                        "otp",
                                        codOtp.otp,
                                        "required|otp|min:4|max:4"
                                    )}
                                    <h6 className="product-title my-2">OTP has been sent to <strong>{codNumber.number}</strong></h6>
                                    <div className="d-flex justify-content-between my-2">
                                        {resendFlag == "resend" ?
                                            <>
                                                <a href="javascript:void(0)" onClick={() => resendHandler()}><u>Resend OTP</u></a>
                                                <a href="javascript:void(0)" onClick={() => resetHandler()}><u>Change number</u></a>
                                            </>
                                            :
                                            <>
                                                <a>
                                                    <u className="text-muted pr-2">Resend OTP</u>
                                                    <b>{props.timer.displayTime}</b>
                                                </a>
                                                <a href="javascript:void(0)" onClick={() => resetHandler()}>
                                                    <u>Change number</u>
                                                </a>
                                            </>}
                                    </div>

                                </div>
                            </> : ""}
                {props.timer.msgFlag.flag && <div className="col-12 mx-md-auto mx-lg-0">
                    <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex align-items-center my-2 p-2 alert-" + props.timer.msgFlag.color} role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d={props.timer.msgFlag.svg} clipRule="evenodd" />
                        </svg>
                        <div className="px-1">
                            {props.timer.msgFlag.msg} {props.timer.msgFlag.flag === "wait_time" ? props.timer.displayTime : ""}
                        </div>
                    </div>
                </div>}
            </div>
            {props.place_order ?
                <div className="App mt-4">
                    <button
                        type="submit"
                        className="btn-solid btn"
                        onClick={props.timer.msgFlag.form === "verified" ? props.submitHandler : () => props.errorMsgAction("OTP_UNVERIFIED")}
                    >
                        place order
                    </button>
                </div> : ""}
        </div >
    )
}

const mapStateToProps = state => ({
    timer: state.timer
});

export default connect(
    mapStateToProps,
    {
        getReqOtp,
        getVerifyOtp,
        timerAction,
        errorMsgAction
    }
)(OtpVerification);

