import React, { Component } from "react";
import { Link } from "react-router-dom";

const Features = () => {

    return (
      <div>
        <section className=" collection_bl">
          <div className="container p-1">
            <div className="row">
              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/internally-flawless-diamonds/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/Fluoresence-icon-svg (1).svg`}
                        className="card-img-top-fill image mx-auto"
                        alt="Internally flawless diamonds"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          Internally flawless diamonds
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/100-money-back/`}>
                      <div className="card-deck">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/sunny/100pr_Money-back.svg`}
                          className="card-img-top-fill image mx-auto"
                          alt="100% money back on diamond value"
                        />
                        <div className="card-body px-2 ">
                          <p className="card-text text-center">
                            100% money back on diamond value
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/education/loose-diamonds/grading/diamond-certificate`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/certification.svg`}
                        className="card-img-top-fill image mx-auto"
                        alt=" Certifications of Diamonds"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          Certifications of Diamonds
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/hallmarked-jewellery/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/BSI_hall_mark.svg`}
                        className="card-img-top-fill image mx-auto"
                        alt=" BIS Hall Mark for Jewellery"
                      />
                      <div className="card-body px-2">
                        <p className="card-text text-center">
                          BIS Hall Mark for Jewellery
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/brand-assured-quality/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/brand_quality.svg`}
                        className="card-img-top-fill image mx-auto"
                        alt="Brand assured quality"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          Brand assured quality
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/15day-return-policy/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/15days_return.svg`}
                        className="card-img-top-fill image mx-auto"
                        alt="15 days return policy"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          15 days return policy
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/cash-on-delivery/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/24_1.png`}
                        className="card-img-top-fill image mx-auto"
                        alt="Cash on delivery"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          Cash on delivery
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <Link to={`${process.env.PUBLIC_URL}/why-buy-from-us/free-shipping/`}>
                    <div className="card-deck">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/sunny/shipping_truck.svg`}
                        className="card-img-top-fill image mx-auto"
                        alt="Pan india free shipping"
                      />
                      <div className="card-body px-2 ">
                        <p className="card-text text-center">
                          Pan india free shipping
                        </p>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Features
