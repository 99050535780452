import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import ReactGA from 'react-ga';
import Breadcrumb from "../common/breadcrumb";
import { removeFromCompare, addToCart, addToWishlist } from "../../actions";
import Seo from "../layouts/common/seo";
import CompareListItem from "./compare-item";

class Compare extends Component {
  constructor(props) {
    super(props);
  }


render() {
  var settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const {
    Items,
    symbol,
    removeFromCompare,
    addToWishlist,
    addToCart
  } = this.props;
  return (
    <div>
      {/*SEO Support*/}
      <Seo type="cart-head" />

      {/*SEO Support End */}

      <Breadcrumb title={"Compare"} />
      {Items.length > 0 ? (
        <section className="compare-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Slider {...settings} className="slide-4">
                  {Items.map((item, index) => (
                    <div key={index}>
                      <CompareListItem
                        item={item}
                        index={index}
                        symbol={symbol}
                        removeFromCompare={removeFromCompare}
                        addToWishlist={addToWishlist}
                        addToCart={addToCart}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mt-5">
                <div>
                  <div className="col-sm-12 empty-cart-cls text-center py-5 my-5">
                    <img
                      src={`${process.env.PUBLIC_URL
                        }/assets/images/sunny/logo/icons/empty-compare.png`}
                      className="img-fluid mb-4"
                      alt="compare empty"
                    />
                    <h3>
                      <strong>Compare List is Empty</strong>
                    </h3>
                    <h4>Explore more shortlist some items.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
  Items: state.compare.items,
  symbol: state.data.symbol
});

export default connect(
  mapStateToProps,
  { removeFromCompare, addToCart, addToWishlist }
)(Compare);
