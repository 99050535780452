import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Seo from "../../../components/layouts/common/seo";
import Breadcrumb from "../../common/breadcrumb";
import Occasion from "./occasion";
import ChooseAmount from "./choose-amount";
import DeliveryDetails from "./delivery-details";
import DeliveryDetailsNew from "./delivery-details_new";
import PreviewPay from "./preview-pay";
import PayApp from "../../checkout/razorpay";
import BalanceGift from "./balance-check";
import Features from "../../../components/layouts/sunny-diamonds/features"


import { getCategory } from "../../../services/index";
import { errorMsgAction, createGiftCard, fetchTemplates } from "../../../actions"
import store from "../../../store";

import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GiftCard = (props) => {

    useEffect(() => {
        store.dispatch({ type: "RECEIVE_REQUEST_OTP", payload: "" });
        store.dispatch({ type: "RECEIVE_VERIFY_OTP", payload: "" });
        store.dispatch({ type: "RECIEVE_GIFTCARD_CREATE_DETAILS", payload: "" });
        props.errorMsgAction("RESET_OTP")
        props.fetchTemplates()
    }, []);



    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const validatoramount = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const validatorMob1 = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const validatorMob2 = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [index, setIndex] = useState(2)
    const [amount, setAmount] = useState("")
    const [cardType, setCardType] = useState("Digital")
    const [occasion, setOccasion] = useState("")
    const [verifyValidity, setVerifyValidity] = useState(false)
    const [mailValidity, setMailValidity] = useState(false)
    const [delivery, setDeliveryDetails] = useState({
        first_name: "",
        email: "",
        c_email: "",
        phone: "",
        message: "",
        s_first_name: "",
        s_email: "",
        date: "",
        s_phone: ""
    })

    const handleIndexData = (index) => setIndex(index)

    const [formIndex, setFormIndex] = useState(1);

    const handleFormIndex = (formIndex) => setFormIndex(formIndex)

    const handleOccationData = (occ) => setOccasion(occ)

    const handleAmountData = (amt) => {
        setAmount(amt)
    }

    const handleDeliveryData = (event) => {
        event.persist()
        setDeliveryDetails(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
    }

    const handleDeliveryDataNumber = (number) => {
        setDeliveryDetails(prevState => ({
            ...prevState, ['s_phone']: number
        }))
    }


    const submitHandler = () => {
        const cardData = {
            purchase_card_type: cardType,
            purchase_occasion_name: occasion.occasion,
            purchase_card_amount: amount,
            purchase_recipients_name: delivery.first_name,
            purchase_recipients_number: delivery.phone,
            purchase_recipients_email: delivery.email,
            purchase_recipients_email_confirm: delivery.c_email,
            purchase_recipients_message: delivery.message,
            purchase_sender_name: delivery.s_first_name,
            purchase_sender_number: delivery.s_phone,
            purchase_sender_email: delivery.s_email,
            purchase_card_delivery_date: delivery.date,
            purchase_otp_token: props.otp_verify.token
        }
        if (window.navigator.onLine) {
            props.createGiftCard(cardData)
        }
        else {
            toast.warn('⚠️ Check your connection and try again later', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <div>
            <Seo type="gift-card" />
            <Breadcrumb title="Gift Cards" />
            {occasion ?
                <PayApp
                    button={true}
                    Order={delivery}
                    orderData={props.orderData}
                    mode="gift"
                /> : ""}

            {/* Banner Section & Check balance  */}
            <div className="d-lg-block d-none">
                <BalanceGift />
            </div>

            {index == 2 ?
                <div className="d-block d-lg-none">
                    <BalanceGift />
                </div> : ""
            }


            {/* Tabs Nav  */}
            <div className="container gift-root">
                <div className="row mt-lg-5 mt-0">
                    <div className="col-lg-12">

                        {/* Occassion  */}
                        <div className={index == 2 ? "gift-tab" : "gift-tab d-none d-lg-block"} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="container ">
                                <div className="row justify-content-lg-between justify-content-center">
                                    <div className="col-lg-8  p-0 mt-3 mt-lg-0">
                                        <div className={
                                            index == 2 ?
                                                "gift-tab-nav mb-3 p-1 shadow gift-tab-nav-active d-none d-lg-block cursor-pointer"
                                                : index > 2 ?
                                                    "gift-tab-nav mb-3 p-1 shadow gift-tab-nav-visited d-none d-lg-block cursor-pointer"
                                                    :
                                                    " gift-tab-nav mb-3 p-1 shadow shadow d-none d-lg-block cursor-pointer"
                                        }
                                            data-toggle="pill"
                                            role="tab"
                                            aria-controls="v-pills-profile"
                                            aria-selected="false"
                                            onClick={() => {
                                                {
                                                    if (occasion) { handleIndexData(2) }
                                                    window.scrollTo(0, 435);
                                                }
                                            }}
                                        >
                                            <span className={index == 2 || occasion ? "dot-visited" : "dot"} />
                                            <i className="fa fa-gift m-2 px-3"></i>
                                            <span className="text-capitalize side-menu-options" >SELECT AN OCCASION</span>
                                        </div>
                                        {
                                            index == 2 ?
                                                <h2 className="mb-2 text-lg-left text-center d-block d-lg-none">
                                                    Select an Occasion
                                                </h2>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-sm-8 d-none d-lg-block px-3">
                                        <div className="d-flex justify-content-center">
                                            {
                                                index == 2 ?
                                                    <>
                                                        <button className="btn btn-dfe2"
                                                            onClick={
                                                                () => {
                                                                    if (occasion) {
                                                                        handleIndexData(index + 1)
                                                                        window.scrollTo(0, 435);
                                                                    } else {
                                                                        validator.current.showMessages();
                                                                        forceUpdate()
                                                                    }
                                                                }
                                                            }>
                                                            <div className="mx-4">Next</div>
                                                        </button>
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div className="row justify-content-center">
                                            {validator.current.message("Occasion", occasion, "required")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    index == 2 ?
                                        <Occasion
                                            handleOccationData={handleOccationData}
                                            handleIndexData={handleIndexData}
                                            cardType={cardType}
                                            occasion={occasion}
                                            cardList={props.gift_template}
                                        />
                                        :
                                        ""
                                }
                            </div>
                            <div className="container d-none d-md-block">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8"></div>
                                    <div className="row justify-content-lg-end justify-content-center mt-3 mt-lg-0 ">
                                        {index == 2 ?
                                            <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift">
                                                <button className="btn btn-dfe2 mx-1"
                                                    onClick={
                                                        () => {
                                                            if (occasion)
                                                                handleIndexData(index + 1)
                                                            else {
                                                                validator.current.showMessages();
                                                                forceUpdate()
                                                            }
                                                        }
                                                    }>
                                                    <div className="mx-4">Next</div>
                                                </button>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                                <div className="row justify-content-lg-end justify-content-center mr-lg-5 pr-lg-5">
                                    {validator.current.message("Occasion", occasion, "required")}
                                </div>
                            </div>
                        </div>

                        {/* Choose an Amount  */}
                        <div className={index == 3 ? "gift-tab" : "gift-tab d-none d-lg-block"} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="container">
                                <div className="row justify-content-lg-end justify-content-center">
                                    <div className="col-lg-8  p-0 mt-3 mt-lg-0 gift-index">
                                        <div className={
                                            index == 3 ?
                                                "gift-index-active  gift-tab-nav-active  mb-3 p-1 shadow  d-none d-lg-block cursor-pointer"
                                                :
                                                index > 3 ?
                                                    " gift-tab-nav mb-3 p-1 shadow gift-tab-nav-visited d-none d-lg-block cursor-pointer" :
                                                    " gift-tab-nav mb-3 p-1 shadow  d-none d-lg-block cursor-pointer"
                                        }
                                            data-toggle="pill"
                                            role="tab"
                                            aria-controls="v-pills-profile"
                                            aria-selected="false"
                                            onClick={
                                                () => {
                                                    if (occasion) {
                                                        handleIndexData(3)
                                                        if (window.outerWidth >= 1024) {
                                                            window.scrollTo(0, 435);
                                                        } else {
                                                            window.scrollTo(0, 0);
                                                        }
                                                    } else {
                                                        validator.current.showMessages();
                                                        forceUpdate()
                                                    }
                                                }
                                            }
                                        >
                                            <span className={index == 3 || amount ? "dot-visited" : "dot"} />

                                            <i className="fas fa-donate m-2 px-3"></i>
                                            <span className="text-capitalize side-menu-options" >CHOOSE AN AMOUNT</span>
                                        </div>
                                        {
                                            index == 3 ?
                                                <h2 className="mb-2 text-lg-left text-center d-block d-lg-none">
                                                    Choose an Amount
                                                </h2> : ""
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-sm-8 d-none d-lg-block px-3">
                                        <div className="d-flex justify-content-center">
                                            {index == 3 ? <>
                                                <button className="btn btn-dfe3 mx-1 gift-index-active"
                                                    onClick={
                                                        () => {
                                                            {
                                                                handleIndexData(index - 1)
                                                                if (window.outerWidth >= 1024) {
                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                        }
                                                    }>
                                                    <div className="mx-3">Previous</div>
                                                </button>
                                                <button className="gift-index-active btn btn-dfe2"
                                                    onClick={
                                                        () => {
                                                            if (amount && amount != 500 && amount % 500 == 0 && amount <= 500000) {
                                                                handleIndexData(index + 1)
                                                                if (window.outerWidth >= 1024) {
                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                            else {
                                                                validatoramount.current.showMessages();
                                                                forceUpdate()
                                                            }
                                                        }
                                                    }>
                                                    <div className="mx-4">Next</div>
                                                </button>
                                            </>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                {
                                    index == 3 ?
                                        <ChooseAmount
                                            handleAmountData={handleAmountData}
                                            handleIndexData={handleIndexData}
                                            amount={amount}
                                            validatoramount={validatoramount}
                                        />
                                        :
                                        ""
                                }
                            </div>
                            <div className="mt-3 d-block d-lg-none">
                                {
                                    index == 3 ?
                                        <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">
                                            <div className="col-6 col-sm-4 px-1">
                                                <button className="btn btn-dfe3 w-100"
                                                    onClick={
                                                        () => {
                                                            handleIndexData(index - 1)
                                                            if (window.outerWidth >= 1024) {
                                                                window.scrollTo(0, 435);
                                                            } else {
                                                                window.scrollTo(0, 0);
                                                            }
                                                        }
                                                    }>
                                                    <div className="mx-3 ">Previous</div>
                                                </button>
                                            </div>
                                            <div className="col-6 col-sm-4 px-1">
                                                <button className="btn btn-dfe3 w-100"
                                                    onClick={
                                                        () => {
                                                            if (amount && amount != 500 && amount % 500 == 0 && amount <= 500000) {
                                                                handleIndexData(index + 1)
                                                                if (window.outerWidth >= 1024) {
                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                            else {
                                                                validatoramount.current.showMessages();
                                                                forceUpdate()
                                                            }
                                                        }
                                                    }>
                                                    <div className="mx-4">Next</div>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                        </div>

                        {/* Delivery Details  */}

                        <div className={index == 4 ? "gift-tab" : "gift-tab d-none d-lg-block"} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="container">
                                <div className="row justify-content-lg-end justify-content-center">
                                    <div className="col-lg-8  p-0 mt-3 mt-lg-0">
                                        <div className={index == 4 ? "gift-index-active  gift-tab-nav gift-tab-nav-active mb-3 p-1 shadow active d-none d-lg-block cursor-pointer"
                                            : index > 4 ? " gift-tab-nav mb-3 p-1 shadow gift-tab-nav-visited d-none d-lg-block cursor-pointer" :
                                                " gift-tab-nav mb-3 p-1 shadow d-none d-lg-block cursor-pointer"
                                        }
                                            data-toggle="pill"
                                            role="tab"
                                            aria-controls="v-pills-profile"
                                            aria-selected="false"
                                            onClick={
                                                () => {
                                                    if (amount && occasion && delivery && amount != 500 && amount % 500 == 0 && amount <= 500000) {
                                                        if (delivery.email == delivery.c_email) {
                                                            if (props.verification.flag == "verified") {
                                                                handleIndexData(4)
                                                                if (window.outerWidth >= 1024) {

                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                            else
                                                                setVerifyValidity(false)
                                                        }
                                                        else setMailValidity(true)
                                                    }
                                                    else {
                                                        validatoramount.current.showMessages();
                                                        forceUpdate()
                                                    }
                                                }
                                            }
                                        >
                                            <span className={index >= 4 ? "dot-visited" : index < 4 && props.verification.flag == "verified" ? "dot-visited" : "dot"} />
                                            <i className="fas fa-landmark m-2 px-3"></i>
                                            <span className="text-capitalize side-menu-options" >DELIVERY DETAILS</span>
                                        </div>

                                        {
                                            index == 4 ?
                                                <h2 className="mb-2 text-lg-left text-center d-block d-lg-none">
                                                    Delivery Details
                                                </h2> : ""
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-sm-8 d-none d-lg-block px-3">
                                        <div className="d-flex justify-content-center">
                                            {
                                                index == 4 ? <>
                                                    <button className="btn btn-dfe3 mx-1 gift-index-active"
                                                        onClick={
                                                            () => {
                                                                handleIndexData(index - 1)
                                                                if (window.outerWidth >= 1024) {
                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                        }>
                                                        <div className="mx-3">Previous</div>
                                                    </button>
                                                    <button className="btn btn-dfe2 gift-index-active"
                                                        onClick={
                                                            () => {
                                                                if (validatorMob1.current.allValid() && validatorMob2.current.allValid()) {
                                                                    if (delivery.email == delivery.c_email) {
                                                                        if (props.verification.flag == "verified") {
                                                                            handleIndexData(index + 1)
                                                                            if (window.outerWidth >= 1024) {
                                                                                window.scrollTo(0, 435);
                                                                            } else {
                                                                                window.scrollTo(0, 0);
                                                                            }
                                                                        }
                                                                        else
                                                                            setVerifyValidity(true)
                                                                    }
                                                                    else setMailValidity(true)
                                                                }
                                                                else {
                                                                    validatorMob1.current.showMessages();
                                                                    validatorMob2.current.showMessages();
                                                                    forceUpdate()
                                                                }
                                                            }
                                                        }>
                                                        <div className="mx-4">Next</div>
                                                    </button>
                                                </>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                {/* {
                                    index == 4 ?
                                        <DeliveryDetails
                                            handleDeliveryData={handleDeliveryData}
                                            handleIndexData={handleIndexData}
                                            handleDeliveryDataNumber={handleDeliveryDataNumber}
                                            delivery={delivery}
                                            verifyValidity={verifyValidity}
                                            mailValidity={mailValidity}
                                            setMailValidity={setMailValidity}
                                        /> : ""} */}

                                {
                                    index == 4 ?
                                        <DeliveryDetailsNew
                                            validatorMob1={validatorMob1}
                                            validatorMob2={validatorMob2}
                                            formIndex={formIndex}
                                            delivery={delivery}
                                            handleFormIndex={handleFormIndex}
                                            handleDeliveryDataNumber={handleDeliveryDataNumber}
                                            handleDeliveryData={handleDeliveryData}
                                            verifyValidity={verifyValidity}
                                            mailValidity={mailValidity}
                                            setMailValidity={setMailValidity}
                                            verification={props.verification}
                                        /> : ""}
                            </div>
                            <div className="container">
                                <div className="row d-block d-lg-none">
                                    {index == 4 ?
                                        <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">

                                            {formIndex != 1 ?

                                                <div className="col-6 col-sm-4 px-1">
                                                    <button className="btn btn-dfe3 w-100"
                                                        onClick={() => {
                                                            handleFormIndex(formIndex - 1)
                                                            if (window.outerWidth >= 1024) {
                                                                window.scrollTo(0, 435);
                                                            } else {
                                                                window.scrollTo(0, 0);
                                                            }
                                                        }}>
                                                        Pervious
                                                    </button>
                                                </div>
                                                :
                                                <div className="col-6 col-sm-4 px-1">
                                                    <button className="btn btn-dfe3 w-100"
                                                        onClick={() => {
                                                            handleIndexData(3)
                                                            if (window.outerWidth >= 1024) {
                                                                window.scrollTo(0, 435);
                                                            } else {
                                                                window.scrollTo(0, 0);
                                                            }
                                                        }}>
                                                        <div className="mx-3">Previous</div>
                                                    </button>
                                                </div>
                                            }

                                            {formIndex == 1 ? //This next button used for only in recepient details form in mobile device
                                                <div className="col-6 col-sm-4 px-1">
                                                    <button className="btn btn-dfe3 w-100"
                                                        onClick={() => {
                                                            if (validatorMob1.current.allValid()) {
                                                                if (delivery.email == delivery.c_email) {
                                                                    handleFormIndex(formIndex + 1)
                                                                    if (window.outerWidth >= 1024) {
                                                                        window.scrollTo(0, 435);
                                                                    } else {
                                                                        window.scrollTo(0, 0);
                                                                    }
                                                                }
                                                                else setMailValidity(true)
                                                            }
                                                            else {
                                                                validatorMob1.current.showMessages();
                                                                forceUpdate()
                                                            }
                                                        }}
                                                    >Next
                                                    </button>
                                                </div> :
                                                <div className="col-6 col-sm-4 px-1">
                                                    <button className="btn btn-dfe3 w-100"
                                                        onClick={
                                                            () => {
                                                                if (validatorMob2.current.allValid()) {
                                                                    if (props.verification.flag == "verified") {
                                                                        handleIndexData(index + 1)
                                                                    }
                                                                    else
                                                                        setVerifyValidity(true)

                                                                }
                                                                else {
                                                                    validatorMob2.current.showMessages();
                                                                    forceUpdate()
                                                                }
                                                            }
                                                        }>
                                                        <div className="mx-4">Next</div>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Payment & Preview  */}

                        <div className={index == 5 ? "gift" : "gift-tab d-none d-lg-block"} id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="container">
                                <div className="row justify-content-lg-end justify-content-center">
                                    <div className="col-lg-8  p-0 mt-3 mt-lg-0">
                                        <div className={
                                            index == 5 ?
                                                "gift-index-active  gift-tab-nav mb-3 p-1 shadow active gift-tab-nav-active d-none d-lg-block cursor-pointer"
                                                : index > 5 ?
                                                    " gift-tab-nav mb-3 p-1 shadow gift-tab-nav-visited d-none d-lg-block cursor-pointer"
                                                    :
                                                    " gift-tab-nav p-1 shadow d-none d-lg-block cursor-pointer"
                                        }
                                            data-toggle="pill"
                                            role="tab"
                                            aria-controls="v-pills-profile"
                                            aria-selected="false"
                                            onClick={
                                                () => {
                                                    if (amount && occasion && delivery && props.verification.flag == "verified" && validatorMob1.current.allValid() && validatorMob2.current.allValid()) {
                                                        handleIndexData(5)
                                                        if (window.outerWidth >= 1024) {
                                                            window.scrollTo(0, 435);
                                                        } else {
                                                            window.scrollTo(0, 0);
                                                        }
                                                    }
                                                    else {
                                                        validatorMob1.current.showMessages();
                                                        validatorMob2.current.showMessages();
                                                        forceUpdate()
                                                    }

                                                }
                                            }
                                        >
                                            <span className={index == 5 ? "dot-visited" : index > 5 ? "dot-visited" : "dot"}></span>
                                            <i className="fas fa-wallet m-2 px-3"></i>
                                            <span className="text-capitalize side-menu-options" >PREVIEW & PAY</span>
                                        </div>
                                        {
                                            index == 5 ?
                                                <h2 className="mb-2 text-lg-left text-center d-block d-lg-none">
                                                    Preview & Pay
                                                </h2>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-sm-8 d-none d-lg-block">
                                        <div className="d-flex justify-content-center">
                                            {index == 5 ?
                                                <>
                                                    <button className="btn btn-dfe3 mx-1 gift-index-active"
                                                        onClick={
                                                            () => {
                                                                handleIndexData(index - 1)
                                                                if (window.outerWidth >= 1024) {
                                                                    window.scrollTo(0, 435);
                                                                } else {
                                                                    window.scrollTo(0, 0);
                                                                }
                                                            }
                                                        }>
                                                        <div className="mx-3">Previous</div>
                                                    </button>
                                                    <button className="btn btn-dfe2 gift-index-active" onClick={() => {
                                                        if (props.verification.flag == "verified") submitHandler()
                                                    }}>
                                                        <div>proceed to pay</div>
                                                    </button>
                                                </>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="v-pills-tabContent">
                                {index == 5 ?
                                    <PreviewPay
                                        handleIndexData={handleIndexData}
                                        submitHandler={submitHandler}
                                        occasion={occasion}
                                        delivery={delivery}
                                        amount={amount}
                                    /> : ""}
                            </div>
                            <div className="container">
                                <div className="row justify-content-lg-end justify-content-center mt-3 mt-lg-0 d-block d-lg-none">
                                    {index == 5 ?
                                        <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-3 px-md-5 mx-md-5">
                                            <div className="col-6 col-sm-4 px-1">
                                                <button className="btn btn-dfe3 w-100"
                                                    onClick={() => {
                                                        handleIndexData(4)
                                                        if (window.outerWidth >= 1024) {
                                                            window.scrollTo(0, 435);
                                                        } else {
                                                            window.scrollTo(0, 0);
                                                        }
                                                    }}>
                                                    <div className="mx-2">Previous</div>
                                                </button></div>
                                            <div className="col-6 col-sm-4 px-1">
                                                <button className="btn btn-dfe3 w-100"
                                                    onClick={() => {
                                                        if (props.verification.flag == "verified") submitHandler()
                                                    }}>
                                                    <div>proceed to pay</div>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Terms&Condition  */}

                <div className="row justify-content-end mt-3 mb-5 px-3">
                    <Link to={`${process.env.PUBLIC_URL}/gift-card-terms-and-conditions`}>Terms & Conditions*</Link>
                </div>
            </div>
            <Features />
        </div >
    )
}

const mapStateToProps = state => ({
    otp_verify: state.data.otp_verify,
    gift_template: state.gift.templates,
    orderData: state.gift.card_create,
    verification: state.timer.msgFlag,
    head: getCategory(state.data.products, "gift-card")
});

export default connect(
    mapStateToProps,
    { errorMsgAction, createGiftCard, fetchTemplates }
)(GiftCard);