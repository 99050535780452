import React from 'react';

import '../css/MobileFilterPanel.css'
import Filter from './filter/filter';

const MobileFilterPanel = (props) => {
    return (
        <div className="uk-hidden@l">
            <button className="uk-button uk-button-default uk-width-1-1 uk-margin-small-bottom  uk-flex uk-flex-middle" data-uk-toggle="target: #modal-fitter-menu-mob">
                <p className="uk-text-left uk-width-1-1 uk-margin-remove">Filter and Sort by</p>
                <a className="uk-text-right" href="" data-uk-icon="settings"></a>
            </button>
            <div id="modal-fitter-menu-mob" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <div className="uk-width-1-1 uk-padding-remove side-bar-listing">
                        <Filter />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFilterPanel;