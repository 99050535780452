import React, { useState } from "react";
import { connect } from "react-redux";

import { filterBrand } from "../../../../actions";

const Dropdown = (props) => {

    const [placeholder, setPlaceHolder] = useState("")

    const dropDownHandler = (event) => {

        var selectedFilters = []

        setPlaceHolder(props.placeholder)
        if (props.filters.placeholder[placeholder]) {
            selectedFilters = props.filters.placeholder[placeholder]
            var index = selectedFilters.indexOf(event.target.value);
        }

        if (event.target.checked) {
            if (!selectedFilters.includes(event.target.value)) {
                selectedFilters.push(event.target.value);
                props.filterBrand(selectedFilters, event.target.placeholder);
            }
        } else {
            selectedFilters.splice(index, 1);
            props.filterBrand(selectedFilters, event.target.placeholder);
        }
    }

    return (
        <div>
            {props.element.list.map((list, i) => (
                <label
                    key={i}
                    className="uk-padding-remove uk-width-1-1"
                >
                    <input
                        id={list.value}
                        className="uk-checkbox"
                        type="checkbox"
                        value={list.value}
                        onClick={e => dropDownHandler(e)}
                        placeholder={props.placeholder}
                    />
                    {list.name}
                </label>
            ))}
        </div>
    )
}

const mapStateToProps = state => ({
    filters: state.filters
});

export default connect(mapStateToProps, { filterBrand })(Dropdown);
