import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import {
  postAllNewCustomerAccount,
  // postZohoDetailsSubmit,
  // postAllUserEmail,
  postUniqueCheckDetails,
  // postUserId
} from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import store from "../../store";
import ReCAPTCHA from "react-google-recaptcha";

const Register = (props) => {

  const _reCaptchaRef = React.createRef()
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShown1, setPasswordShown1] = useState(false)
  const { emailUnique } = props;
  const eye = <FontAwesomeIcon icon={faEye} />;
  const [button, setButton] = useState("Create account")


  useEffect(() => {
    store.dispatch({ type: "RECEIVE_UNIQUE", payload: "" });
    store.dispatch({ type: "RECEIVE_ZOHO_SUBMIT", payload: "" });
    store.dispatch({ type: "RECEIVE_NEW_CUSTOMER_DETAIL", payload: "" });
  }, [])

  const [register, setRegister] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    c_password: "",
    subscription: false,
    terms_and_condition: ""
  })

  const handleChange = (event) => {
    event.persist()
    if (event.target.name == 'subscription') {
      setRegister(prevState => ({
        ...prevState, [event.target.name]: event.target.checked
      }))
    } else {
      setRegister(prevState => ({
        ...prevState, [event.target.name]: event.target.value
      }))
    }
  }

  const onRecaptcha = (e) => {
    e.preventDefault();
    if (validator.current.allValid() && props.emailUnique == true) {
      setButton("Please wait...")
      _reCaptchaRef.current.executeAsync();
    } else {
      if (props.emailUnique == "")
        props.postUniqueCheckDetails(register.email)
      validator.current.showMessages();
      forceUpdate()
    }
  }

  const handleSubmit = (code) => {
    if (code)
      props.postAllNewCustomerAccount(register);
  };

  return (
    <div>
      <Helmet>
        <meta property="og:site_name" content="Sunny Diamonds" />
        <meta property="og:type" content="Summary" />
        <meta
          property="og:title"
          content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
        />
        <meta
          property="og:description"
          content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
        />
        <meta property="og:url" content="https://sunnydiamonds.com/" />
        <meta name="twitter:card" content="Summary" />
        <meta name="twitter:site" content="@SunnyDiamond_4c" />
        <meta name="twitter:creator" content="Sunny Diamonds" />
        <meta
          name="twitter:title"
          content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
        />
        <meta
          name="twitter:description"
          content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
        />
      </Helmet>

      <Breadcrumb title={"create account"} />

      {/*Regsiter section*/}
      <section className="register-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="theme-card bg-white my-4">
                <h2 className="text-center py-4">
                  Sign Up With Sunny Diamonds
                </h2>
                <form
                  className="theme-form"
                  onSubmit={onRecaptcha}
                >

                  <div className="form-row">

                    <div className="col-md-6 px-4">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="first_name"
                        id="first_name"
                        value={register.first_name}
                        onChange={handleChange}
                        onBlur={() => validator.current.showMessageFor('first_name')}
                      />
                      {validator.current.message("first_name", register.first_name, "required|alpha_space")}
                    </div>

                    <div className="col-md-6 px-4">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="last_name"
                        id="last_name"
                        value={register.last_name}
                        onChange={handleChange}
                        onBlur={() => validator.current.showMessageFor('last_name')}
                      />
                      {validator.current.message("last_name", register.last_name, "required|alpha_space")}
                    </div>
                  </div>

                  <div className="form-row">

                    <div className="col-md-6 mt-2 mt-md-3 px-4">
                      <label className="form-label" htmlFor="email">email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        autoComplete="new-email"
                        placeholder="Email"
                        value={register.email}
                        onBlur={() =>
                          props.postUniqueCheckDetails(register.email)
                        }
                        onChange={handleChange}
                      />
                      {emailUnique === "false" ? (<div className="email-message">Email already exists</div>
                      ) : (
                        ""
                      )}
                      {validator.current.message("email", register.email, "required|email")}
                    </div>

                    <div className="col-md-6 mt-2 mt-md-3 px-4">
                      <label className="form-label" htmlFor="phone">Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        value={register.phone}
                        onChange={handleChange}
                        onBlur={() => validator.current.showMessageFor('phone')}
                      />
                      {validator.current.message("phone", register.phone, "required|phone|min:10|max:12")}
                    </div>

                    <div className="col-md-6 mt-2 mt-md-3 position-relative px-4">
                      <label className="form-label" htmlFor="review">Password</label>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        name="password"
                        id="password"
                        autoComplete="new-password"
                        placeholder="Enter your password"
                        value={register.password}
                        onChange={handleChange}
                        onPaste={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={e => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <i
                        className="reg_password"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {eye}
                      </i>
                      {validator.current.message("password", register.password, "required|min:8|max:30")}
                    </div>

                    <div className="col-md-6 mt-2 mt-md-3 px-4">
                      <label className="form-label" htmlFor="review">
                        Confirm Password</label>
                      <input
                        type={passwordShown1 ? "text" : "password"}
                        className="form-control pass-wrapper"
                        name="c_password"
                        id="c_password"
                        placeholder="Confirm password"
                        value={register.c_password}
                        onChange={handleChange}
                        onPaste={e => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={e => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <i
                        className="reg_password"
                        onClick={() => setPasswordShown1(!passwordShown1)}
                      >
                        {eye}
                      </i>
                      {validator.current.message("Confirm password", register.c_password,
                        `required|min:8|max:30|in:${register.password}`,
                        { messages: { in: "Passwords need to match!" } }
                      )}
                    </div>
                  </div>

                  <div className="row pl-5 mt-md-1 mt-0 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="subscription"
                      id="subscription"
                      // checked={register.subscription}
                      onChange={handleChange}
                    />
                    <label htmlFor="subscription" className="pl-2">Sign Up For Newsletter</label>
                    {validator.current.message(
                      "checkbox",
                      register.subscription,
                      ""
                    )}
                  </div>
                  <div
                    className="row pl-5 pt-0 mt-md-1 mt-0 d-flex align-items-center"
                    style={{ marginTop: "-30px" }}
                  >
                    <input
                      type="checkbox"
                      name="terms_and_condition"
                      id="terms_and_condition"
                      className="mt-3"
                      onChange={handleChange}
                    />
                    <label htmlFor="terms_and_condition" className="pl-2 pt-3">
                      I agree{" "}
                      <a
                        href={`${process.env.PUBLIC_URL
                          }/terms-and-conditions`}
                        style={{ color: "#521d4d" }}
                      >
                        <b>Terms and Condition</b>
                      </a>
                    </label>
                  </div>
                  {validator.current.message("checkbox", register.terms_and_condition, "required")}

                  <ReCAPTCHA
                    ref={_reCaptchaRef}
                    size="invisible"
                    sitekey={global.reCaptcha}
                    onChange={handleSubmit}
                  />

                  <div className="text-center col-md-12 ">
                    <button
                      type="submit"
                      value="Submit"
                      data-lng="en"
                      className="btn btn-solid "
                    >
                      {button}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end of POP-up Message  */}
    </div>
  );
}

const mapStateToProps = state => ({
  emailUnique: state.data.unique_data,
  user_id: state.data.newuser,
  zoho_id: state.data.zoho_user
});

export default connect(
  mapStateToProps,
  {
    postAllNewCustomerAccount,
    // postZohoDetailsSubmit,
    // postAllUserEmail,
    postUniqueCheckDetails,
    // postUserId
  }
)(Register);
