import React from 'react';
import { Link } from 'react-router-dom'

const CartHeader = ({ item, symbol, removeFromMiniCart }) => {

    const CartimgeSource = (item) => {
        let images = []
        let imgeSrc = []
        let imgI = 0;
        const exts = [".png", ".jpg", ".webp", ".gif"];
        // if (item.variants[0].image) {
        if (item.selectedVariant.image) {
            let allImages = item.selectedVariant.image;
            images = allImages.split(",")
            images.forEach(function (img) {
                let ext = img.substring(img.lastIndexOf("."));

                if (exts.includes(ext)) {
                    imgeSrc[imgI] = img;
                    imgI++;
                }
            });
        }
        return imgeSrc[0];
    };

    const removeFromCart = item => {
        removeFromMiniCart(item, item.selectedVariant)
    }

    return (
        <li className='d-inline0flex'>
            <div className="media">
                <Link to={`${process.env.PUBLIC_URL}/cart`}>
                    <img
                        src={`${process.env.PUBLIC_URL}` + CartimgeSource(item)}
                        onError={e => {
                            e.target.src = `${process.env.PUBLIC_URL
                                }/assets/images/sunny/no_image/no_images.webp`;
                        }}
                        className="img-fluid lazyload bg-img"
                        alt="products on cart"
                    />
                </Link>
                <div className="media-body pl-2">
                    <Link to={`${process.env.PUBLIC_URL}/cart`}><h4>{item.name}</h4></Link>
                    {item.selectedVariant ?
                        <div className='d-inline-flex'>
                            <p className={"border border-secondary price_update d-inline-block rounded px-2 ml-1 mb-2 "}>
                                {item.selectedVariant.color.replace("-", " ")}
                            </p>
                            {
                                item.selectedVariant.size ?
                                    <p className={"border border-secondary price_update d-inline-block rounded px-2 ml-1 mb-2 "}>
                                        {item.selectedVariant.size}
                                    </p> :
                                    item.selectedVariant.default_size
                            }
                        </div>
                        : ""
                    }
                    <h4 className='cart_sum'><span>{item.qty} x {symbol} {(item.selectedVariant.offerprice)}
                    </span>
                    </h4>
                </div>
                <div className="close-circle">
                    <a href={null} onClick={() => { removeFromCart(item) }}><i className="fa fa-times" aria-hidden="true"></i></a>
                </div>
            </div>
        </li>
    )
}



export default CartHeader;
