import {
    DECREMENT_TIME,
    INITIAL_TIME,
    OTP_VERIFIED,
    SET_OTP_DETAILS,
    ERROR_MSG,
    OTP_HANDLER
} from "../constants/ActionTypes"

const initialState = {
    sendTime: 0,
    timeCounter: 0,
    displayTime: "00:00",
    msgFlag: {
        flag: "",
        color: "",
        msg: "",
        svg: "",
        form: "number"
    },
    codOtp: {
        otp: "",
        token: "",
        otpTime: ""
    }
}

const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_TIME:
            return {
                ...state,
                timeCounter: action.payload.timeCounter,
                sendTime: action.payload.sendTime
            };
        case DECREMENT_TIME:
            if (state.timeCounter > 0) {
                return {
                    ...state,
                    timeCounter: state.timeCounter - 1,
                    sendTime: state.sendTime + action.delay,
                    displayTime: "00:" + addLeadingZeros(state.timeCounter),
                    msgFlag: {
                        flag: state.msgFlag['flag'],
                        color: state.msgFlag['color'],
                        msg: state.msgFlag['msg'],
                        svg: state.msgFlag['svg'],
                        form: action.formHandler
                    }
                };
            } else {
                return { ...state }
            }
        case OTP_HANDLER:
            return {
                ...state,
                displayTime: action.payload.clearCounter ? "00:00" : state.displayTime,
                timeCounter: action.payload.clearCounter ? 0 : state.timeCounter,
                msgFlag: {
                    flag: "",
                    color: "",
                    msg: "",
                    svg: "",
                    form: action.payload.form
                },
            };
        case OTP_VERIFIED:
            return {
                ...state,
                displayTime: "00:00",
                timeCounter: 0,
                msgFlag: {
                    flag: "verified",
                    color: "success",
                    msg: " Verification Successfull",
                    svg: "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                    form: "verified"
                }
            };
        case SET_OTP_DETAILS:
            return {
                ...state,
                codOtp: action.payload
            };
        case ERROR_MSG:
            return {
                ...state,
                msgFlag: errorCallbacks[action.payload](state)
            };
        default:
            return state;
    }
};

export default timerReducer

function addLeadingZeros(num, totalLength = 2) {
    return String(num).padStart(totalLength, '0');
}

const errorCallbacks = {
    INVALID_REQUEST: (state) =>
    ({
        flag: "invalid_request",
        color: "danger",
        msg: " Invalid request",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: state.msgFlag['form']
    }),
    OTP_MISSMATCH: (state) =>
    ({
        flag: "otp_mismatch",
        color: "danger",
        msg: " Enter valid OTP",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: "otp"
    }),
    OTP_EXPIRED: (state) =>
    ({
        flag: "otp_expired",
        color: "danger",
        msg: " Your OTP has been expired",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: "number"
    }),
    TOKEN_CANCELED: (state) =>
    ({
        flag: "token",
        color: "danger",
        msg: " Your OTP token has been expired",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: "number"
    }),
    NO_OTP_TOKEN: (state) =>
    ({
        flag: "no_token",
        color: "danger",
        msg: " Invalid request",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: "number"
    }),
    WAIT_TIMEOUT: (state) =>
    ({
        flag: "wait_time",
        color: "warning",
        msg: "Please try again after",
        svg: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
        form: "number"
    }),
    OTP_UNVERIFIED: (state) =>
    ({
        flag: "unverified",
        color: "warning",
        msg: "Please verify your mobile number",
        svg: "M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z",
        form: state.msgFlag['form']
    }),
    MSG_RESET: (state) =>
    ({
        flag: "",
        color: "",
        msg: "",
        svg: "",
        form: state.msgFlag['form']
    }),
    INVALID_SECRET: (state) =>
    ({
        flag: "invalid_otp",
        color: "danger",
        msg: " Invalid OTP",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: state.msgFlag['number']
    }),
    NETWORK_ERROR: (state) =>
    ({
        flag: "network_error",
        color: "danger",
        msg: " Network Error",
        svg: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
        form: state.msgFlag['form']
    }),
    OFFLINE: (state) =>
    ({
        flag: "offline",
        color: "warning",
        msg: "Please check your connection",
        svg: "M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z",
        form: state.msgFlag['form']
    })
}
