import {
    RECIEVE_GIFTCARD_BALANCE,
    RECIEVE_GIFTCARD_CREATE_DETAILS,
    RECIEVE_GIFTCARD_TEMPLATE,
    RECIEVE_GIFTCARD_DETAILS
} from '../constants/ActionTypes'

const initialState = {
    balance: [],
    card_create: [],
    templates: [],
    details: []
}

const giftCardReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECIEVE_GIFTCARD_BALANCE:
            return {
                ...state,
                balance: action.payload
            };
        case RECIEVE_GIFTCARD_CREATE_DETAILS:
            return {
                ...state,
                card_create: action.payload
            };
        case RECIEVE_GIFTCARD_TEMPLATE:
            return {
                ...state,
                templates: action.payload
            };
        case RECIEVE_GIFTCARD_DETAILS:
            return {
                ...state,
                details: action.payload
            };
        default:
            return state;
    }
}

export default giftCardReducer;
