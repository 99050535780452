import React, { Component } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      multiplier :props.multiplier?props.multiplier:1,
    };
  }

  render() {
    return (
      <div className='d-inline'>
        <ScaleLoader height={10*this.state.multiplier} width={3*this.state.multiplier} color={"#722257"} loading={this.state.loading} speedMultiplier={1} />
        {this.props.text && <span className='px-2 loader-text' style={{ color: "#722257" }}>{this.props.text}</span>}
      </div>
    );
  }
}

export default Spinner;
