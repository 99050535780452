import React from "react";

import DOMPurify from 'dompurify';
import { Link } from "react-router-dom";





const OpenPosition = (props) => {
    return (


        <div className="uk-container">
            <div className="uk-padding side-bar-listing">
                <div className="uk-margin-medium">
                    <h2 className="uk-h2">Open Positions</h2>
                    <p className="uk-margin-small">Sunny Diamonds offers some of the best opportunities to learn and grow in the industry.
                        Do take a look if there’s an available role that you think fits you. Or simply email us your resume and we’ll get back to you..
                    </p>
                </div>

                <div className="text-center">
                    <Link
                        to={`${process.env.PUBLIC_URL}/careers/apply`}
                        data-lng="en"
                        className="uk-button uk-button-default uk-margin-bottom"
                    >
                        Apply Now
                    </Link>
                </div>

                <div className="uk-width-1-1 sd-accordion">
                    <ul uk-accordion="duration : 1000">
                        {props.job.map((data, i) => (
                            <li key={i}>
                                <a className="uk-accordion-title" href="#">{data.name}</a>
                                <div className="uk-accordion-content">
                                    <div className="uk-flex uk-flex-wrap">
                                        <div className="uk-width-1-3@m">
                                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-margin-left">
                                                <h3 className="uk-card-title uk-text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.subheading1) }}></h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content1) }}></p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-margin-left">
                                                <h3 className="uk-card-title uk-text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.subheading2) }}></h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content2) }}></p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-margin-left">
                                                <h3 className="uk-card-title uk-text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.subheading3) }}></h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content3) }}></p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-margin-left">
                                                <h3 className="uk-card-title uk-text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.subheading4) }}></h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content4) }}></p>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-margin-left">
                                                <h3 className="uk-card-title uk-text-center" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.subheading5) }}></h3>
                                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.content5) }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>


    )
}

export default OpenPosition