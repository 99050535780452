import React from "react";

import "./service_layout.css"

import ServiceLayoutData from "./service_laypout.json"


const ServiceLayout = () => {

    return (
        <section className="service-section">
            <div className="container">
                <div className="service-contents">
                    {ServiceLayoutData.map((item, index) => (
                        <div className="service-item" key={index}>
                            <div className="service-img">
                                <img src={item.image} alt={item.title} />
                            </div>
                            <div className="service-text">
                                 <p className="para-one" style={{textAlign:"center"}}>{item.title}</p>
                                <p className="para-one">{item.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ServiceLayout;