import React, { useState, useRef } from "react";

import SimpleReactValidator from "simple-react-validator";

const NomineeForm = (props) => {

    const validator = useRef(new SimpleReactValidator())
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const handleNominee = () => {
        if (validator.current.allValid()) {
            props.handleIndexData(3)
        } else {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    // console.log(props.nominee.verify_age)

    return (
        <>
            {/* Nominee Details  */}
            < div className="container bg-white p-5 border">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row my-4">
                            <h3><strong>Nominee Details</strong></h3>
                        </div>

                        <div className="row mb-4">
                            <label className="form-label">Full Name<span style={{color:"#EB5406"}}>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={props.nominee.name}
                                onChange={(e) => {
                                    props.handleNomineeDetails(e)
                                    validator.current.showMessageFor('name')
                                }
                                }
                            />

                            {validator.current.message("name", props.nominee.name, "required|alpha_space")}

                        </div>

                        <div className='row mb-4'>
                            <label className="form-label">Relationship<span style={{color:"#EB5406"}}>*</span></label>
                            <select
                                className="form-control"
                                name="relationship"
                                value={props.nominee.relationship}
                                onChange={(e) => {
                                    props.handleNomineeDetails(e)
                                    validator.current.showMessageFor('relationship')
                                }}
                            >
                                <option value=""></option>
                                <option value="father">Father</option>
                                <option value="mother">Mother</option>
                                <option value="child">Child</option>
                                <option value="spouse">Spouse</option>
                            </select>

                            {validator.current.message("relationship", props.nominee.relationship, "required")}

                        </div>

                        <div className='row mb-4'>
                            <label className="form-label">Mobile Number<span style={{color:"#EB5406"}}>*</span></label>
                            <input
                                type="tel"
                                inputMode="numeric"
                                className="form-control"
                                name="phone"
                                value={props.nominee.phone}
                                onChange={(e) => {
                                    props.handleNomineeDetails(e)
                                    validator.current.showMessageFor('phone')

                                }}
                            />
                            {validator.current.message("phone", props.nominee.phone, "required|phone|max:10|min:10")}

                        </div>

                        <div className="row flex-column mb-4">
                            <label className="form-label">Nationality<span style={{color:"#EB5406"}}>*</span></label>
                            <div className="d-flex bd-highlight mt-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio" name="nationality"
                                        id="exampleRadios1"
                                        value="option1"
                                        onClick={props.handleNomineeDetails}
                                    />
                                    <label className="form-check-label" htmlFor="exampleRadios1">
                                        Indian
                                    </label>
                                </div>
                                <div className="form-check mx-3">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="nationality"
                                        id="exampleRadios2"
                                        value="option2"
                                        onClick={props.handleNomineeDetails}
                                    />
                                    <label className="form-check-label " htmlFor="exampleRadios2">
                                        Other
                                    </label>
                                </div>
                            </div>
                            {validator.current.message("nationality", props.nominee.nationality, "required")}

                        </div>

                        <small className="text-muted">
                            <div class="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="verify_age"
                                    name="verify_age"
                                    value={props.nominee.verify_age}
                                    onClick={props.handleNomineeDetails}
                                />
                                <label className="form-check-label" htmlFor="verify_age">
                                    I hearby acknowledge that the nominee is above 18 year's old
                                </label>
                                {validator.current.message("Checkbox", props.nominee.verify_age, "required")}
                            </div>
                        </small>

                    </div>
                    <div className="mt-3 d-block d-lg-none">
                        <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">
                            <div className="col-md-12 col-6 px-1">
                                <button className="btn btn-dfe2 w-100"
                                    onClick={() => { props.handleIndexData(1) }}
                                >
                                    <div className="mx-3 ">Previous</div>
                                </button>
                            </div>
                            <div className="col-md-12 col-6 px-1">
                                <button className="btn btn-dfe3 w-100"
                                    onClick={handleNominee}>
                                    <div className="mx-4">Next</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default NomineeForm;
