import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import "./shop-by-category.css"


const ShopByCategoryNew = () => {


    const sliderRef = useRef();

    const prev = () => {
        sliderRef.current.slickPrev();
    }

    const next = () => {
        sliderRef.current.slickNext();
    }

    const sliderImage = {
        dots: false,
        autoplay: true,
        speed: 3000,
        infinite: true,
        autoplaySpeed: 5500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="discover-diamond discover-homepage">
            <div className="home-container">
                <div className="discover-title">
                    <div className="heading-section">
                        <h2 className="heading-title title-normal">Discover the world of Diamond</h2>
                        <div className="heading-icon">
                            <span className="hr-line">&#160;</span>
                            <span className="diamond-icon">&#160;</span>
                            <span className="hr-line">&#160;</span>
                        </div>
                    </div>
                    <div className="nav-content">
                        <p className="para-one">SHOP ALL</p>
                        <div className="custom-arrow">
                            <span className="left-arrow nav-arrow" onClick={prev}>&#160;</span>
                            <span className="right-arrow nav-arrow" onClick={next}>&#160;</span>
                        </div>
                    </div>
                </div>
                <div className="discover-contents">
                    <Slider ref={sliderRef}{...sliderImage}>
                        {window.shopbycategory.images.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="discover-item">
                                    <Link to={`${process.env.PUBLIC_URL}/` + item.link} >
                                        <div className="discover-img">
                                            <img src={item.pictures} alt={item.title} />
                                        </div>
                                        <div className="discover-name">
                                            <p className="para-one">{item.title}</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default ShopByCategoryNew;