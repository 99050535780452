import React from "react";

const GoogleTagEmbeded = () => {
    return (
        <div
            className="tagembed-container"
            style={{ width: "100%", height: "100%", overflow: "auto" }}
        >
            <div
                className="tagembed-socialwall"
                data-wall-id={window.rc.googlewidget.data_wall_id}
                view-url={window.rc.googlewidget.view_url}
            />
        </div>
    );
}

export default GoogleTagEmbeded;