import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { getContact } from "../../services/index";
import { connect } from "react-redux";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Link } from "react-router-dom";
import Features from "../layouts/sunny-diamonds/features";
import { Helmet } from "react-helmet";
import shop from "../../api/shop";
import ReactGA from "react-ga"

class StoreDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      selectValue: "",
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  componentDidMount() {
    this.setState({ selectValue: this.props.match.params.id });
    ReactGA.pageview(window.location.pathname);
  }

  handleDropdownChange(e) {
    this.props.history.push("./" + e.target.value);
    this.setState({ selectValue: e.target.value });
  }

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url);
    return ["https:", "http:"].includes(parsed.protocol);
  };

  render() {
    const containerStyle = {
      position: "relative",
      width: "100%",
      height: "500px",
    };

    const { store } = this.props;

    let Showroom = getContact(store, this.props.match.params.id);

    let Shop = this.props.match.params.id;

    return (
      <div>
        {/* seo section  */}
        <Helmet>
          <title>{Showroom[0].seo.title}</title>
          <meta name="description" content={Showroom[0].seo.description} />
          <meta name="keywords" content={Showroom[0].seo.keyWords} />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta property="og:title" content={Showroom[0].seo.title} />
          <meta
            property="og:description"
            content={Showroom[0].seo.description}
          />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta name="twitter:title" content={Showroom[0].seo.title} />
          <meta
            name="twitter:description"
            content={Showroom[0].seo.description}
          />
          <meta name="robots" content={Showroom[0].seo.robots} />
        </Helmet>
        <Breadcrumb title={Showroom[0].name} />

        <section className=" contact-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-0">
                <div className="media media-container d-none d-md-block">
                  <img
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}` + Showroom[0].banner}
                    alt={Showroom[0].title}
                  />
                  <div className="media_banner_text">
                    <h1
                      className="banner-title-light"
                      style={{ color: "#f4eee7" }}
                    > {Showroom[0].name} STORE</h1>
                  </div>
                  <div className="media_paragraph-text">
                    <p
                      className="banner-title-light d-md-block d-none"
                      style={{ color: "#f4eee7" }}
                    >
                      {Showroom[0].banner_paragraph}
                    </p>
                  </div>
                </div>
                <div className="title4 shp-title d-md-none">
                  <h1 className="title-inner4">{Showroom[0].name} STORE</h1>
                </div>
              </div>
              <div className="col-sm-12 store-locator mt-3">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="subject">Select City</label>
                    <select
                      id="dropdown"
                      className="form-control"
                      onChange={this.handleDropdownChange}
                      value={this.state.selectValue}
                    >
                      <option value="calicut">Calicut</option>
                      <option value="kochi">Kochi</option>
                      <option value="nagercoil">Nagercoil</option>
                      <option value="thrissur">Thrissur</option>
                      <option value="trivandrum">Trivandrum</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-4 store-locator">
                    <div className="contact-right">
                      <ul>
                        <li>
                          <div className="contact-icon">
                            <i
                              className="fa fa-phone"
                              aria-hidden="true"
                              style={{ transform: "rotateY(180deg)" }}
                            />
                            <h6 className="d-none d-md-block">Contact Us</h6>
                          </div>
                          <div className="media-body pt-md-3 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="tel:+919961870099"
                            >
                              <p>{Showroom[0].phone1}</p>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />
                            <h6 className="d-none d-md-block">Address</h6>
                          </div>
                          <div className="media-body">
                            <p>{Showroom[0].address}</p>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <i className="fas fa-envelope" aria-hidden="true" />
                            <h6 className="d-none d-md-block">Email</h6>
                          </div>
                          <div className="media-body pt-md-3 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="mailto:customerservice@sunnydiamonds.com"
                            >
                              <p>{Showroom[0].email1}</p>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <i className="fab fa-whatsapp" aria-hidden="true" />
                            <h6 className="d-none d-md-block">WhatsApp</h6>
                          </div>
                          <div className="media-body pt-md-3 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://api.whatsapp.com/send?phone=919961870099"
                            >
                              <p>{Showroom[0].whatsapp1}</p>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <br />
                    <div className="col-md-12 pb-3 text-center">
                      <Link
                        to={`${process.env.PUBLIC_URL}/locations`}
                        data-lng="en"
                        className="btn btn-solid "
                      >
                        Store locator
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-8 store-form">
                    <iframe
                      src={Shop === "calicut" ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d411.3083160694126!2d75.79840309422003!3d11.258011243388038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba659379c56b3e5%3A0x8429646857ba42a8!2sSunny%20Diamonds%20Calicut!5e0!3m2!1sen!2sin!4v1652164851816!5m2!1sen!2sin"
                        : Shop === "kochi" ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d491.18290040612976!2d76.28497932922188!3d9.978542650674544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d4dd096cded%3A0x6ad34efb2efe5b5b!2sSunny%20Diamonds%20Kochi!5e0!3m2!1sen!2sin!4v1652165025973!5m2!1sen!2sin"
                          : Shop === "thrissur" ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d583.1162144929007!2d76.20409291838826!3d10.522495241181817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ef76203a564d%3A0x2cca91da112972af!2sSunny%20Diamonds%2C%20Thrissur!5e0!3m2!1sen!2sin!4v1652164952986!5m2!1sen!2sin"
                            : Shop === "trivandrum" ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d493.2251069903957!2d76.94242888004626!3d8.518706759885328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbdde9a00ac3%3A0x9fc1f1d7fadd298a!2sSunny%20Diamonds%20Trivandrum!5e0!3m2!1sen!2sin!4v1652164692154!5m2!1sen!2sin"
                              : Shop === "nagercoil" ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d987.3097035998868!2d77.41998220772668!3d8.17868022673029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f1d944a3fb6d%3A0xaef362c0c1d74e8d!2sSunny%20Diamonds%20Nagercoil!5e0!3m2!1sen!2sin!4v1652165099357!5m2!1sen!2sin" : ""}
                      width="100%"
                      height="450"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Sunny diamonds features section*/}
        <div className="mt-5 pt-2">
          <Features />
        </div>
        {/* end of sunny diamonds features section */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: getContact(state.data.products, "All"),
});

const connector = connect(mapStateToProps)(StoreDetails);

export default GoogleApiWrapper({
  apiKey: "",
})(connector);