import React, { useState } from "react";
import { connect } from "react-redux";

import OtpVerification from "../../checkout/otp";

const VerifyOTP = (props) => {
    if (props.verification.flag != "verified") {
        if (props.verifyValidity) {
            return (
                <div className="alert-warning d-flex align-items-center my-3 p-2" role="alert">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                            clipRule="evenodd" />
                    </svg>
                    <div className="px-1">
                        Please verify the mobile number
                    </div>
                </div>
            )

        }

    }
}


const SenderDetails = (props) => {

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row justify-content-center justify-content-lg-start align-item-center">
                        <div className=" col-12 col-md-10 mb-2 mt-3 my-xl-0 px-lg-2 px-0 d-inline-flex">
                            <h4 className="lead mb-0">Sender Details</h4>
                            <p
                                className="px-1 mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="someone who recieves a surprise present.">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={20} className="h-6 w-6"
                                    fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </p>
                        </div>
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-lg-0 mt-3">
                            <label className="form-label" id="sender name">
                                Name
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='s_first_name'
                                value={props.delivery.s_first_name}
                                onChange={props.handleDeliveryData}
                            />

                            {
                                props.validatorMob2.current
                                    .message("sender name", props.delivery.s_first_name, "required|alpha_space")
                            }

                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-lg-0 mt-3">
                            <label className="form-label" id="sender email">
                                Email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name='s_email'
                                value={props.delivery.s_email}
                                onChange={props.handleDeliveryData}
                            />

                            {
                                props.validatorMob2.current
                                    .message("sender email", props.delivery.s_email, "required|email")
                            }

                        </div>
                    </div>


                    <div className="row justify-content-lg-start justify-content-center align-item-center">
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-lg-3 mt-4 pt-lg-4 pt-0">
                            <label className="form-label" id="sender phone">
                                Mobile
                                <span className="text-danger">*</span>
                            </label>

                            <OtpVerification
                                otp={props.otp_req}
                                otp_verify={props.otp_verify}
                                phone=''
                                place_order={false}
                                label=""
                                handleData={props.handleDeliveryDataNumber}
                            />
                            {props.validatorMob2.current
                                .message("sender number", props.delivery.s_phone, "required|phone|max:10|min:10")}

                            {VerifyOTP(props)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    otp_req: state.data.otp_request,
    otp_verify: state.data.otp_verify,
});

export default connect(mapStateToProps)(SenderDetails)