import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import Seo from "../layouts/common/seo";
import { removeFromCart, incrementQty, decrementQty, postCartItems, addCartToServer } from "../../actions";
import Loader from "../common/spinner"

class cartComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sync: false
    }
  }

  componentDidMount() {
    this.ga();
    // this.props.postCartItems(this.props.cartItems) this line is commented because cart products show different sum values in response 
  }

  ga = () => {
    const products = []
    for (let i = 0; i < this.props.cartItems.length; i++) {
      products.push({
        'name': this.props.cartItems[i].name,
        'id': this.props.cartItems[i].sku,
        'price': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.offerprice,
        'brand': 'Sunny Diamonds',
        'category': this.props.cartItems[i].value,
        'variants': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.color,
        'quantity': this.props.cartItems[i].qty
      })
    }
    window.dataLayer = window.dataLayer || [];
    try {
      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'INR',
          'impressions': products
        }
      });
    }
    catch (err) {
      if (window.dataLayer.filter(function (obj) {
        return obj.errorMsg === err.message;
      }).length == 0) {
        window.dataLayer.push({
          'event': 'variable error',
          'errorMsg': err.message
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartItems != prevProps.cartItems) {
      this.setState({
        sync: true
      })
      this.props.addCartToServer(this.props.cartItems)
    }
  }

  cartRemove = (item) => {

    const products = []
    for (let i = 0; i < this.props.cartItems.length; i++) {
      products.push({
        'name': this.props.cartItems[i].name,
        'id': this.props.cartItems[i].sku,
        'price': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.offerprice,
        'brand': 'Sunny Diamonds',
        'category': this.props.cartItems[i].value,
        'variants': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.color,
        'quantity': this.props.cartItems[i].qty
      })
    }
    window.dataLayer = window.dataLayer || [];
    try {
      window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
          'remove': {
            'products': products
          }
        },
      });
    }
    catch (err) {
      if (window.dataLayer.filter(function (obj) {
        return obj.errorMsg === err.message;
      }).length == 0) {
        window.dataLayer.push({
          'event': 'variable error',
          'errorMsg': err.message
        });
      }
    }

    console.log("Removing from cart page", item)
    this.props.removeFromCart(item, item.selectedVariant)
    this.props.addCartToServer(this.props.cartItems)
  }

  CartimgeSource = imageSet => {
    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];
    images = imageSet.split(",");
    images.forEach(function (img) {
      let ext = img.substring(img.lastIndexOf("."));
      if (exts.includes(ext)) {
        imgeSrc[imgI] = img;
        imgI++;
      }
    });
    return imgeSrc[0];
  };

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  render() {
    const { cartItems, symbol, total } = this.props;

    return (
      <div>
        {/*SEO Support*/}
        <Seo type="cart-head" />
        <Breadcrumb title={"Cart"} />

        {cartItems.length > 0 ? (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row mt-5 d-flex justify-content-center">
                {!this.state.sync ?
                  <>
                    <div className="alert alert-secondary mb-3 d-none d-md-block">
                      <Loader text="Please wait as we are synchronizing your cart with server" />
                    </div>
                    <div className="alert alert-secondary mb-3 d-block d-md-none">
                      <Loader text="syncing your cart with server" />
                    </div>
                  </> : ""
                }

                <table className="table cart-table d-none d-md-block bg-white p-5">
                  <thead>
                    <tr className="table-head">
                      <th scope="col">image</th>
                      <th scope="col">product name</th>
                      <th scope="col" className="d-md-none d-lg-block">price</th>
                      <th scope="col">quantity</th>
                      <th scope="col">total</th>
                      <th scope="col">action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.slice().reverse()
                      .map((item, index) => {
                        const price = item.selectedVariant.offerprice < item.old_offerprice ? "Price drop" : "Price hike"
                        const color = item.selectedVariant.offerprice < item.old_offerprice ? "text-success border-success" : "text-danger border-danger"
                        return (
                          <tr key={index}>
                            <td>
                              <Link
                                to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                              >
                                <img
                                  src={`${process.env.PUBLIC_URL}` + this.CartimgeSource(item.selectedVariant.image)}
                                  alt={item.name}
                                  onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                                  />
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                              >
                                {item.name}
                                <br></br>
                                SKU: {item.sku}
                                <br></br>
                                <p className={"border border-secondary price_update d-inline-block rounded px-2 ml-1 mb-2 "}>Color: {item.metal_purity}{item.selectedVariant.color.replace("-", " ")}</p>
                              </Link>
                            </td>
                            <td className="d-md-none d-lg-table-cell">
                              <h3 className="cart_sum">
                                {symbol}
                                {item.selectedVariant.offerprice}
                              </h3>
                            </td>
                            <td>
                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="quantity-left-minus"
                                      onClick={() => this.props.decrementQty(item.id, item.selectedVariant)}
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fas fa-minus" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={item.qty}
                                    readOnly={true}
                                    className="form-control input-number"
                                  />
                                  <span className="input-group-prepend">
                                    <button
                                      className="quantity-right-plus"
                                      onClick={() => this.props.incrementQty(item, 1, item.selectedVariant)}
                                      data-type="plus"
                                      disabled={item.made_to_order == "false" ? item.qty >= item.stock ? true : false : false}
                                    >
                                      <i className="fas fa-plus" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              {item.made_to_order == "false" ? item.qty > item.stock ? "out of Stock" : "" : ""}
                            </td>
                            <td>
                              <h3 className="td-color cart_sum">
                                {symbol} {item.sum}
                              </h3>
                              {item.updated ?
                                <p data-toggle="popover" title={"Price changed from " + symbol + item.old_offerprice + " to " + symbol + item.selectedVariant.offerprice + " due to change in gold price"}
                                  className={"border price_update d-inline-block rounded px-1 mt-0 " + color}>
                                  {price}
                                </p>
                                : ""}
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                className="icon"
                                onClick={() => this.cartRemove(item, item.selectedVariant)}
                              >
                                <i className="fa fa-times" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="d-md-none d-block bg-white p-1">
                  {cartItems.slice().reverse()
                    .map((item, index) => {
                      const price = item.selectedVariant.offerprice < item.old_offerprice ? "Price drop" : "Price hike"
                      const color = item.selectedVariant.offerprice < item.old_offerprice ? "text-success border-success" : "text-danger border-danger"
                      return (
                        <div key={index} className="mb-2">
                          <div className="row d-flex justify-content-center">
                            <div className="col-4">
                              <Link
                                to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                              >
                                <img src={`${process.env.PUBLIC_URL}` + this.CartimgeSource(item.selectedVariant.image)}
                                  className="cart-image"
                                  alt={item.name}
                                  onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                                  />
                              </Link>
                              {item.made_to_order == "false" ? item.qty > item.stock ? "out of Stock" : "" : ""}
                            </div>
                            <div className="col-8">
                              <div className="d-flex justify-content-between">
                                <Link
                                  to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                                  className="ml-4"
                                >
                                  {item.name}
                                </Link>
                                <span className="td-color mr-3">
                                  <a
                                    href="javascript:void(0)"
                                    className="icon"
                                    onClick={() => this.cartRemove(item)}
                                  >
                                    <i className="icon-close" />
                                  </a>
                                </span>
                              </div>
                              <div className="row m-2">
                                <div className="qty-box ml-4">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <button
                                        type="button"
                                        className="quantity-left-minus"
                                        onClick={() =>
                                          this.props.decrementQty(item.id, item.selectedVariant)
                                        }
                                        data-type="minus"
                                        data-field=""
                                      >
                                        <i className="fas fa-minus" />
                                      </button>
                                    </span>
                                    <input
                                      type="text"
                                      name="quantity"
                                      value={item.qty}
                                      readOnly={true}
                                      className="form-control input-number"
                                    />
                                    <span className="input-group-prepend">
                                      <button
                                        className="quantity-right-plus"
                                        onClick={() =>
                                          this.props.incrementQty(item, 1, item.selectedVariant)
                                        }
                                        data-type="plus"
                                        disabled={item.made_to_order == "false" ? item.qty >= item.stock ? true : false : false}
                                      >
                                        <i className="fas fa-plus" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <h3 className="td-color cart_sum  ml-5">
                                  {symbol} {item.sum}
                                </h3>
                                {item.updated ?
                                  <p data-toggle="popover" title={"Price changed from " + symbol + item.old_offerprice + " to " + symbol + item.selectedVariant.offerprice + " due to change in gold price"} className={"border price_update d-inline-block rounded px-1 ml-1 " + color}>{price}</p>
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div>
                <h3 className="cart_sum text-center mt-4 ">
                  Total price : {symbol} {total}
                </h3>
              </div>
              <div className="row mx-md-5 px-md-5">
                <div className="col pb-3 text-md-center mt-2 mx-md-5 ">
                  <Link
                    to={`${process.env.PUBLIC_URL}/trending`}
                    data-lng="en"
                    className="btn btn-solid d-block"
                    rel="noopener noreferrer"
                  >
                    <div className="px-0">continue shopping</div>
                  </Link>
                </div>
                <div className="col pb-3 text-md-center mt-2 mx-md-5">
                  <Link
                    to={`${process.env.PUBLIC_URL}/checkout`}
                    data-lng="en"
                    className="btn btn-solid d-block"
                    rel="noopener noreferrer"
                  >
                    <div className="px-2">
                      <i className="fas fa-lock fa_lock_cart" /> checkout Securely
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 mt-5">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center py-5 my-5">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                        className="img-fluid mb-4"
                        alt=""
                      />
                      <h3>
                        <strong>Your Cart is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  extendedItems: state.cartList.extended_cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart)
});

export default connect(
  mapStateToProps,
  { removeFromCart, incrementQty, decrementQty, postCartItems, addCartToServer }
)(cartComponent);
