import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import store from "../../store";
import { getAllCategories, filterSort } from "../../actions";
import { MainLoader } from "../../loader";

class CollectionLeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutColumns: 4,
      layoutGrid: true,
      loaded: false,
      selectedColor: ''
    };
  }

  LayoutViewClicked(columns) {
    this.setState({
      layoutColumns: columns
    });
  }

  setColor = color => {
    this.setState({
      selectedColor: color,
    })
  }

  componentDidMount() {
    store.dispatch({ type: "FILTER_COLOR", color: "" })
    store.dispatch({ type: "SORT_BY", sort_by: "LowToHigh" })
    if
      (window.location.pathname == "/trending") this.props.getAllCategories("trending");
    else
      this.props.getAllCategories(
        this.props.match.params.id1 == "nose-pins" ?
          "nosepins"
          :
          this.props.match.params.id1
      );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ loaded: false })
      store.dispatch({ type: "RECEIVE_DB_CATEGORY", payload: "" });
      store.dispatch({ type: "FILTER_COLOR", color: "" });
      store.dispatch({ type: "FILTER_CAT", placeholder: "" });
      store.dispatch({ type: "SORT_BY", sort_by: "LowToHigh" });
      if
        (window.location.pathname == "/trending") this.props.getAllCategories("trending");
      else
        this.props.getAllCategories(
          this.props.match.params.id1 == "nose-pins" ?
            "nosepins"
            :
            this.props.match.params.id1
        );
    }
  }

  componentWillUnmount(){
    store.dispatch({ type: "RECEIVE_DB_CATEGORY", payload: "" });
  }

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  //List Layout View
  listLayout() {
    this.setState({ layoutGrid: false })
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-lg-12");
    });
    setTimeout(function () {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
  }

  //Grid Layout View
  gridLayout() {
    this.setState({ layoutGrid: true })
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document
      .querySelector(".product-wrapper-grid").classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function (el) {
      el.className = "";
      el.classList.add("col-lg-4");
    });
  }

  render() {
    let cat = this.props.match.params.id1;
    let type = this.props.match.params.id2;
    let value = this.props.match.params.id3;
    const { allCategory } = this.props;

    if (allCategory && allCategory.length != 0)
      return (
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>{allCategory.seo_title}</title>
            <meta name="description" content={allCategory.seo_description} />
            <meta name="keywords" content={allCategory.seo_keywords} />
            <meta property="og:site_name" content="Sunny Diamonds" />
            <meta property="og:type" content="Summary" />
            <meta property="og:title" content={allCategory.seo_title} />
            <meta
              property="og:description"
              content={allCategory.seo_description}
            />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="Summary" />
            <meta name="twitter:site" content="@SunnyDiamond_4c" />
            <meta name="twitter:creator" content="Sunny Diamonds" />
            <meta name="twitter:title" content={allCategory.seo_title} />
            <meta
              name="twitter:description"
              content={allCategory.seo_description}
            />
            {/* <meta name="robots" content={allCategory.seo_robots} /> */}
          </Helmet>

          <Breadcrumb title={cat ? cat : "all jewellery"} />

          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 collection-filter">
                    <div className="filter-stick">
                      <Filter
                        type={type}
                        value={value}
                        setColor={this.setColor}
                        allCategory={allCategory}
                      />
                      <NewProduct />
                    </div>
                    {/*side-bar banner end here*/}
                  </div>
                  <div className="collection-content col-lg-9 col-md-12">
                    <div className="page-main-content ">
                      <div className="row">
                        <div className="col-sm-12">
                          {/* Banner section  */}
                          <div className="col-lg-12 mt-0 pl-0 px-0">
                            <div className="media-container d-none d-md-block">
                              <img
                                className="img-fluid img-opacity"
                                src={
                                  `${process.env.PUBLIC_URL}` +
                                  allCategory.banner_src
                                }
                                onError={e => {
                                  e.target.src = `${process.env.PUBLIC_URL
                                    }/assets/images/sunny/no_image/no_Banner.webp`;
                                }}
                                onLoad={() => this.setState({ loaded: true })}
                                alt="Category page banner"
                              />
                            </div>
                            {/* {this.state.loaded === true ? <div className="media-text d-none d-md-block w-75">
                              <h1
                                className={
                                  "banner-title-" + allCategory.text_align
                                }
                                style={{
                                  color: allCategory.text_color
                                }}
                              >
                                {allCategory.banner_title}
                              </h1>

                              <h4
                                className={
                                  "banner-title-" + allCategory.text_align
                                }
                                style={{
                                  color: allCategory.text_color
                                }}
                              >
                                {allCategory.banner_content}
                              </h4>
                            </div> : ""} */}

                            <div className="title4 shp-title d-md-none">
                              <h1 className="title-inner4 banner-theme">
                                {allCategory.banner_title}
                              </h1>
                            </div>
                          </div>

                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="d-flex d-sm-flex d-md-none pt-4 px-1">
                                  <div className="flex-grow-1 d-flex justify-content-around">
                                    <div className="filter-main-btn">
                                      <button
                                        onClick={this.openFilter}
                                        className="filter-btn btn btn-theme px-4"
                                      >
                                        <i
                                          className="fa fa-filter"
                                          aria-hidden="true"
                                        />
                                        Filter
                                      </button>
                                    </div>
                                  </div>
                                  <div className="product-filter-content d-md-none">
                                    <div className="product-page-filter">
                                      <select
                                        className="py-1"
                                        onChange={e =>
                                          this.props.filterSort(e.target.value)
                                        }
                                      >
                                        <option value="LowToHigh">Price: Low to High</option>
                                        <option value="HighToLow">Price: High to Low</option>
                                        <option value="Newest">Newest Items</option>
                                        <option value="AscOrder">Sort By Name: A To Z</option>
                                        <option value="DescOrder">Sort By Name: Z To A</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <FilterBar
                                      listLayout={() => this.listLayout()}
                                      gridLayout={() => this.gridLayout()}
                                      onLayoutViewClicked={colmuns =>
                                        this.LayoutViewClicked(colmuns)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Products Listing Component */}
                            <ProductListing
                              listLayout={() => this.listLayout()}
                              gridLayout={() => this.gridLayout()}
                              layoutGrid={this.state.layoutGrid}
                              colSize={this.state.layoutColumns}
                              cat={cat}
                              selectedColor={this.state.selectedColor}
                              // type={type}
                              // value={value}
                              allCategory={allCategory}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    else
      return (
        <div className="height">
          <Breadcrumb />
          <MainLoader />
        </div>
      );
  }
}

const mapStateToProps = state => ({
  allCategory: state.data.category,
});

export default connect(
  mapStateToProps,
  {
    getAllCategories,
    filterSort  }
)(CollectionLeftSidebar);
