import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';

const ProductSlider = (props) => {

    const [picture, setPicture] = useState("");

    useEffect(() => {
        setPicture(props.item.variants[0].image.split(","))
    }, []);

    const validateURL = (url) => {
        const parsed = new URL(global.publicUrl + url)
        return ['https:', 'http:'].includes(parsed.protocol)
    }

    return (
        <div className="item">
            <div className="glitz-item uk-inline-clip uk-transition-toggle uk-light" tabIndex="0" style={{ padding: '15px' }}>
                <div className="glitz-img uk-inline-clip uk-transition-toggle" tabindex="0">
                    <Link to={validateURL(`${process.env.PUBLIC_URL}/${props.item.value}`) ? `${process.env.PUBLIC_URL}/${props.item.value}` : ''}>
                        <img
                            src={`${process.env.PUBLIC_URL}` + picture[0]}
                            onError={e => {
                                e.target.src = `${process.env.PUBLIC_URL
                                    }/assets/images/sunny/no_image/no_images.webp`;
                            }}
                            alt={props.item.name}
                        />

                        <img
                            className="uk-transition-scale-up uk-position-cover"
                            src={picture[1] ? `${process.env.PUBLIC_URL}` + picture[1] : `${process.env.PUBLIC_URL}` + picture[0]}
                            onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                            alt={props.item.name}
                        />
                    </Link>
                </div>
                <div className="glitz-content">
                    <Link to={validateURL(`${process.env.PUBLIC_URL}/${props.item.value}`) ? `${process.env.PUBLIC_URL}/${props.item.value}` : ''}>
                        <p className="para-one">{props.item.name}</p>
                    </Link>
                    <div className="price">
                        <span className="new-price">{props.item.variants[0].offerprice}</span>
                        <span className="old-price">{props.item.variants[0].price}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSlider;
