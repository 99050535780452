import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCategory } from "../../../services";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: this.props.type
    };
  }

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  push() {
    this.props.history.push("/terms-and-conditions")
  }


  render() {
    const { card } = this.props;
    const category = this.props.type;
    return (
      <div className="col-xl-3 col-lg-4 col-md-5 px-md-0 px-5">
        <div className="blog-sidebar sticky">
          <div className="theme-card px-0">
            {/* side bar  */}
            {/* <h2 className="pl-2">{card[0].title}</h2> */}
            <ul className="recent-blog">
              {card.map((item, i) => (
                <li
                  key={i}
                  className={item.name === category ? "active" : ""}
                >

                  <Link to={this.validateURL(`${process.env.PUBLIC_URL}${item.link}`) ? `${process.env.PUBLIC_URL}${item.link}` : ''}>
                    <h5 className="latest-faq my-1">{item.content}</h5>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, Ownprops) => ({
  card: getCategory(state.data.products, Ownprops.card)
});

export default connect(mapStateToProps)(SideBar);
