import React, { useState, useCallback, useRef } from "react";


import HowItsWork from "./dfe-how-its-work";
import CalculateEmi from "./dfe-calculate-emi";
import WhySunnyDiamonds from "./dfe-why-sunny-diamonds";
import DfeFaq from "./dfe-faq";
import DfeUserDetails from "./dfe-user-details";
import RedeemPlan from "./dfe-redeem-plan";




const DiamondForEveryone = (props) => {


    const userDfeDetails = () => {
        if (navigator.cookieEnabled) {
            if (sessionStorage.getItem("user_type") == "CUSTOMER") {
                return (
                    <DfeUserDetails />
                )
            } else return null
        }
        else return null

    }


    const [scroll, setScroll] = useState({
        how_it_work: "how_it_work",
        calculate_dfe: "calculate_dfe",
        why_sunny_diamonds: "why_sunny_diamonds"
    });

    const scrollToNode = useCallback((node) => {
        node.scrollIntoView({ behavior: 'smooth' });
    }, []);



    return (
        <section>
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                            <ul>
                                <li>
                                    <img
                                        src={`${process.env.PUBLIC_URL
                                            }/assets/images/sunny/Diamond_for_Everyone/Logo/SD_DFE_Logo.png`}
                                        alt="Sunny diamonds"
                                        className="img-fluid"
                                        style={{ width: 170 }}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className=" col-md-8 col-lg-8 col-xl-8 d-md-block d-none">
                            <ul className="nav justify-content-end d-flex flex-row dfe-top-header-font ">
                                <li className="h5 p-2 cursor-pointer dfe-hover-effect">
                                    Overview
                                </li>
                                <li className="h5 p-2 cursor-pointer dfe-hover-effect"
                                    onClick={() => scrollToNode(scroll.how_it_work)}
                                >
                                    How it works
                                </li>
                                <li className="h5 p-2 cursor-pointer dfe-hover-effect"
                                    onClick={() => scrollToNode(scroll.calculate_dfe)}
                                >
                                    Calculate DFE
                                </li>
                                <li className="h5 p-2 cursor-pointer dfe-hover-effect"
                                    onClick={() => scrollToNode(scroll.why_sunny_diamonds)}
                                >
                                    Why Sunny Diamonds

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container-fluid dfe-hellobar-header d-md-block d-none">
                    <div className="row justify-content-center">
                        <h5 className="text-center m-0 py-1">Introducing Sunny Diamonds DFE! A hassle-free Diamond for everyone to buy jewellery quickly, without burning a hole in your pocket</h5>
                    </div>
                </div>
            </section>
            <section>
                <div ref={(node) => scroll.how_it_work = node}></div>
                <div className="container">
                    <div className="row">
                        <img
                            src={`${process.env.PUBLIC_URL
                                }/assets/images/sunny/Diamond_for_Everyone/banner/banner.jpg`}
                            alt="Sunny diamonds"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </section>
            <div ref={(node) => scroll.calculate_dfe = node}></div>
            {/* User Details Section  */}

            {userDfeDetails()}


            {/* How it works section  */}
            <HowItsWork />

            <div ref={(node) => scroll.why_sunny_diamonds = node}></div>
            {/* Calculate DFE Section */}
            <CalculateEmi />

            {/* Redeem Plan  */}
            {/* <RedeemPlan /> */}

            {/* Why Sunny Diamonds Section  */}
            <WhySunnyDiamonds />

            {/*FAQ Section */}
            <DfeFaq />

            {/* Last paragraph */}
            <section>
                <div className="container mt-5">
                    <div className="row">
                        <p className="px-5 justify-content-center">*Gift/Gift Voucher shall carry the meaning of discount vouchers provided by the Company for the monthly installments made by customer scheme under the DFE! and should not be construed as any other benefits and/or contribution by the company to the customers in this regard.</p>
                    </div>
                </div>
            </section>

            <div className="d-none">

            </div>


        </section >
    );
}


export default DiamondForEveryone;