import React, { Component } from 'react';

class FairPrice extends Component {
    render() {
        const {
            price,
            strike_price,
            symbol
          } = this.props;
      
        return (
            <div className="row px-4 px-md-0">
                <div className="col-lg-12 col-md-12">
                    <div className="stripe-section">
                        <h5 className='text-center'>Fair Pricing</h5> 
                        <span className='d-block text-center'>
                            <a href={`${process.env.PUBLIC_URL}/fair-pricing`} target="_blank" rel='noreferrer noopener'>
                                <h6>
                                    <u>Learn More</u>
                                </h6>
                            </a>
                        </span>
                        <div className="row mt-3">
                            <div className="col-6 d-flex align-items-center justify-content-center" style={{borderRight:"1px solid"}}>
                                <h6>{symbol}{price} Sunny Diamonds</h6>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h6>{symbol}{strike_price} Estimated Retail</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FairPrice;