import React, { useState } from "react";

import giftJson from "../../../api/gift.json"

const ChooseAmount = (props) => {

    const [maxAmount, setMaxAmount] = useState(true);

    return (
        <div className="container gift-index-active px-lg-5 px-0 py-lg-5 py-0">
            <div className="row justify-content-center">
                <div className="col-sm-12 col-md-10 col-lg-12 mx-2 text-center d-flex flex-lg-row flex-column">
                    <div className="mx-lg-3 mx-5">
                        <p className="text-center gift-para text-uppercase">Please enter custom amount</p>
                        <input
                            type="tel"
                            className="form-control mb-2"
                            placeholder="₹"
                            value={props.amount}
                            onChange={(e) => {
                                let amount = Number.parseInt(e.target.value)
                                props.handleAmountData(isNaN(amount) ? "" : amount)
                            }}
                            onBlur={() => { props.amount <= 500000 ? setMaxAmount(true) : setMaxAmount(false) }}
                        />
                        {
                            props.validatoramount.current.message("Amount", props.amount, "required|numeric|min:0,num")
                        }

                        {!maxAmount ?
                            <div className="text-danger">
                                <b>NOTE:</b>
                                <small>
                                    Maximum amount limit is ₹500000.
                                </small>
                            </div> :
                            props.amount != 500 && props.amount % 500 == 0 ?
                                <div>
                                    <b>NOTE:</b>
                                    <small>
                                        Amount must be minimum of ₹1000 and <br />multiple of ₹500
                                    </small>
                                </div> :
                                <div className="text-danger">
                                    <b>NOTE:</b>
                                    <small>
                                        Amount must be minimum of ₹1000 and <br />multiple of ₹500
                                    </small>
                                </div>
                        }
                    </div>
                    <div className="mx-lg-3 mx-0">
                        <p className="text-center mt-lg-5 mt-2 font-weight-bold gift-para text-uppercase">or</p>
                    </div>
                    <div className="mx-lg-3 mx-0">
                        <p className="text-center m-lg-1 m-0 gift-para text-uppercase">
                            Choose from below
                        </p>
                        <div className="d-block text-center">
                            {
                                giftJson.filter(c => c.filter === "amount")[0].amounts.map((amount, i) => (
                                    <button className="btn btn-gift m-2"
                                        onClick={
                                            () => {
                                                props.handleAmountData(amount)
                                                props.handleIndexData(4)
                                                if (window.outerWidth >= 1024) {
                                                    window.scrollTo(0, 435);
                                                } else {
                                                    window.scrollTo(0, 0);
                                                }
                                            }
                                        }
                                        key={i}>
                                        ₹{amount}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChooseAmount;
