import React from "react"

import { BrowserRouter, Route, Switch, } from 'react-router-dom';

// Layouts
import Sunny from './components/layouts/sunny-diamonds/main';

//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";

//Gift Card Page

import GiftCard from "./components/pages/Gift-Card/index";
import GiftPurchaseResponse from "./components/pages/Gift-Card/gift-status-page";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";

// Features
import App from './components/app'
import Cart from './components/cart'
import Compare from './components/compare/index'
import wishList from './components/wishlist'
import checkOut from './components/checkout'
import orderResponse from './components/checkout/order_response'

// Extra Pages
import AboutUs from './components/layouts/sunny-diamonds/about-us'
import AboutCms from './components/layouts/sunny-diamonds/about-cms'
import PageNotFound from './components/pages/404'
import Login from './components/pages/login'
import Register from './components/pages/register'
import Search from './components/pages/search/search'
import ForgetPassword from './components/pages/forget-password'
import ForgetNewPassword from './components/pages/forgot-new-passwrd'
import ChangePassword from './components/pages/change-password'
import CustomerService from './components/layouts/sunny-diamonds/customer-service'
import StoreDetails from './components/pages/storedetails'
import StoreLoc from './components/pages/store-locator'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq/index'
import Careers from './components/pages/Career-page/index';
import Form from './components/pages/form';

//Diamond For Everyone
import DiamondForEveryone from "./components/pages/Diamond-for-everyone/dfe-landing-page";
import DfeForm from "./components/pages/Diamond-for-everyone/dfe-forms/dfe-form";
import ActivePlan from "./components/pages/Diamond-for-everyone/active-plan";

//policies
import PrivacyPolicy from './components/pages/policy/privacy-policy';
import TermsAndCondition from './components/pages/policy/terms-and-condition';
import MoneyBackPolicy from './components/pages/policy/money-back';
import ReturnPolicy from './components/pages/policy/return-policy';
import CancellationPolicy from './components/pages/policy/cancellation';
import ShippingPolicy from './components/pages/policy/shipping';
import CashOnDeliveryPolicy from './components/pages/policy/cash-on-delivery';
import ExchangePolicy from './components/pages/policy/exchange-and-resizing-policy';
import OldGoldPolicy from './components/pages/policy/old-gold';
import DfeTc from './components/pages/policy/dfe-tc';

//customer
import OrderTrack from './components/pages/order-tracking';
import TrackDetails from './components/pages/track-details';
import MyOrderDetails from './components/pages/my_orders';
import Profile from './components/pages/profile';
import Success from './components/pages/success';

//Education
import RingSize from './components/pages/education/ring-size-chart';
import Education from './components/pages/education/index';

//News Page
import NewsPage from './components/pages/news-page/index'

//Why Sunny Diamonds
import fairPricing from './components/pages/fair-pricing';
import GradingCertificate from './components/pages/grading-diamond-certificates';
import bangleSizeChart from './components/pages/education/bangle-size-chart';
import PaymentMethods from './components/pages/payment-method';
import WhyFromUs from './components/pages/why-buy-from-us';
import giftCardTc from "./components/pages/policy/gift-card-tc";

import { ScrollContext } from 'react-router-scroll-4';

// Checkout dummy
import CheckOut1 from "./components/checkout/upgraded-checkout/index_1"

//new template
import Listing from "./components/new_listing";
import NewHomePage from "./components/new_home_page/index";
import PdpDetails from "./components/new_pdp";

//new CMS Pages
import NewAboutUs from "./components/pages/about-us/index"



const Routes = () => {
    return (
        <BrowserRouter basename={'/'}>
            <ScrollContext>
                <Switch>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Sunny} /> */}
                    <App>
                        <Switch>

                            {/* new home page  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={NewHomePage} />

                            {/*Routes For Features (Product Collection) */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/trending`} component={CollectionLeftSidebar} /> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2/:id3`} component={CollectionLeftSidebar} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2`} component={CollectionLeftSidebar} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1`} component={CollectionLeftSidebar} /> */}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/jewellery/`} component={CollectionLeftSidebar} /> */}

                            {/*Routes For custom Features*/}
                            <Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
                            <Route path={`${process.env.PUBLIC_URL}/wishlists`} component={wishList} />
                            <Route path={`${process.env.PUBLIC_URL}/compare_list`} component={Compare} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut} /> */}
                            <Route path={`${process.env.PUBLIC_URL}/order-response/:id`} component={orderResponse} />

                            {/*Routes For Extra Pages*/}
                            <Route exact path={`${process.env.PUBLIC_URL}/locations`} component={StoreLoc} />
                            <Route exact path={`${process.env.PUBLIC_URL}/locations/:id`} component={StoreDetails} />
                            <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Careers} />
                            <Route exact path={`${process.env.PUBLIC_URL}/careers/apply`} component={Form} />
                            {/* <Route path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} /> */}

                            {/* <Route path={`${process.env.PUBLIC_URL}/about-cms`} component={AboutCms} /> */}

                            <Route path={`${process.env.PUBLIC_URL}/search`} component={Search} />
                            <Route path={`${process.env.PUBLIC_URL}/customer-service`} component={CustomerService} />
                            <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                            <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
                            <Route path={`${process.env.PUBLIC_URL}/sales/guest/form`} component={OrderTrack} />
                            <Route path={`${process.env.PUBLIC_URL}/track-details`} component={TrackDetails} />
                            <Route path={`${process.env.PUBLIC_URL}/my-orders`} component={MyOrderDetails} />
                            <Route path={`${process.env.PUBLIC_URL}/user/profile`} component={Profile} />
                            <Route path={`${process.env.PUBLIC_URL}/customer/account/success`} component={Success} />

                            {/* Education */}
                            <Route path={`${process.env.PUBLIC_URL}/education/size-guide/rings-size-guide`} component={RingSize} />
                            <Route path={`${process.env.PUBLIC_URL}/education/size-guide/bangle-and-bracelet-size-guide`} component={bangleSizeChart} />
                            <Route exact path={`${process.env.PUBLIC_URL}/education/:id1/:id2/:id3`} component={Education} />
                            <Route exact path={`${process.env.PUBLIC_URL}/education/:id1/:id2`} component={Education} />

                            {/* account */}
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/create`} component={Register} />
                            <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgetPassword} />
                            <Route path={`${process.env.PUBLIC_URL}/change-password`} component={ChangePassword} />
                            <Route path={`${process.env.PUBLIC_URL}/new-password`} component={ForgetNewPassword} />

                            {/* Policies */}
                            <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsAndCondition} />
                            <Route path={`${process.env.PUBLIC_URL}/money-back-policy`} component={MoneyBackPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/cancellation-policy`} component={CancellationPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/return-policy`} component={ReturnPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/cash-on-delivery-policy`} component={CashOnDeliveryPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/shipping-policy`} component={ShippingPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/exchange-and-resizing-policy`} component={ExchangePolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/old-gold-purchase-policy`} component={OldGoldPolicy} />
                            <Route path={`${process.env.PUBLIC_URL}/gift-card-terms-and-conditions`} component={giftCardTc} />
                            <Route path={`${process.env.PUBLIC_URL}/dfe-terms-and-conditions`} component={DfeTc} />

                            {/* News Page */}
                            <Route path={`${process.env.PUBLIC_URL}/news`} component={NewsPage} />

                            {/* Why Sunny Diamonds  */}
                            <Route path={`${process.env.PUBLIC_URL}/fair-pricing`} component={fairPricing} />
                            <Route path={`${process.env.PUBLIC_URL}/education/grading-diamond-certificates`} component={GradingCertificate} />
                            <Route path={`${process.env.PUBLIC_URL}/why-buy-from-us/payment-option`} component={PaymentMethods} />
                            <Route path={`${process.env.PUBLIC_URL}/why-buy-from-us/:id`} component={WhyFromUs} />

                            {/*Diamond For Everyone*/}

                            <Route path={`${process.env.PUBLIC_URL}/diamond-for-everyone`} component={DiamondForEveryone} />
                            <Route path={`${process.env.PUBLIC_URL}/dfe-form`} component={DfeForm} />
                            <Route path={`${process.env.PUBLIC_URL}/dfe-active-plan`} component={ActivePlan} />

                            {/* Gift Card Page  */}
                            <Route path={`${process.env.PUBLIC_URL}/gifts`} component={GiftCard} />
                            <Route path={`${process.env.PUBLIC_URL}/gifts-purchase/:id`} component={GiftPurchaseResponse} />

                            {/* 404 */}
                            <Route path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound} />

                            {/* checkout routing */}
                            <Route exact path={`${process.env.PUBLIC_URL}/checkout`} component={CheckOut1} />


                            {/* new routes  */}

                            {/* Listing page  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/trending`} component={Listing} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/`} component={Listing} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1`} component={Listing} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2`} component={Listing} />
                            <Route exact path={`${process.env.PUBLIC_URL}/jewellery/:id1/:id2/:id3`} component={Listing} />


                            {/* NEW CMS PAGE ROUTE  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={NewAboutUs} />

                            {/* Product detail page  */}
                            <Route exact path={`${process.env.PUBLIC_URL}/:id`} component={PdpDetails} />


                            {/*Routes For Single Product*/}
                            {/* <Route exact path={`${process.env.PUBLIC_URL}/:id`} component={LeftSideBar} /> */}

                            <Route path={`${process.env.PUBLIC_URL}/*`} component={PageNotFound} />
                        </Switch>
                    </App>
                </Switch>
            </ScrollContext>
        </BrowserRouter>
    )
}

export default Routes
