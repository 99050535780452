import React from 'react';

import './instagram-widget.css'

const InstagramWidget = (props) => {

    return (
        <section class="image-gallery">
            <div class="home-container">
                <div class="heading-section">
                    <h2 class="heading-title title-normal">As Seen On @Sunnydiamonds</h2>
                    <div class="heading-icon">
                        <span class="hr-line">&#160;</span>
                        <span class="diamond-icon">&#160;</span>
                        <span class="hr-line">&#160;</span>
                    </div>
                </div>
            </div>

            <div className="tagembed-container" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
                <div
                    className="tagembed-socialwall"
                    data-wall-id={window.rc.pinterest.data_wall_id}
                    view-url={window.rc.pinterest.view_url}
                />
            </div>


        </section>
    );
};

export default InstagramWidget;