import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { errorMsgAction, dfeSubmit } from "../../../../actions";
import store from "../../../../store";
import ContactDfe from "./contact-details-dfe";
import NomineeForm from "./nominee";
import PaymentDfe from "./payment-dfe";

const DfeForm = (props) => {

    const [index, setIndex] = useState(1)

    const [contact, setContact] = useState({
        full_name: '',
        email: '',
        address: '',
        phone: '',
        pincode: '',
        state: '',
        city: '',
        dob: ''
    })

    const [nominee, setNominee] = useState({
        name: "",
        relationship: "",
        phone: "",
        nationality: "",
        verify_age: false
    })

    const [plan, setPlan] = useState({
        amount: '',
        pan_card: '',
        pan_image: '',
        automate_payment: false
    })

    const handleIndexData = (index) => { setIndex(index) }

    useEffect(() => {
        store.dispatch({ type: "RECEIVE_REQUEST_OTP", payload: "" });
        store.dispatch({ type: "RECEIVE_VERIFY_OTP", payload: "" });
        props.errorMsgAction("RESET_OTP")
    }, []);

    const handleContactDetails = (event) => {
        event.persist()
        setContact(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
    }

    const handleMobileDetails = (value) => {
        setContact(prevState => ({
            ...prevState, ["phone"]: value
        }))
    }

    const handleNomineeDetails = (event) => {
        event.persist()

        if (event.target.name == 'verify_age') {
            setNominee(prevState => ({
                ...prevState, [event.target.name]: event.target.checked
            }))
        } else {
            setNominee(prevState => ({
                ...prevState, [event.target.name]: event.target.value
            }))
        }
    }

    const handlePlanData = (event) => {
        event.persist()
        if (event.target.name == 'automate_payment') {
            setPlan(prevState => ({
                ...prevState, [event.target.name]: event.target.checked
            }))
        }
        if (event.target.name == 'pan_image') {
            setPlan(prevState => ({
                ...prevState, [event.target.name]: event.target.files[0]
            }))
        }
        else {
            setPlan(prevState => ({
                ...prevState, [event.target.name]: event.target.value.toUpperCase()
            }))
        }
    }
    const handleAmountData = (value) => {
        setPlan(prevState => ({
            ...prevState, ["amount"]: value
        }))
    }

    const handleSubmit = () => {
        props.dfeSubmit({ contact, nominee, plan })
    }

    return (
        <section>
            {/* Timeline  */}
            <div className="container">
                <div className="row text-center justify-content-center mb-5">
                    <div className="col-lg-5 col-md-8 col-10">
                        <img
                            src={`${process.env.PUBLIC_URL
                                }/assets/images/sunny/Diamond_for_Everyone/Logo/SD_DFE_LOGO.png`}
                            alt="Sunny diamonds"
                            className="img-fluid"
                            style={{ width: "150px" }}
                        />
                    </div>
                </div>
            </div>
            {window.innerWidth >= 1024 ?
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <ContactDfe
                                otp_req={props.otp_req}
                                otp_verify={props.otp_verify}
                                contact={contact}
                                handleContactDetails={handleContactDetails}
                                handleMobileDetails={handleMobileDetails}
                            />
                        </div>
                        <div className="col-lg-4 col-12">
                            <NomineeForm
                                nominee={nominee}
                                handleNomineeDetails={handleNomineeDetails}
                            />
                        </div>
                        <div className="col-lg-4 col-12">
                            <PaymentDfe
                                plan={plan}
                                handlePlanData={handlePlanData}
                                handleAmountData={handleAmountData}
                                handleSubmit={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
                :
                <div className="contaienr">
                    <div className="row justify-content-center">
                        <div className="col-12 p-4">
                            {index == 1 ?
                                <ContactDfe
                                    handleIndexData={handleIndexData}
                                    otp_req={props.otp_req}
                                    otp_verify={props.otp_verify}
                                    contact={contact}
                                    handleContactDetails={handleContactDetails}
                                    handleMobileDetails={handleMobileDetails}
                                /> : ""
                            }

                            {index == 2 ?
                                <NomineeForm
                                    handleIndexData={handleIndexData}
                                    nominee={nominee}
                                    handleNomineeDetails={handleNomineeDetails}
                                /> : ""
                            }

                            {index == 3 ?
                                <PaymentDfe
                                    plan={plan}
                                    handleIndexData={handleIndexData}
                                    handlePlanData={handlePlanData}
                                    handleAmountData={handleAmountData}
                                    handleSubmit={handleSubmit}
                                /> : ""
                            }

                        </div>
                    </div>
                </div>

            }


            {/* Need Help  */}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-7 mt-3">

                        <h3 className="text-center mt-3">Need Help</h3>
                        <p className="text-center">
                            <a href="tel:+919961870099" target="_blank" rel="noopener noreferrer" >
                                +91 9961 870 099
                            </a>{' '}
                            (Helpline) everyday, 9AM to 6:30 PM IST (MON-SUN)</p>

                        <div className="d-flex justify-content-around">
                            <a
                                href="tel:+919961870099"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#00000" class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                </svg>
                            </a>
                            <a
                                href="mailto:customerservice@sunnydiamonds.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#00000" class="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                </svg>

                            </a>
                            <a
                                href="https://api.whatsapp.com/send?phone=919961870099"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "rgb(114, 34, 87)" }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#00000" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

const mapStateToProps = state => ({
    otp_req: state.data.otp_request,
    otp_verify: state.data.otp_verify,
});


export default connect(mapStateToProps, { errorMsgAction, dfeSubmit })(DfeForm);