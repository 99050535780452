import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../common/index.scss";
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import FeatureIn from "../layouts/common/featurein";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import {
  addToCart,
  addToCartUnsafe,
  addToWishlist,
  addToCompare,
  getAllPdps,
  getPromoCode,
  addCartToServer
} from "../../actions";
import store from "../../store";
import { MainLoader } from "../../loader";
import Recently from "../layouts/sunny-diamonds/recently";

class LeftSideBar extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      nav1: null,
      nav2: null,
      variants: [],
      pictures: [],
      price_breakup: [],
      qty: 1,
    };
  }

  componentDidMount() {
    store.dispatch({ type: "RECEIVE_PDP", payload: "" });
    this.props.getAllPdps(this.props.match.params.id);
    this.props.getPromoCode();
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      store.dispatch({ type: "RECEIVE_PDP", payload: "" });
      this.props.getAllPdps(this.props.match.params.id);
    }
    if (this.props.allProducts && this.props.allProducts !== prevProps.allProducts) {
      this.setState({
        variants: this.props.allProducts.variants[0]
      });
    }
  }

  setVariant = vari => {
    // console.log(vari)
    this.setState({
      variants: vari,
    })
  }

  filterClick() {
    document.getElementById("filter").style.left = "-15px";
  }

  backClick() {
    document.getElementById("filter").style.left = "-365px";
  }

  render() {

    const {
      symbol,
      addToCart,
      addToCartUnsafe,
      addToWishlist,
      addToCompare,
      allProducts,
      cartItems,
      addCartToServer
    } = this.props;

    const { variants } = this.state;

    if (allProducts && allProducts.length !== 0)
      return (
        <div>
          {/* SEO Support */}
          <Helmet>
            <title>{allProducts.name}</title>
            <meta name="description" content={allProducts.description} />
            <meta name="keywords" content={allProducts.name} />
            <meta property="og:site_name" content="Sunny Diamonds" />
            <meta property="og:type" content="Summary" />
            <meta property="og:title" content={allProducts.name} />
            <meta
              property="og:description"
              content={allProducts.description}
            />
            <meta property="og:url" content={window.location.href} />
            <meta name="twitter:card" content="Summary" />
            <meta name="twitter:site" content="@SunnyDiamond_4c" />
            <meta name="twitter:creator" content="Sunny Diamonds" />
            <meta name="twitter:title" content={allProducts.name} />
            <meta
              name="twitter:description"
              content={allProducts.description}
            />
            <meta name="robots" content={allProducts.seo_robots} />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb title={allProducts ? allProducts.name : ""} />

          {/*Section Start*/}
          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 collection-filter" id="filter">
                    <div className="collection-mobile-back pl-5 d-md-none d-sm-block">
                      <span onClick={this.backClick} className="filter-back">
                        <i className="fa fa-angle-left" aria-hidden="true" />
                        back
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="row">
                      <div className="row">
                        <DetailsWithPrice
                          cartItems={cartItems}
                          symbol={symbol}
                          item={allProducts}
                          qty={this.state.qty}
                          navOne={this.state.nav1}
                          addToCartClicked={addToCart}
                          addCartToServer={addCartToServer}
                          BuynowClicked={addToCartUnsafe}
                          addToWishlistClicked={addToWishlist}
                          addToCompareClicked={addToCompare}
                          setVariant={this.setVariant}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                    <DetailsTopTabs item={variants} />
                    {/* Recently Section */}
                    <div className="my-3">
                      <Recently type="sunny-diamonds" />
                    </div>
                    <FeatureIn />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Section End*/}
        </div>
      );
    else
      return (
        <div className="height">
          <Breadcrumb />
          <MainLoader />
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    symbol: state.data.symbol,
    allProducts: state.data.pdp,
    cartItems: state.cartList.cart
  };
};

export default connect(
  mapStateToProps,
  { addToCart, addToCartUnsafe, addToWishlist, addToCompare, getAllPdps, getPromoCode, addCartToServer }
)(LeftSideBar);
