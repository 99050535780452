import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import ProductDetails from './components/product-details';
import ProductInfo from './components/product-info';
import RelatedProducts from './components/related-products';

import {
    addToCart,
    addToCartUnsafe,
    addToWishlist,
    addToCompare,
    getAllPdps,
    addCartToServer
} from "../../actions";
import store from "../../store";
import { MainLoader } from "../../loader";

// import "./assets/css/index.css"
import './css/main.css'
import InstagramWidget from "../new_template_common/instagram_widget/index";
class PdpDetails extends Component {

    constructor() {
        super();
        this.state = {
            variants: [],
        };
    }

    urlParamter() {
        const queryParams = new URLSearchParams(window.location.search);
        const variant_id = queryParams.get('variant_id')
        let selectedVariant = []
        let list = this.props.allProducts.variants.map((item) => (item.id == variant_id))
        if (list.includes(true))
            selectedVariant = this.props.allProducts.variants.filter(item => (item.id == variant_id))[0]
        else {
            window.history.replaceState({}, "", window.location.pathname);
            selectedVariant = this.props.allProducts.variants[0]
        }
        this.setState({ variants: selectedVariant })
    }

    componentDidMount() {
        store.dispatch({ type: "RECEIVE_PDP", payload: "" });
        this.props.getAllPdps(this.props.match.params.id);
        if (window.ApplicationTagEmbed)
            window.ApplicationTagEmbed.init()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            store.dispatch({ type: "RECEIVE_PDP", payload: "" });
            this.props.getAllPdps(this.props.match.params.id);
        }
        if (this.props.allProducts && this.props.allProducts !== prevProps.allProducts) {
            this.urlParamter()
        }
    }

    setVariant = vari => {
        this.setState({ variants: vari, })
    }

    render() {

        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            addToCompare,
            allProducts,
            addCartToServer,
            cartItems
        } = this.props;

        const { variants } = this.state;

        if (allProducts && allProducts.length != 0)
            return (
                <div className="site-main">
                    {/* SEO Support */}
                    <Helmet>
                        <title>{allProducts.name}</title>
                        <meta name="description" content={allProducts.description} />
                        <meta name="keywords" content={allProducts.name} />
                        <meta property="og:site_name" content="Sunny Diamonds" />
                        <meta property="og:type" content="Summary" />
                        <meta property="og:title" content={allProducts.name} />
                        <meta property="og:description" content={allProducts.description} />
                        <meta property="og:url" content={window.location.href} />
                        <meta name="twitter:card" content="Summary" />
                        <meta name="twitter:site" content="@SunnyDiamond_4c" />
                        <meta name="twitter:creator" content="Sunny Diamonds" />
                        <meta name="twitter:title" content={allProducts.name} />
                        <meta name="twitter:description" content={allProducts.description} />
                        <meta name="robots" content={allProducts.seo_robots} />
                    </Helmet>
                    {/*SEO Support End */}

                    <ProductDetails
                        symbol={symbol}
                        item={allProducts}
                        cartItems={cartItems}
                        addToCartClicked={addToCart}
                        addCartToServer={addCartToServer}
                        BuynowClicked={addToCartUnsafe}
                        addToWishlistClicked={addToWishlist}
                        addToCompareClicked={addToCompare}
                        setVariant={this.setVariant}
                        history={this.props.history}
                    />

                    <ProductInfo item={variants} />

                    <RelatedProducts />

                    <InstagramWidget />
                </div>
            );
        else
            return (
                <div className="height">
                    <MainLoader />
                </div>
            );
    }
}

const mapStateToProps = (state) => {
    return {
        symbol: state.data.symbol,
        allProducts: state.data.pdp,
        cartItems: state.cartList.cart
    };
};

export default connect(mapStateToProps, { addToCart, addToCartUnsafe, addCartToServer, addToWishlist, addToCompare, getAllPdps })(PdpDetails);