import React from "react";


import './style.css'
import TimeLineData from "./timeline_data.json"

const TimeLine = (props) => {

    return (
        <section className="about-us-section">
            <div className="container">

                <div className="about-us-tile">
                    <div class="heading-section">
                        <h2 class="heading-title title-normal p-0">About Us</h2>
                        <div class="heading-icon m-0">
                            <span class="hr-line">&#160;</span>
                            <span class="diamond-icon">&#160;</span>
                            <span class="hr-line">&#160;</span>
                        </div>
                    </div>
                </div>


                <div className="aboutus-content">
                    <p className="normal-para">
                        For true diamond cognoscenti, there is only one name that stands for unwavering trust and unparalleled brilliance.
                        Sunny Diamonds. Hallmarked as the go-to destination for the perfect diamond experience, we are the only retail jeweller
                        who offers Internally Flawless (IF) Diamonds on the entire spectrum of diamond jewellery today.
                    </p>
                </div>

                <div className="about-us-title" >
                    <div class="heading-section">
                        <h2 class="heading-title title-normal p-0">Timeline</h2>
                        <div class="heading-icon m-0">
                            <span class="hr-line">&#160;</span>
                            <span class="diamond-icon">&#160;</span>
                            <span class="hr-line">&#160;</span>
                        </div>
                    </div>
                </div>
                <div className="aboutus-content">
                    {TimeLineData.map((data, i) => (
                        <div key={i}>
                           {data.type == "image" ? <img src={data.image} alt="timeline image" /> : ""} 
                            <p className="normal-para">{data.paragraph}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section >
    )
}

export default TimeLine