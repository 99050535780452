import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    actChangePassword,
    actProfile,
    actProfileEdit,
    actUserLogout
} from "../../actions";
import { connect } from "react-redux";

class ForgetNewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPasswrd: "",
            confirmPasswrd: "",
            email: this.props.profile_data.email,
            user_id: this.props.profile_data.user_id,
            passwordShown: false,
            passwordShown1: false
        };
        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = event => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    };

    componentDidMount() {
        if (
            sessionStorage.getItem("user_id") === "" ||
            sessionStorage.getItem("token") === "" ||
            sessionStorage.getItem("user_type") !== "CUSTOMER"
        ) {
            this.props.history.push("/login");
        }
        this.props.actProfile();
    }

    togglePasswordVisiblity = () => {
        this.setState({
            passwordShown: this.state.passwordShown ? false : true
        });
    };

    togglePasswordVisiblity1 = () => {
        this.setState({
            passwordShown1: this.state.passwordShown1 ? false : true
        });
    };


    render() {
        const eye = <FontAwesomeIcon icon={faEye} />;

        const handleSubmit = event => {
            event.preventDefault();
            if (this.validator.allValid()) {
                this.props.actChangePassword(this.state);
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        };

        const logoutSubmit = event => {
            this.props.actUserLogout();
        };

        return (
            <div>
                <Breadcrumb title={"change password"} />

                {/*Forget Password section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2">
                                <div className="account-sidebar">
                                    <a className="popup-btn">my account</a>
                                </div>
                                <div className="dashboard-left mt-2">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true" /> back
                                        </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                                                    My Orders
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                                                    Edit Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                                                    DFE Active Plan
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL}/change-password`}>
                                                    Change Password
                                                </Link>
                                            </li>
                                            <li className="last">
                                                <a onClick={logoutSubmit}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="dashboard-right mt-2">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>Forgot Password</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <form className="theme-form" onSubmit={handleSubmit}>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">
                                                            New Password
                                                        </label>
                                                        <input
                                                            type={this.state.passwordShown1 ? "text" : "password"}
                                                            className="form-control"
                                                            name="newPassword"
                                                            id="newPassword"
                                                            placeholder="New Password"
                                                            value={this.state.newPassword}
                                                            onChange={this.setStateFromInput}
                                                        />
                                                        <i
                                                            className="new_password"
                                                            onClick={this.togglePasswordVisiblity}
                                                        >
                                                            {eye}
                                                        </i>{" "}
                                                        {this.validator.message(
                                                            "New Password",
                                                            this.state.newPassword,
                                                            "required"
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="number">
                                                            Confirm Password
                                                        </label>
                                                        <input
                                                            type={this.state.passwordShown2 ? "text" : "password"}
                                                            className="form-control"
                                                            name="confirmPassword"
                                                            placeholder="Confirm Password"
                                                            value={this.state.confirmPassword}
                                                            onChange={this.setStateFromInput}
                                                        />
                                                        <i
                                                            className="confirm_password"
                                                            onClick={this.togglePasswordVisiblity1}
                                                        >
                                                            {eye}
                                                        </i>{" "}
                                                        {this.validator.message(
                                                            "confirm Password",
                                                            this.state.confirmPassword,
                                                            `required|in:${this.state.newPassword}`,
                                                            { messages: { in: "Passwords need to match!" } }
                                                        )}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <button className="btn btn-solid btn-login" type="submit">
                                                                Change
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <img src="/assets/images/sunny/change_password/Password.png" className="d-md-block d-none" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile_data: state.data.profile_data
});

export default connect(
    mapStateToProps,
    { actProfile, actProfileEdit, actChangePassword, actUserLogout }
)(ForgetNewPassword);
