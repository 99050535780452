import React, { Component } from "react";
import { Link } from "react-router-dom";

import Modal from "react-responsive-modal";




class SpecialProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openQuantity: false,
      inStock: "",
      quantity: 1,
      quickQty: 1,
      images: "",
      selectedColor: "",
      selectedVariant: {},
      price: ""
    };
  }

  componentDidMount() {
    this.setState({
      images: JSON.parse(this.props.product.variants)[0].image.split(","),
      price: JSON.parse(this.props.product.variants)[0].offerprice,
      selectedVariant: JSON.parse(this.props.product.variants)[0],
      inStock:
        this.props.product.made_to_order == "true" ? "Made to order" : "In Stock"
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.product != prevProps.product) {
      this.setState({
        // images: this.props.product.variants[0].image.split(",")
        images: JSON.parse(this.props.product.variants)[0].image.split(","),
        price: JSON.parse(this.props.product.variants)[0].offerprice
      });
    }
    if (this.props.selectedColor != prevProps.selectedColor) {
      if (this.props.selectedColor) {
        let filterItemId = [];
        let variants = JSON.parse(this.props.product.variants).filter(vari => {
          if (vari.color == this.props.selectedColor) {
            return true;
          }
        })
        if (variants[0]) {
          this.onClickHandle(variants[0])
        }
      }
    }
  }


  onClickHandle(vari) {
    this.setState({
      images: vari.image.split(","),
      price: vari.offerprice,
      selectedColor: vari.color,
      selectedVariant: vari
    })
  }

  colorHandle(event, class_name) {
    var elems = document.querySelectorAll("." + class_name + " li");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active-cat");
    });
    event.target.classList.add("active-cat");
  }

  colorViewHandle(event, class_name) {
    var elems = document.querySelectorAll("." + class_name + " li");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
  }



  minusQty = () => {
    if (this.state.quantity > 1 && this.state.quickQty > 1) {
      this.setState({
        inStock: "InStock",
        quickQty: this.state.quickQty - 1,
        quantity: this.state.quantity - 1
      });
      this.props.onDecrementClicked();
    } else {
      this.setState({
        quantity: 1,
        quickQty: 1,
        openQuantity: false
      });
      this.props.onDecrementClicked();
    }
  };

  plusQty = (item) => {

    item.selectedVariant = this.state.selectedVariant

    if (JSON.parse(this.props.product.variants)[0].made_to_order == "false") {
      if (JSON.parse(this.props.product.variants)[0].stock > this.state.quantity) {
        this.setState({
          quantity: this.state.quantity + 1,
          quickQty: this.state.quickQty + 1
        });
      } else {
        this.setState({ inStock: "Out of Stock !" });
      }
    } else {
      this.setState({
        quantity: this.state.quantity + 1,
        quickQty: this.state.quickQty + 1
      });
      this.props.onIncrementClicked(this.props.product, 1, item.selectedVariant);
      this.props.addCartToServer(this.props.cartItems)
    }
  };


  minusQtyQuick = () => {
    if (this.state.quantity > 1 && this.state.quickQty > 1) {
      this.setState({ inStock: "InStock" });
      this.setState({
        quickQty: this.state.quickQty - 1,
        quantity: this.state.quantity - 1
      });
    }
    else {
      this.setState({ quantity: 1 });
    }
  };

  plusQtyQuick = (product) => {

    if (JSON.parse(this.props.product.variants)[0].made_to_order === "false") {
      if (JSON.parse(this.props.product.variants)[0].stock > this.state.quantity) {
        this.setState({
          quickQty: this.state.quickQty + 1,
          quantity: this.state.quantity + 1
        });
      } else {
        this.setState({ inStock: "Out of Stock !" });
      }
    } else {
      this.setState({
        quickQty: this.state.quickQty + 1,
        quantity: this.state.quantity + 1
      });
    }
  };


  onAddToCartClicked = item => {
    item.selectedVariant = this.state.selectedVariant
    if (item.made_to_order == "false") {
      if (item.stock >= this.state.quantity) {
        this.props.onAddToCartClicked(item, 1, item.selectedVariant);
        this.setState({
          quantity: this.state.quantity + 1
        });
      } else this.setState({ inStock: "Out of Stock !" });
    } else {
      this.props.onAddToCartClicked(item, 1, item.selectedVariant);
      this.setState({
        quantity: this.state.quantity + 1
      });
    }
    this.props.addCartToServer(this.props.cartItems)
  };



  onAddToWishlistClicked = item => {
    item.selectedVariant = this.state.selectedVariant
    this.props.onAddToWishlistClicked(item, item.selectedVariant)
  }

  onAddToCompareClicked = item => {
    item.selectedVariant = this.state.selectedVariant
    this.props.onAddToCompareClicked(item, item.selectedVariant)
  }

  openQuantity = item => {
    item.selectedVariant = this.state.selectedVariant
    this.setState({ openQuantity: true });
    this.props.onAddToCartClicked(item, 1, item.selectedVariant);
    this.props.addCartToServer(this.props.cartItems)
  };

  closeQuantity = () => {
    this.setState({
      openQuantity: false,
      quickQty: 1
    });
  };


  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false, quickQty: 1 });
  };

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }


  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      onAddToCompareClicked,
      cartItems
    } = this.props;

    const { open, images, price } = this.state;
    const colorArray = [...new Map(JSON.parse(product.variants).map(item => [item.color, item])).values()];

    return (
      <div className="product-box">
        <div className="img-wrapper">
          <div className="front">
            <Link to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}>

              <img
                src={`${process.env.PUBLIC_URL}` + images[0]}
                onError={e => {
                  e.target.src = `${process.env.PUBLIC_URL
                    }/assets/images/sunny/no_image/no_images.webp`;
                }}
                className="img-fluid lazyload bg-img"
                alt="trending product variants"
              />
            </Link>
          </div>
          <div className="back">
            <Link to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}>
              <img
                src={images[1]
                  ? `${process.env.PUBLIC_URL}` + images[1]
                  : `${process.env.PUBLIC_URL}` + images[0]}
                onError={e => {
                  e.target.src = `${process.env.PUBLIC_URL
                    }/assets/images/sunny/no_image/no_images.webp`;
                }}
                className="img-fluid lazyload bg-img"
                alt="trending product variants"
              />
            </Link>
          </div>
          <div className="cart-info cart-wrap">
            <a
              href="javascript:void(0)"
              title="Add to Wishlist"

              onClick={() => {
                product.selectedVariant = this.state.selectedVariant
                onAddToWishlistClicked(product, product.selectedVariant)
              }}
            >
              <i className="fa fa-heart" aria-hidden="true" />
            </a>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#quick-view"
              title="Quick View"
              onClick={this.onOpenModal}
            >
              <i className="fas fa-eye" aria-hidden="true" />
            </a>

            <a
              href="javascript:void(0)"
              data-toggle="modal"
              title="Compare"
              onClick={() => {
                product.selectedVariant = this.state.selectedVariant
                onAddToCompareClicked(product, product.selectedVariant)
              }}
            >
              <i className="fa fa-refresh" aria-hidden="true" />
            </a>
          </div>
          <div className="addtocart_btn">
            <button
              className="add-button add_cart"
              title="Add to cart"
              onClick={() => {
                this.openQuantity(product)
              }}
            >
              ADD TO CART
            </button>
            <div
              className={`qty-box cart_qty ${this.state.openQuantity ? "open" : "close"
                }`}
            >
              <div className="input-group">
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn quantity-left-minus"
                    onClick={this.minusQty}
                    data-type="minus"
                    data-field=""
                  >
                    <i className="fas fa-minus" />
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  maxLength="2"
                  value={this.state.quickQty}
                  onBlur={this.closeQuantity}
                  className="form-control input-number"
                  readOnly
                />
                <span className="input-group-prepend">
                  <button
                    type="button"
                    className="btn quantity-right-plus"
                    onClick={() => this.plusQty(product)}
                    data-type="plus"
                    data-field=""
                  >
                    <i className="fas fa-plus" />
                  </button>
                </span>
              </div>
            </div>
          </div>
          {/* {this.state.inStock !== "InStock" ? <span>Out Of Stock</span> : ""} */}
        </div>
        <div className="product-detail text-center">
          <div>
            <Link to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}>
              <h5>{product.name}</h5>
            </Link>
            {/* {product.variants[0].price !== 0 ? ( */}
            {JSON.parse(product.variants)[0].price !== 0 ? (
              <h4 className="cart_sum">
                {symbol}
                {/* {JSON.parse(product.variants)[0].offerprice} */}
                {price}
                {/* <del>
                  <span className="money pl-2 cart_sum">
                    {symbol} {JSON.parse(product.variants)[0].price}
                  </span>
                </del> */}
              </h4>
            ) : (
              ""
            )}
            {colorArray[1] ? (
              <ul className={"color-variant pt-2 " + product.value}>
                {colorArray.map((vari, i) => {
                  return (
                    <li
                      className={
                        (this.state.selectedColor)
                          ? (vari.color == this.state.selectedColor) ? vari.color + " active-cat" : vari.color
                          : (i == 0) ? vari.color + " active-cat" : vari.color
                      }
                      key={i}
                      title={vari.color}
                      onClick={(e) => {
                        this.onClickHandle(vari)
                        this.colorHandle(e, product.value)
                      }}
                    />
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>

        <Modal open={open} onClose={this.onCloseModal} center>
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content quick-view-modal">
              <div className="modal-body mt-4">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div className="quick-view-img">
                      <img
                        src={
                          images[2]
                            ? `${process.env.PUBLIC_URL}` + images[2]
                            : `${process.env.PUBLIC_URL}` + images[0]
                        }
                        onError={e => {
                          e.target.src = `${process.env.PUBLIC_URL
                            }/assets/images/sunny/no_image/no_images.webp`;
                        }}
                        alt="listed products"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2>
                        {product.name}
                      </h2>
                      {/* {product.variants[0].price !== 0 ? ( */}
                      {JSON.parse(product.variants)[0].price !== 0 ? (
                        <h3 className="cart_sum">
                          {symbol}
                          {/* {JSON.parse(product.variants)[0].offerprice} */}
                          {price}
                          {/* <del>
                            <span className="money  pl-2">
                              {symbol} {JSON.parse(product.variants)[0].price}
                            </span>
                          </del> */}
                        </h3>
                      ) : (
                        ""
                      )}
                      {colorArray[1] ? (
                        <ul className={"color-variant " + product.value}>
                          {colorArray.map((vari, i) => (
                            <li
                              className={
                                (this.state.selectedColor)
                                  ? (vari.color == this.state.selectedColor) ? vari.color + " active" : vari.color
                                  : (i == 0) ? vari.color + " active" : vari.color
                              }
                              key={i}
                              title={vari.color}
                              onClick={(e) => { this.onClickHandle(vari); this.colorViewHandle(e, product.value) }}
                            />
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                      {JSON.parse(product.variants)[0].short_description ? (
                        <div className="border-product">
                          <h6 className="product-title">product details</h6>
                          <p>{JSON.parse(product.variants)[0].short_description}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="product-description border-product">

                        <h6 className="product-title my-2">Stock: {this.state.inStock}</h6>
                        <h6 className="product-title">Quantity</h6>

                        <div className="qty-box">
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-left-minus"
                                onClick={this.minusQty}
                                data-type="minus"
                                data-field=""
                              >
                                <i className="fas fa-minus" />
                              </button>
                            </span>

                            <input
                              type="text"
                              name="quantity"
                              maxLength="2"
                              readOnly={true}
                              value={this.state.quickQty}
                              // onChange={this.changeQty}
                              // onBlur={this.updateQty}
                              className="form-control input-number"
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-right-plus"
                                onClick={() => this.plusQtyQuick(product)}
                                data-type="plus"
                                data-field=""
                                disabled={
                                  product.made_to_order == "false"
                                    ? this.state.quantity >= product.stock
                                      ? true
                                      : false
                                    : false
                                }
                              >
                                <i className="fas fa-plus" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <a
                            className="btn btn-solid"
                            onClick={() => {
                              product.selectedVariant = this.state.selectedVariant;
                              this.setState({ quickQty: 1 });
                              onAddToCartClicked(product, this.state.quickQty, product.selectedVariant)
                            }
                            }
                          >
                            add to cart
                          </a>
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                          <Link
                            to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}
                            className="btn btn-solid"
                          >
                            view detail
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>




      </div>
    );
  }
}

export default SpecialProductItem;
