import React, { Component } from "react";
import Seo from "../layouts/common/seo";

import Breadcrumb from "../common/breadcrumb";

class GradingCertificate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {/* seo section  */}
        <Seo type="certificate-head" />
        <Breadcrumb title={"Grading Certificate"} />

        <section className="p-0">
          <div className="row">
            <div className="col-md-12">
              <img
                className="abt-banner media-container"
                src="assets/images/sunny/under_const/under_const.png"
                alt="sitemap"
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default GradingCertificate;
