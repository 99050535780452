import React, { Component } from "react";
import { IntlActions } from "react-redux-multilingual";
import Pace from "react-pace-progress";

import store from "../../../store";
import NavBar from "./common/navbar";
import CartContainer from "./../../../containers/CartContainer";
import TopBarDark from "./common/topbar-dark";
import TopBarUserDark from "./common/topbaruser-dark";
import { changeCurrency, postUserLog } from "../../../actions";
import { connect } from "react-redux";
import LogoImage from "./common/logo";
import { Helmet } from "react-helmet";

class HeaderSix extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      recaptchaLoaded: false,
      isLoading: false,
      pathname: "",
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.getLocationData();
    this.setState({ pathname: window.location.pathname });
    setTimeout(() => {
      this.setState({ load: true });
    }, 2000);
  }

  componentDidUpdate() {
    if (this.state.pathname !== window.location.pathname) {
      this.setState({ pathname: window.location.pathname });
      this.getLocationData();
    }
  }

  getLocationData = () => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => {
        response
          .json()
          .then((data) => {
            var userLog = {
              visited_page: window.location.pathname,
              ip_address: data.IPv4,
              address: data.country_name,
              latitude: data.latitude == "Not found" ? 0 : data.latitude,
              longitude: data.longitude == "Not found" ? 0 : data.longitude,
              user_id:
                navigator.cookieEnabled && sessionStorage.getItem("user_id"),
            };
            this.props.postUserLog(userLog);
          })
          .catch(() => {
            var userLog = {
              visited_page: window.location.pathname,
              user_id:
                navigator.cookieEnabled && sessionStorage.getItem("user_id"),
            };
            this.props.postUserLog(userLog);
          });
      })
      .catch(() => {
        var userLog = {
          visited_page: window.location.pathname,
          user_id:
            navigator.cookieEnabled && sessionStorage.getItem("user_id"),
        };
        this.props.postUserLog(userLog);
      });
  };

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 0) {
      if (window.innerWidth < 0) {
        document.getElementById("sticky").classList.remove("fixed");
        document.getElementById("divider").classList.remove('fixed');
      } else document.getElementById("sticky").classList.add("fixed");
      document.getElementById("divider").classList.add('fixed');
    } else {
      document.getElementById("sticky").classList.remove("fixed");
      document.getElementById("divider").classList.remove('fixed');
    }
  };

  handleChange = (value) => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!", recaptchaLoaded: true });
  };

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }

  openPage() {
    window.location.href = "/search";
  }

  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  selectTopBar = () => {
    if (navigator.cookieEnabled)
      if (sessionStorage.getItem("user_type") == "CUSTOMER")
        return (<TopBarUserDark />)
      else
        return (<TopBarDark />)
    else
      return (<TopBarDark />)
  }

  showSearch = () => {
    if (this.state.pathname !== "/search") {
    return (<div>
      <form className="form_search" role="form">
        <input
          id="query search-autocomplete"
          type="search"
          placeholder="Find the best for you.... "
          onChange={this.openPage}
          onClick={this.openPage}
          className="nav-search nav-search-field"
          aria-expanded="true"
        />
        <button
          type="button"
          className="btn-search"
          onClick={this.openPage}
        >

          {/* {Desktop search icon} */}
          <svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            className="bi bi-search" viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
      </form>
    </div>)
  }else return null;
  }

render() {
  return (
    <div>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <header id="sticky" className="sticky header-6 shadow-sm">

        {this.state.isLoading ? <Pace color="#27ae60" /> : null}
        <div className="mobile-fix-option" />
        {/*Top Header Component*/}
        {this.selectTopBar()}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="main-menu">
                <div className="brand-logo layout2-logo d-none d-md-block pt-lg-3 pt-md-2 pb-md-2 pt-0">
                  <LogoImage
                    logo={this.props.logoName}
                    alt="sunny diamonds logo"
                  />
                </div>
                <div className="brand-logo layout2-logo d-md-none">
                  <LogoImage
                    logo={this.props.logoName}
                    alt="sunny diamonds logo"
                  />
                </div>
                {this.state.pathname !== "/search" ? (
                  <div>
                    <form className="form_search" role="form">
                      <input
                        id="query search-autocomplete"
                        type="search"
                        placeholder="Find the best for you.... "
                        onChange={this.openPage}
                        onClick={this.openPage}
                        className="nav-search nav-search-field"
                        aria-expanded="true"
                      />
                      <button
                        type="button"
                        className="btn-search"
                        onClick={this.openPage}
                      >

                        {/* {Desktop search icon} */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                          className="bi bi-search" viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </button>
                    </form>
                  </div>
                ) : (
                  ""
                )}
                <div className="menu-right pull-right">
                  <div className="icon-nav">
                    <ul>
                      <li className="onhover-div mobile-search">
                        <div>
                          {/* <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                              onClick={this.openPage}
                              onChange={this.openPage}
                              className="img-fluid"
                              alt="search"
                            />
                            <i
                              className=""
                              onClick={() => (window.location.href = "/search")}
                            /> */}

                          {/* {Mobile footer search icon} */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                            fill="#722257" className="bi bi-search" viewBox="0 0 16 16"
                            onClick={() => (window.location.href = "/search")}
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>

                        </div>
                      </li>
                      <CartContainer />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-nav-center">
                <NavBar />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="d-none d-lg-block">
        <div id="divider" className="divider">

        </div>
      </div>
      <div id="search-overlay" className="search-overlay">
        <div>
          <span
            className="closebtn"
            onClick={this.closeSearch}
            title="Close Overlay"
          >
            ×
          </span>
          <div className="overlay-content">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search a Product"
                        onChange={this.openPage}
                        onClick={this.openPage}
                      />
                    </div>
                    <button type="submit" onClick={this.openPage}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                        fill="#ffffff" className="bi bi-search" viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

const mapStateToProps = (state) => ({
  data: state.data,
  symbol: state.data.symbol,
  currency_code: state.data.currency_code,
});

export default connect(mapStateToProps, { changeCurrency, postUserLog })(
  HeaderSix
);
