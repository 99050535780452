import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";


class DetailsTopTabs extends Component {
  showDiamonds(item) {

    // if (item.diamond_price !== 0) {
    if (item.diamond_count) {
      return (
        <>
          <h3 className="p-2 mt-4">Diamond details</h3>
          <table className="table table-striped mb-0">
            <tbody>
              <tr>
                <th width="50%">Diamond Count</th>
                <td width="50%">{item.diamond_count}</td>
              </tr>
              <tr>
                <th>Diamond Color</th>
                <td>{item.diamond_color.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Diamond Clarity</th>
                <td>{item.diamond_clarity.toUpperCase()}</td>
              </tr>
              <tr>
                <th>Diamond Carat</th>
                <td>{item.diamond_carat}</td>
              </tr>
              <tr>
                <th>Diamond Cut</th>
                <td>{item.diamond_cut}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else return null;
  }

  showGemstone(item) {
    // if (item.gemstone_price !== 0) {
    if (item.gemstone_type) {
      return (
        <>
          <h3 className="p-2 mt-4">Gemstone details</h3>
          <table className="table table-striped mb-0">
            <tbody>
              <tr>
                <th width="50%">Gem Type</th>
                <td width="50%">{item.gemstone_type.replaceAll("_", " ")}</td>
              </tr>
              <tr>
                <th>Gem Carat</th>
                <td>{item.gemstone_carat}</td>
              </tr>
              <tr>
                <th>Gem Count</th>
                <td>{item.gemstone_count}</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else return null;
  }

  showMetal(item) {
    return (
      <>
        <h3 className="p-2">Setting details</h3>
        <table className="table table-striped mb-0">
          <tbody>
            <tr>
              <th width="50%">Metal Weight (Gm)</th>
              <td width="50%">{item.metal_weight}</td>
            </tr>
            <tr>
              <th>Product Setting</th>
              <td>{item.product_setting}</td>
            </tr>
            <tr>
              <th>Metal Purity</th>
              <td>{item.metal_purity}</td>
            </tr>
            <tr>
              <th>Metal Type</th>
              <td>{item.metal_type}</td>
            </tr>
            <tr>
              <th>Metal Color</th>
              <td>{item.color}</td>
            </tr>
            <tr>
              <th>SKU</th>
              <td>{item.sku}</td>
            </tr>
          </tbody>
        </table></>
    )
  }

  render() {
    const { item } = this.props;

    return (
      <section className="tab-product m-0">
        <div className="row">
          <div className="col-sm-12 col-lg-12 p-4 mb-8">
            <Tabs className="tab-content nav-material">
              <TabList className="nav nav-tabs nav-material">
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-man-in-glasses" />
                    Details
                  </span>
                  <div className="material-border" />
                </Tab>
                <Tab className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-ui-home" />
                    Description
                  </span>
                  <div className="material-border" />
                </Tab>
                {/* {item.youtube !== "" ? (
                  <Tab className="nav-item">
                    <span className="nav-link">
                      <i className="icofont icofont-contacts" />
                      Video
                    </span>
                    <div className="material-border" />
                  </Tab>
                ) : (
                  ""
                )} */}
              </TabList>
              <TabPanel className="tab-pane fade mt-4 show active">
                {this.showMetal(item)}
                {this.showDiamonds(item)}
                {this.showGemstone(item)}
              </TabPanel>
              <TabPanel>
                <h5 className="mt-4 px-5 mb-5 pb-5 ">{item.short_description}</h5>
              </TabPanel>
              {/* {item.youtube !== "" ? (
                <TabPanel>
                  <div className="mt-4 text-center">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title="Youtube"
                        src={item.youtube}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </TabPanel>
              ) : (
                ""
              )} */}
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

export default DetailsTopTabs;
