import React, { Component } from "react";
import { connect } from "react-redux";
import MultiRangeSlider from "./multiRangeSlider";

import { SlideToggle } from "react-slide-toggle";
import { getMinMaxPrice } from "../../../services";
import { filterBrand, filterColor, filterPrice } from "../../../actions";
import Dropdown from "./dropdown";
import filterList from "../../../api/category.json";
import store from "../../../store";

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openFilter: false,
      target: [],
      placeholder: "",
      filterCat: []
    };
  }

  componentDidMount() {
    let phKeys = Object.keys(this.props.filters.placeholder);
    phKeys.map((phKey, i) => {
      store.dispatch({ type: "FILTER_CAT_NULL", placeholder: phKey });
    });
    store.dispatch({
      type: "FILTER_PRICE_RANGE",
      value: { min: this.props.prices.min, max: this.props.prices.max }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.prices.min != prevProps.prices.min || this.props.prices.max != prevProps.prices.max) {
      store.dispatch({
        type: "FILTER_PRICE_RANGE",
        value: { min: this.props.prices.min, max: this.props.prices.max }
      });
    }
  }

  componentWillUnmount() {
    this.props.setColor('');
  }

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  colorHandle(event, color) {
    if (event.target.classList.contains("active")) {
      event.target.classList.remove("active");
      this.props.filterColor();
      this.props.setColor();
    } else {
      var elems = document.querySelectorAll(".color-selector ul li");
      [].forEach.call(elems, function (el) {
        el.classList.remove("active");
      });
      event.target.classList.add("active");
      this.props.filterColor(color);
      this.props.setColor(color);
    }
  }

  clear() {
    let phKeys = Object.keys(this.props.filters.placeholder);
    phKeys.map((phKey, i) => {
      store.dispatch({ type: "FILTER_CAT_NULL", placeholder: phKey });
    });
    var w = document.getElementsByTagName("input");
    for (var i = 0; i < w.length; i++) {
      if (w[i].type == "checkbox") {
        w[i].checked = false;
      }
    }
  }

  render() {

    const { allFilters } = this.props;
    if (allFilters.products != undefined)
      return (
        <div className="collection-filter-block">
          {/*brand filter start*/}
          <div className="collection-mobile-back d-lg-none d-block">
            <span className="filter-back" onClick={e => this.closeFilter(e)}>
              <i className="fa fa-angle-left" aria-hidden="true" /> back
            </span>
          </div>
          <div className="filter-tag px-2 mt-2" onClick={() => this.clear()}>
            <p className="mb-0 text-center">Clear All</p>
          </div>
          {
            allFilters.filters && allFilters.filters ? <SlideToggle collapsed>
              {({ onToggle, setCollapsibleElement }) => (
                <div className="collection-collapse-block">
                  <h6 className="collapse-block-title" onClick={onToggle}>
                    {allFilters.filters[0].name}
                  </h6>
                  <div
                    className="collection-collapse-block-content"
                    ref={setCollapsibleElement}
                  >
                    <Dropdown
                      placeholder={allFilters.filters[0].value}
                      list={allFilters.filters}
                      index="0"
                    />
                  </div>
                </div>
              )}
            </SlideToggle>
              :
              ""
          }
          {
            filterList.map((filter, i) => (
              <SlideToggle key={i} collapsed>
                {({ onToggle, setCollapsibleElement }) => (
                  <div className="collection-collapse-block">
                    <h6 className="collapse-block-title" onClick={onToggle}>
                      {filter.name}
                    </h6>
                    <div
                      className="collection-collapse-block-content"
                      ref={setCollapsibleElement}
                    >
                      {
                        filter.type === "list" ?
                          < Dropdown
                            placeholder={filter.value}
                            list={filterList}
                            index={i}
                          />
                          : filter.type === "option" ?
                            <div className="color-selector">
                              <ul>
                                {filter.list.map((color, index) => {
                                  return (
                                    <li
                                      className={color.value}
                                      title={color.value}
                                      onClick={e =>
                                        this.colorHandle(e, color.value)
                                      }
                                      key={index}
                                    />
                                  );
                                })}
                              </ul>
                            </div>
                            : filter.type === "range" ?
                              <div className="custom-control custom-checkbox collection-filter-checkbox pb-5 pt-3 px-2">
                                <MultiRangeSlider
                                  min={this.props.prices.min}
                                  max={this.props.prices.max}
                                  value={this.props.filters.value}
                                  onChange={value =>
                                    this.props.filterPrice({ value })
                                  }
                                />
                              </div>
                              :
                              ""
                      }
                    </div>
                  </div>
                )}
              </SlideToggle>
            ))
          }
        </div>
      );
    else return null;
  }
}

const mapStateToProps = state => ({
  prices: getMinMaxPrice(state.data.category.products),
  allFilters: state.data.category,
  filters: state.filters
});

export default connect(
  mapStateToProps,
  { filterBrand, filterColor, filterPrice }
)(Filter);
