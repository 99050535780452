import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST
} from "../constants/ActionTypes";
import { toast } from "react-toastify";

export default function wishlistReducer(
  state = {
    list: []
  },
  action
) {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      const productId = action.product.id;
      const variantId = action.variants.id
      if (state.list.findIndex(product => product.variant.id === variantId) !== -1) {
        const list = state.list.reduce((cartAcc, product) => {
          cartAcc.push({
            ...product,
            // variant: action.variants
          });
          return cartAcc;
        }, []);
        toast.warn("Item already Added");
        return { ...state, list };
      } else {
        toast.success("Item Added to Wishlist");
      }
      return { ...state, list: [...state.list, {...action.product, variant:action.variants}] };

    case REMOVE_FROM_WISHLIST:
      return {
        list: state.list.filter(id => id !== action.product_id)
      };
    default:
  }
  return state;
}
