import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllNewProducts } from "../../actions";
import Slider from "react-slick";

class NewProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getAllNewProducts();
  }

  imgeSource = product => {
    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];
    // if (product.variants[0].image) {
    //   let allImages = product.variants[0].image;
    if (JSON.parse(product.variants)[0].image) {
      let allImages = JSON.parse(product.variants)[0].image;
      images = allImages.split(",");
      images.forEach(function (img) {
        let ext = img.substring(img.lastIndexOf("."));

        if (exts.includes(ext)) {
          imgeSrc[imgI] = img;
          imgI++;
        }
      });
    }
    return imgeSrc[0];
  };

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  render() {
    const { items, symbol } = this.props;

    if (items) {
      var arrays = [];
      while (items.length > 0) {
        arrays.push(items.splice(0, 3));
      }
      return (
        <div className="theme-card mt-4">
          <h5 className="title-border">New product</h5>
          <Slider className="offer-slider slide-1">
            {arrays.map((products, index) => (
              <div className="new-product-css scroller" key={index}>
                {products.map((product, i) => (
                  <div className="media mr-0" key={i}>
                    <Link to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}>
                      <img
                        className="img-fluid m-0 pr-3"
                        src={
                          `${process.env.PUBLIC_URL}` + this.imgeSource(product)
                        }
                        onError={e => {
                          e.target.src = `${process.env.PUBLIC_URL
                            }/assets/images/sunny/no_image/no_images.webp`;
                        }}
                        alt={product.name}
                      />
                    </Link>
                    <div className="media-body align-self-center">
                      <Link
                        to={this.validateURL(`${process.env.PUBLIC_URL}/${product.value}`) ? `${process.env.PUBLIC_URL}/${product.value}` : ''}
                      >
                        <h6>{product.name}</h6>
                      </Link>
                      {JSON.parse(product.variants)[0].price !== 0 ? (
                        <h3 className="cart_sum">
                          {symbol}
                          {JSON.parse(product.variants)[0].offerprice}
                          <del>
                            <span className="money ml-1">
                              {symbol}
                              {JSON.parse(product.variants)[0].price}
                            </span>
                          </del>
                        </h3>
                      ) : ("")}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Slider>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = state => ({
  items: state.data.newproduct,
  symbol: state.data.symbol
});

export default connect(
  mapStateToProps,
  {
    getAllNewProducts
  }
)(NewProduct);
