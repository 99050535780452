import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  RefinementList,
  Pagination,
  Highlight,
  HierarchicalMenu,
  ClearRefinements,
  Panel,
} from "react-instantsearch-dom";
import {
  ClearFiltersMobile,
  SaveFiltersMobile,
} from "./widgets";
import PropTypes from "prop-types";
import "./css/App.css";
import "./css/search.css";

const searchClient = algoliasearch(
  window.rc.algoliasearch.appId,
  window.rc.algoliasearch.apiKey
);

class Search extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef(null);
    this.headerRef = React.createRef(null);
  }

  openFilters = () =>  {
  document.body.classList.add("filtering");
  window.scrollTo(0, 0);
  window.addEventListener("keyup", this.onKeyUp);
  window.addEventListener("click", this.onClick);
}

closeFilters = () => {
  document.body.classList.remove("filtering");
  this.containerRef.current.scrollIntoView();
  window.removeEventListener("keyup", this.onKeyUp);
  window.removeEventListener("click", this.onClick);
}

onKeyUp = (event) => {
  if (event.key !== "Escape") {
    return;
  }
  this.closeFilters();
}

onClick = (event) => {
  if (event.target !== this.headerRef.current) {
    return;
  }
  this.closeFilters();
}

render() {


  return (
    <div>
      <Breadcrumb title={"Search"} />

      {/*Search section*/}
      <section className="authentication-page section-b-space">
        <div className="container">
          <section className="search-block" >
            <div className="row">
              <div className="col-lg-12">
                <div className="search">
                  <div className="container">
                    <InstantSearch
                      searchClient={searchClient}
                      indexName="products_list"
                      onSearchStateChange={searchState => {
                        const page = `?query=${searchState.query}`;
                        window.ga('send', 'pageView', page);
                        window.dataLayer.push({'event': 'search', 'Search Query': searchState.query});
                      }}
                    >
                      <Configure facets={["*"]} maxValuesPerFacet={20} />
                      <div className="container-wrapper" >
                        <section
                          className="container-filters"
                          onKeyUp={this.onKeyUp}
                          ref={this.headerRef}
                        >
                          <div className="container-header" ref={this.containerRef}>
                            <h2>Filters</h2>
                            <div
                              className="clear-filters"
                              data-layout="desktop"
                            >
                              <ClearRefinements
                                translations={{
                                  reset: (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                      >
                                        <g
                                          fill="none"
                                          fillRule="evenodd"
                                          opacity=".4"
                                        >
                                          <path d="M0 0h11v11H0z" />
                                          <path
                                            fill="#000"
                                            fillRule="nonzero"
                                            d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                                          />
                                        </g>
                                      </svg>
                                      Clear filters
                                    </>
                                  ),
                                }}
                              />
                            </div>
                          </div>

                          <div className="container-body">
                            <Panel header="design type">
                              <RefinementList attribute="design_type" />
                              <HierarchicalMenu
                                attributes={[
                                  "hierarchicalCategories.lvl0",
                                  "hierarchicalCategories.lvl1",
                                ]}
                              />
                            </Panel>
                            <Panel header="Category">
                              <RefinementList attribute="category" />
                            </Panel>
                            <Panel header="Metal">
                              <RefinementList attribute="metal_type" />
                            </Panel>
                            <Panel header="Metal Purity">
                              <RefinementList attribute="metal_purity" />
                            </Panel>
                            <Panel header="Metal Colour">
                              <RefinementList attribute="metal_color" />
                            </Panel>
                          </div>
                        </section>

                        <footer
                          className="container-filters-footer"
                          data-layout="mobile"
                        >
                          <div className="container-filters-footer-button-wrapper">
                            <ClearFiltersMobile
                              containerRef={this.containerRef}
                            />
                          </div>
                          <div className="container-filters-footer-button-wrapper">
                            <SaveFiltersMobile onClick={this.closeFilters} />
                          </div>
                        </footer>
                      </div>
                      <div className="search-panel__results col-md-12">
                        <SearchBox
                          className="searchbox"
                          autoFocus={true}
                          translations={{
                            placeholder: "Product, Brand, Color, ...",
                          }}
                        />
                        <Hits hitComponent={Hit} />

                        <div className="pagination">
                          <Pagination />
                        </div>
                      </div>

                      <aside data-layout="mobile">
                        <button
                          className="filters-button"
                          data-action="open-overlay"
                          onClick={this.openFilters}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 14"
                          >
                            <path
                              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
                              stroke="#fff"
                              strokeWidth="1.29"
                              fill="none"
                              fillRule="evenodd"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Filters
                        </button>
                      </aside>
                    </InstantSearch>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
}
function Hit(props) {
  return (
    <article>
      <header className="hit-image-container">
        <a href={props.hit.url}>
          <Highlight attribute="images" hit={props.hit} />
          <img
            src={props.hit.media}
            alt={props.hit.name}
            className="hit-image"
          />
        </a>
      </header>
      <h1 className="productname">
        <a href={props.hit.url}>
          <Highlight attribute="name" hit={props.hit} />
        </a>
      </h1>
      <h3>
        <a href={props.hit.url}>
          <Highlight attribute="sku" hit={props.hit} />
        </a>
      </h3>
    </article>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default Search;
