import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import './shop-now-ad.css';

const ShopNowAd = (props) => {


    const setting = {
        dots: true,
        dotsClass: "slick-dots",
        lazyLoad: true,
        arrows : false,
        autoplay: true,
        infinite: true,
        speed: 4500,
        autoplaySpeed: 4500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <section className="banner-section">
            <div className="banner_slider_wrap">
                <div className="banner_slider">
                    <Slider {...setting}>
                        {window.bannerAds.banner.map((item, index) => (
                            <div key={index}>
                                <img src={item.image} alt={item.title} />
                                <div className="banner-text"><span>{item.title}</span>
                                    <h2>{item.sub_content}</h2>
                                    <Link to={`${process.env.PUBLIC_URL}/` + item.link}>
                                        <button>{item.button}</button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </section>
    );
};

export default ShopNowAd;