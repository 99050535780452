import React, { useRef, useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import SimpleReactValidator from "simple-react-validator";
import { Helmet } from "react-helmet";

import { postAllSubscribe } from "../../actions"

import "./footer.css"

const Footer = (props) => {

    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [accordion, setAccordion] = useState("d-none")
    const _reCaptchaRef = React.createRef()
    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const handleChange = (event) => {
        event.persist()
        setEmail(event.target.value)
    }

    const onRecaptcha = (e) => {
        // e.preventDefault();
        if (validator.current.allValid()) {
            setLoader(true)
            _reCaptchaRef.current.executeAsync();
        } else {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const handleSubmit = (code) => {
        if (code) {
            var subscriptionEmail = { email: email }
            props.postAllSubscribe(subscriptionEmail);
            _reCaptchaRef.current.reset();
        }
    };

    useEffect(() => {
        setLoader(false)
        setEmail("")
        validator.current.hideMessageFor('email');
    }, [props.subscribe_data])

    const handleFooterAccordion = () => {
        if (accordion == "d-none")
            setAccordion("d-block")
        else
            setAccordion("d-none")
    }

    const handleLinks = (list, i) => {
        return (
            <li key={i}> <Link to={`${process.env.PUBLIC_URL}/` + list.link}>{list.nav}</Link> </li>
        )
    }

    const handleAnchors = (list, i) => {
        return (
            <li key={i}> <a href={list.href} target="_blank" rel="noopener noreferrer">{list.nav}</a> </li>
        )
    }

    return (
        <footer>
            <Helmet>
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <div className="footer-element">
                <div className="left-column">

                    {/* Mapping of Navlinks in footer  */}

                    {window.footerLinks.navLinks.map((element, i) => (
                        <div className="column px-3">
                            <h3 className="footer-toggle" onClick={handleFooterAccordion}>{element.heading}</h3>
                            <ul className={"inner d-md-block " + accordion}>
                                {element.links.map((list, i) => {
                                    if (list.type == "link")
                                        return (
                                            handleLinks(list, i)
                                        )
                                    else if (list.type == "anchor")
                                        return (
                                            handleAnchors(list, i)
                                        )
                                })}
                            </ul>
                        </div>
                    ))}
                    <div className="column-last mt-4">
                        <span>© Sunny Diamonds 2022</span>
                    </div>
                </div>
                <div className="right-column">
                    <div className="right-outer">
                        <div className="right-inner mx-3">
                            <h2>Know It All First!</h2>
                            <div className="newsletter-outer">
                                <span>Never Miss Anything From Sunny Diamonds By Signing Up To Our Newsletter.</span>
                                <div className="newsletter">
                                    <input
                                        id="newsletter"
                                        type="text"
                                        name="email"
                                        value={email}
                                        placeholder="Enter Your Email Address"
                                        className="input-text"
                                        maxlength="128"
                                        role="combobox"
                                        aria-haspopup="false"
                                        aria-autocomplete="both"
                                        autocomplete="off"
                                        aria-expanded="false"
                                        onChange={handleChange}
                                        onBlur={() => validator.current.showMessageFor('email')}

                                    />
                                    <button
                                        className="action subscribe primary"
                                        title="Subscribe"
                                        type="submit"
                                        aria-label="Subscribe"
                                        onClick={() => onRecaptcha()}
                                    >
                                        <span>Subscribe</span>
                                    </button>
                                    {validator.current.message("email", email, "required|email")}
                                    {loader && <div data-uk-spinner className="uk-padding-small"></div>}
                                </div>
                                <ReCAPTCHA
                                    ref={_reCaptchaRef}
                                    size="invisible"
                                    sitekey={global.reCaptcha}
                                    onChange={handleSubmit}
                                />
                            </div>
                            <div className="social-links">

                                {/* Mapping of social media links  */}

                                {window.footerLinks.socials.map((element, i) => (
                                    <a href={element.href} target="_blank" rel="noopener noreferrer" className={"social-" + (i + 1)}></a>
                                ))}
                            </div>
                            <div className="payment-links">
                                <img src="assets/images/new_template/images/group-212.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-filter-sidebar"></div>
        </footer>
    )
}

const mapStateToProps = state => ({
    subscribe_data: state.data.subscribe_data
});

export default connect(mapStateToProps, { postAllSubscribe })(Footer);
