import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Seo from "../layouts/common/seo";
import Breadcrumb from "../common/breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import "react-tabs/style/react-tabs.scss";
import SimpleReactValidator from "simple-react-validator";
import { actUserLogin, actProfile } from "../../actions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from '@greatsumini/react-facebook-login';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem("email") && localStorage.getItem("password") ? localStorage.getItem("email") : "",
      password: localStorage.getItem("email") && localStorage.getItem("password") ? localStorage.getItem("password") : "",
      passwordShown: false,
      rememberMe: false,
      user_type: "CUSTOMER"
    };
    this._reCaptchaRef = React.createRef();
    this.validator = new SimpleReactValidator({ locale: 'en', autoForceUpdate: this });
  }

  componentDidMount() {
    if (navigator.cookieEnabled ? localStorage.getItem("user_type") === "CUSTOMER" : false) {
      this.props.history.push("/dashboard");
    }
    // if (localStorage.getItem("email") && localStorage.getItem("password")) {
    //   this.setState({
    //     email: this.state.email,
    //     password: this.state.password
    //   });
    // }
  }


  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  setStateFromCheckbox = event => {
    this.setState({
      rememberMe: event.target.checked
    });
  };

  togglePasswordVisiblity = () => {
    this.setState({
      passwordShown: this.state.passwordShown ? false : true
    });
  };


  onRecaptcha = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this._reCaptchaRef.current.executeAsync();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSubmit = (code) => {

    if (this.state.rememberMe) {
      localStorage.setItem("email", this.state.email);
      localStorage.setItem("password", this.state.password);
    }

    if (code) {
      var login_details = {
        email: this.state.email,
        password: this.state.password,
      }
      this.props.actUserLogin(login_details);
      this._reCaptchaRef.current.reset();
    } else { }
  };

  handlemobileSubmit = () => { }
  handleemailSubmit = () => { }
  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;

  

    return (
      <div>
        <Helmet>
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta
            property="og:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            property="og:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta property="og:url" content="https://sunnydiamonds.com/" />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta
            name="twitter:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            name="twitter:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
        </Helmet>
        <Seo type="seo-head" />
        <Breadcrumb title={"Login"} />

        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 my-5">
                <h2 className="py-4 px-4">Welcome back, Please login to your account</h2>
                <Accordion defaultActiveKey="1" alwaysOpen={false} flush>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="p-0">
                      <h3 className="accordian_login_heading">
                        Login with Email and Password
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body className="dark-accordian">
                      <form className="theme-form" autoComplete="off"
                        // onSubmit={this.handleSubmit}
                        onSubmit={this.onRecaptcha}
                      >
                        <div className="form-group">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter your Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.setStateFromInput}
                            onBlur={() => this.validator.showMessageFor('email')} 
                          />
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required|email"
                          )}
                        </div>
                        <div className="form-group position-relative">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <input
                            type={
                              this.state.passwordShown ? "text" : "password"
                            }
                            className="form-control pass-wrapper"
                            name="password"
                            placeholder="Enter your password"
                            value={this.state.password}
                            onChange={this.setStateFromInput}
                          />
                          <i
                            className="login_password"
                            onClick={this.togglePasswordVisiblity}
                          >
                            {eye}
                          </i>{" "}
                          {this.validator.message(
                            "password",
                            this.state.password,
                            "required"
                          )}
                        </div>
                        <div className="row pl-3">
                          <input
                            type="checkbox"
                            id="remeberMe"
                            name="remeberMe"
                            onChange={this.setStateFromCheckbox}
                          />
                          <h6 className="pl-2">Remember me</h6>
                        </div>
                        <ReCAPTCHA
                          ref={this._reCaptchaRef}
                          size="invisible"
                          // sitekey="6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V"
                          sitekey={global.reCaptcha}
                          onChange={this.handleSubmit}
                        />
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <button
                              className="btn btn-solid btn-login"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 mt-3">
                            <Link
                              to={`${process.env.PUBLIC_URL}/forgot-password`}
                              data-lng="en"
                            >
                              <h6 className="text-right forget_password">
                                Forgot password ?
                              </h6>{" "}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h3 className="accordian-login-heading">Login via Facebook/Google</h3>
                    </Accordion.Header>
                    <Accordion.Body className="dark-accordian">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                          
                          <FacebookLogin
                            appId="2873749276183986"
                            // autoLoad={true}
                            fields="name,email,picture"
                            // onClick={componentClicked}
                            // callback={responseFacebook} 
                            style={{
                              backgroundColor: '#4267b2',
                              color: '#fff',
                              fontSize: '16px',
                              padding: '12px 24px',
                              border: 'none',
                              borderRadius: '4px',
                            }}
                            
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 mt-md-0 mt-2 text-center">
                          <GoogleLogin
                            clientId="456971777746-d8sr4lf21mb6df5h3og333ko800a9rrd.apps.googleusercontent.com"
                            buttonText="Login With Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>
              </div>

              {/* Right Side View  */}

              <div className="col-lg-6 right-login my-4">
                {/* Mobile View  */}

                <Accordion
                  className="d-block d-md-block d-lg-none"
                  defaultActiveKey="0"
                  alwaysOpen={false}
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h3 className="accordian-login-heading">
                        New User? Create an account
                      </h3>
                    </Accordion.Header>
                    <Accordion.Body className="dark-accordian">
                      <h2 className="login-title-font mt-4 text-center text-md-left">
                        New User? Create an account{" "}
                      </h2>
                      <h3 className="login-title-font mt-5 d-none d-md-block">
                        Sign up and know more about our perfect world of Diamond
                        Collection
                      </h3>
                      <p className="login-title-font">
                        Benefits of Creating a New Account:
                      </p>
                      <ul>
                        <li className="create_acct_li">• Faster Checkout</li>
                        <li className="create_acct_li">
                          • Access your order status
                        </li>
                        <li className="create_acct_li">
                          • View online order history
                        </li>
                        <li className="create_acct_li">
                          • You will get Special Previews to our Limited
                          Collections and Sales
                        </li>
                      </ul>
                      <Link
                        to={`${process.env.PUBLIC_URL}/create`}
                        data-lng="en"
                        className="btn btn-solid btn-create_account mt-3"
                      >
                        Create Account
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {/* end of Mobile View  */}

                {/* Desktop View  */}

                <div className="authentication-right d-none d-md-none d-lg-block">
                  <h2 className="login-title-font mt-4 text-center text-md-left">
                    New User? Create an account{" "}
                  </h2>
                  <h3 className="login-title-font mt-5 d-none d-md-block">
                    Sign up and know more about our perfect world of Diamond
                    Collection
                  </h3>
                  <p className="login-title-font">
                    Benefits of Creating a New Account:
                  </p>
                  <ul>
                    <li className="create_acct_li">• Faster Checkout</li>
                    <li className="create_acct_li">
                      • Access your order status
                    </li>
                    <li className="create_acct_li">
                      • View online order history
                    </li>
                    <li className="create_acct_li">
                      • You will get Special Previews to our Limited Collections
                      and Sales
                    </li>
                  </ul>
                  <Link
                    to={`${process.env.PUBLIC_URL}/create`}
                    data-lng="en"
                    className="btn btn-solid btn-create_account mt-3"
                  >
                    Create Account
                  </Link>
                </div>
                {/* end of Desktop View  */}
              </div>
            </div>
            {/* end of Right Side View  */}
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile_data: state.data.profile_data
});

export default connect(
  mapStateToProps,
  {
    actUserLogin,
    actProfile
  }
)(Login);
