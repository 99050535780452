import React, {useEffect} from "react";

import HomeBanner from "./home_banner/index";
import ShopByCategoryNew from "./shop_by_category/index";
import GiftsIdeas from './gift_ideas/index'
import ChooseYourStyleBlog from "./choose_your_style/index";
import Glitz from "./putting_on_the_glitz/index";
import ShopNowAd from "./shop_now_ad/index";
import BehingTheScene from "./behing_the_scene/index";
import GoogleReview from "../new_template_common/google_review/index";
import FeaturedIn from "./featured_in/index";
import NewTrend from "./new_trend/index";
import Promise from "./promise/index";
import InstagramWidget from "../new_template_common/instagram_widget/index";

const NewHomePage = () => {

    useEffect(() => {
        if (window.ApplicationTagEmbed)
            window.ApplicationTagEmbed.init()
    }, [window.ApplicationTagEmbed])


    return (
        <div className="main-container">

            <HomeBanner />

            <ShopByCategoryNew />

            <GiftsIdeas />

            <ChooseYourStyleBlog />

            <Glitz />

            <ShopNowAd />

            <BehingTheScene />

            <GoogleReview />

            <FeaturedIn />

            <NewTrend />

            <Promise />

            <InstagramWidget />

        </div>
    )
}
export default NewHomePage