import React, { Component } from "react";

import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import store from "../../store";
import Breadcrumb from "../common/breadcrumb";
import axios from "axios";
import {
  removeFromWishlist,
  removeFromCart,
  postPromoCodeDetails,
  postAllOrderdetails,
  actProfile,
  postCartItems,
  errorMsgAction,
} from "../../actions";
import pinJson from "../../api/pincode.json";
import { getCartTotal } from "../../services";
import PayApp from "./razorpay";
import { toast } from "react-toastify";
import Seo from "../layouts/common/seo";
import ReCAPTCHA from "react-google-recaptcha";
import OtpVerification from "../checkout/otp";
import Loader from "../common/spinner"
import GiftCardModule from "./gift-card";

class checkOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      zoho_id: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
      ship_address: "",
      ship_city: "",
      ship_state: "",
      ship_country: "India",
      ship_pincode: "",
      payment_mode: "",
      amount: this.props.total,
      discount: 0,
      gift_discount: 0,
      ip_address: "00000000",
      code: "",
      same_address: false,
      button_disable: false,
      disabled: false,
      cod_active: false,
      cod_msg: "COD available only in india",
      cod_color: "alert-primary",
      cod_limit: 49000,
      cod_pincode: false,
      sync: false,
      verify_number: false
    };
    this._reCaptchaRef = React.createRef();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  ga = () => {
    window.dataLayer.push({ ecommerce: null });
    const products = []
    for (let i = 0; i < this.props.cartItems.length; i++) {
      products.push({
        'name': this.props.cartItems[i].name,
        'id': this.props.cartItems[i].sku,
        'price': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.offerprice,
        'brand': 'Sunny Diamonds',
        'category': this.props.cartItems[i].value,
        'variants': this.props.cartItems[i].selectedVariant && this.props.cartItems[i].selectedVariant.color,
        'quantity': this.props.cartItems[i].qty
      });
    }
    window.dataLayer.push({
      'event': 'InitiateCheckout',
      'ecommerce': {
        'currencyCode': 'INR',
        'impressions': {
          'products': products
        },
      },
      'value': this.state.amount
    });
  }

  componentDidMount() {
    const content_name = []
    const content_ids = []
    for (let i = 0; i < this.props.cartItems.length; i++) {
      content_ids.push(
        this.props.cartItems[i].sku,
      );
      content_name.push(
        this.props.cartItems[i].name
      )
    }
    // window.fbq('track', 'InitiateCheckout', { content_ids: content_ids, value: this.state.amount, content_name: content_name, currency: "INR", event_time: new Date().getTime() });

    this.ga();

    if (this.props.cartItems.length == 0) { this.props.history.push("/jewellery"); }
    store.dispatch({ type: "RECEIVE_ORDER_ID", payload: "" });
    store.dispatch({ type: "RECEIVE_PROMO", payload: "" });
    store.dispatch({ type: "RECEIVE_REQUEST_OTP", payload: "" });
    store.dispatch({ type: "RECEIVE_VERIFY_OTP", payload: "" });
    store.dispatch({ type: "RECIEVE_GIFTCARD_BALANCE", payload: 0 });
    this.props.errorMsgAction("RESET_OTP")
    this.props.postCartItems(this.props.cartItems)

    if (navigator.cookieEnabled) {
      if (
        sessionStorage.getItem("user_id") &&
        sessionStorage.getItem("token") &&
        sessionStorage.getItem("user_type") == "CUSTOMER"
      ) {
        this.props.actProfile();
      }
    }
    if (this.props.total >= this.state.cod_limit) {
      this.setState({
        cod_active: false,
        cod_msg: "COD available only for orders below ₹" + this.state.cod_limit,
        cod_color: "alert-danger"
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cartItems.length == 0) {
      this.props.history.push("/jewellery");
    }
    if (this.props.promo_data && this.props.promo_data !== prevProps.promo_data) {
      if (this.props.promo_data.code == this.state.code) {
        this.setState({ button_disable: true });
        if (this.props.promo_data.type == "FLAT") {
          this.setState({
            discount: this.props.promo_data.value,
            amount: this.state.amount - this.props.promo_data.value
          });
        } else if (this.props.promo_data.type == "PERCENTAGE") {
          this.setState({
            discount: (this.props.promo_data.value * this.props.total) / 100,
            amount: this.state.amount - (this.props.promo_data.value * this.props.total) / 100
          });
        }
        toast.success("Promo Code applied successfully");
      }
    }

    if (this.props.balance && this.props.balance !== prevProps.balance) {
      this.setState({
        gift_discount: this.props.balance.purchase_card_balance,
        amount: this.state.amount - this.props.balance.purchase_card_balance
      });
    }
    if (this.props.cartItems !== prevProps.cartItems) {
      if (this.state.button_disable) {
        this.setState({
          button_disable: false,
          code: "",
          discount: 0
        })
      }
    }
    if (this.props.profile_data !== prevProps.profile_data) {
      this.setState({
        user_id: this.props.profile_data.user_id,
        zoho_id: this.props.profile_data.zoho_id,
        first_name: this.props.profile_data.first_name,
        last_name: this.props.profile_data.last_name,
        phone: this.props.profile_data.phone,
        email: this.props.profile_data.email,
        address: this.props.profile_data.address,
        city: this.props.profile_data.city,
        state: this.props.profile_data.state,
        pincode: this.props.profile_data.pincode,
      });
    }
    if (this.props.total !== prevProps.total) {
      this.setState({ amount: this.props.total })
      this.checkCOD(this.state.ship_pincode)
    }
    if (this.state.discount !== prevState.discount) {
      this.checkCOD(this.state.ship_pincode)
    }

    if (this.props.cartItems !== prevProps.cartItems) {
      this.setState({ sync: true })
    }
    if (this.props.orderData !== prevProps.orderData) {
      if (this.props.orderData && this.props.orderData.order_id.includes("cod")) {
        toast.success("Order Has been Placed Successfully");
        this.props.history.push("/order-response/success")
      }
    }
  }

  checkPaymentHandle = (value) => {

    this.DummyRazorClick()
    this.setState({ disabled: false });
    this.setState({
      payment_mode: value
    })
    if (value === 'cod') this.checkCOD(this.state.ship_pincode)
  }

  checkCOD = (value) => {
    if ((this.props.total - this.state.discount) >= this.state.cod_limit) {
      this.setState({
        cod_active: false,
        cod_msg: "COD available only for orders below ₹" + this.state.cod_limit,
        cod_pincode: pinJson.includes(value) ? true : false,
        cod_color: "alert-danger"
      });
    }
    else if (value.length == 6)
      this.checkPinAPI(value)
    else
      this.setState({
        cod_active: false,
        cod_msg: "COD available only in india",
        cod_pincode: pinJson.includes(value) ? true : false,
        cod_color: "alert-primary"
      });


  }

  checkPinAPI = async (value) => {
    await axios
      .get("https://api.postalpincode.in/pincode/" + value)
      .then(response => {
        response = response.data
        if (response[0].Status == "Success") {
          this.setState({
            cod_active: true,
            cod_msg: "COD available in " + value,
            cod_pincode: true,
            cod_color: "alert-success"
          });
        }
        else {
          this.setState({
            cod_msg: "COD not available in " + value,
            cod_active: false,
            cod_color: "alert-danger"
          });
        }
      })
      .catch(error => {
        if (error) {
          this.setState({
            cod_msg: "COD not available in " + value,
            cod_active: false,
            cod_color: "alert-danger"
          });
        }
      });
  }

  setStateFromInput = event => {
    var obj = {};
    if (event.target.name == "ship_pincode") {
      this.checkCOD(event.target.value)
    }
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  sameAddress = event => {
    this.setState({
      same_address: event.target.checked
    });
    if (event.target.checked) {
      this.setState({
        ship_address: this.state.address,
        ship_city: this.state.city,
        ship_state: this.state.state,
        ship_pincode: this.state.pincode
      });
      this.checkCOD(this.state.pincode)
    }
    else {
      this.setState({
        ship_address: "",
        ship_city: "",
        ship_state: "",
        ship_pincode: ""
      });
    }
  };

  DummyRazorClick = () => {
    if (this.state.payment_mode === "") {
      this.setState({
        payment_mode: "select_payment",
        cod_msg: ""
      });
    }
    if (this.validator.allValid()) {
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  CheckoutimgeSource = item => {
    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];
    if (item.selectedVariant.image) {
      let allImages = item.selectedVariant.image;
      images = allImages.split(",");
      images.forEach(function (img) {
        let ext = img.substring(img.lastIndexOf("."));
        if (exts.includes(ext)) {
          imgeSrc[imgI] = img;
          imgI++;
        }
      });
    }
    return imgeSrc[0];
  };

  checkPromo = event => {
    if (this.state.code) {
      event.preventDefault();
      this.props.postPromoCodeDetails(this.state.code);
    }
  };

  onRecaptcha = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this._reCaptchaRef.current.executeAsync();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  clearField = () => {
    this.setState({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      ship_address: "",
      ship_city: "",
      ship_state: "",
      ship_pincode: "",
      disabled: true
    });
  }

  handleSubmit = (code) => {
    if (!this.state.disabled) {
      var profile_details = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: "India",
        phone: this.state.phone,
        email: this.state.email,
        pincode: this.state.pincode,
        user_id: this.state.user_id,
        zoho_id: this.state.zoho_id,
        ship_address: this.state.ship_address,
        ship_city: this.state.ship_city,
        ship_state: this.state.ship_state,
        ship_pincode: this.state.ship_pincode,
        ip_address: this.state.ip_address,
        payment_mode: this.state.payment_mode,
        payment_status: "unpaid",
        amount: this.state.amount,
        cart_price: this.props.total,
        discount: this.state.discount,
        promo_code: this.props.promo_data == "" ? "" : this.state.code,
        cod_token: this.state.payment_mode == "cod" ? this.props.otp_verify.token : ""
      };

      var guest_details = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone: this.state.phone,
        email: this.state.email,
        subscription: false,
        password: "password",
        c_password: "password",
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: "India",
        pincode: this.state.pincode,
        cod_token: this.state.payment_mode == "cod" ? this.props.otp_verify.token : ""
      };

      if (code) {
        this.props.postAllOrderdetails(
          profile_details,
          this.props.cartItems,
          guest_details,
          this.state.payment_mode
        );
        this.validator.hideMessages();
        // this.clearField()
        if (this.state.payment_mode === "razorpay") {
          toast.dark("Order proccessing... please wait", {
            autoClose: 3500,
            hideProgressBar: false,
            progress: undefined
          });
        }
        else
          toast.dark("Order proccessing... please wait");
        this._reCaptchaRef.current.reset();
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': { 'step': 1, 'option': this.payment_mode },
            'products': [{
              'name': this.props.cartItems.name,
              'id': this.props.cartItems.sku,
              'price': this.props.cartItems,
              'brand': 'Sunny Dimaonds',
              'category': this.props.cartItems.value,
              'variants': this.props.cartItems.color,
              'quantity': this.props.cartItems.qty
            }]
          }
        },
      });
      // window.fbq('track', 'AddPaymentInfo', {
      //   contents: this.state.payment_mode,
      //   // content_ids: content_ids,
      //   value: this.state.amount,
      //   // content_name: content_name,
      //   currency: "INR",
      //   event_time: new Date().getTime()
      // })
    }
  };

  render() {
    const { symbol, total } = this.props;
    let { cartItems } = this.props;

    return (
      <div>
        {/*SEO Support*/}
        <Seo type="checkout-head" />
        <Breadcrumb title="checkout" />

        <section className="section-b-space">
          <div className="container padding-cls">
            <div className="checkout-page">
              <div className="checkout-form">
                <div className="checkout row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5">
                    <div className="row check-out">
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">First Name</div>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          value={this.state.first_name}
                          onChange={this.setStateFromInput}
                          onBlur={() => this.validator.showMessageFor('first_name')}
                        />
                        {this.validator.message(
                          "first_name",
                          this.state.first_name,
                          "required|alpha_space"
                        )}
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Last Name</div>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          value={this.state.last_name}
                          onChange={this.setStateFromInput}
                          onBlur={() => this.validator.showMessageFor('last_name')}
                        />
                        {this.validator.message("last_name", this.state.last_name, "required|alpha_space")}
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Phone</div>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          value={this.state.phone}
                          onChange={this.setStateFromInput}
                          onBlur={() => this.validator.showMessageFor('phone')}
                        />
                        {this.validator.message(
                          "phone",
                          this.state.phone,
                          "required|phone|min:10|max:10"
                        )}
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="field-label">Email Address</div>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.setStateFromInput}
                          onBlur={() => this.validator.showMessageFor('email')}
                        />
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required|email"
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="row check-out">
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Country</div>
                            <select
                              name="country"
                              className="form-control"
                              value={this.state.country}
                              onChange={this.setStateFromInput}
                            >
                              <option>India</option>
                            </select>
                            {this.validator.message(
                              "country",
                              this.state.country,
                              "required"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">
                              Billing Address
                            </div>
                            <textarea
                              name="address"
                              cols="8"
                              className="form-control"
                              value={this.state.address}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "address",
                              this.state.address,
                              "required"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Town/City</div>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              value={this.state.city}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "city",
                              this.state.city,
                              "required|alpha_num_space"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <div className="field-label">State</div>
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              value={this.state.state}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "state",
                              this.state.state,
                              "required|alpha_space"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <div className="field-label">Postal Code</div>
                            <input
                              type="text"
                              name="pincode"
                              className="form-control"
                              value={this.state.pincode}
                              onChange={this.setStateFromInput}
                              onBlur={() => this.validator.showMessageFor('pincode')}
                            />
                            {this.validator.message(
                              "pincode",
                              this.state.pincode,
                              "required|numeric|min:6|max:6"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row check-out">
                          <div className="form-group col-md-12 col-sm-12 col-xs-12 mt-4 pt-md-3 d-flex">
                            <input
                              type="checkbox"
                              name="same_address"
                              id="same_address"
                              onChange={this.sameAddress.bind(this)}
                            />
                            <label htmlFor="same_address" className="pl-2 mt-1 d-flex">Same as Billing Address</label>
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row">
                              <div className="field-label">
                                Shipping Address
                              </div>
                            </div>
                            <textarea
                              name="ship_address"
                              className="form-control"
                              cols="8"
                              value={this.state.ship_address}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "address",
                              this.state.ship_address,
                              "required"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-12 col-xs-12">
                            <div className="field-label">Town/City</div>
                            <input
                              type="text"
                              name="ship_city"
                              className="form-control"
                              value={this.state.ship_city}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "city",
                              this.state.ship_city,
                              "required|alpha_num_space"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <div className="field-label">State</div>
                            <input
                              type="text"
                              name="ship_state"
                              className="form-control"
                              value={this.state.ship_state}
                              onChange={this.setStateFromInput}
                            />
                            {this.validator.message(
                              "state",
                              this.state.ship_state,
                              "required|alpha_space"
                            )}
                          </div>
                          <div className="form-group col-md-12 col-sm-6 col-xs-12">
                            <div className="field-label">Postal Code</div>
                            <input
                              type="text"
                              name="ship_pincode"
                              className="form-control"
                              value={this.state.ship_pincode}
                              onChange={this.setStateFromInput}
                              onBlur={() => this.validator.showMessageFor('pincode')}
                            />
                            {this.validator.message(
                              "Pincode",
                              this.state.ship_pincode,
                              "required|numeric|min:6|max:6"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5">
                    {!this.state.sync ?
                      <>
                        <div className="alert alert-secondary mb-3 d-none d-md-block mx-3">
                          <Loader text="Please wait as we are synchronizing your cart with server" />
                        </div>
                        <div className="alert alert-secondary mb-3 d-block d-md-none">
                          <Loader text="syncing your cart with server" />
                        </div>
                      </>
                      : ""
                    }
                    <div className="checkout-details mt-3 px-0">
                      <div className="order-box">
                        <div className="qty">
                          {cartItems.map((item, index) => {
                            item.product_id = item.id;
                            // item.price = item.variants[0].offerprice;
                            // item.color = item.variants[0].color;
                            item.price = item.selectedVariant.offerprice;
                            item.color = item.selectedVariant.color;
                            return (
                              <div key={index}>
                                <div className="d-block d-sm-flex d-md-flex py-2">
                                  <div className="d-flex mb-2 mb-sm-0 mb-md-0 justify-content-center">
                                    <img
                                      src={
                                        `${process.env.PUBLIC_URL}` +
                                        this.CheckoutimgeSource(item)
                                      }
                                      onError={e => {
                                        e.target.src = `${process.env.PUBLIC_URL
                                          }/assets/images/sunny/no_image/no_images.png`;
                                      }}
                                      alt={item.name}
                                      style={{
                                        width: "100px",
                                        height: "100px"
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex flex-grow-1">
                                    <div
                                      className="flex-grow-1 px-lg-4"
                                      key={index}
                                    >
                                      <h5><b>{item.name.toUpperCase()}</b></h5>
                                      <h6>{item.sku}</h6>
                                      <h6>{item.color.replace("-", " ").toUpperCase()}</h6>
                                      <h6>
                                        <span className="cart_sum mr-4">
                                          {symbol}{item.price}
                                        </span>
                                      </h6>
                                      <h6>
                                        Qty: {item.qty}
                                        <span className="cart_sum pl-3">
                                          Total: {symbol}{item.sum}
                                        </span>
                                      </h6>

                                      <br />
                                    </div>
                                    <div>
                                      <h5 className="td-color mr-2">
                                        <a
                                          href="javascript:void(0)"
                                          className="icon"
                                          onClick={() => {
                                            this.props.removeFromCart(item)
                                          }}
                                        >
                                          <i className="icon-close" />
                                        </a>
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <ul className="sub-total">
                          <li>
                            Subtotal
                            <span className="count text-right">
                              {symbol}{total.toFixed(2)}
                            </span>
                          </li>
                        </ul>
                        <div className="row my-5">

                          <div className="d-flex flex-row col-12 col-md-6 col-lg-12 col-xl-6">
                            <input
                              type="text"
                              name="code"
                              placeholder="Promo code"
                              className="form-control"
                              value={this.state.code}
                              onChange={this.setStateFromInput}
                              disabled={this.state.button_disable}
                            />
                            <button
                              type="button"
                              className="btn btn-promo-code"
                              onClick={this.checkPromo}
                              disabled={this.state.button_disable}
                            >
                              Apply
                            </button>
                          </div>
                        </div >
                        {this.state.discount != 0 ? (
                          <ul className="sub-total mt-3">
                            <li>
                              Promo code discount
                              <span className="count text-right">
                                -{symbol}{this.state.discount.toFixed(2)}
                              </span>
                            </li>
                          </ul>
                        ) : ""}
                        <ul className="total mt-3">
                          <li>
                            Total
                            <span className="count cart_sum text-right">
                              {symbol}{(
                                total - this.state.discount - this.state.gift_discount > 0 ?
                                  total - this.state.discount - this.state.gift_discount : 0).toFixed(2)}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <ReCAPTCHA
                        ref={this._reCaptchaRef}
                        size="invisible"
                        sitekey={global.reCaptcha}
                        onErrored={console.log}
                        onChange={this.handleSubmit}
                      />
                      <div className="payment-box">
                        <div className="upper-box">
                          <div className="payment-options">
                            <ul>
                              <li className="row pl-3">
                                <div className="radio-option cod col-md-6 col-sm-12">
                                  <input type="radio" name="payment-group" id="payment-2" onClick={() => this.checkPaymentHandle('cod')} disabled={!this.state.cod_active} />
                                  <label htmlFor="payment-2">Cash on delivery</label>
                                  {this.state.cod_msg && this.state.payment_mode != "razorpay" && <div className={"d-flex align-items-center my-3 p-2 " + this.state.cod_color} role="alert">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                    </svg>
                                    <div className="px-1">
                                      {this.state.cod_msg}
                                    </div>
                                  </div>}
                                  {this.state.payment_mode === "select_payment" ?
                                    <div className="alert-warning d-flex align-items-center my-3 p-2" role="alert">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z" clipRule="evenodd" />
                                      </svg>
                                      <div className="px-1">
                                        Please select a payment method
                                      </div>
                                    </div> : ""}
                                </div>
                                <div className="radio-option razorpay col-md-6 col-sm-12">
                                  <input type="radio" name="payment-group" id="payment-1" onClick={() => this.checkPaymentHandle('razorpay')} />
                                  <label htmlFor="payment-1">Online Payment<span className="image"></span></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="payment-box text-center">
                          {
                            (this.state.first_name &&
                              this.state.last_name &&
                              this.state.phone &&
                              this.state.email &&
                              this.state.address &&
                              this.state.city &&
                              this.state.state &&
                              this.state.country &&
                              this.state.pincode &&
                              this.state.ip_address &&
                              this.state.ship_address &&
                              this.state.ship_city &&
                              this.state.ship_state &&
                              this.state.ship_country &&
                              this.state.ship_pincode) ?
                              ((this.state.payment_mode == "razorpay") ?
                                (
                                  <div className="d-flex justify-content-end">
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 ">
                                      <PayApp
                                        Order={this.state}
                                        Disabled={this.state.disabled}
                                        submitHandler={this.onRecaptcha}
                                        orderData={this.props.orderData}
                                        mode="product"
                                      // place_order={this.state.place_order}
                                      />
                                    </div>
                                  </div>
                                ) : (this.state.payment_mode == "cod" && this.state.cod_active === true) ?
                                  (
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12">
                                      <OtpVerification
                                        otp={this.props.otp_req}
                                        otp_verify={this.props.otp_verify}
                                        phone={this.state.phone}
                                        submitHandler={this.onRecaptcha}
                                        place_order={true}
                                        label="Enter your mobile number for OTP"
                                      />
                                    </div>
                                  ) : ""
                                // (
                                //   <button
                                //     type="button"
                                //     className="btn-solid btn"
                                //     onClick={() => this.DummyRazorClick()}
                                //   >
                                //     Place Order
                                //   </button>
                                // )
                              )
                              : ""
                            // <button
                            //   type="button"
                            //   className="btn-solid btn"
                            //   onClick={() => this.DummyRazorClick()}
                            // >
                            //   Place Order
                            // </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
  profile_data: state.data.profile_data,
  promo_data: state.cartList.promo_details,
  otp_req: state.data.otp_request,
  otp_verify: state.data.otp_verify,
  gift_otp_verify: state.otp.access_otp_verify,
  orderData: state.data.orderid_data,
  balance: state.gift.balance,
});

export default connect(
  mapStateToProps,
  {
    removeFromWishlist,
    removeFromCart,
    postAllOrderdetails,
    actProfile,
    postPromoCodeDetails,
    postCartItems,
    errorMsgAction,
  }
)(checkOut);
