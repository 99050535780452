import React from "react";
import { Link } from "react-router-dom";


const HowItsWork = () => {
    return (


        <>
            <section>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="row justify-content-center my-4">
                                <h3>HOW IT WORKS</h3>
                                <p className="px-5 justify-content-center">It’s quick and simple, just like popping a bubble. It's so hassle-free that the only thing you need to worry about is choosing the outfit you'll be wearing with your favourite jewellery. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="row justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} height={50} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>

                            </div>
                            <div className="row justify-content-center">
                                <h4 className="mt-3"><strong>Pay Monthly</strong></h4>
                                <p className="mt-3 px-5 justify-content-center">Take easy, baby steps by choosing a fixed plan amount to keep aside with Sunny diamonds every month...</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="row justify-content-center">
                                <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" strokeWidth={1.5} height={50} xmlns="http://www.w3.org/2000/svg">
                                    <path d="M479.41,454.14,381.72,403a10,10,0,0,0-5.43-1.13c-1.1.07-110.56,7.75-126.64,8.13-14.1-1.63-124-56.54-198.69-95.59,6.36-5.37,20.88-12.62,42.78-4.06,22.26,8.71,73,27.9,96,36.56a50.27,50.27,0,0,0-5,14.74,18.2,18.2,0,0,0,18,20.87,18.66,18.66,0,0,0,4.19-.49c7.87-1.85,79.22-12.31,101-13.52a10.18,10.18,0,0,0,9.6-10.72,10.3,10.3,0,0,0-10.74-9.6c-20.26,1.12-83.71,10.14-101.17,13.34,2.53-7.67,10.52-21.21,26.13-30.1,30.83-17.54,88.84-37.4,122.78-37.84,24.33.23,56.37,27.53,66.55,38.29a10,10,0,0,0,2.35,1.84l46.21,26.43a10.17,10.17,0,1,0,10.1-17.66l-45-25.71c-7-7.11-44.45-43.55-79.85-43.55h-.66c-22.6.29-53.75,8-82.34,18.19-1.67-1.62-37.59-35.93-71.82-35.93-22.37,0-39.05,11.92-39.75,12.43a10.11,10.11,0,0,0-3.47,4.5,138.42,138.42,0,0,0-15.41-4.29c-29.24-5.93-52.89,10.59-61.88,18.14C52.65,284.11,33.87,299,28.78,310.09c-4,8.71-.16,15.63,5,18.37C66.3,345.51,229,430.39,249.48,430.38h.15c15.71-.36,107.33-6.72,125.21-8L470,472.17a10.08,10.08,0,0,0,4.7,1.16,10.17,10.17,0,0,0,4.73-19.19ZM200.11,275.89c17.32,0,38.3,14,50.45,23.88-5.72,2.42-11.17,4.9-16.27,7.4-9.68-5.08-32.7-16.85-55.42-26.35A51.49,51.49,0,0,1,200.11,275.89Zm-62.7,12.24c20.28,4.13,55.43,20.85,75.88,31.14a83.25,83.25,0,0,0-11.58,10.46c-18.45-7-69.83-26.35-95.83-36.45C114.29,289,125.35,285.68,137.41,288.13Z" /><path d="M309.08,99.73c11.63-10.5,23.33-26,17.07-42.72a28,28,0,0,0-31.56-17.87c-9,1.63-18.91,7.78-25.29,19.79-6.39-12-16.3-18.15-25.3-19.79A28,28,0,0,0,212.43,57c-6.26,16.69,5.44,32.22,17.07,42.72H171.37V137.3H367.21V99.73Zm-84-37.95a14.28,14.28,0,0,1,16.46-9.28c9.66,1.75,20.93,12.59,20.93,37.39a6.79,6.79,0,1,0,13.57,0c0-24.8,11.28-35.64,20.92-37.4,7.45-1.28,13.83,2.31,16.46,9.29,5.72,15.26-18.39,32.51-26.78,38H251.81C240.85,92.75,219.86,75.83,225.12,61.78Z" />
                                    <rect height="90.1" width="144.21" x="197.19" y="150.87" />
                                </svg>
                            </div>
                            <div className="row justify-content-center">
                                <h4 className="mt-3"><strong>Our Gift For You</strong></h4>
                                <p className="mt-3 px-5 justify-content-center">At the end of 12 months, Sunny diamonds will issue a gift*value equivalent to 100% of your first months's plan amount</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="row justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} height={50} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                </svg>

                            </div>
                            <div className="row justify-content-center">
                                <h4 className="mt-3"><strong>Purchase</strong></h4>
                                <p className="mt-3 px-5 justify-content-center">You can redeem by purchasing jewellery of your choice, either at our stores or online</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center my-lg-5 my-3">
                        <Link
                            to={`${process.env.PUBLIC_URL}/dfe-form`}
                            className="btn btn-solid"
                        >
                            Plan Now
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HowItsWork