import React from "react";
import FaqSidepanel from "../side-panel";

import { Link } from "react-scroll";

import { v4 as uuidv4 } from "uuid";


const MobileFaqSidePanel = (props) => {

    const validateURL = (url) => {
        const parsed = new URL(global.publicUrl + url)
        return ['https:', 'http:'].includes(parsed.protocol)
    }


    return (
        <div className="uk-hidden@l">
            <button className="uk-button uk-button-default uk-width-auto@s uk-margin-small-top  uk-flex uk-flex-middle" data-uk-toggle="target: #modal-fitter-menu-mob">
                <p className="uk-text-left uk-width-1-1 uk-margin-remove">FAQ</p>
                <a className="uk-text-right" href="" data-uk-icon="settings"></a>
            </button>
            <div id="modal-fitter-menu-mob" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <div className="uk-width-1-1 uk-padding-remove side-bar-listing"></div>
                    <div className="uk-width-auto@m">
                        <h2>FAQ</h2>
                        <div>
                            <ul className="uk-tab-left" data-uk-tab>
                                {props.card3
                                    .filter(data => data.heading)
                                    .map((item, i) => (
                                        <Link
                                            key={uuidv4()}
                                            to={validateURL(`${item.heading}`) ? `${item.heading}` : ''}
                                            spy={true}
                                            smooth={true}
                                            offset={-275}
                                            activeClass="active"
                                        >
                                            <li className="uk-active uk-padding-small uk-modal-close">
                                                <p className="normal-para">{item.heading}</p>
                                            </li>
                                        </Link>

                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileFaqSidePanel;
