import React, { useState, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";

import { checkGiftBalance, errorAccessMsgAction } from "../../../actions"
import SecureAccessCard from "./secure-access-card"
import store from "../../../store"
import Loader from "../../common/spinner"


const BalanceGift = (props) => {

    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [cardData, setCardData] = useState("")
    const [dialogBox, setDialogBox] = useState(true)
    const [loadDialog, setLoadDialog] = useState(false)

    useEffect(() => {
        store.dispatch({ type: "ACCESS_RECEIVE_REQUEST_OTP", payload: "" });
        store.dispatch({ type: "ACCESS_RECEIVE_VERIFY_OTP", payload: "" });
        props.errorAccessMsgAction("ACCESS_RESET_OTP")
    }, []);

    const handleChange = (event) => {
        event.persist()
        setCardData(event.target.value)
    }

    const handleValidity = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            props.checkGiftBalance(cardData, props.otp_verify && props.otp_verify.access.access_token)
        }
        else {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const unixTime = 1697692664;
    const date = new Date(unixTime*1000);
    const time = date.toLocaleDateString("en-IN");


    const showDialog = () => {
        return (
            <div className="balance-card p-1 p-lg-4 mr-lg-4 text-center">
                <p className='text-right mb-0 d-block d-lg-none' onClick={() => setDialogBox(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        height="15px"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}>
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </p>
                {(props.timer.msgFlag.flag == "verified") ? <>
                    {props.balance ?
                        <div>
                            <h2 className='mb-2'>Here is your balance</h2>
                            <h2 className='gift-balance my-2'>{props.symbol}<b>{props.balance.purchase_card_balance}</b></h2>
                            <h4 className='gift-balance'>Card holder: {props.balance.purchase_recipients_name}</h4>
                            {/* <h4 className='gift-balance'>Email: {props.balance.purchase_recipients_email}</h4> */}
                            {props.balance.purchase_card_expiry ? <h4 className='gift-balance'>Expiry date: {time}</h4> : ""}
                            {props.balance.purchase_card_delivery_date ? <h4 className='gift-balance'>Delivery date: {props.balance.purchase_card_delivery_date}</h4> : ""}
                            <button
                                className="btn btn-dfe2 mt-3"
                                type="submit"
                                onClick={() => props.errorAccessMsgAction("ACCESS_RESET_OTP")}
                            >
                                Try another
                            </button>
                        </div>
                        : <div className='my-5'>
                            <Loader />
                        </div>
                    }
                </>
                    : <div>
                        <h3 className='mb-3'>Check Your Card Balance</h3>
                        <input
                            type="text"
                            name="cardNumber"
                            className="form-control mb-3"
                            placeholder="Gift Card Number"
                            value={cardData}
                            onChange={handleChange}
                        />
                        {validator.current.message("Card number", cardData, "required")}
                        <SecureAccessCard
                            otp_verify={props.otp_verify}
                            credentials={cardData}
                            handleSubmit={handleSubmit}
                            handleValidity={handleValidity}
                            access_tag="balance"
                        />
                    </div>
                }
            </div>
        )
    }

    return (
        <div>
            <div className="col-12 d-flex flex-row justify-content-center d-block d-lg-none mt-3 px-4">
                {dialogBox ?
                    showDialog() :
                    <button className="btn btn-dfe2 mx-2" onClick={() => setDialogBox(true)}>
                        <div className="mx-3">Check balance</div>
                    </button>
                }
            </div>
            <div className="container media-container d-lg-block d-none">
                <img
                    src={`${process.env.PUBLIC_URL
                        }/assets/images/sunny/Gift_card/banner/Gift_card_banner.jpg`}
                    alt="Sunny diamonds"
                    className="img-fluid"
                    onLoad={() => setLoadDialog(true)}
                />
                <div className="container balance-text d-flex align-items-end flex-column">
                    {loadDialog ? showDialog() : ""}
                </div>
            </div>
        </div   >
    )
}

const mapStateToProps = state => ({
    otp_verify: state.otp.access_otp_verify,
    timer: state.otp,
    symbol: state.data.symbol,
    balance: state.gift.balance
});

export default connect(mapStateToProps, { checkGiftBalance, errorAccessMsgAction })(BalanceGift)
