import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getShoppCategory } from "../../../services/index";

class ShopCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: "mobile"
    };
  }

  componentWillMount() {
    if (window.innerWidth > 600) {
      this.setState({ device: "mobile" })
    }
    if (window.innerWidth < 700) {
      this.setState({ device: "desktop" })
    }
  }

  render() {
    const { item } = this.props;
    if (item.length > 0)
      return (
        <div>
          <section className="section-b-space mx-0">
            <div className="container">
              <div className="title4">
                <h2 className="title-inner4">Shop by Category</h2>
              </div>
              <div className="row">
                {item.map((data, i) => (
                   <div key={i} className="col-md-4 pt-2 pt-xl-4 px-1 d-flex justify-content-around">
                   <div className="product-box2">
                     <div className="media media-container">
                       <Link to={`${process.env.PUBLIC_URL}` + data.link}>
                         <img
                           className="img-fluid blur-up lazyload image_category rounded shadow bg-body"
                           src={`${process.env.PUBLIC_URL}` + data.pictures}
                           alt={data.title}
                         />
                         <div className="media-text ml-lg-0">
                           <div className="text px-0 text-left">{data.title}</div>
                         </div>
                       </Link>
                     </div>
                   </div>
                 </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  item: getShoppCategory(state.data.products, ownProps.type)
});

export default connect(mapStateToProps)(ShopCategory);
