import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { connect } from "react-redux";
import { getCategory } from "../../../services";
import DOMPurify from 'dompurify';
import Seo from "../../layouts/common/seo";

import Banner from "../../layouts/common/banner";

import "./faq.css"
import MobileFaqSidePanel from "./mobile-side-panel";
import FaqSidepanel from "./side-panel";
import Promise from "../../new_home_page/promise";

class Faq extends Component {



  render() {
    const { listfaq, card3 } = this.props;
    if (listfaq.length > 0) {
      return (
        <div>
          {/* seo section  */}
          <Seo type="faq-head" />

          <Breadcrumb title={listfaq[0].title} />

          {/*Dashboard section*/}
          <section className="faq-section section-b-space blog-page">
            <div className="uk-section sd-inner-content uk-padding-small">
              <div className="uk-container uk-container-large">

                <div data-uk-grid>

                  <div className='mt-2'>
                    <Banner type="faq-head" move="false" title_light={true} />
                  </div>


                  <MobileFaqSidePanel
                    card3={card3}
                  />

                  {/* side-panel faq  */}
                  <FaqSidepanel
                    card3={card3}
                  />

                  {/* main faq  */}
                  <div className="uk-width-expand@m sd-accordion  side-bar-listing">
                    <ul data-uk-accordion="duration : 1000">
                      {listfaq.map((item, i) => (
                        <li key={i}>
                          {item.heading ? (
                            <h2 className="title-medium" id={item.heading}>{item.heading}</h2>
                          ) : (
                            " "
                          )}
                          <a className="uk-accordion-title" href="#">{item.question}</a>
                          <div className="uk-accordion-content uk-padding-small">
                            <p className="normal-para" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }}></p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Sunny diamonds promise section*/}
          <div className="mt-5 pt-2">
            <Promise />
          </div>

        </div>
      );
    } else return 0;
  }
}

const mapStateToProps = state => ({
  listfaq: getCategory(state.data.products, "faq"),
  card3: getCategory(state.data.products, "faq")
});

export default connect(mapStateToProps)(Faq);
