import {
    FETCH_SINGLE_PRODUCT,
    CHANGE_CURRENCY,
    RECEIVE_PRODUCTS,
    RECEIVE_TRENDING,
    RECEIVE_PDP,
    RECEIVE_DB_CATEGORY,
    RECEIVE_CUSTOMER_DETAIL,
    RECEIVE_NEW_PRODUCTS,
    RECEIVE_NEW_CUSTOMER_DETAIL,
    RECEIVE_CUSTOMIZED_DETAIL,
    RECEIVE_LOGIN_DETAIL,
    RECEIVE_LOGOUT_DETAIL,
    RECEIVE_SUBSCRIBE,
    RECEIVE_ORDER_ID,
    RECEIVE_ZOHO_ACCESS_TOKEN,
    RECEIVE_ZOHO_SUBMIT,
    RECEIVE_PROFILE,
    RECEIVE_UNIQUE,
    RECEIVE_ORDER_TRACKING_SUBMIT,
    RECEIVE_MY_ORDER,
    RECEIVE_ORDER_PRODUCTS,
    RECEIVE_REQUEST_OTP,
    RECEIVE_VERIFY_OTP,
    RECEIVE_CMS,
    RECEIVE_PROMO_CODE,
    SET_LOADER,
    RESET_CHECKOUT
} from "../constants/ActionTypes";

const initialState = {
    products: [],
    trending: [],
    category: [],
    pdp: [],
    newproduct: [],
    customer_detail: [],
    newuser: [],
    customize_detail: [],
    login_data: [],
    symbol: "₹",
    currency_code: "INR",
    product_details: [],
    subscribe_data: [],
    orderid_data: [],
    access_token: [],
    zoho_user: [],
    profile_data: [],
    unique_data: "",
    order_track: [],
    order_detail: [],
    user_type: "GUEST",
    otp_request: [],
    otp_verify: [],
    about_json: [],
    promo_code_name: [],
    loader: false
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return { ...state, products: action.products };

        case FETCH_SINGLE_PRODUCT:
            if (
                state.products.findIndex(product => product.id === action.productId) !==
                -1
            ) {
                const singleItem = state.products.reduce((itemAcc, product) => {
                    return product;
                }, []);
                return { ...state, product_details: singleItem };
            }
            break;
        case RECEIVE_TRENDING:
            return {
                ...state,
                trending: action.payload
            };

        case RECEIVE_DB_CATEGORY:
            return {
                ...state,
                category: action.payload
            };

        case RECEIVE_PDP:
            return {
                ...state,
                pdp: action.payload
            };

        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.payload.symbol,
                currency_code: action.payload.currency_code
            };

        case RECEIVE_CUSTOMER_DETAIL:
            return {
                ...state,
                customer_detail: action.payload
            };
        case RECEIVE_NEW_PRODUCTS:
            return {
                ...state,
                newproduct: action.payload
            };
        case RECEIVE_NEW_CUSTOMER_DETAIL:
            return {
                ...state,
                newuser: action.payload
            };
        case RECEIVE_CUSTOMIZED_DETAIL:
            return {
                ...state,
                customize_detail: action.payload
            };
        case RECEIVE_LOGIN_DETAIL:
            return {
                ...state,
                login_data: action.payload,
                user_type: action.payload.user_type
            };
        case RECEIVE_LOGOUT_DETAIL:
            return {
                ...state,
                login_data: '',
                user_type: "GUEST"
            };
        case RECEIVE_SUBSCRIBE:
            return {
                ...state,
                subscribe_data: action.payload
            };
        case RECEIVE_ORDER_ID:
            return {
                ...state,
                orderid_data: action.payload,
            };
        case RECEIVE_ZOHO_ACCESS_TOKEN:
            return {
                ...state,
                access_token: action.payload
            };
        case RECEIVE_ZOHO_SUBMIT:
            return {
                ...state,
                zoho_user: action.payload
            };
        case RECEIVE_PROFILE:
            return {
                ...state,
                profile_data: action.payload
            };
        case RECEIVE_UNIQUE:
            return {
                ...state,
                unique_data: action.payload
            };
        case RECEIVE_ORDER_TRACKING_SUBMIT:
            return {
                ...state,
                order_track: action.payload
            };
        case RECEIVE_ORDER_PRODUCTS:
            return {
                ...state,
                order_products: action.payload
            };
        case RECEIVE_MY_ORDER:
            return {
                ...state,
                order_detail: action.payload
            };

        case RECEIVE_REQUEST_OTP:
            return {
                ...state,
                otp_request: action.payload
            };
        case RECEIVE_VERIFY_OTP:
            return {
                ...state,
                otp_verify: action.payload
            };
        case RECEIVE_CMS:
            return {
                ...state,
                about_json: action.payload
            };
        case RECEIVE_PROMO_CODE:
            return {
                ...state,
                promo_code_name: action.payload
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case RESET_CHECKOUT:
            return {
                ...state,
                orderid_data: [],
                promo_code_name: [],
                otp_request: [],
                otp_verify: [],
                loader: false
            }
        default:
            return state;
    }
};
export default productReducer;