import React from 'react';

import { connect } from "react-redux";

import Slider from "react-slick";

import { getAllNewProducts, getAllTrending } from "../../../actions";

import ProductSlider from './product-slider';
import './glitz.css'
import { useEffect } from 'react';

const Glitz = (props) => {

    useEffect(() => {
        props.getAllTrending();
    }, [])


    const Arrow = (props) => {
        let className = props.type === "next" ? "nextArrow" : "prevArrow";
        className += " arrow";
        const char = props.type === "next" ? "⌃" : "⌃";
        return (
            <span className={className} onClick={props.onClick}>
                {char}
            </span>
        );
    }

    const slider = {
        dots: false,
        arrows: true,
        autoplay: true,
        speed: 2500,
        infinite: true,
        autoplaySpeed: 5500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <Arrow type="next" />,
        prevArrow: <Arrow type="prev" />,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const { product, items } = props;

    return (
        <section className="putting-glitz my_l">
            <div className="home-container">
                <div className="glitz-left">
                    <div className="glitz-top">
                        <p className="para-one">GLAM LIKE DIAMOND</p>
                        <div className="heading-section">
                            <h2 className="heading-title title-normal">Putting on the Glitz</h2>
                            <div className="heading-icon">
                                <span className="hr-line">&nbsp;</span>
                                <span className="diamond-icon">&nbsp;</span>
                                <span className="hr-line">&nbsp;</span>
                            </div>
                        </div>
                    </div>
                    <div className="glitz-carousel">
                        <Slider {...slider}>
                            {product.map((item, index) => (
                                <div key={index}>
                                    <ProductSlider
                                        item={item}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    {/* <p className="bottom-border"><span>&#160;</span></p> */}
                </div>
                <div className="glitz-right">
                    <img src="/assets/images/new_template/images/05.png" alt="" />
                </div>
            </div>
        </section>
    );
};


const mapStateToProps = (state, ownProps) => ({
    product: state.data.trending,
    items: state.data.newproduct
});

export default connect(mapStateToProps, { getAllNewProducts, getAllTrending })(Glitz);