import React from 'react';
import { Link } from 'react-router-dom';

import "./gifts-ideas.css";

const GiftsIdeas = () => {

    return (
        <section className="gift-ideas-section">
            <div className="gift-ideas-container">
                <div className="heading-section">
                    <h2 className="heading-title title-normal">Gifts Ideas</h2>
                    <div className="heading-icon">
                        <span className="hr-line">&nbsp;</span>
                        <span className="diamond-icon">&nbsp;</span>
                        <span className="hr-line">&nbsp;</span>
                    </div>
                </div>
                <div className="gift-content">
                    {window.giftIdeasLinks.map((element, i) => (
                        <Link to={`${process.env.PUBLIC_URL}/` + element.link}>{element.menu}</Link>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GiftsIdeas;