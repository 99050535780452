import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import { getAboutus } from "../../../services/index";
import Features from "./features";
import store from "../../../store";
import { v4 as uuidv4 } from "uuid";
import Seo from "../common/seo";
import Banner from "../common/banner";

import FeatureIn from "../common/featurein";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeVal: 0
    };
  }

  componentDidMount() {

    if (navigator.cookieEnabled) {
      if (localStorage.getItem("themeLayout") === "dark") {
        store.dispatch({ type: "THEME_LAYOUT", themeColor: "dark" });
      } else {
        store.dispatch({ type: "THEME_LAYOUT", themeColor: "light" });
      }
    }
  }

  render() {
    const { themeColor, about_data, service_data } = this.props;

    if (about_data.length > 0) {

    return (
      <div>
        {/* seo section  */}
        <Seo type="about-us-head" />

        <Breadcrumb title={about_data[0].about[0].title} />
        {/*about section*/}
        <section>
          <div className="container">
            <div className="row">
              {/* Banner section  */}
              <Banner type="about-us-head" move="false" />
            </div>
          </div>
        </section>

        {/* {Timeline} */}
        <section className="abt-section mt-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                {about_data[0].about
                  .filter(data => data.type !== "Banner")
                  .map(data => (
                    <div className="col-lg-12" key={uuidv4()}>
                      {data.type === "TCI" ? <h2>{data.title}</h2> : ""}
                      {data.type === "TC" ? <h2>{data.title}</h2> : ""}
                      {data.type === "TCI" ? (
                        <div className="banner-section timeline">
                          <img
                            className="timeline-banner"
                            src={
                              `${process.env.PUBLIC_URL}` +
                              data.image[themeColor].src
                            }
                            alt={data.title}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <p>{data.content}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>

        {/* {Features in} */}
      <FeatureIn />

        {/* service layout */}
        <div className="container about-cls section-b-space">
          <section className="service small-section-service ">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {service_data.map((data, i) => (
                    <div className="col-md-4 service-block" key={i}>
                      <div className="media">
                        <div className="row">
                          <div className="col-md-12 media-head">
                            <img
                              src={
                                `${process.env.PUBLIC_URL}` +
                                data.image[themeColor].src
                              }
                              alt={data.title}
                            />
                          </div>
                          <div className="media-body">
                            <h4 className="serv-head">{data.title}</h4>
                            <p className="serv-para">{data.content}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* {/*service layout end */}

        {/*Leadership*/}
        <section className="leadership small-section-lead">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="ldshp-head">Leadership Team</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                        <a
                          href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            className="img-fluid"
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/sunny/about_us/sunny _new.webp`}
                            alt="P.P. Sunny"
                          />
                        </a>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-5 p-0 mt-5">
                        <a
                          href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <h4 className="leadership_text">P.P. Sunny</h4>
                          <p className="leadership_text">
                            Founder & CMD
                            <br />
                            Graduate from GIA
                            <br />
                            <b>Linked</b>
                            <i className="fab fa-linkedin" aria-hidden="true" />
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-5 p-0 mt-5">
                        <a
                          href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <h4 className="leadership_text" style={{textAlign:'right'}}>Prem Sunny</h4>
                          <p className="leadership_text" style={{textAlign:'right'}}>
                            Director of Operations
                            <br />
                            Graduate from GIA
                            <br />
                            <b>Linked</b>
                            <i className="fab fa-linkedin" />
                          </p>
                        </a>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                        <a
                          href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img
                            className="img-fluid"
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/sunny/about_us/prem_new.webp`}
                            alt="Prem Sunny"
                          />
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Sunny diamonds features section*/}
        <div>
          <Features />
        </div>
        {/* end of sunny diamonds features section */}
      </div>
    );
  } else return 0;

  }
}

const mapStateToProps = state => ({
  product: getAboutus(state.data.products),
  about_data: getAboutus(state.data.products, "about-us-new"),
  service_data: getAboutus(state.data.products, "about-us-service"),
  featurein_data: getAboutus(state.data.products, "about-us-feature"),
  themeColor: state.theme.themeColor
});

export default connect(mapStateToProps)(AboutUs);
