import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { getCategory } from "../../../services/index";
import { connect } from "react-redux";

class Seo extends Component {
  render() {
    const { seo } = this.props;

    if (seo.length > 0) {
      return (
        <Helmet>
          <title>{seo[0].seo.title}</title>
          <meta name="description" content={seo[0].seo.description} />
          <meta name="keywords" content={seo[0].seo.keyWords} />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta property="og:title" content={seo[0].seo.title} />
          <meta property="og:description" content={seo[0].seo.description} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta name="twitter:title" content={seo[0].seo.title} />
          <meta name="twitter:description" content={seo[0].seo.description} />
          {/* <meta name="robots" content={seo[0].seo.robots} /> */}
        </Helmet>
      );
    } else return '';
  }
}
const mapStateToProps = (state, Ownprops) => ({
  seo: getCategory(state.data.products, Ownprops.type)
});
export default connect(mapStateToProps)(Seo);
