import React from "react";
import { Link } from "react-router-dom";

import '../css/sd-features.css'

const SDFeatures = () => {
    return (
        <div className="internally-flawless pdp-feature-img">
            <div className="uk-grid uk-flex-left uk-flex-left@m uk-flex-center@s">
                <div className="uk-padding-small uk-padding-remove-vertical">
                    <ul className="uk-list">
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/internally-flawless-diamonds/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: nut" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/diamond.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">Internally flawless diamonds</p> </span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/100-money-back/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: heart" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/money-back.svg"  width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">100% Money back on diamond value</p> </span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/education/loose-diamonds/grading/diamond-certificate`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: bag" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/certification.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">Certifications of Diamonds</p>  </span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/hallmarked-jewellery/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: bookmark" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/hall-mark.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">BIS Hall Mark for Jewellery</p></span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="uk-padding-small uk-padding-remove-vertical">
                    <ul className="uk-list ">
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/brand-assured-quality/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: star" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/brand-assured.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">Brand assured quality</p></span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/15day-return-policy/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: refresh" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/days-return.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">15 Days return policy</p></span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/cash-on-delivery/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: bell" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/cash-delivery.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">Cash on delivery</p></span>
                            </Link>
                        </li>
                        <li className="uk-iconnav">
                            <Link
                                to={`${process.env.PUBLIC_URL}/why-buy-from-us/free-shipping/`}
                                className="uk-flex-inline"
                            >
                                {/* <span data-uk-icon="icon: server" className="uk-padding-remove"></span> */}
                                <img  src="/assets/images/new_template/images/shipping-truck_1.svg" width="25" height="10" data-uk-svg></img>
                                <span className="uk-text-middle"> <p className="uk-padding-small uk-padding-remove-vertical">Pan india free shipping</p></span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SDFeatures