import React from "react";
// import Loader from "../../common/spinner"

const OrderPreview = (props) => {

    const CheckoutimgeSource = item => {
        let images = [];
        let imgeSrc = [];
        let imgI = 0;
        const exts = [".png", ".jpg", ".webp", ".gif"];
        if (item.image) {
            let allImages = item.image;
            images = allImages.split(",");
            images.forEach(function (img) {
                let ext = img.substring(img.lastIndexOf("."));
                if (exts.includes(ext)) {
                    imgeSrc[imgI] = img;
                    imgI++;
                }
            });
        }
        return imgeSrc[0];
    };

    if (props.preview)
        return (
            <div className="bg-white border p-4 mb-4">
                <div className="d-flex justify-content-between">
                    <p className="text-secondary text-uppercase">Order preview</p>
                    {/* <div className="mt-1">
                    <Loader />
                </div> */}
                </div>
                <div className="order-box mb-4">
                    <div className="qty scroller">
                        {props.preview && props.preview.items.map((item, index) => {
                            item.product_id = item.id;
                            item.price = item.offerprice;
                            item.color = color;
                            const price = item.price < item.old_offerprice ? "Price drop" : "Price hike"
                            const color = item.price < item.old_offerprice ? "text-success border-success" : "text-danger border-danger"
                            return (
                                <div key={index}>
                                    <div className="d-flex py-2 align-items-center">
                                        <div className="d-flex mb-2 mb-sm-0 mb-md-0 justify-content-center">
                                            <img
                                                src={`${process.env.PUBLIC_URL}` + CheckoutimgeSource(item)}
                                                onError={e => {
                                                    e.target.src = `${process.env.PUBLIC_URL
                                                        }/assets/images/sunny/no_image/no_images.png`;
                                                }}
                                                alt={item.name}
                                                style={{
                                                    width: "100px",
                                                    height: "100px"
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex flex-grow-1">
                                            <div
                                                className="flex-grow-1 px-lg-2"
                                                key={index}
                                            >
                                                <h5><b>{item.name.toUpperCase()}</b></h5>
                                                <h6>{item.sku}</h6>
                                                {/* <h6>{item.color.replace("-", " ").toUpperCase()}</h6> */}
                                                <span className="cart_sum mr-4">
                                                    {props.symbol}{item.price}
                                                </span>
                                                Qty: {item.cart_product_quantity}
                                                <span className="cart_sum pl-3">
                                                    Total: {props.symbol}{item.price * item.cart_product_quantity}
                                                </span>
                                                <br />
                                                {(item.updated && item.old_offerprice) ?
                                                    <p
                                                        data-toggle="popover"
                                                        title={"Price changed from " + props.symbol + item.old_offerprice + " to " + props.symbol + item.offerprice + " due to change in gold price"}
                                                        className={"border price_update d-inline-block rounded px-1 ml-1 mt-1 " + color}
                                                    >
                                                        {price}
                                                    </p>
                                                    : ""}
                                            </div>
                                            <div>
                                                <h5 className="td-color mr-md-2">
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="icon"
                                                        onClick={() => {
                                                            props.removeFromCart(item)
                                                        }}
                                                    >
                                                        <i className="icon-close" />
                                                    </a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="mb-0">Cart Subtotal</p>
                    <p className="mb-0">
                        {/* {props.symbol}{props.total.toFixed(2)} */}
                        {props.symbol}6282.00

                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="mb-0">Giftcard Discount</p>
                    <p className="mb-0">
                        {props.symbol}0.00
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="mb-0">Promocode Discount</p>
                    <p className="mb-0">
                        {props.symbol}0.00
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="mb-2">Shipping charge</p>
                    <p className="mb-2">
                        {props.symbol}0.00
                    </p>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <h4><b>Grand Total</b></h4>
                    <h3 className="cart_sum">
                        {props.symbol}0.00
                    </h3>
                </div>
            </div>
        )
    else return null
}

export default OrderPreview