import React from 'react';

import './featured-in.css'

const FeaturedIn = (props) => {

    const Features = [
        {
            "image": "/assets/images/new_template/images/pinkerala21.png",
            "alt": "pinkerala",
            "link": "http://www.pinkerala.com/news/sunny-diamonds-makes-a-glittering-entry-to-the-online-market",
        },
        {
            "image": "/assets/images/new_template/images/the-week-_.png",
            "alt": "The week",
            "link": "https://www.theweek.in/news/biz-tech/2021/11/10/sunny--diamonds--going-above-and-beyond-in-indias-diamond-indust.html",
        },
        {
            "image": "/assets/images/new_template/images/02h.png",
            "alt": "YourStory",
            "link": "https://yourstory.com/video/sunny-diamonds-kochis-rising-story/amp",
        },
        {
            "image": "/assets/images/new_template/images/et3_2.png",
            "alt": "The Economic Times",
            "link": "https://economictimes.indiatimes.com/topic/Sunny-Diamonds",
        },
        {
            "image": "/assets/images/sunny/about_us/crunchbase.png",
            "alt": "Crunchbase",
            "link": "https://www.crunchbase.com/organization/sunny-diamonds",
        },
        {
            "image": "/assets/images/sunny/about_us/dailymotion.png",
            "alt": "DailyMotion",
            "link": "https://www.dailymotion.com/video/x3j6onc",
        },
    ]

    return (
        <section className="featured-in">
            <div className="home-container">
                <div className="heading-section">
                    <h2 className="heading-title title-normal">Featured in</h2>
                    <div className="heading-icon">
                        <span className="hr-line">&#160;</span>
                        <span className="diamond-icon">&#160;</span>
                        <span className="hr-line">&#160;</span>
                    </div>
                </div>
                <div className="featured-contents">
                    {Features.map((item, i) => (
                        <div className="featured-item">
                            <a href={item.link} target="_blank" rel=''>
                                <img src={item.image} alt={item.alt} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeaturedIn;