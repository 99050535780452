import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import "../common/index.scss";
import "react-tabs/style/react-tabs.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  actUserLogout,
  actMyOrderList,
  actOrderProductDetails,
} from "../../actions";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-responsive-modal";
import store from "../../store";
import TimeOut from "../../sessions/timeOut";


class MyOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      navClose: { left: "-410px" },
    };
  }

  componentDidMount() {
    store.dispatch({ type: "RECEIVE_MY_ORDER", payload: "" });
    if (
      sessionStorage.getItem("user_id") === "" ||
      sessionStorage.getItem("token") === "" ||
      sessionStorage.getItem("user_type") !== "CUSTOMER"
    ) {
      this.props.history.push("/login");
    }
    this.props.actMyOrderList();
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { left: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { left: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { left: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { left: "-410px" } });
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  logoutSubmit = (event) => {
    this.props.actUserLogout();
  };

  viewProduct = (id) => {
    store.dispatch({ type: "RECEIVE_ORDER_PRODUCTS", payload: "" });
    this.props.actOrderProductDetails(id);
    if (this.props.product_details) this.onOpenModal();
  };

  render() {
    const { order_details, product_details } = this.props;
    if (order_details)
      return (
        <div>
          <Helmet>
            <title>
              Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds |
              Orders
            </title>
            <meta
              name="description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally
          Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
            <meta
              name="keywords"
              content="Buy Diamonds online, Online diamonds shopping, Diamond Jewellery Store, Buy Diamond Rings Online, 
          diamond earrings online, diamond pendants online kerala, buy diamond bangles online, buy diamond necklace,  
          Buy wedding rings online,  online diamond jewellery India"
            />
            <meta property="og:site_name" content="Sunny Diamonds" />
            <meta property="og:type" content="Summary" />
            <meta
              property="og:title"
              content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
            />
            <meta
              property="og:description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
            <meta property="og:url" content="https://sunnydiamonds.com/" />
            <meta name="twitter:card" content="Summary" />
            <meta name="twitter:site" content="@SunnyDiamond_4c" />
            <meta name="twitter:creator" content="Sunny Diamonds" />
            <meta
              name="twitter:title"
              content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
            />
            <meta
              name="twitter:description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
          </Helmet>
          <Breadcrumb title={"My orders"} />
          <section className="section-b-space  blog-page">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-3">
                  <div
                    className="account-sidebar"
                    onClick={this.openNav.bind(this)}
                  >
                    <a className="popup-btn btn btn-solid">my account</a>
                  </div>
                  <div
                    className="dashboard-left mt-2"
                    style={this.state.navClose}
                  >
                    <div
                      className="collection-mobile-back"
                      onClick={this.closeNav.bind(this)}
                    >
                      <span className="filter-back">
                        <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                        back
                      </span>
                    </div>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                            My Orders
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                            Edit Profile
                          </Link>
                        </li>
                        {/* <li>
                          <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                            DFE Active Plan
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/change-password`}
                          >
                            Change Password
                          </Link>
                        </li>
                        <li className="last">
                          <a onClick={this.logoutSubmit}>Log Out</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-9 col-lg-10 px-0">
                  <div className="dashboard-right mt-2">
                    <div className="dashboard px-0 bg-white">
                      <div className="page-title">
                        <h2 className="pl-3">My Orders</h2>

                      </div>
                      <TimeOut logout={this.logoutSubmit} msg={null} />

                      <Accordion defaultActiveKey="0" alwaysOpen={false}>
                        {order_details.length > 0 ? (
                          order_details.map((item, i) => (
                            <div
                              key={i}
                              className="col-xl-12"
                              id={item.order_id}
                            >
                              <Accordion.Item eventKey={item.id}>
                                <Accordion.Header className="p-0">
                                  {
                                    <div className="d-flex justify-content-between w-100 px-md-4 mt-1">
                                      <h4 className="accordian-question">
                                        Order No: {item.order_no}
                                      </h4>
                                      <h4 className="accordian-question text-right pr-2">
                                        Status: {item.status}
                                      </h4>
                                    </div>
                                  }
                                </Accordion.Header>
                                <Accordion.Body className="dark-accordian px-0">
                                  <table
                                    class="table table-bordered d-none d-md-block"
                                    style={{ width: "100%" }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th><h5 className="normal-para">Status</h5></th>
                                        <td><h6 className="normal-para">{item.status}</h6></td>
                                        <th><h5 className="normal-para">Order Date</h5></th>
                                        <td><h6 className="normal-para">{item.order_date}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">Currency</h5></th>
                                        <td><h6 className="normal-para"> {item.currency}</h6></td>
                                        <th><h5 className="normal-para">Amount</h5></th>
                                        <td><h6 className="normal-para">{item.amount}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">Payment Id</h5></th>
                                        <td><h6 className="normal-para">{item.payment_id}</h6></td>
                                        <th><h5 className="normal-para">Payment Mode</h5></th>
                                        <td><h6 className="normal-para"> {item.payment_mode}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">Payment Status</h5></th>
                                        <td><h6 className="normal-para">{item.payment_status}</h6></td>
                                        <th><h5 className="normal-para">Order Id</h5></th>
                                        <td><h6 className="normal-para">{item.order_id}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">Ship address </h5></th>
                                        <td><h6 className="normal-para">{item.address}</h6></td>
                                        <th><h5 className="normal-para">City</h5></th>
                                        <td><h6 className="normal-para">{item.city}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">State</h5></th>
                                        <td><h6 className="normal-para">{item.state}</h6></td>
                                        <th><h5 className="normal-para">Country</h5></th>
                                        <td><h6 className="normal-para">{item.country}</h6></td>
                                      </tr>
                                      <tr>
                                        <th><h5 className="normal-para">Pincode</h5></th>
                                        <td><h6 className="normal-para">{item.pincode}</h6></td>
                                        <th><h5 className="normal-para">Products</h5></th>
                                        <td><h6 className="normal-para">
                                          <a
                                            href={"javascript:void(0)"}
                                            onClick={() =>
                                              this.viewProduct(item.order_id)
                                            }
                                          >
                                            view
                                          </a>
                                        </h6></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div class="container table-responsive px-0">
                                    <table
                                      class="table table-bordered d-md-none d-block "
                                      style={{ width: "100%" }}
                                    >
                                      <tbody>
                                        <tr>
                                          <th><h5 className="normal-para">Status</h5></th>
                                          <td><h6 className="normal-para">{item.status}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Order Date</h5></th>
                                          <td><h6 className="normal-para">{item.order_date}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Currency</h5></th>
                                          <td><h6 className="normal-para"> {item.currency}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Amount</h5></th>
                                          <td><h6 className="normal-para">{item.amount}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Payment Id</h5></th>
                                          <td><h6 className="normal-para">{item.payment_id}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Payment Mode</h5></th>
                                          <td><h6 className="normal-para"> {item.payment_mode}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Payment Status</h5></th>
                                          <td><h6 className="normal-para">{item.payment_status}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Order Id</h5></th>
                                          <td><h6 className="normal-para">{item.order_id}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Ship address </h5></th>
                                          <td><h6 className="normal-para">{item.address}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">City</h5></th>
                                          <td><h6 className="normal-para">{item.city}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">State</h5></th>
                                          <td><h6 className="normal-para">{item.state}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Country</h5></th>
                                          <td><h6 className="normal-para">{item.country}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Pincode</h5></th>
                                          <td><h6 className="normal-para">{item.pincode}</h6></td>
                                        </tr>
                                        <tr>
                                          <th><h5 className="normal-para">Products</h5></th>
                                          <td><h6 className="normal-para">
                                            <a
                                              href={"javascript:void(0)"}
                                              onClick={() =>
                                                this.viewProduct(item.order_id)
                                              }
                                            >
                                              view
                                            </a>
                                          </h6></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </div>
                          ))
                        ) : (
                          <div className="row">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                              <h3>
                                Sorry! Couldn't find the Orders you were looking
                                For!!!
                              </h3>
                            </div>
                          </div>
                        )}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {product_details ? (
            <Modal open={this.state.open} onClose={this.onCloseModal} center>
              <div
                className="modal-dialog modal-lg modal-dialog-centered"
                role="document"
              >
                <div className="modal-content quick-view-modal">
                  <h4 className="accordian-question ml-3">
                    <b> Product details</b>
                  </h4>
                  <div className="modal-body mt-4">
                    <table
                      class="table table-bordered d-none d-md-block"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th><h5 className="normal-para">Sl</h5></th>
                          <th><h5 className="normal-para">Name</h5></th>
                          <th><h5 className="normal-para">SKU</h5></th>
                          <th><h5 className="normal-para">Quantity</h5></th>
                          <th><h5 className="normal-para">Price</h5></th>
                          <th><h5 className="normal-para">Amount</h5></th>
                        </tr>
                      </thead>
                      <tbody>
                        {product_details.items.map((items, i) => (
                          <tr key={i}>
                            <td><h6 className="normal-para">{i + 1}</h6></td>
                            <td><h6 className="normal-para">{items.name}</h6></td>
                            <td><h6 className="normal-para">{items.sku}</h6></td>
                            <td><h6 className="normal-para">{items.qty}</h6></td>
                            <td><h6 className="normal-para">{items.price}</h6></td>
                            <td><h6 className="normal-para">{items.qty * items.price}</h6></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div class="container table-responsive px-0">
                      {product_details.items.map((items, i) => (
                        <table
                          class="table table-bordered d-md-none d-block "
                          style={{ width: "100%" }}
                        >
                          <h6 className="normal-para">{items.name}</h6>
                          <tbody>
                            <tr>
                              <th><h5 className="normal-para">SKU</h5></th>
                              <td><h6 className="normal-para">{items.sku}</h6></td>
                            </tr>
                            <tr>
                              <th><h5 className="normal-para">Qty</h5></th>
                              <td><h6 className="normal-para">{items.qty}</h6></td>
                            </tr>
                            <tr>
                              <th><h5 className="normal-para">Price</h5></th>
                              <td><h6 className="normal-para">{items.price}</h6></td>
                            </tr>
                            <tr>
                              <th><h5 className="normal-para">Amount</h5></th>
                              <td><h6 className="normal-para">{items.qty * items.price}</h6></td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          ) : (
            ""
          )}
        </div>
      );
    else
      return (
        <div className="height">
          <Breadcrumb />
          <img
            src={`${process.env.PUBLIC_URL
              }/assets/images/sunny/loader/loader.gif`}
            alt=""
            className="loader"
          />
        </div>
      );
  }
}

const mapStateToProps = (state) => ({
  order_details: state.data.order_detail,
  product_details: state.data.order_products,
});

export default connect(
  mapStateToProps,
  {
    actUserLogout,
    actMyOrderList,
    actOrderProductDetails,
  }
)(MyOrderDetails);
