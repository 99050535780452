import React, { useState } from "react";


const mailVerify = (props) => {
    if (props.delivery.email != props.delivery.c_email) {
        if (props.mailValidity) {
            return (
                <div className="text-danger">
                    Please enter same recipient mail id to confirm
                </div>
            )
        }
    }
}


const RecipientDetails = (props) => {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="row justify-content-center justify-content-lg-start align-item-center">
                        <div className="col-12 col-md-10 mt-3 my-lg-0 px-lg-2 px-0 d-inline-flex">
                            <h4 className="lead mb-0">Recipient Details</h4>
                            <p
                                className="px-1 mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="someone who recieves a surprise present.">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={20} className="h-6 w-6"
                                    fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </p>
                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-3 mt-lg-0">
                            <label className="form-label" id="recipient_name">
                                Name
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='first_name'
                                value={props.delivery.first_name}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validatorMob1.current
                                    .message("Recipient name", props.delivery.first_name, "required|alpha_space")
                            }

                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-3 mt-lg-0">
                            <label className="form-label" id="recipient_number">
                                Mobile
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                name='phone'
                                value={props.delivery.phone}
                                onChange={props.handleDeliveryData}
                            />

                            {
                                props.validatorMob1.current
                                    .message("recipient number", props.delivery.phone, "required|phone|max:10|min:10")
                            }

                            <small>
                                <b>NOTE:</b>
                                This number is used for future purposes.
                            </small>
                        </div>
                    </div>

                    <div className="row justify-content-center align-item-center">
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="recipient_email">
                                Email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name='email'
                                value={props.delivery.email}
                                onChange={props.handleDeliveryData}
                            />

                            {
                                props.validatorMob1.current
                                    .message("recipient email", props.delivery.email, "required|email")
                            }

                        </div>
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="recipient_confirm_email">
                                Confirm email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name='c_email'
                                value={props.delivery.c_email}
                                onChange={props.handleDeliveryData}
                            // onBlur={() => {
                            //     props.delivery.email != props.delivery.c_email ?
                            //         props.setMailValidity(true)
                            //         :
                            //         props.setMailValidity(false)
                            // }}
                            />
                            {props.validatorMob1.current
                                .message("recipient email", props.delivery.c_email, "required|email")}

                            {/* {
                                props.mailValidity ? <div className="text-danger">
                                    Please enter same recipient mail id to confirm
                                </div> : ""
                            } */}

                            {mailVerify(props)}

                        </div>
                    </div>

                    <div className="row justify-content-center align-item-center">
                        <div className="col-lg-12 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="recipient_message">Message for Recipient</label>
                            <textarea
                                className="form-control"
                                name="message"
                                placeholder="Message maximum length should be 150 charcter."
                                rows="3"
                                value={props.delivery.message}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validatorMob1.current
                                    .message("message", props.delivery.message, "max:150")
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecipientDetails