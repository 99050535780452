import React, { useEffect } from "react";



const EmiPieChart = (props) => {


    useEffect(() => {

        window.google.charts.load('current', { 'packages': ['corechart'] }).then(d => {

            // console.log(props.youPay)
            // console.log(props.youGet)

            var data = window.google.visualization.arrayToDataTable([
                ['Payment', 'Amount'],
                ['You Pay', props.youPay],
                ['You Get', props.youGet],
            ]);

            const chart = new window.google.visualization.PieChart(document.getElementById('myChart'));
            const options = {
                legend: "none",
                chartArea:{left:120,top:-90,width:'50%',height:'75%'},
                backgroundColor: '#ffffff',
                colors: ['#a07993', '#722257'],
                slices: [{ offset: 0.05 }],
                pieStartAngle: 190,
                pieSliceText: 'value',
                pieSliceTextStyle: { color: "#ffffff", fontSize: 30 },
                tooltip: {textStyle: {color: '#722257'}, showColorCode: true},
                tooltipText:'value'
            };
            chart.draw(data, options);
        });

    }, [props.youPay]);

    return (
        <div
            id="myChart" style={{ width: "900px", height: "500px" }}>
        </div>
    )
}

export default EmiPieChart;
