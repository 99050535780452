import React, { Component } from "react";
import { connect } from "react-redux";
import { getPolicy } from "../../services";
import Breadcrumb from "../common/breadcrumb";
import Sidebar from "../layouts/common/sidebar";

import DOMPurify from 'dompurify';
import Seo from "../layouts/common/seo";
import Promise from "../new_home_page/promise";


const PaymentMethods = (props) => {


  const { listPolicy } = props;

  return (
    <div>
      {/* seo section  */}
      <Seo type="payment-head" />
      <Breadcrumb title={" WHY BUY FROM US-PAYMENT OPTIONS"} />

      <section className="section-b-space  blog-page">
        <div className="container">
          <div className="row">
            {/* Sidebar section  */}
            <Sidebar type="payment-option" card="card-2" />

            <div className="col-xl-9 col-lg-8 col-md-7">
              <h1 className="pt-4">Sunny Diamonds Guarantee </h1>
              <h2>Payment Options</h2>
              <p>We offer multiple payment options, which include online and offline.</p>
              <h3>Online</h3>
              <ol>
                <li className="online_payment_method">1. Credit Card: All Visa, Master and American Express Credit Cards are accepted</li>
                <li className="online_payment_method">2. Debit Card: All Visa, Maestro and RuPay Debit Cards are accepted</li>
                <li className="online_payment_method">3. Net Banking: You can choose from all major banks in India to transfer money</li>
              </ol>
              <h3>Offline</h3>
              <p>Bank Transfer: You can send money from your bank account to our State Bank Of India account. Our bank account details are given below:</p>
              <table style={{ width: '75%' }} className="mx-auto payment-method-table mb-3">
                <tbody>
                  <tr>
                    <th className="payment-method-table">Bank Name</th>
                    <th className="payment-method-table">	State Bank Of India</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">Branch Address</th>
                    <th className="payment-method-table">SSI Ernakulam</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">Bank Account Number</th>
                    <th className="payment-method-table">00000067177341770</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">Account holder name</th>
                    <th className="payment-method-table">SUNNY DIAMONDS</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">IFSC Code</th>
                    <th className="payment-method-table">	SBIN0005387</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">CIF Number</th>
                    <th className="payment-method-table">	77067622560</th>
                  </tr>
                  <tr>
                    <th className="payment-method-table">MICR Code</th>
                    <th className="payment-method-table">	682002007</th>
                  </tr>
                </tbody>
              </table>
              <p>Customers making bank transfer should contact Sunny Diamonds on 1800-4250-4949 (Toll Free) or +91-9961870099 (Mobile) or email to customerservice@sunnydiamomds.com and confirm his/her payment modalities.</p>
            </div>
          </div>
        </div>
      </section>
      {/*Sunny diamonds features section*/}
      <div className="mt-5 pt-2">
        <Promise />
      </div>
      {/* end of sunny diamonds features section */}
    </div>
  );
}

const mapStateToProps = state => ({
  card: getPolicy(state.data.products, "card-2"),
  listPolicy: getPolicy(state.data.products, "payment-option")
});

export default connect(mapStateToProps)(PaymentMethods);