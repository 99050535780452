import React, { Component } from "react";
import { connect } from "react-redux";
import { filterBrand } from "../../../actions";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: "",
    };
  }

  filterHandler(event) {
    var brands = []
    this.setState({ placeholder: this.props.placeholder});
    if (this.props.filters.placeholder[this.state.placeholder]) {
      brands = this.props.filters.placeholder[this.state.placeholder]
      var index = brands.indexOf(event.target.value);
    }

    if (event.target.checked) {
      if (!brands.includes(event.target.value)) {
        brands.push(event.target.value);
        this.props.filterBrand(brands, event.target.placeholder);
      }
    } else {
      brands.splice(index, 1);
      this.props.filterBrand(brands, event.target.placeholder);
    }
  }

  render() {
    const filtersList = this.props.list;
    return (
      <div className="collection-brand-filter scroller">
        {filtersList[this.props.index].list.map((item, index) => (
          <div
            className="custom-control custom-checkbox collection-filter-checkbox"
            key={index}
          >
            <input
              type="checkbox"
              onClick={e => this.filterHandler(e)}
              value={item.value}
              placeholder={this.props.placeholder}
              // defaultChecked={
              //   filteredBrands.includes(item.value) ? true : false
              // }
              className="custom-control-input"
              id={item.value}
            />
            <label className="custom-control-label" htmlFor={item.value}>
              {
                item.name
                //  + "  (" + item.product_count + ")"
              }
            </label>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.filters
});

export default connect(
  mapStateToProps,
  { filterBrand }
)(Dropdown);
