import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import './choose-your-style-blog.css';

const ChooseYourStyleBlog = () => {

    const sliderRef = useRef();

    const prev = () => {
        sliderRef.current.slickPrev();
    }

    const next = () => {
        sliderRef.current.slickNext();
    }

    const blogSliderImage = {
        dots: false,
        autoplay: true,
        speed: 2500,
        infinite: true,
        autoplaySpeed: 5500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide : 0,
        responsive: [
            {
                breakpoint: 1367,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const BlogImageAndContent = [
        {
            "title": "8 Types Of Jewellery That Every Party Goer Must Have",
            "link": "jewellery",
            "button": "CHECK NOW",
            "pictures": "/assets/images/new_template/images/06.png",
        },
        {
            "title": "Bold, Beautiful and Brand new style showcase",
            "link": "jewellery",
            "button": "CHECK NOW",
            "pictures": "/assets/images/new_template/images/05.png",
        },
        {
            "title": "Quick tips to pick & style the Ideal Diamond jewellery for you",
            "link": "jewellery",
            "button": "CHECK NOW",
            "pictures": "/assets/images/new_template/images/04.png",
        },
        {
            "title": "8 Types Of Jewellery That Every Party Goer Must Have",
            "link": "jewellery",
            "button": "CHECK NOW",
            "pictures": "/assets/images/new_template/images/06.png",
        },
        {
            "title": "Bold, Beautiful and Brand new style showcase",
            "link": "jewellery",
            "button": "CHECK NOW",
            "pictures": "/assets/images/new_template/images/05.png",
        },
    ]


    return (
        <section className="choose-style">
            <div className="home-container">
                <div className="choose-title">
                    <div className="heading-section">
                        <h2 className="heading-title title-normal">Choose your Style</h2>
                        <div className="heading-icon">
                            <span className="hr-line">&#160;</span>
                            <span className="diamond-icon">&#160;</span>
                            <span className="hr-line">&#160;</span>
                        </div>
                    </div>
                    <div className="nav-content">
                        <p className="para-one">SEE ALL</p>
                        <div className="custom-arrow">
                            <span className="left-arrow nav-arrow" onClick={prev}>&#160;</span>
                            <span className="right-arrow nav-arrow" onClick={next}>&#160;</span>
                        </div>
                    </div>
                </div>
                <div className="choose-contents">
                    <Slider  ref={sliderRef} {...blogSliderImage}>
                        {BlogImageAndContent.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="choose-item">
                                    <Link to={`${process.env.PUBLIC_URL}/` + item.link}>
                                        <div className="choose-img">
                                            <img src={item.pictures} alt="Sunny Diamonds" />
                                        </div>
                                        <div className="choose-text">
                                            <p className="para-one">{item.title}</p>
                                            <span className="para-one">{item.button}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default ChooseYourStyleBlog;