import React from "react";
import './shimmer.scss'

const VOCartPreview = (props) => {

  const loadingPreview = () => {
    return (
      <div className="bg-white border p-4 mb-4">
        <p className="text-secondary text-uppercase">Order preview</p>

        <div className="card-text placeholder-glow">
          <div className="d-flex align-items-start">
            <div className="placeholder placeholder-thumb"></div>
            <div className="p-1 px-3 flex-fill">
              <p className="placeholder placeholder-xl w-100"></p>
              <p className="placeholder col-8"></p>
            </div>
          </div>
          <hr />
          <p className="placeholder col-8"></p>
          <p className="placeholder col-7"></p>
          <p className="placeholder col-6"></p>
          <p className="placeholder col-8"></p>
          <br></br>
          <p type="button" className="placeholder btn  btn-promo-code w-100 d-block">Apply</p>
        </div>
      </div>
    )
  }

  const cartPreview = () => {

    const items = props.cart.map((item, index) => {
      return (
        <div className="d-flex py-2 align-items-start" key={index}>
          {/* {console.log(index, item)} */}
          <img className="border rounded shadow-sm" src={`${process.env.PUBLIC_URL}` + item.cart_product_image.split(',')[0]}
            onError={e => {
              e.target.src = `${process.env.PUBLIC_URL
                }/assets/images/sunny/no_image/no_images.png`;
            }} alt={item.cart_product_name}
            style={{
              width: "100px",
              height: "100px"
            }}
          />
          <div className="p-1 px-3 flex-fill">
            <h5 className="d-flex justify-content-between text-uppercase ">
              <strong>{item.cart_product_name}</strong>
              <a href="javascript:void(0)"
                className="icon"
                onClick={() => {
                  // console.log("remove ",item);
                  props.removeFromCart(item.cart_product_data.product_id,{id:item.cart_product_data.id});
                }}>
                <i className="icon-close" />
              </a>
            </h5>
            <h6>{item.cart_product_sku}</h6>
            <p className="mr-4">
              {item.cart_product_unit_price} X {item.cart_product_quantity}
            </p>
          </div>
        </div>
      )
    })

    const pricing = (
      <>
        <div className="d-flex justify-content-between">
          <p className="mb-0">Cart Subtotal</p>
          <p className="mb-0">
            {/* {props.symbol} */}
            {props.symbol}{props.VOData.order.summery.subtotal}
          </p>
        </div>
        {/* <div className="d-flex justify-content-between">
          <p className="mb-0">Giftcard Discount</p>
          <p className="mb-0">
            {props.symbol}{props.VOData.order.summery.redeems}
          </p>
        </div> */}
        <div className="d-flex justify-content-between">
          <p className="mb-0">Promocode Discount</p>
          <p className="mb-0">
            {props.symbol}{props.VOData.order.summery.discounts}
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-2">Service Charges</p>
          <p className="mb-2">
            {props.symbol}{props.VOData.order.summery.charges}
          </p>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <h4><b>Grand Total</b></h4>
          <h3 className="cart_sum">
            {props.symbol}{props.VOData.order.summery.payable_amount}
          </h3>
        </div>
      </>
    )

    return (
      <div className="bg-white border p-4 mb-4">
        <p className="text-secondary text-uppercase">Order preview</p>
        <div style={{ maxHeight: '50vh', overflow: "auto" }}>
          {items}
        </div>
        <hr />
        {pricing}
        <button
          type="button"
          className="btn-solid btn w-100 d-block mt-2"
          onClick={props.onRecaptcha}
          >
          Pay and Place Order
        </button>
      </div>
    )
  }

  if (props.syncing) return loadingPreview();
  else return cartPreview();
  // return [
  //   loadingPreview(),
  //   cartPreview()
  // ]
}

export default VOCartPreview;
