import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import "../common/index.scss";
import SimpleReactValidator from 'simple-react-validator';
import { actForgotPassword, actProfile } from '../../actions'
import { connect } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this._reCaptchaRef = React.createRef();
    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  onRecaptcha = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this._reCaptchaRef.current.executeAsync();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSubmit = (code) => {
    if (code) {
      var forgetPassword = {
        email: this.state.email
      }
      this.props.actForgotPassword(forgetPassword)
      this.setState({ email: '' })
      this._reCaptchaRef.current.reset();
    } else { }
  };


  render() {


    return (
      <div>
        <Helmet>
          <title>
            Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds | Forget Password
          </title>
          <meta
            name="description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally
          Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta
            name="keywords"
            content="Buy Diamonds online, Online diamonds shopping, Diamond Jewellery Store, Buy Diamond Rings Online, 
          diamond earrings online, diamond pendants online kerala, buy diamond bangles online, buy diamond necklace,  
          Buy wedding rings online,  online diamond jewellery India"
          />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta
            property="og:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            property="og:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta property="og:url" content="https://sunnydiamonds.com/" />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta
            name="twitter:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            name="twitter:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
        </Helmet>
        <Breadcrumb title={"forget password"} />

        {/*Forget Password section*/}
        <section className="pwd-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2 className="py-2">
                  Please enter your email address below to receive a password
                  reset link.
                </h2>
                <form className="theme-form" 
                  // onSubmit={this.handleSubmit}
                  onSubmit={this.onRecaptcha}
                >
                  <div className="form-row">
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.setStateFromInput}
                      />
                      {this.validator.message(
                        "email",
                        this.state.email,
                        "required|email"
                      )}
                    </div>
                    <ReCAPTCHA
                      ref={this._reCaptchaRef}
                      size="invisible"
                      // sitekey="6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V"
                      sitekey={global.reCaptcha}
                      onChange={this.handleSubmit}
                    />
                    <button
                      className="btn btn-solid"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile_data: state.data.profile_data
})

export default connect(
  mapStateToProps,
  { actForgotPassword, actProfile }
)(ForgetPassword)