import React, { Component } from "react";
import { connect } from "react-redux";
import { getCategory } from "../../../../services";

class Banner extends Component {

  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }
  render() {
    const { head, align, text_color, move, title_background } = this.props;
    if (head.length > 0)
      return (
        <section>
        <div className="container">
          <div className="row">

        <div
          className={
            move === "false" ? "col-lg-12 mt-0" : "col-lg-12 mt-0 pl-0 px-0"
          }
        >
          <div className="media-container d-none d-md-block">
            <img
              className="img-fluid"
              src={`${process.env.PUBLIC_URL}` + head[0].banner.image}
              onError={e => {
                e.target.src = `${process.env.PUBLIC_URL
                  }/assets/images/sunny/no_image/no_Banner.webp`;
              }}
              onLoad={() => this.setState({ loaded: true })}
              alt={"head[0].banner.title"}
            />
          </div>
          {this.state.loaded === true ?
            <div className="media-text d-none d-md-block">
              <h1
                className={
                  align === "left"
                    ? "banner-title-left"
                    : align === "right"
                      ? "banner-title-right"
                      : title_background === true
                        ? "banner-title-background"
                        : "banner-title"
                }
                style={{ color: text_color }}
              >
                {head[0].banner.title}
              </h1>
              <br />
              <h3
                className={
                  align === "left"
                    ? "banner-title-left"
                    : align === "right"
                      ? "banner-title-right"
                      : title_background === true
                        ? "banner-title-background"
                        : "banner-title"
                }
                style={{ color: text_color }}
              >
                {head[0].banner.subTitle}
              </h3>
            </div> : ""}
          <div className="title4 shp-title d-md-none">
            <h1 className="title-inner4 banner-theme">
              {head[0].banner.title}
            </h1>
          </div>
        </div>
        </div>
            </div>
          </section>
      );
  }
}

const mapStateToProps = (state, Ownprops) => ({
  head: getCategory(state.data.products, Ownprops.type)
});
export default connect(mapStateToProps)(Banner);
