import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Product5 } from "../../../services/script";
import {
  addToCart,
  addToWishlist,
  addToCompare,
  incrementQty,
  decrementQty,
  removeFromCart,
  getAllTrending
} from "../../../actions";
import ProductItem from "../common/trending-product";

class Recently extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getAllTrending();
  }

  render() {
    const {
      items,
      symbol,
      addToCart,
      addToWishlist,
      addToCompare,
      incrementQty,
      decrementQty,
      removeFromCart
    } = this.props;

    if (items) {
      return (
        <div>
          {/*Paragraph*/}
          <section className="section-b-space addtocart_count ratio_square">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="title4 trend">
                    <h2 className="title-inner4">Latest Products</h2>
                    <h4 className="text-md-right mt-md-0 mt-2"><Link to={`${process.env.PUBLIC_URL}/trending`}><u>Shop all</u></Link></h4>
                  </div>
                  <br />
                  <Slider
                    {...Product5}
                    className="product-4 product-m no-arrow mob_trend"
                  >
                    {items.map((product, index) => (
                      <div key={index}>
                        <ProductItem
                          product={product}
                          symbol={symbol}
                          onAddToCompareClicked={addToCompare}
                          onAddToWishlistClicked={addToWishlist}
                          onAddToCartClicked={addToCart}
                          onIncrementClicked={incrementQty}
                          onDecrementClicked={() => decrementQty(product.id)}
                          onRemoveFromCart={() => removeFromCart(product)}
                          key={index}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  // items: getTrendingCollection(ownProps.type),
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  items: state.data.trending
});

export default connect(
  mapStateToProps,
  {
    addToCart,
    addToWishlist,
    addToCompare,
    incrementQty,
    decrementQty,
    removeFromCart,
    getAllTrending
  }
)(Recently);
