import React, { useState, useRef, useEffect } from 'react'; 

import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";

import { validateCheckout, errorAccessMsgAction } from "../../actions"
import SecureAccessCard from "../pages/Gift-Card/secure-access-card"
import store from "../../store"

const BalanceGift = (props) => {

    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [cardData, setCardData] = useState()

    useEffect(() => {
        store.dispatch({ type: "ACCESS_RECEIVE_REQUEST_OTP", payload: "" });
        store.dispatch({ type: "ACCESS_RECEIVE_VERIFY_OTP", payload: "" });
        props.errorAccessMsgAction("ACCESS_RESET_OTP")
    }, []);

    const handleChange = (event) => {
        event.persist()
        setCardData(event.target.value)
    }

    const handleValidity = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            props.validateCheckout(cardData, props.otp_verify && props.otp_verify.access.access_token)
        }
        else {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    return (
        <div className="text-center">
            <input
                type="text"
                name="cardNumber"
                className="form-control mb-3"
                placeholder="Gift Card Number"
                value={cardData}
                onChange={handleChange}
            />
            {validator.current.message("Card number", cardData, "required")}
            {(props.timer.msgFlag.flag == "verified") ?
                ""
                :
                <SecureAccessCard
                    otp={props.otp_req}
                    otp_verify={props.otp_verify}
                    phone=""
                    credentials={cardData}
                    handleSubmit={handleSubmit}
                    handleValidity={handleValidity}
                    access_tag="redeem"
                />
            }
        </div>
    )
}

const mapStateToProps = state => ({
    otp_req: state.otp.access_otp_request,
    otp_verify: state.otp.access_otp_verify,
    timer: state.otp,
});

export default connect(mapStateToProps, { validateCheckout, errorAccessMsgAction })(BalanceGift)