import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import { v4 as uuidv4 } from "uuid";

import { _headerNav } from "../../../../navigator/_headerNav";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "-410px" }
    };
  }

  clickBrandHendle(event, brands) {
    var index = brands.indexOf(event.target.value);
    if (event.target.checked) {
      brands.push(event.target.value);
    }
    // push in array checked value
    else {
      brands.splice(index, 1);
    } // removed in array unchecked value

    this.props.filterBrand(brands);
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-410px" } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-set");
    }
  }

  handleSubmenu = event => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (event.target.nextElementSibling.classList.contains("opensubmenu"))
      event.target.nextElementSibling.classList.remove("opensubmenu");
    else {
      document.querySelectorAll(".nav-submenu").forEach(function (value) {
        value.classList.remove("opensubmenu");
      });
      document
        .querySelector(".mega-menu-container")
        .classList.remove("opensubmenu");
      event.target.nextElementSibling.classList.add("opensubmenu");
    }
  };

  handleMegaSubmenu = event => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function (value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  Menupopulate = menu => {
    return (
      <div key={uuidv4()} className="col mega-box">
        <div className="link-section">
          <div className="menu-content">
            <ul>
              {menu.map((row, key) =>
                row._tag === "NavTitle" ? (
                  <li key={key}>
                    <h6>
                      <b>{row.name}</b>
                    </h6>
                  </li>
                ) : row._tag === "NavImage" ? (
                  <li key={key}>
                    <img
                      src={`${process.env.PUBLIC_URL + row.src}`}
                      className="nav_menu_images"
                      style={{ width: 250, height: 250 }}
                      alt="navbar"
                    />
                  </li>
                ) : (
                  <li key={key}>
                    <Link to={this.validateURL(`${process.env.PUBLIC_URL}` + row.to) ? `${process.env.PUBLIC_URL}` + row.to : ''}>
                      {row._tag === "NavIcon" ? (
                        <i className={"fad fa-gem " + row.icons} />
                      ) : (
                        ""
                      )} {row.name}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  render() {
    const { translate } = this.props;

    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav">
            <div className="toggle-nav" onClick={this.openNav.bind(this)}>
              <i className="fa fa-bars sidebar-bar mt-2" />
            </div>
            <ul className="nav-menu" style={this.state.navClose}>
              <li className="back-btn" onClick={this.closeNav.bind(this)}>
                <div className="mobile-back text-right">
                  <span>Back</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL
                    }/trending`}
                  className="d-md-none d-block"
                >
                  {translate("trending")}
                </Link>
              </li>
              {_headerNav.map((menu, index) => (
                <li
                  key={index}
                  className="mega-menu"
                  onClick={this.closeNav.bind(this)}
                >
                  <Link
                    to={this.validateURL(`${process.env.PUBLIC_URL}` + menu.route) ? `${process.env.PUBLIC_URL}` + menu.route : ''}
                    className="dropdown"
                  >
                    {menu.name}
                    <span className={menu.icon + " d-md-block d-none "} />
                  </Link>
                  {menu._children ?
                    <div className="mega-menu-container shadow border rounded">
                      <div className="container">
                        <div className="row">
                          {menu._children.map((row, key) =>
                            this.Menupopulate(row._children)
                          )}
                        </div>
                      </div>
                    </div>
                    : ""}
                </li>
              ))}
              {/* <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL}/gifts`}
                  className="dropdown"
                >
                  {translate("gifts")}
                </Link>
              </li> */}
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <a
                  href="https://sunnydiamonds.com/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dropdown"
                >
                  {translate("blog")}
                </a>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL
                    }/education/loose-diamonds/4cs/cut`}
                  className="dropdown"
                >
                  {translate("education")}
                </Link>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL
                    }/trending`}
                  className="d-md-block d-none ribbon-new"
                >
                  {translate("trending")}
                </Link>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL}/wishlists`}
                  className="dropdown d-md-none d-block"
                >
                  {translate("wishlist")}
                </Link>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL}/sales/guest/form`}
                  className="dropdown d-md-none d-block"
                >
                  {translate("order_tracking")}
                </Link>
              </li>
              <li className="mega-menu" onClick={this.closeNav.bind(this)}>
                <Link
                  to={`${process.env.PUBLIC_URL}/compare_list`}
                  className="dropdown d-md-none d-block"
                >
                  {translate("compare")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslate(NavBar);
