import React from "react";

import "../../common/index.scss";

import { connect } from "react-redux";
import { getCareer } from "../../../services/index";


import DOMPurify from 'dompurify';


import Seo from "../../layouts/common/seo";
import Breadcrumb from "../../common/breadcrumb";
import Banner from "../../layouts/common/banner";
import OpenPosition from "./open-positions";
import WorkAndJoin from "./work-join.jsx";
import BenefitAndPerk from "./benefits-perk";
import MoreSweetPerk from "./more-sweet-perk";
import Promise from "../../new_home_page/promise/index";
import CoreValues from "./core-values";




const Careers = (props) => {


  const { career, job } = props;

  if (career.length > 0) {
    return (
      <div>
        {/* seo section  */}
        <Seo type="careers-head" />
        <Breadcrumb title={career[0].title} />

        {/*Banner section*/}
        <section className="blog-detail-page section-b-space ">
          <div className="container">
            <div className="row">
              <Banner type="careers-head" move="false" />
            </div>
          </div>

          {/* open job section */}
          <OpenPosition
            job={job}
          />

          {/* where we work  */}
          <WorkAndJoin />

          {/* benefits & perks */}
          <BenefitAndPerk />

          {/* More Sweet Perk */}
          <MoreSweetPerk />

          {/* Our Core Value */}
          <CoreValues />

        </section>
        {/*Sunny diamonds features section*/}
        <div className="mt-5 pt-2">
          <Promise />
        </div>
        {/* end of sunny diamonds features section */}
      </div>
    );
  } else return 0;
}

const mapStateToProps = state => ({
  career: getCareer(state.data.products, "careers"),
  job: getCareer(state.data.products, "job-description")
});

export default connect(mapStateToProps)(Careers);
