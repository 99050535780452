import React from "react";
import { Link } from "react-router-dom";

function LogoImage(props) {

  return (
    <Link to={`${process.env.PUBLIC_URL}/`}>

      {window.logo.desktop.map((data, i) => (
        <div key={i} className="d-none d-md-block">
          <img
            className="img-fluid"
            src={`${process.env.PUBLIC_URL
              }` + data.image}

            alt={data.alt}

          />
        </div>
      ))}
    
      {window.logo.mobile.map((data, i) => (
        <div key={i} className="d-block d-md-none">
          <img
            className="img-fluid "
            src={`${process.env.PUBLIC_URL
              }` + data.image}

            alt={data.alt}
          />
        </div>
      ))}
    </Link >
  );
}

export default LogoImage;
