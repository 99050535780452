import React from "react";

import { v4 as uuidv4 } from "uuid";

const LatestNews = (props) => {
    return (

        <div className="uk-width-1-1 uk-width-1-4@l uk-width-1-4@xl uk-padding-remove side-bar-listing uk-visible@l">
            <h2>Latest News</h2>
            <div className="uk-width-1-1  sd-accordion">
                <ul uk-accordion="multiple: true" key={uuidv4()}>
                    {props.popularNews.map((item, i) => (
                        <li key={uuidv4()} className="latest_news uk-card uk-card-hover uk-card-body">
                            <a
                                className="read_more"
                                href={item.links}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={
                                        `${process.env.PUBLIC_URL}` + item.pictures
                                    }
                                    alt="sunny diamonds News"
                                    style={{ width: 148 }}
                                />
                                <p style={{ textAlign: "left" }}>
                                    {item.heading}
                                </p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>

    )
}

export default LatestNews;