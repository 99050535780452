import React from "react";

import { v4 as uuidv4 } from "uuid";

const MobileLatestNews = (props) => {
    return (
        <div className="uk-hidden@l">
            <button className="uk-button uk-button-default uk-width-auto@s uk-margin-small-top  uk-flex uk-flex-middle" data-uk-toggle="target: #modal-fitter-menu-mob">
                <p className="uk-text-left uk-width-1-1 uk-margin-remove">Latest News</p>
                <a className="uk-text-right" href="" data-uk-icon="settings"></a>
            </button>
            <div id="modal-fitter-menu-mob" data-uk-modal>
                <div className="uk-modal-dialog uk-modal-body">
                    <button className="uk-modal-close-default" type="button" data-uk-close></button>
                    <div className="uk-width-1-1 uk-padding-remove side-bar-listing"></div>

                    <div className="uk-width-1-1 uk-width-1-4@l uk-width-1-4@xl uk-padding-remove side-bar-listing">
                        <h2>Latest News</h2>
                        <div className="uk-width-1-1  sd-accordion">
                            <ul uk-accordion="multiple: true" key={uuidv4()}>
                                {props.popularNews.map((item, i) => (
                                    <li key={uuidv4()} className="latest_news uk-card uk-card-hover uk-card-body">
                                        <a
                                            className="read_more"
                                            href={item.links}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={
                                                    `${process.env.PUBLIC_URL}` + item.pictures
                                                }
                                                alt="sunny diamonds News"
                                                style={{ width: 148 }}
                                            />
                                            <p style={{ textAlign: "left" }}>
                                                {item.heading}
                                            </p>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MobileLatestNews