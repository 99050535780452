import React, { Component, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Seo from "../layouts/common/seo";
import Breadcrumb from "../common/breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";
import "react-phone-input-2/lib/style.css";
import "react-tabs/style/react-tabs.scss";
import Banner from "../layouts/common/banner";
import SimpleReactValidator from "simple-react-validator";
import "react-toastify/dist/ReactToastify.min.css";
import { actOrderTracking, actProfile } from "../../actions";
import ReCAPTCHA from "react-google-recaptcha";


const OrderTrack = (props) => {

  const [orderTrack, setOrderTrack] = useState({
    phone: "",
    email: "",
    order_id: "",
    user_type: "GUEST"
  })

  const _reCaptchaRef = React.createRef()
  const validator = useRef(new SimpleReactValidator())
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const handleChange = (event) => {
    event.persist()
    setOrderTrack(prevState => ({
      ...prevState, [event.target.name]: event.target.value
    }))
  }


  const onRecaptcha = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      _reCaptchaRef.current.executeAsync();
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  }

  const handleSubmit = (code) => {

    if (code) {

      var orderTrack_details = {
        order_id: orderTrack.order_id,
        email: orderTrack.email,
        phone: orderTrack.phone,
        user_type: orderTrack.user_type
      }
      props.actOrderTracking(orderTrack_details);
      _reCaptchaRef.current.reset();
    } else {
      // Invalid session
    }
  };

  return (
    <div>
      <Helmet>
        <meta property="og:site_name" content="Sunny Diamonds" />
        <meta property="og:type" content="Summary" />
        <meta
          property="og:title"
          content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
        />
        <meta
          property="og:description"
          content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
        />
        <meta property="og:url" content="https://sunnydiamonds.com/" />
        <meta name="twitter:card" content="Summary" />
        <meta name="twitter:site" content="@SunnyDiamond_4c" />
        <meta name="twitter:creator" content="Sunny Diamonds" />
        <meta
          name="twitter:title"
          content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
        />
        <meta
          name="twitter:description"
          content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
        />
      </Helmet>
      <Breadcrumb title={"Order Tracking"} />

      {/* Banner  */}
      <section>
        <div className="container">
          <div className="row">
            {/* Banner section  */}
            <Banner type="order-track-head" move="false" title_light={true}/>
          </div>
        </div>
      </section>
      {/* Banner End  */}

      {/*Login section*/}
      <section className="login-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <form className="theme-form border bg-white"
                // onSubmit={handleSubmit}
                onSubmit={onRecaptcha}
              >
                <div className="form-group px-5 my-3">
                  <label className="form-label" htmlFor="email">
                    Order id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="order_id"
                    id="order_id"
                    placeholder="Order ID"
                    value={orderTrack.order_id}
                    onChange={handleChange}
                  />
                  {validator.current.message(
                    "order_id",
                    orderTrack.order_id,
                    "required"
                  )}
                </div>
                <div className="form-group px-5 my-3">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={orderTrack.email}
                    onChange={handleChange}
                  />
                  {validator.current.message(
                    "email",
                    orderTrack.email,
                    "required|email"
                  )}
                </div>
                <div className="form-group px-5 my-3">
                  <label className="form-label" htmlFor="number">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={orderTrack.phone}
                    onChange={handleChange}
                  />
                  {validator.current.message(
                    "phone",
                    orderTrack.phone,
                    "required|phone|min:10|max:10"
                  )}
                </div>
                <ReCAPTCHA
                  ref={_reCaptchaRef}
                  size="invisible"
                  // sitekey="6LdG_wAgAAAAAJtwvnhJaCPTEpDLXVSdCflRuC0V"
                  sitekey={global.reCaptcha}
                  onChange={handleSubmit}
                />
                <div className="row px-5 my-3">
                  <div className="col-sm-12 col-md-12">
                    <button className="btn btn-solid btn-login" type="submit">
                      VIEW ORDER STATUS
                    </button>
                  </div>
                </div>
              </form>
              <br />
            </div>

            {/* Right Side View  */}

            <div className="col-lg-6 right-login">
              {/* Desktop View  */}

              <div className="authentication-right d-none d-md-none d-lg-block mt-">
                <h3 className="login-title-font mt-5 pt-3 d-none d-md-block">
                  Would you like to know, what are all logged users doing
                  right now? Well, they:
                </h3>
                <ul>
                  <li className="create_acct_li">• Create wishlists</li>
                  <li className="create_acct_li">• Track their shipments</li>
                  <li className="create_acct_li">
                    • View their order history
                  </li>
                  <li className="create_acct_li">
                    • Cancel individual orders
                  </li>
                  <li className="create_acct_li">
                    • Benefit from great deals and many other offers
                  </li>
                </ul>
              </div>
              {/* end of Desktop View  */}
            </div>
          </div>
          {/* end of Right Side View  */}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = state => ({
  profile_data: state.data.profile_data
});

export default connect(mapStateToProps, { actOrderTracking, actProfile })(OrderTrack);
