import React, { useState } from "react";

import RecipientDetails from "./recipient-details";
import SenderDetails from "./sender-details";

const DeliveryDetailsNew = (props) => {

    return (

        <section>

            {window.innerWidth > 1024 ?
                <div className="container gift-index-active px-lg-5 px-0 py-lg-5 py-0">
                    {/* Desktop View  */}
                    <div className="row">

                        {/* Recepient Details Form  */}
                        <div className="col-xl-6 col-lg-6">
                            <RecipientDetails
                                validatorMob1={props.validatorMob1}
                                delivery={props.delivery}
                                handleDeliveryData={props.handleDeliveryData}
                                mailValidity={props.mailValidity}
                                setMailValidity={props.setMailValidity}
                            />
                        </div>

                        {/* Sender Details Form  */}
                        <div className="col-xl-6 col-lg-6">
                            <SenderDetails
                                validatorMob2={props.validatorMob2}
                                delivery={props.delivery}
                                handleDeliveryData={props.handleDeliveryData}
                                handleDeliveryDataNumber={props.handleDeliveryDataNumber}
                                verifyValidity={props.verifyValidity}
                                verification={props.verification}
                            />
                        </div>
                    </div>

                </div>
                :
                <div className="container gift-index-active px-5">
                    {/* Mobile View & Tab View */}
                    <div className="row">
                        <div className="col-12 ">

                            {/* Recepient Details Form  */}
                            {props.formIndex == 1 ?
                                <RecipientDetails
                                    delivery={props.delivery}
                                    handleDeliveryData={props.handleDeliveryData}
                                    handleFormIndex={props.handleFormIndex}
                                    validatorMob1={props.validatorMob1}
                                    mailValidity={props.mailValidity}
                                    setMailValidity={props.setMailValidity}
                                /> : ""
                            }
                        </div>

                        <div className="col-12">

                            {/* Sender Details Form  */}
                            {props.formIndex == 2 ?
                                <SenderDetails
                                    handleFormIndex={props.handleFormIndex}
                                    validatorMob2={props.validatorMob2}
                                    delivery={props.delivery}
                                    handleDeliveryData={props.handleDeliveryData}
                                    handleDeliveryDataNumber={props.handleDeliveryDataNumber}
                                    verifyValidity={props.verifyValidity}
                                    verification={props.verification}
                                /> : ""
                            }
                        </div>
                    </div>
                </div>}
        </section>

    )
}


export default (DeliveryDetailsNew)