import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actUserLogin } from "../../../../actions";

class TopBarUserDark extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedin: false
        };
    }

    render() {
        return (
            <div className="top-header top-header-dark3 ">
                <div className="container">
                    {/* Horizontal scrolling issue  */}
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <div className="header-contact d-md-block d-none">
                                <ul className="d-inline-flex">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/locations`}>
                                            <i className="fas fa-map-marker-alt" aria-hidden="true" />
                                            Store Locator
                                        </Link>
                                    </li>
                                    <li className="mobile-account ">
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/sales/guest/form`}
                                            data-lng="en"
                                        >
                                            <i className="fas fa-truck" aria-hidden="true" />Order
                                            Tracking
                                        </Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <a
                                            href="https://api.whatsapp.com/send?phone=919961870099"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fab fa-whatsapp" aria-hidden="true" />
                                            WhatsApp
                                        </a>
                                    </li>
                                    {/* <li className="d-none d-md-block d-lg-none">
                                        <a href="javascript:$zoho.salesiq.floatwindow.open()">
                                            <i className="fa fa-headphones" aria-hidden="true" />
                                            Help desk
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className=" col-md-6 col-lg-6 col-xl-6 text-right">
                            <ul className="header-dropdown">
                                <li className="mobile-chat d-md-none">
                                    <a href="javascript:$zoho.salesiq.floatwindow.open()">

                                        {/* Mobile footer chat icon  */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                            fill="#722257" className="bi bi-chat" viewBox="0 0 16 16"
                                        >
                                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                        </svg>

                                    </a>
                                </li>
                                <li className="mobile-compare">
                                    <Link to={`${process.env.PUBLIC_URL}/compare_list`}>
                                        <i className="fas fa-refresh mob-comp" aria-hidden="true" />
                                        Compare
                                    </Link>
                                </li>
                                <li className="mobile-wishlist">
                                    <Link to={`${process.env.PUBLIC_URL}/wishlists`}>
                                        <i className="fas fa-heart wishlist" aria-hidden="true" />
                                        wishlist
                                    </Link>
                                </li>
                                <li className="mobile-home d-md-none">
                                    <Link to={`${process.env.PUBLIC_URL}/`}>

                                        {/* Mobile footer home icon  */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                            fill="#722257" className="bi bi-house-door" viewBox="0 0 16 16">
                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                                        </svg>

                                    </Link>
                                </li>
                                <li className="mobile-account">
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/dashboard`}
                                        data-lng="en"
                                    >
                                        {/* Desktop profile icon */}
                                        <i
                                            className="fas fa-user-alt user-account"
                                            aria-hidden="true"
                                        />

                                        {/*Mobile profile icon */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                            fill="#722257" className="bi bi-person d-block d-md-none" viewBox="0 0 16 16"
                                        >
                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                        </svg>

                                        Dashboard
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    type_user: state.data.user_type
});

export default connect(
    mapStateToProps,
    {
        actUserLogin
    }
)(TopBarUserDark);
