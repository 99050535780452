import React, { Component } from "react";

class EducationMobileSidePanel extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const {
            edu
        } = this.props;

        return (
            <div className="uk-hidden@l">
                <button className="uk-button uk-button-default uk-width-auto@s uk-margin-small-top  uk-flex uk-flex-middle" data-uk-toggle="target: #modal-fitter-menu-mob">
                    <p className="uk-text-left uk-width-1-1 uk-margin-remove">{this.props.type}</p>
                    <a className="uk-text-right" href="javas" data-uk-icon="settings"></a>
                </button>
                <div id="modal-fitter-menu-mob" data-uk-modal>
                    <div className="uk-modal-dialog uk-modal-body uk-width-auto uk-margin-auto">
                        <button className="uk-modal-close-default" type="button" data-uk-close></button>
                        <h2 className="uk-modal-title">Education Options</h2>
                        <div className="uk-child-width-1-4" data-uk-grid>
                            <ul className="uk-tab-left uk-margin-medium-top" data-uk-tab>
                                {edu.map((item, i) => (
                                    <li
                                        key={i}
                                        className={
                                            item.category.value === this.props.type
                                                ? "active cursor-pointer uk-modal-close"
                                                : "cursor-pointer uk-modal-close"
                                        }
                                        onClick={() => this.props.onClickTypeHandler(item)}
                                    >
                                        <div className="uk-flex uk-margin-medium">
                                            <img
                                                src={
                                                    `${process.env.PUBLIC_URL}` +
                                                    `${item.category.image}`
                                                }
                                                className="img-fluid education_sidebar_image"
                                                alt="sunny diamonds"
                                            />
                                            <p className="normal-para uk-margin-left">{item.category.label}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EducationMobileSidePanel
