import React, { Component } from "react";
import { connect } from "react-redux";
import RelatedProductItem from './related-product-item'

import { getAllTrending } from "../../../actions";

// import '../assets/css/relatedProducts.css'
class RelatedProducts extends Component {

  componentDidMount() {
    this.props.getAllTrending();
  }

  render() {
    const { items } = this.props;
    if (items) {
      return (
        <div className="uk-section sd-completelook uk-padding-remove">
          <div className="uk-container uk-container-xlarge">
            <div className="uk-width-1-3@m uk-text-left uk-padding-small">
              <h2>Complete The Look</h2>
              <hr className="uk-divider-icon" />
            </div>
            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider="autoplay: false; autoplay-interval:3000;">
              <ul className="listing-box-main uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@l uk-child-width-1-5@xl uk-grid">

                {items.map((product, index) => (
                  <li key={index}>
                    <RelatedProductItem product={product} />
                  </li>
                ))}

              </ul>
              <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
              <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => ({
  items: state.data.trending
});

export default connect(mapStateToProps, { getAllTrending })(RelatedProducts);