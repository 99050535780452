import React from "react";

const PriceBreakUp = (props) => {
    const { product, symbol } = props
    const totalAmount = product.metal_price + product.diamond_price + product.gemstone_price + product.making_charge
    return (
        <div className="pricebreakup">
            <div className="uk-overflow-auto">
                <nav className="uk-navbar-container" uk-navbar>
                    <a>Price Breakup <span data-uk-icon="icon:plus; ratio: 0.7" /></a>
                    <div
                        className="uk-card-default uk-dropbar uk-padding-small"
                        uk-drop="stretch: x; target: !.uk-navbar-container; animation: reveal-top; animate-out: true; duration: 700">
                        <div className="uk-overflow-auto">
                            <table className="uk-table uk-table-small uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Rate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Metal price</td>
                                        <td>{symbol} {Number(product.metal_price).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>Diamond price</td>
                                        <td>{symbol} {Number(product.diamond_price).toFixed(2)}</td>
                                    </tr>
                                    {product.gemstone_price ?
                                        <tr>
                                            <td>Gemstone price</td>
                                            <td>{symbol} {Number(product.gemstone_price).toFixed(2)}</td>
                                        </tr> : ""}
                                    <tr>
                                        <td>Making charge</td>
                                        <td>{symbol} {Number(product.making_charge).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>GST({product.gst}%)</td>
                                        <td>{symbol} {(totalAmount * (product.gst / 100)).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td><b>{symbol} {Number(product.offerprice).toFixed(2)}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default PriceBreakUp