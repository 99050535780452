import React from "react";
import { Link } from "react-router-dom";

import "./css/topbar.css"

const TopBar = (props) => {
    return (
        <div className="top-info-bar">
            <div className="top-text">
                <div className="head-contact-detail">
                    <Link
                        to={`${process.env.PUBLIC_URL}/locations`}
                        className="store-locator"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}` + "assets/images/new_template/images/map-marker-alt-white.svg"}
                            title=""
                            alt=""
                            width="14" />
                        <span>Store Locator</span>
                    </Link>
                    <Link
                        to={`${process.env.PUBLIC_URL}/sales/guest/form`}
                        className="order-traking"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}` + "assets/images/new_template/images/truck-white.svg"}
                            alt=""
                            width="20" />
                        <span>Order Tracking</span>
                    </Link>
                    <a
                        href="https://api.whatsapp.com/send?phone=919961870099"
                        target="_blank"
                        rel="noopener noreferrer" className="whats-app">
                        <img
                            src={`${process.env.PUBLIC_URL}` + "assets/images/new_template/images/whatsapp-brands.svg"}
                            alt=""
                            width="14"
                        />
                        <span>WhatsApp</span>
                    </a>
                </div>
                <span>{props.topbarText}</span>
            </div>
        </div>
    )
}

export default TopBar