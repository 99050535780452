import React, { Component } from "react";
import { Link } from "react-router-dom";

// import '../assets/css/relatedProducts.css'
class RelatedProductItem extends Component {

  render() {
    const { product } = this.props;

    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];

    if (product.variants.length != 0) {
      if (product.variants[0].image) {
        let allImages = product.variants[0].image;
        images = allImages.split(",");
        images.forEach(function (img) {
          let ext = img.substring(img.lastIndexOf("."));

          if (exts.includes(ext)) {
            imgeSrc[imgI] = img;
            imgI++;
          }
        });
      }
    }

    return (
      <div className="uk-panel">
        <div className="uk-text-center">
          <div className="uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
            <Link to={`${process.env.PUBLIC_URL}` + product.value}>
              <img
                src={`${process.env.PUBLIC_URL}` + imgeSrc[0]}
                onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                alt={product.name}
              />
            </Link>
          </div>
        </div>
        <div className="listing-page-content uk-text-left">
          <Link to={`${process.env.PUBLIC_URL}` + product.value}>
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-expand  uk-padding-remove">{product.name.toUpperCase()}</div>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default RelatedProductItem;