// New method for checkout

import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import SimpleReactValidator from "simple-react-validator";

// chckout components

import UserDetails from "./user-details";
import AddressDetails from "./shipping-details";
import ShippingMethod from "./shipping-methods";
import Coupons from "./coupons";
import OrderPreview from "./preview"
import PaymentMethod from "./payments-methods";
import "./shimmer.scss"

// core components

import store from "../../../store";
import SDClient from "../../../sd_core/client";
import {
  removeFromCart,
  postCartItems,
  addCartToServer,
  errorMsgAction,
} from "../../../actions";

// feature components

import Seo from "../../layouts/common/seo";
import Breadcrumb from "../../common/breadcrumb";

//dummy response
import response from "./response.json"
import VOCartPreview from "./VOPreview";
import BillingAddressDetails from "./billing-details";
import Loader from "../../common/spinner"
import Link from "react-router-dom/Link";
import useRazorpay from "react-razorpay";
import { Toast } from "react-bootstrap";
import { REMOVE_ALL_CART } from "../../../constants/ActionTypes";
import { randomInt } from "crypto";


window.SD = SDClient

const CheckOut1 = (props) => {

  const [mobIndex, setMobIndex] = useState(1)
  const [sync, setSync] = useState(1)
  const [message, setMessage] = useState({ description: "" })

  const _reCaptchaRef = React.createRef()
  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }))
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [loadingVO, setLoadingVO] = React.useState(true);
  const [VOData, setVOData] = React.useState({});

  const [BillingShippingAddressSame, setBillingShippingAddressSame] = React.useState(true);
  const BillingAddressBlockRef = useRef(null);
  const ShippingAddressBlockRef = useRef(null);
  const UserDetailsBlockRef = useRef(null);


  const [inTransition, setInTransition] = useState(false);
  const [SelectedPaymentConfirmor, setSelectedPaymentConfirmor] = useState(null);

  const Razorpay = useRazorpay();
  const [Order, setOrder] = useState(null);
  const [OrderReError, setOrderReError] = useState(null);
  const [OrderPayment, setOrderPayment] = useState(null);
  const [OrderResponse, setOrderResponse] = useState(null);
  const [remoteValidationTrace, setRemoteValidationTrace] = useState({
    user: {},
    shipping_address: {},
  });


  const syncCart = (callback) => {
    let cart = []
    props.cartItems.map((item, i) => {
      cart.push({
        sku: item.sku,
        quantity: item.qty,
        variant: item.selectedVariant.id
      })
    })
    store.dispatch({
      type: "READY_UPDATE_TO_SERVER",
    });
    SDClient.endpoint('cart_update', {
      cart: cart
    }).then((data) => {
      if (data.message.action) {
        store.dispatch({
          type: "UPDATE_TO_SERVER",
          payload: { cart: data.message.cart }
        });
        callback();
      } else {
        // props.history.push("/cart")
        toast.error("Something Went Wrong, please try again.");
        return
      }
    }, error => {
      console.log(error)
      if (error.reason == "CLIENT_ERROR") {
        toast.warn("Oops! Unable to checkout, please make sure to enable cookies or try clearing your browser cache.", { autoClose: 5000, hideProgressBar: true, })
        props.history.push("/cart")
      }
    });
  }

  const updateVO = (callback = null) => {
    setLoadingVO(true);
    SDClient.endpoint("virtual_order").then(
      response => {
        // console.log('VO', response.message)
        setVOData(response.message)
        setLoadingVO(false);
        if (callback) callback(response);
      },
      error => {
        // console.log(error)
        if (callback) callback(error);
      }
    )
  }

  // useEffect(() => {
  //   /* Loader in reducer set to false for development purpose*/
  //   store.dispatch({ type: "RESET_CHECKOUT" });
  //   store.dispatch({ type: "RECIEVE_GIFTCARD_BALANCE", payload: 0 });
  //   props.errorMsgAction("RESET_OTP")
  //   if (props.cartItems.length === 0) {

  //     return
  //   };


  // }, []);


  const gaInitiateCheckout = () => {
    /*GA code for initate checkout*/
    const products = []
    for (let i = 0; i < props.cartItems.length; i++) {
      products.push({
        'name': props.cartItems[i].name,
        'id': props.cartItems[i].sku,
        'price': props.cartItems[i].selectedVariant && props.cartItems[i].selectedVariant.offerprice,
        'brand': 'Sunny Diamonds',
        'category': props.cartItems[i].value,
        'variants': props.cartItems[i].selectedVariant && props.cartItems[i].selectedVariant.color,
        'quantity': props.cartItems[i].qty
      });
    }
    window.dataLayer = window.dataLayer || [];
    try {
      window.dataLayer.push({
        'event': 'InitiateCheckout',
        'ecommerce': {
          'currencyCode': 'INR',
          'impressions': {
            'products': products
          },
        },
      });
    }
    catch (err) {
      console.error(err);
      if (window.dataLayer.filter(function (obj) {
        return obj.errorMsg === err.message;
      }).length == 0) {
        window.dataLayer.push({
          'event': 'variable error',
          'errorMsg': err.message
        });
      }
    }
  }


  // FIXME : Conditional hooks are causing :
  useEffect(() => {
    if (OrderResponse) return;
    if (props.cartItems.length) {
      syncCart(updateVO);
    }
    else {
      toast.warn("Oops! Cannot checkout an empty cart.", { autoClose: 5000, hideProgressBar: true, })
      props.history.push("/cart")
    }
  }, [props.cartItems]);


  const checkAddressEquality = (addr1, addr2) => {
    // console.log("[CHECKOUT] comparing addresses", addr1, addr2)
    if (addr1.virtual_order_address_country === addr2.virtual_order_address_country)
      if (addr1.virtual_order_address_address === addr2.virtual_order_address_address)
        if (addr1.virtual_order_address_state === addr2.virtual_order_address_state)
          if (addr1.virtual_order_address_zipcode === addr2.virtual_order_address_zipcode)
            return true;
    return false;
  }


  useEffect(() => {
    // console.log('[CHECKOUT] VO DATA CHANGED', VOData);
    if (VOData.order) {
      if (VOData.order.billing_address && VOData.order.shipping_address) {
        // console.log("[CHECKOUT] Address check", checkAddressEquality(VOData.order.billing_address, VOData.order.shipping_address))
        setBillingShippingAddressSame(checkAddressEquality(VOData.order.billing_address, VOData.order.shipping_address));
      }
      gaInitiateCheckout();
    }
  }, [VOData]);

  useEffect(() => {
    // console.log('[BillingBlock] BillingShippingAddressSame Changed ', BillingAddressBlockRef.current)
    if (BillingAddressBlockRef.current)
      BillingAddressBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
  }, [BillingShippingAddressSame])



  /*Recaptcha*/

  const onRecaptcha = () => {
    _reCaptchaRef.current.executeAsync();
  }


  const errorHandler = {
    priceChange: () => setMessage({
      description: "Your cart is outdated. Please update for further proceeding",
    })
  }

  const generate_order = () => {
    setOrder(null);
    // console.log("GENERATE ORDER");
    setInTransition(true);
    SDClient.endpoint('order_generate', {
      // cart: cart
    }).then((data) => {
      setInTransition(false);
      // console.log("Generate Order Response ", data);
      if (data.message.action) {
        let order_data = data.message.order;
        order_data.items = data.message.items;
        let payment_initiation = data.message.payment_data;
        order_data.payment_data = payment_initiation;
        // console.log("Generate Order data ", order_data);
        // console.log("Generate Order Payment data ", payment_initiation);
        setOrder(order_data);
        setOrderPayment(payment_initiation);
        setSelectedPaymentConfirmor(order_data.payment_mode)

        /*GA calling checkout generation*/

        const products = []
        for (let i = 0; i < order_data.items.length; i++) {
          products.push({
            'name': order_data.items[i].name,
            'id': order_data.items[i].sku,
            'price': order_data.items[i].total,
            'brand': 'Sunny Diamonds',
            'category': order_data.items[i].sku,
            'variant': order_data.items[i].color,
            'quantity': order_data.items[i].qty,
          });
        }
        window.dataLayer = window.dataLayer || [];
        try {
          window.dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': { 'step': 4, 'option': order_data.payment_mode },
                'products': [products]
              }
            },
          });
        }
        catch (err) {
          if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
          }).length == 0) {
            window.dataLayer.push({
              'event': 'variable error',
              'errorMsg': err.message
            });
          }
        }



      } else {
        if (data.message.reason == "SYSTEM_RUNTIME_EXCEPTION") {
          if (data.message.trace.error == "INCOMPLETE_ORDER_USER") {
            toast.warn("Oops! Please complete your user details and procced to checkout.", { autoClose: 5000, hideProgressBar: true, })
            setRemoteValidationTrace({
              ...remoteValidationTrace,
              user: {
                first_name: true,
                last_name: true,
                email: true,
                phone: true,
              }
            })
            UserDetailsBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_SHIPPING_ADDRESS") {
            toast.warn("Oops! Please add your shipping addresss and procced to checkout.", { autoClose: 5000, hideProgressBar: true, })
            setRemoteValidationTrace({
              ...remoteValidationTrace,
              shipping_address: {
                'address_country': true,
                'address_zipcode': true,
                'address_state': true,
                'address_city': true,
                'address_address': true,
              }
            })
            ShippingAddressBlockRef.current.scrollIntoView({ block: "end", inline: "nearest", behavior: 'smooth' });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_CART") {
            toast.error("Oops! Your cart seems empty, cannot checkout with an empty cart.", { autoClose: 5000, hideProgressBar: true, });
          } else if (data.message.trace.error == "INCOMPLETE_ORDER_PAYMENT") {
            toast.warn("Oops! No payment method selected, please choose a payment method to continue.", { autoClose: 5000, hideProgressBar: true, });
          } else {
            toast.error("Oops! Something went wrong, please make sure to enable cookies or try again after clearing your browser cache", { autoClose: 5000, hideProgressBar: true, });
          }
        } else {
          toast.error("Oops! Something went wrong, please make sure to enable cookies or try again after clearing your browser cache", { autoClose: 5000, hideProgressBar: true, });
        }
      }
    });
  };

  const showBillingAddress = () => {
    if (!BillingShippingAddressSame)
      return (
        <BillingAddressDetails
          type="shipping"
          // handleChange={handleTextChange}
          // address={shippingAddress}
          // sameAddress={sameAddress}
          validator={validator}
          VOData={VOData}
          updateVO={updateVO}
        />
      );
    else return null;
  }

  const TransactLoading = () => {
    if (inTransition) {
      return (
        <div className="blurred-background d-flex justify-content-center align-items-center p-4">
          <div className="bg-white shadow p-5 rounded d-flex flex-column align-items-center">
            <Loader multiplier={2} />
            <p className="text-dark text-center p-2 m-0">
              Please wait as we are processing your order, this will only take a while.
            </p>
          </div>
        </div>
      )
    }
  }

  const clear_order = () => {
    //setOrder(null)
    setSelectedPaymentConfirmor(null)
  }


  const OrderStatus = () => {
    if (OrderResponse)
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center p-4">
          <div className="bg-white shadow p-5 rounded d-flex flex-column align-items-center w-100">
            <div class="success-text my-5 py-4"><i class="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "5rem" }}></i>
              <h2 class="mt-4">Thank You for the purchase !</h2>
              <p class="m-0 text-center mt-4">Confirmation mail has been sent to your mail id</p>
              <p class="text-center mb-4">We hope you enjoy your purchase</p>
              <a data-lng="en" class="btn btn-solid mt-2" rel="noopener noreferrer" href="/jewellery/">
                <div>continue shopping</div>
              </a>
            </div>
          </div>
        </div>
      )
  }


  const confirm_order = (confirmation, callback = (data) => { }) => {
    setInTransition(true);
    setSelectedPaymentConfirmor(null)
    // console.log("Confirm Order",{Order,confirmation})
    SDClient.endpoint('order_confirm', {
      confirmation: confirmation,
      order_id: Order.order_id,
      vo_id: Order.vo_id,
    }).then((data) => {
      setInTransition(false);
      if (data.message.action) {

        /* GA calling transaction*/

        const products = []
        for (let i = 0; i < Order.items.length; i++) {
          products.push({
            'name': Order.items[i].name,
            'id': Order.items[i].sku,
            'price': Order.items[i].total,
            'brand': 'Sunny Diamonds',
            'category': Order.items[i].sku,
            'variant': Order.items[i].color,
            'quantity': Order.items[i].qty,
            'coupon': ''
          });
        }
        window.dataLayer = window.dataLayer || [];
        try {
          window.dataLayer.push({
            'event': 'transaction',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': data.message.order.order_id,
                  'affiliation': 'Sunny Diamonds Online Store',
                  'revenue': data.message.order.amount,
                  'tax': '0.00',
                  'shipping': '0.00',
                  'discount': data.message.order.discount
                },
                'products': products,
              }
            }
          });
        }
        catch (err) {
          if (window.dataLayer.filter(function (obj) {
            return obj.errorMsg === err.message;
          }).length == 0) {
            window.dataLayer.push({
              'event': 'variable error',
              'errorMsg': err.message
            });
          }
        }


        setOrderResponse(data.message);
        setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }, 500)
        store.dispatch({ type: "REMOVE_ALL_CART" });
      } else {
        callback(data.message);
      }
    });
  }

  const CODConfirm = () => {
    const [countdown, setCountdown] = useState(1);
    const [OTP, setOPT] = useState("");
    const [OTPError, setOTPError] = useState(null);
    useEffect(() => {
      setCountdown(OrderPayment.otp.server_resend_step)
      console.log("This is cod jook");
      const timer = setInterval(() => {
        // let c = countdown + 1;
        setCountdown(c => c == 0 ? 0 : c - 1)
        // console.log("tick!",countdown)
      }, OrderPayment.otp.server_resend_rate);
      return () => {
        console.log("This is cod unmount jook");
        return clearInterval(timer);
      }
    }, [])

    if (Order && OrderPayment) {
      // console.log("COD Confirm ",{Order,OrderPayment})
      return (
        <div className="blurred-background d-flex justify-content-center align-items-center p-4">
          <div className="bg-white shadow p-4 rounded smart-dialog">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="m-0 text-uppercase">Verify your mobile number</h5>
              <svg onClick={() => { clear_order() }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mx-1 text-secondary cursor-pointer" viewBox="0 0 16 16">
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"></path>
              </svg>
            </div>
            <div className="py-2">
              <hr />
              <div className="px-1 d-flex flex-column align-items-center">
                <svg style={{ width: "6rem", height: "6rem" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-sd" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                {OrderPayment.otp.success && (
                  <p className="text-dark text-center p-2">
                    Please verify yourself using the One Time Password that has been sent to <b>+91{Order.phone}</b> and proceed with your order.
                  </p>
                )}
              </div>
              {!OrderPayment.otp.success && (
                <div class="alert alert-warning my-2" role="alert">
                  <b>Oops!</b> Please wait {countdown} seconds before requesting a new one time password to number <b>+91{Order.phone}</b>.
                </div>
              )}
              {OTPError && (
                <div class="alert alert-warning" role="alert">
                  <b>Oops!</b> {OTPError}
                </div>
              )}

              <div className="form-group mb-2">
                <div className="text-uppercase field-label py-1">ONE TIME PASSWORD</div>
                <input
                  type="number" style={{ letterSpacing: "1.5rem" }}
                  // name="virtual_order_profile_first_name"
                  className="form-control text-center"
                  value={OTP}
                  onChange={(e) => { setOPT(e.target.value) }}
                />
              </div>
              <div class="row">
                {countdown == 0 ? (
                  <div class="col">
                    <a onClick={generate_order}>Request another OTP</a>
                  </div>
                ) : (<div class="col text-left">
                  Resend a new otp in {countdown} Seconds
                </div>)}

              </div>
              <button
                type="button"
                className="btn-solid btn w-100 d-block mt-4"
                onClick={(e) => {
                  console.log("OTP is ", OTP);
                  if (OTP.length == 4) {
                    setOTPError(false);
                    confirm_order({ otp: OTP }, () => { clear_order(); toast.error("Order failed with incorrect one time password, please enter new correct one.", { autoClose: 5000, hideProgressBar: true, }); });
                  }
                  else setOTPError("Please enter a valid 4 digit one time password.");
                }}>
                CONFIRM and Place Order
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const Razorpay_Confirm = () => {
    const options = {
      key: global.rzpKey,
      amount: Order.amount * 100,
      currency: Order.currency,
      order_id: Order.order_id,
      name: "Sunny Diamonds",
      description: "Sunny Diamonds Payment",
      image: "https://sunnydiamonds.com/assets/images/sunny/logo/razorpay/logo.webp",
      // callback_url: props.mode == "gift" ? global.rzpGiftCallbk : global.rzpCallbk,
      // cancel_url: props.mode == "gift" ? global.rzpGiftCancel : global.rzpCancel,
      // redirect: true,
      redirect: false,
      handler: function (response) {
        // console.log("Razorpay Handler", response);
        clear_order();
        confirm_order(response, (data) => {
          toast.error("Payment signature verification failed, please try again.");
        })
      },
      modal: {
        confirm_close: true,
        ondismiss: function () {
          // console.log("RPay dismissed")
          clear_order();
        }
      },
      prefill: {
        name: Order.first_name + " " + Order.last_name,
        email: Order.email,
        contact: Order.phone
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#722257"
      },
      timeout: 300
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  }

  const Payment_Confirmor = () => {
    if (SelectedPaymentConfirmor == "payment_on_delivery") {
      if (Order) return (<CODConfirm></CODConfirm>); else return null;
    } else if (SelectedPaymentConfirmor == "online_payment_razorpay") {
      if (Order) Razorpay_Confirm();
    }
  }

  const deviceDisplay = () => {
    if (OrderResponse)
      // if (true)
      return (<>{OrderStatus()}</>)
    else
      return (
        <>

          {TransactLoading()}
          {Payment_Confirmor()}

          <div className="px-2">
            <div className="bg-white border p-4 mb-4">

            </div>
          </div>
          <div className="row px-2">
            <div className="col-12 col-md-6 col-lg-4">
              <div ref={UserDetailsBlockRef}>
                <UserDetails
                  // handleChange={handleTextChange}
                  // userDetails={userDetails}
                  validationTrace={remoteValidationTrace.user}
                  validator={validator}
                  VOData={VOData}
                  syncing={loadingVO || props.extendedItems.syncing}
                  updateVO={() => { syncCart(updateVO) }}
                />
              </div>
              <div ref={BillingAddressBlockRef}>
                {showBillingAddress()}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div ref={ShippingAddressBlockRef}>
                <AddressDetails
                  type="shipping"
                  // handleChange={handleTextChange}
                  // address={shippingAddress}
                  // sameAddress={sameAddress}
                  validator={validator}
                  validationTrace={remoteValidationTrace.shipping_address}
                  VOData={VOData}
                  updateVO={updateVO}
                  sameAddress={BillingShippingAddressSame}
                  setSameAddress={setBillingShippingAddressSame}
                />
              </div>
              <Coupons
                symbol={props.symbol}
                VOData={VOData}
                syncing={loadingVO}
                updateVO={() => { syncCart(updateVO) }}
              />
              {/* <ShippingMethod
              handleChange={handleCheckboxChange}
              options={orderDetails.options}
            /> */}

            </div>
            <div className="col-12 col-md-6 col-lg-4">
              {/* {console.log('RENDERING Payment Method', VOData.order ? VOData.order.applied_payment_method : "")} */}
              <PaymentMethod
                VOData={VOData}
                syncing={loadingVO || props.extendedItems.syncing}
                updateVO={() => { syncCart(updateVO) }}
              />
              {/* {console.log('RENDERING VO CART', props)} */}
              <VOCartPreview
                removeFromCart={props.removeFromCart}
                cart={props.extendedItems.list}
                syncing={loadingVO || props.extendedItems.syncing}
                VOData={VOData}
                generate_order={generate_order}
                onRecaptcha={onRecaptcha}
              />
            </div>
          </div>
        </>
      )
  }

  const incrementIndex = () => {
    window.scrollTo(0, 0);
    setMobIndex(mobIndex + 1)
  }

  const decrementIndex = () => {
    window.scrollTo(0, 0);
    setMobIndex(mobIndex + 1)
  }

  return (
    <div>
      <Seo type="checkout-head" />
      <Breadcrumb title="checkout" />
      <section className='container pt-4'>
        {message.description &&
          <div className="alert alert-danger" role="alert" style={{ cursor: "pointer" }} onClick={() => setMessage({ description: "" })}>
            {message.description}
          </div>
        }
        {deviceDisplay()}

        <ReCAPTCHA
          ref={_reCaptchaRef}
          size="invisible"
          sitekey={global.reCaptcha}
          onChange={generate_order}
        />
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  extendedItems: state.cartList.extended_cart,
  symbol: state.data.symbol,
})

export default connect(
  mapStateToProps,
  {
    removeFromCart,
    postCartItems,
    errorMsgAction,
    addCartToServer
  }
)(CheckOut1)
