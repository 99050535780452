import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import { setSessionData } from "../../../services";
import Loader from "../../common/spinner"
// import Loader from "../../common/spinner"

function ShippingAddressDetails(props) {

  const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  const [validationTrace, setValidationTrace] = useState({});
  const [shippingDetails, setshippingDetails] = useState({
    'address_country': "",
    'address_zipcode': "",
    'address_state': "",
    'address_city': "",
    'address_address': "",
  })

  useEffect(()=>{
    // console.log("Shipping Address Got Change in validationTrace",props.validationTrace)
    setValidationTrace(props.validationTrace);
  },[props.validationTrace])

  useEffect(() => {
    // console.log("Shipping Details Changed", shippingDetails)
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [shippingDetails])

  useEffect(() => {
    // console.log("[SHIPPING] VOData Changed", props.VOData)
    if (props.VOData.order)
      if (props.VOData.order.shipping_address)
        setshippingDetails({
          address_country: props.VOData.order.shipping_address.virtual_order_address_country,
          address_zipcode: props.VOData.order.shipping_address.virtual_order_address_zipcode,
          address_state: props.VOData.order.shipping_address.virtual_order_address_state,
          address_city: props.VOData.order.shipping_address.virtual_order_address_city,
          address_address: props.VOData.order.shipping_address.virtual_order_address_address,
        })
  }, [props.VOData])

  // console.log("Render Shipping Details ", shippingDetails)

  const shippingDetailsync = () => {
    SDClient.endpoint("virtual_order_set_shipping_address", { ...shippingDetails }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({});
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          }
        }
      },
      console.log
    )
  }

  const setBillingAddressToShippingAddress = () => {
    SDClient.endpoint("virtual_order_set_billing_address", { ...shippingDetails }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({});
          props.updateVO()
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          }
        }
      },
      console.log
    )
  }

  const debounce = () => {
    if (debounceLock) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      // console.log("DEBOUNCE CALLBACK!")
      shippingDetailsync();
      setDebounceLock(false);
    }, 1000));
    setDebounceLock(true);
  }

  const showshippingDetailsForm = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">Country</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={shippingDetails.address_country}
            onChange={(e) => { setshippingDetails({ ...shippingDetails, address_country: e.target.value }) }}
          />
          {validationTrace.address_country ? (<small className="text-uppercase text-danger"> Please specify a valid country name. </small>) : null}
        </div>
        <div className="form-group ">
          <label className="text-uppercase field-label">Address</label>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={shippingDetails.address_address}
            onChange={(e) => { setshippingDetails({ ...shippingDetails, address_address: e.target.value }) }}
          />
          {validationTrace.address_address ? (<small className="text-uppercase text-danger"> Please specify an address for shipping address.</small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">City </div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={shippingDetails.address_city}
            onChange={(e) => { setshippingDetails({ ...shippingDetails, address_city: e.target.value }) }}
          />
          {validationTrace.address_city ? (<small className="text-uppercase text-danger"> Please specify a valid city for shipping address.</small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">State</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={shippingDetails.address_state}
            onChange={(e) => { setshippingDetails({ ...shippingDetails, address_state: e.target.value }) }}
          />
          {validationTrace.address_state ? (<small className="text-uppercase text-danger"> Please specify a valid state for shipping address. </small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Postal code</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={shippingDetails.address_zipcode}
            onChange={(e) => { setshippingDetails({ ...shippingDetails, address_zipcode: e.target.value }) }}
          />
          {validationTrace.address_zipcode ? (<small className="text-uppercase text-danger"> Please specify a valid pin code for shipping address. </small>) : null}
        </div>
      </div>
    )
  }

  const differentBillingAddressRequsted = (e) => {
    if(!props.sameAddress){
      setBillingAddressToShippingAddress();
    }
    props.setSameAddress(!props.sameAddress);
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">Country</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">City </div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">State</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Postal code</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white border p-4 mb-4">
      <div className="d-flex justify-content-between">
        <p className="text-secondary text-uppercase">Billing Details</p>
        <div className="mt-1">
          {debounceLock ? (<Loader />) : ""}
        </div>
      </div>
      <div className="form-group d-inline-flex mb-3">
        <input
          type="checkbox"
          name="same_shipping_address"
          id="same_shipping_address"
          checked={props.sameAddress}
          onChange={differentBillingAddressRequsted}
        />
        <label
          htmlFor="same_shipping_address"
          className="mb-0">
          <p className="ml-2 m-0 text-left">
            Use my billing address as shipping address.
          </p>
        </label>
      </div>
      {props.VOData.order ? showshippingDetailsForm() : showLoadingInputs()}
    </div>
  )
}

export default ShippingAddressDetails;
