import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from "uuid";

import './news-list.css'

const NewsList = (props) => {

    const [limit, setLimt] = useState(4)

    const [hasMoreItems, setHasMoreItems] = useState(true)

    const fetchMoreItems = items => {
        if (limit >= items.length) {
            setHasMoreItems(false)
            return;
        }
        setTimeout(() => {
            setLimt(limit + 3)
        }, 1000);
    };

    return (
        <div className="uk-width-1-1 uk-width-3-4@l">
            <InfiniteScroll
                dataLength={limit} //This is important field to render the next data
                next={() => fetchMoreItems(props.mainNews)}
                hasMore={hasMoreItems}
                loader={
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="faqdot1 bg-dark rounded-circle m-2">
                        </div>
                        <div class="faqdot2 bg-secondary rounded-circle m-2">
                        </div>
                        <div class="faqdot3 bg-light rounded-circle m-2">
                        </div>
                    </div>

                }
            >
                <br />
                {props.mainNews.slice(0, limit).map((item, i) => (
                    <div key={uuidv4()} className="row blog-media">
                        <div className="col-xl-5 mt-4">
                            <div className="blog-left">
                                <img
                                    src={`${process.env.PUBLIC_URL}` + item.pictures}
                                    className="img-fluid"
                                    alt="sunny diamonds"
                                />
                            </div>
                        </div>
                        <div className="col-xl-7 mt-4">
                            <div className="blog-right">
                                <div>
                                    <h6 className="para-one">{item.date}</h6>
                                    <h4 className="normal-para">{item.heading}</h4>
                                    {/* <p>{item.news}</p> */}
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.news) }} className="para-one"></p>
                                    <p className="para-one">
                                        <a
                                            className="read_more"
                                            href={item.links}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <b>{item.readmore}</b>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default NewsList;