import React, { useState, useRef } from "react";

import { connect } from 'react-redux';

import OtpVerification from "../../checkout/otp";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DeliveryDetails = (props) => {

    let ddate = new Date();

    const inputRef = useRef(null);

    let errorMessages = Object.values(props.validator1.current.errorMessages)

    const checkValidation = () => {
        props.validator1.current.messagesShown && errorMessages.map((message, i) => (
            toast.info(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        ));
    }

    return (
        <div className="container gift-index-active px-lg-5 px-0 py-lg-5 py-0">
            <div className="row">
                <div className="col-xl-6 col-12 px-5">
                    {checkValidation()}  
                    <div className="row justify-content-center justify-content-lg-start align-item-center">
                        <div className="col-12 col-md-10 mt-2 my-lg-0 px-lg-2 px-0 d-inline-flex">
                            <h4 className="lead mb-0">Recipient Details</h4>
                            <p
                                className="px-1 mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="someone who recieves a surprise present.">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={20} className="h-6 w-6"
                                    fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </p>
                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-3 mt-lg-0">
                            <label className="form-label" id="recipient name">
                                Name
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='first_name'
                                value={props.delivery.first_name}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("recipient name", props.delivery.first_name, "required|alpha_space")
                            }
                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-3 mt-lg-0">
                            <label className="form-label" id="recipient number">
                                Mobile
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                id="phone1231232"
                                type="tel"
                                ref={inputRef}
                                className="form-control"
                                name='phone'
                                value={props.delivery.phone}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("recipient number", props.delivery.phone, "required|phone|max:10|min:10")
                            }
                            <small>
                                <b>NOTE:</b>
                                This number is used for future purposes.
                            </small>
                        </div>
                    </div>

                    <div className="row justify-content-center align-item-center">
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="recipient email">
                                Email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name='email'
                                value={props.delivery.email}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("recipient email", props.delivery.email, "required|email")
                            }
                        </div>
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="confirm email">
                                Confirm email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='c_email'
                                value={props.delivery.c_email}
                                onChange={props.handleDeliveryData}
                                onBlur={() => {
                                    props.delivery.email != props.delivery.c_email ?
                                        props.setMailValidity(true)
                                        :
                                        props.setMailValidity(false)
                                }}
                            />
                            {
                                props.mailValidity ? <div className="text-danger">
                                    Please enter same recipient mail id to confirm
                                </div> :
                                    props.validator1.current
                                        .message("confirm email", props.delivery.c_email, "required|email")
                            }
                        </div>
                    </div>

                    <div className="row justify-content-center align-item-center">
                        <div className="col-lg-12 col-12 col-md-10 px-lg-2 px-0 mt-4">
                            <label className="form-label" id="message">Message for Recipient</label>
                            <textarea
                                className="form-control"
                                name="message"
                                placeholder="Message maximum length should be 150 charcter."
                                rows="3"
                                value={props.delivery.message}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("message", props.delivery.message, "max:150")
                            }
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-12 px-5">
                    <div className="row justify-content-center justify-content-lg-start align-item-center">
                        <div className=" col-12 col-md-10 mb-2 mt-4 my-xl-0 px-lg-2 px-0 d-inline-flex">
                            <h4 className="lead mb-0">Sender Details</h4>
                            <p
                                className="px-1 mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="someone who recieves a surprise present.">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height={20} className="h-6 w-6"
                                    fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </p>
                        </div>
                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-0">
                            <label className="form-label" id="sender name">
                                Name
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name='s_first_name'
                                value={props.delivery.s_first_name}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("sender name", props.delivery.s_first_name, "required|alpha_space")
                            }
                        </div>

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-lg-0 mt-4">
                            <label className="form-label" id="sender email">
                                Email
                                <span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name='s_email'
                                value={props.delivery.s_email}
                                onChange={props.handleDeliveryData}
                            />
                            {
                                props.validator1.current
                                    .message("sender email", props.delivery.s_email, "required|email")
                            }
                        </div>
                    </div>

                    <div className="row justify-content-lg-start justify-content-center align-item-center">

                        <div className="col-lg-6 col-12 col-md-10 px-lg-2 px-0 mt-4 pt-3">
                            <label className="form-label" id="sender phone">
                                Mobile
                                <span className="text-danger">*</span>
                            </label>
                            <OtpVerification
                                otp={props.otp_req}
                                otp_verify={props.otp_verify}
                                phone=''
                                place_order={false}
                                label=""
                                handleData={props.handleDeliveryDataNumber}
                            />
                            {
                                props.verifyValidity ?
                                    <div className="alert-warning d-flex align-items-center my-3 p-2" role="alert">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor">
                                            <path
                                                fillRule="evenodd"
                                                d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                                                clipRule="evenodd" />
                                        </svg>
                                        <div className="px-1">
                                            Please verify the mobile number
                                        </div>
                                    </div> : props.validator1.current
                                        .message("sender number", props.delivery.s_phone, "required|phone|max:10|min:10")
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

const mapStateToProps = state => ({
    otp_req: state.data.otp_request,
    otp_verify: state.data.otp_verify,
    verification: state.timer.msgFlag
});

export default connect(mapStateToProps)(DeliveryDetails)