export const _headerNav = [
  {
    _tag: "NavItem",
    name: "All Jewellery",
    route: "/jewellery",
    _children: [
      {
        _tag: 'NavChild',
        _children: [
          {
            _tag: 'NavItem',
            name: 'Necklace',
            to: '/jewellery/necklace',
          },
          {
            _tag: 'NavItem',
            name: 'Nosepins',
            to: '/jewellery/nosepins',
          },
          {
            _tag: "NavItem",
            name: "Earrings",
            to: "/jewellery/earrings",
          },
          {
            _tag: "NavItem",
            name: "Pendants",
            to: "/jewellery/pendants",
          }
        ]
      },
      {
        _tag: 'NavChild',
        _children: [
          {
            _tag: "NavItem",
            name: "Rings",
            to: "/jewellery/rings",
          },
          {
            _tag: 'NavItem',
            name: 'Bracelets',
            to: '/jewellery/bracelets',
          },
          {
            _tag: 'NavItem',
            name: 'Bangles',
            to: '/jewellery/bangles',
          },
        ]
      },
      {
        _tag: 'NavChild',
        _children: [
          {
            _tag: 'NavImage',
            src: '/assets/images/sunny/navbar/all_jewellery.webp',
          },  
        ]        
      },
    ]
  },
  {
    _tag: "NavItem",
    name: "Nosepins",
    route: "/jewellery/nosepins"
  },
  {
    _tag: "NavItem",
    name: "Earrings",
    route: "/jewellery/earrings"
  },
  {
    _tag: "NavItem",
    name: "Gift cards",
    route: "/gifts"
  },
  {
    _tag: "NavItem",
    name: "Rings",
    route: "/jewellery/rings"
  },
  {
    _tag: "NavItem",
    name: "Bracelets",
    route: "/jewellery/bracelets"
  },
  {
    _tag: "NavItem",
    name: "Bangles",
    route: "/jewellery/bangles"
  }
];