import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { addToCart, addToWishlist, addToCompare, addCartToServer } from "../../../actions";
import { getCategoryProducts } from "../../../services";
import ProductListItem from "./product-list-item";
import store from "../../../store";

// import product from "../../../api/dummy.json"

class ProductListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 12,
      hasMoreItems: true,
      filterTagDisplay: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartItems != prevProps.cartItems) {
      this.props.addCartToServer(this.props.cartItems)
    }
  }



  fetchCartItem = prod => {
    if (this.props.cartItems.findIndex(item => item.id === prod.id) !== -1) {
      const index = this.props.cartItems.findIndex(item => item.id === prod.id);
      return this.props.cartItems[index].qty;
    } else {
      return 1;
    }
  };

  fetchMoreItems = () => {
    if (this.state.limit >= this.props.products.length) {
      this.setState({ hasMoreItems: false });
      return;
    }
    // a fake async api call
    setTimeout(() => {
      this.setState({
        limit: this.state.limit + 10
      });
    }, 1000);
  }

  clear() {
    this.setState({ filterTagDisplay: false })
    let phKeys = Object.keys(this.props.filters.placeholder);
    phKeys.map((phKey, i) => {
      store.dispatch({ type: "FILTER_CAT_NULL", placeholder: phKey });
    });
    var w = document.getElementsByTagName("input");
    for (var i = 0; i < w.length; i++) {
      if (w[i].type == "checkbox") {
        w[i].checked = false;
      }
    }
  }

  render() {
    const {
      addToCart,
      addCartToServer,
      symbol,
      addToWishlist,
      addToCompare,
      products,
      cartItems
    } = this.props;
    const filterTag = this.props.filters

    return (
      <div>
        <div className="row mt-2">
          <h5 className="mt-3 ml-3">Filtered By:</h5>
          {
            Object.keys(Object.values(filterTag)[0]).map(filter => (
              Object.values(filterTag)[0][filter] && Object.values(filterTag)[0][filter].map((values, i) => (
                <div
                  key={i}
                  className="filter-tag px-2 mx-1 mt-2 bg-white d-flex flex-row"
                // onClick={e => this.clickBrandHendle(values, Object.values(filterTag)[0][filter])}
                >
                  <p className="pl-3 mb-0">{values.replace("_", " ")}</p>
                  {/* <span className="pl-3" onClick={() => this.clear()}><strong>×</strong></span> */}
                </div>
              ))
            ))
          }
          {this.state.filterTagDisplay ?
            <div
              className="filter-tag px-2 mx-1"
              onClick={() => this.clear()}
            >
              <p className="mb-0">Clear All </p>
            </div> : ""}
        </div>
        <div className={`${this.props.layoutGrid ? "product-wrapper-grid" : "product-wrapper-grid list-view"}`}>
          <div className="container-fluid pl-0 px-0">
            {products !== undefined && products.length > 0 ? (
              <InfiniteScroll
                dataLength={this.state.limit} //This is important field to render the next data
                next={() => this.fetchMoreItems(products)}
                hasMore={this.state.hasMoreItems}
                loader={<div className="loading-cls" />}
              >
                <div className="row">
                  {products.slice(0, this.state.limit).map((product, index) => (
                    <div
                      className=
                      {`${this.props.layoutGrid ?
                        this.props.colSize === 4
                          ? "col-xl-4 col-md-6 col-grid-box"
                          : "col-lg-" + this.props.colSize
                        : "col-lg-12"
                        }`}
                      key={index}
                    >
                      <ProductListItem
                        product={product}
                        cartItems={cartItems}
                        symbol={symbol}
                        // Prod={this.fetchCartItem(product)}
                        onAddToCompareClicked={addToCompare}
                        onAddToWishlistClicked={addToWishlist}
                        onAddToCartClicked={addToCart}
                        addCartToServer={addCartToServer}
                        key={index}
                        cat={this.props.cat}
                        selectedColor={this.props.selectedColor}
                      />
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <h3>
                    Sorry! Couldn't find the product you were looking For!!!
                  </h3>
                  <p className="category_paragraph">
                    Please check if you have misspelt something or try searching
                    with other words.
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/trending`}
                    className="btn btn-solid"
                  >
                    continue shopping
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  cartItems: state.cartList.cart,
  products: getCategoryProducts(state.data.category.products, state.filters),
  // products: state.data.category.products,
  symbol: state.data.symbol,
  filters: state.filters
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare, addCartToServer }
)(ProductListing);
