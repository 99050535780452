import React, { useState, useRef } from 'react'

import SimpleReactValidator from "simple-react-validator";
import Otp from '../../../checkout/otp';

const ContactDfe = (props) => {

  const validator = useRef(new SimpleReactValidator())
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [verficationMessage, setVerficationMessage] = useState(false)

  const [ageVerify, setAgeVerify] = useState(false)

  // const handleContact = (event) => {
  //   if (validator.current.allValid()) {
  //     if (props.otp_verify.token) {
  //       props.handleIndexData(2)
  //     }
  //     else
  //       setVerficationMessage(true)
  //   }
  //   else {
  //     validator.current.showMessages();
  //     forceUpdate()
  //   }
  // }

  const handleContact = (event) => {
    if (validator.current.allValid()) {
      props.handleIndexData(2)
    }
    else {
      validator.current.showMessages();
      forceUpdate()
    }
  }


  return (
    <div className="container bg-white p-5 border">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row my-4">
            <h3><strong>Contact Details</strong></h3>
          </div>

          <div className="row mb-4">
            <label className="form-label">Full Name<span style={{color:"#EB5406"}}>*</span></label>
            <input
              type="text"
              className="form-control"
              name='full_name'
              value={props.contact.full_name}
              onChange={(e) => {
                props.handleContactDetails(e)
                validator.current.showMessageFor('full_name')
              }
              }
            />
            {validator.current.message("full_name", props.contact.full_name, "required|alpha_space")}
          </div>

          <div className="row mb-4">
            <label className="form-label">Email address<span style={{color:"#EB5406"}}>*</span></label>
            <input
              type="text"
              className="form-control"
              name='email'
              value={props.contact.email}
              onChange={(e) => {
                props.handleContactDetails(e)
                validator.current.showMessageFor('email')
              }
              }
            />
            {validator.current.message("email", props.contact.email, "required|email")}
          </div>

          <div className="row mb-4">
            <label className="form-label">Address<span style={{color:"#EB5406"}}>*</span></label>
            <input
              type="text"
              className="form-control "
              name='address'
              value={props.contact.address}
              onChange={(e) => {
                props.handleContactDetails(e)
                validator.current.showMessageFor('address')
              }
              }
            />
            {validator.current.message("address", props.contact.address, "required|alpha_num_dash_space")}
          </div>

          <div className="row mb-4">
            <div className='col-md-6 col-12 p-0 pr-md-2 pr-0'>
              <label for="date" class="form-label">DoB<span style={{color:"#EB5406"}}>*</span></label>
              <input
                type="date"
                class="form-control"
                name='dob' id="dob"
                value={props.contact.dob}
                onChange={(e) => {
                  props.handleContactDetails(e)
                  validator.current.showMessageFor('dob')
                }
                }
              />
              {validator.current.message("birth date", props.contact.dob, "required")}
            </div>
            <div className='col-md-6 col-12 p-0 pl-0 pl-md-2 mt-4 mt-md-0'>
              <label className="form-label">City<span style={{color:"#EB5406"}}>*</span></label>
              <input
                type="text"
                className="form-control "
                name='city'
                value={props.contact.city}
                onChange={(e) => {
                  props.handleContactDetails(e)
                  validator.current.showMessageFor('city')
                }
                }
              />
              {validator.current.message("city", props.contact.city, "required|alpha_space")}
            </div>
          </div>

          <div className="row mb-4">
            <div className='col-md-6 col-12 p-0 pr-md-2 pr-0'>
              <label className="form-label">State<span style={{color:"#EB5406"}}>*</span></label>
              <input
                type="text"
                className="form-control"
                name='state'
                value={props.contact.state}
                onChange={(e) => {
                  props.handleContactDetails(e)
                  validator.current.showMessageFor('state')
                }
                }
              />
              {validator.current.message("state", props.contact.state, "required|alpha_space")}
            </div>

            <div className='col-md-6 col-12 p-0 pl-0 pl-md-2 mt-4 mt-md-0'>
              <label className="form-label">Pincode<span style={{color:"#EB5406"}}>*</span></label>
              <input
                type="text"
                className="form-control"
                name='pincode'
                value={props.contact.pincode}
                onChange={(e) => {
                  props.handleContactDetails(e)
                  validator.current.showMessageFor('pincode')
                }
                }
              />
              {validator.current.message("pincode", props.contact.pincode, "required|numeric|min:0,num|max:6")}
            </div>
          </div>

          <div className="row mb-4">
            <Otp
              otp={props.otp_req}
              otp_verify={props.otp_verify}
              place_order={false}
              handleData={props.handleMobileDetails}
            />
            {verficationMessage &&
              <div className="text-danger">
                The mobile number verfication is required
              </div>}
          </div>
        </div>
        {/* <div className="row mt-4 d-lg-none d-block">
          <button
            className="btn btn-dfe2"
            onClick={() => { handleContact() }}
          >
            Next
          </button>
        </div> */}

        <div className="mt-3 d-block d-lg-none">
          <div className="d-flex justify-content-center gift-index-active mobile-fix-option-gift mt-4 px-2 px-md-5 mx-md-5">
            <div className="col-md-12 col-6 px-1">
              <button className="btn btn-dfe2 w-100"
                onClick={() => { handleContact() }}>
                <div className="mx-4">Next</div>
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>
  );

}

export default ContactDfe;