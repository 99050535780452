import React from "react";



const WorkAndJoin = () => {
    return (
        <section className="putting-glitz my_l">
            <div className="container">
                <div className="glitz-left">
                    <div>
                        <h3>
                            Where We Work
                        </h3>
                        <p>
                            Sunny Diamonds is Kerala’s only importer of the exquisite and much-coveted internally flawless diamonds, and that by
                            itself testifies to the quality of our pieces. In fact, our showrooms are at Kochi, Calicut & Trivandrum, Kerala, India.
                            Every piece that our store showcases is a work of art, crafted by our craftsmen and designers...
                        </p>
                    </div>
                    <div>
                        <h3>
                            Why Join Us
                        </h3>
                        <p>
                            We believe “Goals are the fuel in the furnace of achievement”. Taking inspiration from the past,
                            setting milestones in the present, we continually strive to unravel the opportunities for a better tomorrow.
                            It’s with this vision and diligence that we succeeded in capturing the South Indian market.
                            Perfection is a prerequisite for Sunny Diamonds and we believe that it is for this reason that today;
                            Sunny Diamonds instantly comes to mind when anyone attempts recalling jewellery brands...
                        </p>
                    </div>
                </div>

                <div className="glitz-right">
                    <img
                        src="/assets/images/sunny/career/Career_PP.Sunny.webp"
                        className="content-image"
                        alt="Chief Managing Director"
                    />
                </div>
            </div>
        </section >
    )
}

export default WorkAndJoin