import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import EmiPieChart from "./dfe-emi-pie-chart";

const CalculateEmi = () => {


    const validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [amnt, setAmnt] = useState(1000);
    const [youPay, setYouPay] = useState(11000);
    const [youGet, setYouGet] = useState(700);
    const [total, setTotal] = useState(11700);
    const [amtValid, setAmtValid] = useState(true);


    const amountChange = (value) => {
        setAmnt(value)
    };

    const amountChecker = (e) => {
        e.preventDefault();
        if (validator.current.fieldValid("amount") && amnt % 1000 == 0) {
            setAmtValid(true)
            setYouPay(amnt * window.rc.dfe.month)
            setYouGet(amnt * window.rc.dfe.interest)
            setTotal((amnt * window.rc.dfe.month) + (amnt * window.rc.dfe.interest))

        } else {
            setAmtValid(false)
            validator.current.showMessages();
            forceUpdate()
        }
    };


    return (
        <>
            <section>
                <div className="container my-5 calculate-dfe">
                    <div className="row justify-content-center">
                        <h3 className="mt-5">CALCULATE DFE</h3>
                        <p className="px-5 justify-content-center">This plan will turn that frown upside down and make your smile Diamonds for everyone!
                            Here’s how you can calculate your purchase better.
                            Enter the amount you’d like to keep aside with us for 11 months and see our gift* value.
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-12">
                            <div className="row justify-content-center py-md-5 py-3">
                                <label className="d-flex justify-content-between col-lg-8 col-md-8 col-10">
                                    <span>Enter any amount</span>
                                    <span>Min ₹1000</span>
                                </label>
                                <div className="col-lg-8 col-md-8 col-10 d-flex flex-lg-row flex-column justify-content-center align-items-center">
                                    <input type="text"
                                        className="form-control"
                                        name="amount"
                                        placeholder="Enter any amount(Min 1000)"
                                        value={amnt}
                                        onChange={(e) => amountChange(e.target.value)}
                                    />
                                    <button className="btn btn-dfe2 mt-2 mt-lg-0" type="submit" onClick={amountChecker}>Check</button>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                {validator.current.message("amount", amnt, "required|numeric|min:0,num")}
                                {!amtValid && validator.current.fieldValid("amount") ?
                                    <div className="text-danger">
                                        Enter multiples of 1000
                                    </div> : ""
                                }
                            </div>
                            <div className="row justify-content-center">
                                <p className="px-5 justify-content-center">Customer Usually Prefer</p>
                                <div className="col-lg-8 col-md-8 col-10 d-flex flex-row  justify-content-center align-items-center">
                                    <button
                                        className="btn btn-dfe mx-2 my-md-0 my-2"
                                        onClick={() => { amountChange(10000) }}>
                                        10,000
                                    </button>
                                    <button
                                        className="btn btn-dfe mx-2 my-md-0 my-2"
                                        onClick={() => { amountChange(20000) }}>
                                        20,000
                                    </button>
                                    <button
                                        className="btn btn-dfe mx-2 my-md-0 my-2"
                                        onClick={() => { amountChange(30000) }}>
                                        30,000
                                    </button>
                                </div>
                            </div>

                            <div className="row justify-content-center py-3">
                                <Link
                                    to={`${process.env.PUBLIC_URL}/dfe-form`}
                                    className="btn btn-solid"
                                >
                                    Plan Now
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="row justify-content-center">

                                <EmiPieChart
                                    youPay={youPay}
                                    youGet={youGet}
                                />

                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="row justify-content-center py-md-5 py-3">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className=" d-flex justify-content-center">
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ color: '#722257' }}></i>
                                                    <h5 className="mx-2">You Pay (11 Months)</h5>
                                                </div>
                                            </td>
                                            <td>
                                                <h3 className="you-pay">
                                                    {youPay}
                                                </h3>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className=" d-flex justify-content-center">
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ color: '#722257' }}></i>
                                                    <h4 className="mx-2">You Get (70% of 1 Months)</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <h3 className="you-pay">
                                                    {youGet}
                                                </h3>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className=" d-flex justify-content-center">
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ color: '#722257' }}></i>
                                                    <h4 className="mx-2">Total Amount</h4>
                                                    <p className="Lead">(Redeemable)</p>
                                                </div>
                                            </td>
                                            <td>
                                                <h3 className="you-pay">
                                                    {total ? total : "8700"}
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CalculateEmi