import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import Breadcrumbs from '../new_template_common/breadcrumb/BreadCrumb'
import MobileFilterPanel from './components/MobileFilterPanel';
import ListingPanel from './components/ListingPanel';
import Filter from './components/filter/filter';
import Banner from '../new_template_common/banner/banner';
import InstagramWidget from '../new_template_common/instagram_widget';

import store from '../../store';
import { getAllCategories } from "../../actions";
import { MainLoader } from '../../loader';

import './css/lisitng.css'

const Listing = (props) => {

  useEffect(() => {
    store.dispatch({ type: "RECEIVE_DB_CATEGORY", payload: "" })

    if (window.location.pathname == "/trending")
      props.getAllCategories("trending");
    else
      props.getAllCategories(props.match.params.id1 == "nose-pins" ? "nosepins" : props.match.params.id1);
  }, [props.location.pathname])

  if (props.category.length != 0)
    return (
      <main className="site-main">
        <div className="sd-inner-content">
          <div className="uk-container uk-container-xlarge">
            <Breadcrumbs
              ancestor="Jewellery"
              current={props.match.params.id1}
            />

            <MobileFilterPanel />

            <div data-uk-grid id="grid">
              <div className="uk-width-1-1 uk-width-1-5@l uk-width-1-5@xl uk-padding-remove side-bar-listing uk-visible@l">
                <div data-uk-sticky="start: 0; offset:140; end: .sticky-tag; cls-active:uk-active">

                  {/* Filter section */}

                  <Filter />
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-4-5@l">

                {/* Banner  */}

                <Banner bannerImage={props.category.banner_src} />

                {/* Listing section  */}

                <ListingPanel
                  title={props.category.banner_title}
                  description={props.category.banner_content}
                  history={props.history}
                  bannerImage={props.category.banner_src}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='sticky-tag' />
        <InstagramWidget />
      </main>
    );
  else return (
    <div className="height">
      <Breadcrumbs />
      <MainLoader />
    </div>
  )
};


const mapStateToProps = state => ({
  category: state.data.category,
});

export default connect(mapStateToProps, { getAllCategories })(Listing);
