import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CartPage from "../components/common/headers/common/cart-header";
import { removeFromCart, postCartItems } from "../actions";
import { getCartTotal } from "../services";


const CartContainer = ({ cartList, total, symbol, removeFromCart, postCartItems }) => {
  const [button, setButton] = useState("Check For Price Changes")

  useEffect(() => {
    if (button == "please wait...") {
      setButton("Price updated")
    }
  }, [cartList]);

  return (
    <li className="onhover-div mobile-cart pb-0 ml-3">
      {/* {cartList.length > 0 ? (
        <div className="cart-qty-cls d-md-block">{cartList.length}</div>
      ) : ("")
      } */}
      <div className="d-inline-flex">
        <Link to={`${process.env.PUBLIC_URL}/cart`}>

          {/* Desktop & Mobile cart icon  */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
            fill="#722257" className="bi bi-cart-fill d-md-block d-none" viewBox="0 0 16 16"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg> */}

          {/* <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/shopping-cart.svg`}
            className="img-fluid"
            alt="shopping cart"
          /> */}
          {/* <i className="fa fa-shopping-cart" /> */}

          <img
            src={`${process.env.PUBLIC_URL}` + "assets/images/new_template/images/layer-2.svg"}
            alt=""
            width="14"
          />
        </Link>
        {cartList.length > 0 ? (
          <h5 className="ml-1 my-0">({cartList.length})</h5>
        ) : ("")
        }

      </div>
      <ul className="show-div shopping-cart p-2 shadow border rounded">
        {cartList.slice().reverse().map((item, index) => (
          <CartPage
            key={index}
            item={item}
            total={total}
            symbol={symbol}
            removeFromMiniCart={removeFromCart}
          />
        ))}
        {cartList.length > 0 ? (
          <div className="mt-3">
            {/* <button type="button" className="btn pin-button my-2 w-100" onClick={() => {
              if (button == "Check For Price Changes") {
                postCartItems(cartList)
                setButton("please wait...")
              }
            }
            }>{button}</button> */}
            <li>
              <div className="total px-2">
                <h5 className="d-flex justify-content-between text-secondary">
                  subtotal :
                  <b>{symbol}{total}</b>
                </h5>
              </div>
            </li>
            <li>
              <div className="buttons mb-2">
                <Link to={`${process.env.PUBLIC_URL}/cart`} className="view-cart p-2">
                  view cart
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/checkout`}
                  className="checkout px-2 "
                >
                  checkout
                </Link>
              </div>
            </li>
          </div>
        ) : (
          <li className="d-flex justify-content-center align-items-center">
            <h5 className="m-0 py-3">Your cart is currently empty.</h5>
          </li>
        )}
      </ul>
    </li>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cartList.cart,
    extendedItems: state.cartList.extended_cart,
    total: getCartTotal(state.cartList.cart),
    symbol: state.data.symbol
  };
}
export default connect(
  mapStateToProps,
  { removeFromCart, postCartItems }
)(CartContainer);
