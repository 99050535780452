import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import { timerAccessAction, errorAccessMsgAction, getReqAccessOtp, getAccessVerifyOtp } from "../../../actions"
import store from "../../../store"
import Loader from "../../common/spinner"

const SecureAccessCard = (props) => {

    let timer = 0

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator())

    const [resendFlag, setResendFlag] = useState("counter");
    const [compState, setCompState] = useState("number")
    const [displayTime, setDisplayTime] = useState("00:00")

    const [recpNumber, setRecpNumber] = useState("")

    const [otpHandler, setOtpHandler] = useState({
        access_secret: "",
        access_token: "",
    });

    const [timerData, setTimerData] = useState({
        interupt: false,
        timer_step: 0,
        timer_rate: 0,
        last_callback_id: false,
    })

    useEffect(() => {
        if (props.otp_verify) {
            if (props.otp_verify.success) {
                store.dispatch({ type: "ACCESS_OTP_VERIFIED", payload: "" });
                props.handleSubmit()
            }
            else
                if (props.otp_verify.reason) {
                    props.errorAccessMsgAction("ACCESS_ERROR_MSG", props.otp_verify)
                    // setOtpHandler(props.timer.otpHandler)
                }
        }
    }, [props.otp_verify]);

    const handleChangeOtp = (event) => {
        event.persist()
        setOtpHandler(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
    }

    const setTimer = (step, rate) => {
        setTimerData({
            ...timerData,
            timer_step: step,
            timer_rate: rate,
        })
    }

    useEffect(() => {
        if (timerData.timer_step > 0) {
            timer = setTimeout(() => setTimerData({ ...timerData, timer_step: timerData.timer_step - 1 }), 1000);
            setResendFlag('counter')
        } else {
            props.errorAccessMsgAction("ACCESS_MSG_RESET");
            if (timerData.timer_rate > 0) {
                setResendFlag('resend')
            }
        }
        setDisplayTime("00:" + addLeadingZeros(timerData.timer_step))

        return () => {
            clearTimeout(timer);
        };
    }, [timerData.timer_step]);


    function addLeadingZeros(num, totalLength = 2) {
        return String(num).padStart(totalLength, '0');
    }

    const codRequestOtp = () => {
        if (props.credentials) {
            setCompState('loader')
            let data = {
                card_number: props.credentials,
                // mobile_number: "7787787787",
                access_tag: props.access_tag
            }
            axios.post(global.postUrl + "giftcards/access/generate", data, {
                timeout: 10000
            })
                .then(response => {
                    if (response.data.success) {
                        //timerData.interupt = true;
                        setOtpHandler({ "access_token": response.data.access.access_token, "access_secret": "" })
                        setTimer(response.data.access.timer_step, response.data.access.timer_rate)
                        setRecpNumber(response.data.access.mobile_number)
                        setCompState('otp')
                    } else {
                        if (response.data.reason == "WAIT_TIMEOUT") {
                            setTimer(response.data.timer_step, response.data.timer_rate)
                        }
                        setCompState('number')
                        props.errorAccessMsgAction("ACCESS_ERROR_MSG", response.data)
                    }
                })
                .catch(error => {
                    if (error) {
                        setCompState('number')
                        return error;
                    }
                });
        }
        else props.handleValidity()
    }

    const codVerifyOtp = () => {
        if (validator.current.fieldValid("otp")) {
            store.dispatch({ type: "ACCESS_SET_OTP_DETAILS", payload: otpHandler });
            props.errorAccessMsgAction("ACCESS_FORM_LOADER")
            props.getAccessVerifyOtp(otpHandler, "giftcards/access/verify")
        }
        else {
            validator.current.showMessages();
            forceUpdate()
        }
    }

    const resetHandler = () => {
        props.errorAccessMsgAction("ACCESS_MSG_RESET");
        setCompState('number')
    }

    return (
        <div className="container p-0 mt-3">
            <div className="row">
                {compState === "number" ?
                    <div className="col-12 mx-md-auto mx-lg-0">
                        <div className="d-flex flex-md-row justify-content-center mb-2">
                            <button
                                type="button"
                                className="btn btn-dfe2"
                                onClick={codRequestOtp}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    :
                    compState === "loader" ?
                        <div className="col-12 mx-md-auto mx-lg-0">
                            <Loader text="Loading..." />
                        </div> :
                        compState === "otp" ?
                            <div className="col-12 mx-md-auto mx-lg-0">
                                <h6 className="product-title">OTP has been sent to <strong>xxxxxx{recpNumber}</strong></h6>
                                <div className="d-flex flex-md-row justify-content-between px-4 px-md-0">
                                    <input
                                        type="tel"
                                        className="form-control pin text-center"
                                        placeholder="Enter OTP"
                                        minLength="4"
                                        maxLength="4"
                                        name="access_secret"
                                        value={otpHandler.access_secret}
                                        onChange={handleChangeOtp}
                                    />
                                    <button
                                        className="btn btn-promo"
                                        onClick={codVerifyOtp}
                                    >
                                        Verify
                                    </button>
                                </div>
                                {validator.current.message(
                                    "otp",
                                    otpHandler.access_secret,
                                    "required|min:4|max:4"
                                )}
                                <div className="d-flex justify-content-between my-2">
                                    {resendFlag == "resend" ?
                                        <>
                                            <a href="javascript:void(0)" onClick={() => codRequestOtp()}><u>Resend OTP</u></a>
                                            <a href="javascript:void(0)" onClick={() => resetHandler()}><u>Change number</u></a>
                                        </>
                                        :
                                        <>
                                            <a>
                                                <u className="text-muted pr-2">Resend OTP</u>
                                                <b>{displayTime}</b>
                                            </a>
                                            <a href="javascript:void(0)" onClick={() => resetHandler()}>
                                                <u>Change number</u>
                                            </a>
                                        </>}
                                </div>

                            </div>
                            : ""}
                {props.timer.msgFlag.flag && props.credentials && <div className="col-12 mx-md-auto mx-lg-0">
                    <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex align-items-center my-2 p-2 mt-3 alert-" + props.timer.msgFlag.color} role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d={props.timer.msgFlag.svg} clipRule="evenodd" />
                        </svg>
                        <div className="px-1">
                            {props.timer.msgFlag.msg} {props.timer.msgFlag.flag === "wait_time" ? displayTime : ""}
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    timer: state.otp
});

export default connect(
    mapStateToProps,
    {
        getReqAccessOtp,
        getAccessVerifyOtp,
        timerAccessAction,
        errorAccessMsgAction
    }
)(SecureAccessCard);