import React, { Component } from "react";

class EducationSidePanel extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const {
            edu
        } = this.props;

        return (
            <div className="uk-width-1-4@m uk-visible@l">
                <div data-uk-sticky="start: 0; offset: 150; end: true" className="uk-position-z-index">
                    <div>
                        <ul className="uk-tab-left uk-margin-medium-top" data-uk-tab>
                            {edu.map((item, i) => (
                                <li
                                    key={i}
                                    className={
                                        item.category.value === this.props.type
                                            ? "active cursor-pointer"
                                            : "cursor-pointer"
                                    }
                                    onClick={() => this.props.onClickTypeHandler(item)}
                                >
                                    <div className="uk-flex uk-margin-medium">
                                        <img
                                            src={
                                                `${process.env.PUBLIC_URL}` +
                                                `${item.category.image}`
                                            }
                                            className="img-fluid education_sidebar_image"
                                            alt="sunny diamonds"
                                        />
                                        <p className="normal-para uk-margin-left">{item.category.label}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default EducationSidePanel;