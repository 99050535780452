import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import '../css/Item.css'

const Item = (props) => {

    const [quantity, setQuantity] = useState(1)
    const [images, setImages] = useState("")
    const [selectedVariant, setSelectedVariant] = useState({})

    const {
        product,
        symbol,
        onAddToCartClicked,
        onAddToWishlistClicked,
        onAddToCompareClicked
    } = props;

    const colorArray = [...new Map(product.variants.map(item => [item.color, item])).values()];

    useEffect(() => {
        setSelectedVariant(product.variants[0])
        setImages(product.variants[0].image.split(","))
    }, [product])

    const addToCartList = item => {
        item.selectedVariant = selectedVariant
        onAddToCartClicked(item, 1, item.selectedVariant);
        setQuantity(quantity + 1)
    };

    const onAddToWishlist = item => {
        item.selectedVariant = selectedVariant
        onAddToWishlistClicked(item, item.selectedVariant)
    }

    const onAddToCompare = item => {
        item.selectedVariant = selectedVariant
        onAddToCompareClicked(item, item.selectedVariant)
    }

    const handleMetalColor = (color) => {
        if (color == "yellow-gold")
            return "round-1"
        else if (color == "rose-gold")
            return "round-2"
        else return "round-3"
    }

    const onClickHandle = (variant) => {
        setImages(variant.image.split(","))
        setSelectedVariant(variant)
    }

    return (
        <div key={props.key} className="uk-card-default uk-padding-small uk-margin-small uk-margin-small-top">
            <div className="uk-text-center">
                <div className="uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                    <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${selectedVariant.id}`}>
                        <img
                            src={`${process.env.PUBLIC_URL}` + images[0]}
                            onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                            alt={product.name}
                        />
                        <img
                            class="uk-transition-scale-up uk-position-cover"
                            src={images[1] ? `${process.env.PUBLIC_URL}` + images[1] : `${process.env.PUBLIC_URL}` + images[0]}
                            onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                            alt={product.name}
                        />
                    </Link>

                    {/* {window.location.pathname == "/trending" && <div className="uk-position-top-left">
                        <p className="best-seller text-light">Best Seller</p>
                    </div>} */}
                    <div className="uk-position-top-right wish-icon-in uk-padding-small">
                        <a
                            href="javascript:void(0)"
                            onClick={() => onAddToWishlist(product)}
                        >
                            <span uk-icon="icon: heart; ratio: 1" />
                        </a>
                    </div>
                    <div className="uk-position-bottom-right  wish-icon uk-transition-slide-bottom-small uk-padding-small">
                        <ul className="uk-iconnav uk-iconnav-vertical">
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => addToCartList(product)}
                                >
                                    <img src="/assets/images/new_template/other_pages/shopping-cart-solid.svg" width="20" data-uk-svg />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => onAddToCompare(product)}
                                >
                                    <img src="/assets/images/new_template/other_pages/arrows-rotate-solid.svg" width="20" data-uk-svg />
                                </a>
                            </li>
                        </ul>
                    </div>
                    {colorArray[1] && (
                        <div className="color-button uk-position-bottom-left uk-transition-slide-bottom-small uk-padding-small">
                            {colorArray.map((vari, i) => {
                                return (
                                    <button
                                        key={i}
                                        className={handleMetalColor(vari.color)}
                                        onClick={() => onClickHandle(vari)}
                                    >
                                        <label>
                                            <input className="uk-radio" type="radio" name="radio2" />
                                        </label>
                                    </button>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className="listing-page-content uk-text-left">
                {/* <div className="uk-grid-small uk-flex-between" data-uk-grid> */}
                <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${selectedVariant.id}`}>
                    <div className="uk-width-expand">{product.name.toUpperCase()}</div>
                </Link>
                <div>{symbol} {Number(selectedVariant.offerprice).toFixed(2)}
                    {/* <div><del>{symbol} {product.variants[0].price}</del></div> */}
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default Item;