import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Helmet } from "react-helmet";
import "../common/index.scss";
import SimpleReactValidator from "simple-react-validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  actChangePassword,
  actProfile,
  actProfileEdit,
  actUserLogout
} from "../../actions";
import { connect } from "react-redux";
import TimeOut from "../../sessions/timeOut";


class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      email: sessionStorage.getItem("email"),
      user_id: sessionStorage.getItem("user_id"),
      passwordShown: false,
      passwordShown1: false,
      passwordShown2: false,
      navClose: { left: "-410px" }
    };
    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  componentDidMount() {
    if (
      sessionStorage.getItem("user_id") === "" ||
      sessionStorage.getItem("token") === "" ||
      sessionStorage.getItem("user_type") !== "CUSTOMER"
    ) {
      this.props.history.push("/login");
    }
    this.props.actProfile();
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { left: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { left: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { left: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { left: "-410px" } });
  }

  togglePasswordVisiblity = () => {
    this.setState({
      passwordShown: this.state.passwordShown ? false : true
    });
  };

  togglePasswordVisiblity1 = () => {
    this.setState({
      passwordShown1: this.state.passwordShown1 ? false : true
    });
  };

  togglePasswordVisiblity2 = () => {
    this.setState({
      passwordShown2: this.state.passwordShown2 ? false : true
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      var changePassword = {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        email: this.state.email,
        user_id: this.state.user_id
      }
      this.props.actChangePassword(changePassword);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  logoutSubmit = event => {
    this.props.actUserLogout();
  };

  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;

    return (
      <div>
        <Helmet>
          <title>
            Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds |
            Change Password
          </title>
          <meta
            name="description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally
          Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta
            name="keywords"
            content="Buy Diamonds online, Online diamonds shopping, Diamond Jewellery Store, Buy Diamond Rings Online, 
          diamond earrings online, diamond pendants online kerala, buy diamond bangles online, buy diamond necklace,  
          Buy wedding rings online,  online diamond jewellery India"
          />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta
            property="og:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            property="og:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta property="og:url" content="https://sunnydiamonds.com/" />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta
            name="twitter:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            name="twitter:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={"change password"} />

        {/*Forget Password section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3">
                <div
                  className="account-sidebar"
                  onClick={this.openNav.bind(this)}
                >
                  <a className="popup-btn btn btn-solid">my account</a>
                </div>
                <div
                  className="dashboard-left mt-2"
                  style={this.state.navClose}
                >
                  <div
                    className="collection-mobile-back"
                    onClick={this.closeNav.bind(this)}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="block-content">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                          My Orders
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                          Edit Profile
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                          DFE Active Plan
                        </Link>
                      </li> */}
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/change-password`}>
                          Change Password
                        </Link>
                      </li>
                      <li className="last">
                        <a onClick={this.logoutSubmit}>Log Out</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-9">
                <div className="dashboard-right mt-2 bg-white">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>Password Change</h2>
                    </div>
                    <TimeOut logout={this.logoutSubmit} msg={null} />

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <form className="theme-form" onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <label className="form-label" htmlFor="email">
                              Current Password
                            </label>
                            <input
                              type={
                                this.state.passwordShown ? "text" : "password"
                              }
                              className="form-control"
                              name="oldPassword"
                              id="oldPassword"
                              placeholder="Current Password"
                              value={this.state.oldPassword}
                              onChange={this.setStateFromInput}
                            />
                            <i
                              className="current_password"
                              onClick={this.togglePasswordVisiblity}
                            >
                              {eye}
                            </i>{" "}
                            {this.validator.message(
                              "Current Password",
                              this.state.oldPassword,
                              "required"
                            )}
                          </div>
                          <div className="form-group">
                            <label className="form-label" htmlFor="email">
                              New Password
                            </label>
                            <input
                              type={
                                this.state.passwordShown1 ? "text" : "password"
                              }
                              className="form-control"
                              name="newPassword"
                              id="newPassword"
                              placeholder="New Password"
                              value={this.state.newPassword}
                              onChange={this.setStateFromInput}
                              onPaste={e => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={e => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            <i
                              className="new_password"
                              onClick={this.togglePasswordVisiblity1}
                            >
                              {eye}
                            </i>{" "}
                            {this.validator.message(
                              "New Password",
                              this.state.newPassword,
                              "required"
                            )}
                          </div>
                          <div className="form-group">
                            <label className="form-label" htmlFor="number">
                              Confirm Password
                            </label>
                            <input
                              type={
                                this.state.passwordShown2 ? "text" : "password"
                              }
                              className="form-control"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              value={this.state.confirmPassword}
                              onChange={this.setStateFromInput}
                              onPaste={e => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={e => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                            <i
                              className="confirm_password"
                              onClick={this.togglePasswordVisiblity2}
                            >
                              {eye}
                            </i>{" "}
                            {this.validator.message(
                              "confirm Password",
                              this.state.confirmPassword,
                              `required|in:${this.state.newPassword}`,
                              { messages: { in: "Passwords need to match!" } }
                            )}
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <button
                                className="btn btn-solid btn-login"
                                type="submit"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="row">
                          <img
                            src="/assets/images/sunny/change_password/Password.png"
                            className="d-lg-block d-none"
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile_data: state.data.profile_data
});

export default connect(
  mapStateToProps,
  { actProfile, actProfileEdit, actChangePassword, actUserLogout }
)(ChangePassword);
