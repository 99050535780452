import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "./navbar";
import CartContainer from "../../containers/CartContainer"
import StickyNavbar from "./mobile-sticky-navbar";
import MobileSideNavbar from "./mobile-sidebar";

import "./css/header-body.css"

const HeaderBody = (props) => {

    const [user, setUser] = useState("")

    useEffect(() => {
        if (sessionStorage.getItem("user_type") == "CUSTOMER")
            setUser("dashboard")
        else
            setUser("login")
    }, [sessionStorage.getItem("user_type")])

    return (
        <div className="sticky-panel">
            <div className="header-container" data-uk-sticky="start: 0; end:.sticky-filter-sidebar;cls-active:uk-active">
                <div className="header">

                    {/* search box  */}

                    <div className="search-box" onClick={() => (window.location.href = "/search")}                >
                        <input
                            id="search"
                            type="text"
                            value=""
                            placeholder="Find the best for you..."
                            className="input-text"
                            maxLength="128"
                            role="combobox"
                            aria-haspopup="false"
                            aria-autoComplete="both"
                            autoComplete="off"
                            aria-expanded="false"
                        />
                        <button
                            type="submit"
                            title="Search"
                            className="action search"
                            aria-label="Search"
                            disabled=""
                        >
                            <span>Search</span>
                        </button>
                    </div>

                    {/* essential buttons  */}

                    <div className="search-links">
                        <div className="wishlist uk-flex-inline ml-3">
                            <Link to={`${process.env.PUBLIC_URL}/compare_list`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24"
                                    strokeWidth="1.6"
                                    stroke="#722257"
                                    className="w-6 h-6"
                                    width="17"
                                    height="17"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                </svg>
                            </Link>
                            {props.compareItems.length > 0 ? (<h5 className="ml-1 my-0">({props.compareItems.length})</h5>) : ""}
                        </div>
                        <div className="wishlist uk-flex-inline ml-3">
                            <Link to={`${process.env.PUBLIC_URL}/wishlists`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    width="17"
                                    height="17"
                                >
                                    <path
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        d="M10,4 C10,4 8.1,2 5.74,2 C3.38,2 1,3.55 1,6.73 C1,8.84 2.67,10.44 2.67,10.44 L10,18 L17.33,10.44 C17.33,10.44 19,8.84 19,6.73 C19,3.55 16.62,2 14.26,2 C11.9,2 10,4 10,4 L10,4 Z"
                                    />
                                </svg>
                            </Link>
                            {props.wishListItems.length > 0 ? (<h5 className="ml-1 my-0">({props.wishListItems.length})</h5>) : ""}
                        </div>
                        <div className="account ml-3">
                            <Link to={`${process.env.PUBLIC_URL}/` + user}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    width="17"
                                    height="17"
                                >
                                    <circle
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        cx="9.9"
                                        cy="6.4"
                                        r="4.4"
                                    />
                                    <path
                                        fill="none"
                                        stroke="#722257"
                                        strokeWidth="1.6"
                                        d="M1.5,19 C2.3,14.5 5.8,11.2 10,11.2 C14.2,11.2 17.7,14.6 18.5,19.2"
                                    />
                                </svg>
                            </Link>
                        </div>
                        <div class="cart cart-separation ml-3">
                            <CartContainer />
                        </div>
                    </div>

                    {/* Mobile side navbar  */}

                    <MobileSideNavbar />

                    {/* Navbar  */}

                    <Navbar navBar={props.navBar} />

                    {/* logo  */}

                    <div className="logo">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            {window.logo.desktop.map((data, i) => (
                                <div key={i}>
                                    <img
                                        className="img-fluid"
                                        src={`${process.env.PUBLIC_URL}` + data.image}
                                        alt={data.alt}
                                    />
                                </div>
                            ))}
                        </Link >
                    </div>
                </div>

                {/* Sticky footer navbar  */}

                <StickyNavbar />
            </div>
        </div>
    )
}

export default HeaderBody
