import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import {
  addToCartAndRemoveWishlist,
  removeFromWishlist,
  addToCart
} from "../../actions";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";


class wishList extends Component {
  changeQty = e => {
    this.setState({ quantity: parseInt(e.target.value) });
  };


  componentDidMount() {
    const products = []
    const value = []
    for (let i = 0; i < this.props.cartItems.length; i++) {
      products.push({
        'name': this.props.cartItems[i].name,
        'id': this.props.cartItems[i].sku,
        'price': this.props.cartItems[i].variants && this.props.cartItems[i].variants[0].offerprice,
        'brand': 'Sunny Diamonds',
        'category': this.props.cartItems[i].value,
        'variants': this.props.cartItems[i].variants && this.props.cartItems[i].variants[0].color,
        'quantity': this.props.cartItems[i].qty
      });
      value.push({
        "value": this.props.cartItems[i].variants && this.props.cartItems[i].variants[0].offerprice
      })
    }
    window.dataLayer = window.dataLayer || [];
    try {
      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'INR',
          'impressions': products
        },
        'value': value
      });
    }
    catch (err) {
      if (window.dataLayer.filter(function (obj) {
        return obj.errorMsg === err.message;
      }).length == 0) {
        window.dataLayer.push({
          'event': 'variable error',
          'errorMsg': err.message
        });
      }
    }
  };




  WishlistimgeSource = item => {
    let images = [];
    let imgeSrc = [];
    let imgI = 0;
    const exts = [".png", ".jpg", ".webp", ".gif"];
    if (item.variant.image) {
      let allImages = item.variant.image;
      images = allImages.split(",");
      images.forEach(function (img) {
        let ext = img.substring(img.lastIndexOf("."));

        if (exts.includes(ext)) {
          imgeSrc[imgI] = img;
          imgI++;
        }
      });
    }
    return imgeSrc[0];
  };

  addAllToCart = () => {

    toast.success("All Items Added to Cart");
    this.props.cartItems.map((items, i) => {
      this.props.addToCartAndRemoveWishlist(items, 1, items.variant);
    });
  }

  validateURL = (url) => {
    const parsed = new URL(global.publicUrl + url)
    return ['https:', 'http:'].includes(parsed.protocol)
  }

  render() {
    const { cartItems, symbol } = this.props;

    // console.log(cartItems)

    return (
      <div>
        <Helmet>
          <title>
            Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds
          </title>
          <meta
            name="description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally
          Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta
            name="keywords"
            content="Buy Diamonds online, Online diamonds shopping, Diamond Jewellery Store, Buy Diamond Rings Online,
          diamond earrings online, diamond pendants online kerala, buy diamond bangles online, buy diamond necklace,
          Buy wedding rings online,  online diamond jewellery India"
          />
          <meta property="og:site_name" content="Sunny Diamonds" />
          <meta property="og:type" content="Summary" />
          <meta
            property="og:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            property="og:image"
            content="https://sunnydiamonds.com/media/logo/stores/1/logo_sunny_1_live.png"
          />
          <meta
            property="og:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta property="og:url" content="https://sunnydiamonds.com/" />
          <meta name="twitter:card" content="Summary" />
          <meta name="twitter:site" content="@SunnyDiamond_4c" />
          <meta name="twitter:creator" content="Sunny Diamonds" />
          <meta
            name="twitter:title"
            content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
          />
          <meta
            name="twitter:description"
            content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
          />
          <meta
            name="twitter:image"
            content="https://sunnydiamonds.com/media/logo/stores/1/logo_sunny_1_live.png"
          />
        </Helmet>

        <Breadcrumb title={"Wishlist"} />
        {cartItems.length > 0 ? (
          <section className="wishlist-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 mt-5">
                  <table className="table cart-table d-none d-md-block bg-white py-5">
                    <thead>
                      <tr className="table-head">
                        <th scope="col">image</th>
                        <th scope="col">product name</th>
                        <th scope="col">metal type</th>
                        <th scope="col">price</th>
                        <th scope="col">action</th>
                        <th></th>
                      </tr>
                    </thead>
                    {cartItems.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <Link
                                to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                              >
                                <img
                                  src={
                                    `${process.env.PUBLIC_URL}` +
                                    this.WishlistimgeSource(item)
                                  }
                                  className="img-fluid"
                                  alt={item.alt}
                                  onError={e => {
                                    e.target.src = `${process.env.PUBLIC_URL
                                      }/assets/images/sunny/no_image/no_images.webp`;
                                  }}
                                />
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}`}
                              >
                                {item.variant.alt}
                              </Link>
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  <p>in stock</p>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color cart_sum">
                                    <b>
                                      {symbol} {item.variant.offerprice}
                                    </b>
                                  </h2>
                                </div>
                                <div className="col-xs-3">
                                  <h2 className="td-color">
                                    <a
                                      href="javascript:void(0)"
                                      className="icon"
                                      onClick={() => this.props.removeFromWishlist(item)}
                                    >
                                      <i className="fa fa-times" />
                                    </a>
                                    <a
                                      href="javascript:void(0)"
                                      className="cart"
                                      onClick={() => this.props.addToCartAndRemoveWishlist(item, 1, item.variant)}
                                    >
                                      <i className="fa fa-shopping-cart" />
                                    </a>
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td>
                              <h4 className="text-center">{item.variant.color}</h4>
                            </td>
                            <td>
                              <h4 className="cart_sum">
                                {symbol} {item.variant.offerprice}
                                {/* <del>
                                  <span className="money cart_sum">
                                    {symbol}
                                    {item.variants[0].price}
                                  </span>
                                </del> */}
                              </h4>
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                className="btn btn-solid font-weight-bold"
                                onClick={() =>
                                  this.props.addToCartAndRemoveWishlist(item, 1, item.variant)
                                }
                              >
                                <i className="fa fa-shopping-cart" /> ADD TO CART
                              </a>
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                className="icon"
                                onClick={() =>
                                  this.props.removeFromWishlist(item)
                                }
                              >
                                <i className="fa fa-times" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>

                  <div className="d-md-none d-block bg-white p-4">
                    {cartItems
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        return (
                          <div key={index} className="mb-2">
                            <div className="row d-flex justify-content-center">
                              <div className="col-4">
                                <Link
                                  to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}?variant_id=${item.selectedVariant.id}` : ''}
                                >
                                  <img
                                    src={
                                      `${process.env.PUBLIC_URL}` +
                                      this.WishlistimgeSource(item)
                                    }
                                    className="cart-image"
                                    alt={item.name}
                                    onError={e => {
                                      e.target.src = `${process.env.PUBLIC_URL
                                        }/assets/images/sunny/no_image/no_images.png`;
                                    }}
                                  />
                                </Link>

                              </div>
                              <div className="col-8">
                                <div className="d-flex justify-content-between">
                                  <Link
                                    to={this.validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}` : ''}
                                    className="ml-4"
                                  >
                                    {item.variant.alt}
                                  </Link>
                                  <span className="td-color mr-3">
                                    <a
                                      href="javascript:void(0)"
                                      className="icon"
                                      onClick={() =>
                                        this.props.removeFromWishlist(item)
                                      }
                                    >
                                      <i className="icon-close" />
                                    </a>
                                    <br />
                                    <a
                                      href="javascript:void(0)"
                                      className="icon"
                                      onClick={() =>
                                        this.props.addToCartAndRemoveWishlist(item, 1, item.variant)
                                      }
                                    >
                                      <i className="fa fa-shopping-cart" />
                                    </a>
                                  </span>
                                </div>
                                <div className="row">
                                  <h3 className="td-color cart_sum ml-5">
                                    <b>{symbol} {item.variant.offerprice}</b>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="row mx-md-5 px-md-5 my-3">
                <div className="col pb-3 text-md-center mt-2 mx-md-5 ">
                  <Link
                    to={`${process.env.PUBLIC_URL}/jewellery/`}
                    data-lng="en"
                    className="btn btn-solid d-block"
                    rel="noopener noreferrer"
                  >
                    <div className="px-0">continue shopping</div>
                  </Link>
                </div>
                <div className="col pb-3 text-md-center mt-2 mx-md-5">
                  <Link
                    to={`${process.env.PUBLIC_URL}/checkout`}
                    data-lng="en"
                    className="btn btn-solid d-block"
                    rel="noopener noreferrer"
                    onClick={this.addAllToCart}
                  >
                    <div className="px-2">
                      <i className="fas fa-lock fa_lock_cart" /> checkout Securely
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 mt-5">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center py-5 my-5">
                      <img
                        src={`${process.env.PUBLIC_URL
                          }/assets/images/sunny/logo/icons/empty-wishlist.png`}
                        className="img-fluid mb-4"
                        alt="wishlist"
                      />
                      <h3>
                        <strong>WishList is Empty</strong>
                      </h3>
                      <h4>Explore more shortlist some items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  cartItems: state.wishlist.list,
  symbol: state.data.symbol
});

export default connect(
  mapStateToProps,
  { addToCartAndRemoveWishlist, removeFromWishlist, addToCart }
)(wishList);
