import React from "react";
import { connect } from "react-redux";

const CategoryDescription = (props) => {

    const sortFilter = (element) => {
        props.filterSort(element.value)
        props.history.push(window.location.pathname + "?sort_by=" + element.value)
    }

    return (
        <div data-uk-grid>
            <div className="uk-width-expand@m  uk-padding-remove">
                <h2 className="uk-h2">{props.categoryTitle}</h2>
                <p className="uk-margin-remove">{props.categoryDescription}</p>
            </div>
            <div className="uk-width-1-3@m  uk-flex uk-flex-middle sort-by">
                <form>
                    <div className="uk-margin">
                        <p className="uk-text-right@m">{props.productsLength} Products</p>
                        <div className="uk-button-group">
                            <div className="uk-button uk-button-default">{window.filterOption.sortings.filter(item => item.value == props.filters.sortBy)[0].name}</div>
                            <div className="uk-inline">
                                <button className="uk-button uk-button-default" type="button">
                                    <span data-uk-icon="icon:plus" />
                                </button>
                                <div data-uk-dropdown="mode: click; pos: bottom-center; target: !.uk-button-group;">
                                    <ul className="uk-nav uk-dropdown-nav">
                                        {window.filterOption.sortings.map((element, i) => (
                                            <li
                                                key={i} className="d-block"
                                                onClick={() => sortFilter(element)}>
                                                <a href="javascript:void(0)">{element.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    filters: state.filters
});
export default connect(mapStateToProps)(CategoryDescription)