import React, { Component } from "react";
import { connect } from "react-redux";
import { getEdu } from "../../../services";

class RingSize extends Component {
  render() {
    const { listMetal } = this.props;
    return (
      <div>
        <div className="row blog-media mt-4">
          <div className="col-xl-12">
            <h2>
              <b>Ring size guide</b>
            </h2>
            <p className="normal-para">
              Buying a ring online can be challenging if you do not know the
              exact size of the intended ring. With the help of our online ring
              size guide, you can easily determine the size of your ring. No
              need to travel to your nearest jeweller or wait for a ring sizer
              to arrive to measure your ring size. You can now easily do it at
              the comfort of your home or your mobile. Here is a simple method
              you can apply to find out your ring size
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            {/* video section */}

            <video
              className="blog-video"
              src="/assets/video/sunny/education/Ring_Size_With_Ruler-1.mp4"
              loop="loop"
              type="video/mp4"
              preload="metadata"
              controls="controls"
              playsInline="playsinline"
            />
            <center>Measure Your Ring Size With A Ruler</center>
          </div>
          <br />
          <div className="col-md-6">
            <video
              className="blog-video"
              src="/assets/video/sunny/education/How_To_Measure_Ring_Size_With_Tape-1.mp4"
              loop="loop"
              preload="metadata"
              controls="controls"
            />
            <center>Measure Your Ring Size With A Tape</center>
          </div>
        </div>
        <div className="row  blog-media">
          <div className="col-md-6">
            {/* chart section  */}

            <h3 className=" mt-4 py-4 normal-para">FINGER RING (METAL ROD)</h3>
            <table className="uk-table uk-table-striped">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Size</th>
                  <th>Unit</th>
                  <th>mm in Steel Ring</th>
                </tr>
              </thead>
              <tbody>
                {listMetal.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.size}</td>
                    <td>{item.unit}</td>
                    <td>{item.mm}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <h3 className="mt-4 py-4 normal-para">FINGER RING (STEEL RING)</h3>
            <table className="uk-table uk-table-striped">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Size</th>
                  <th>mm in Steel Ring</th>
                </tr>
              </thead>
              <tbody>
                {listMetal.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.size}</td>
                    <td>{item.mm}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row ml-2">
          <a
            className="btn btn-solid"
            href="/assets/docs/ring_size_chart.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Chart
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  listMetal: getEdu(state.data.products, "Metal Rod")
});

export default connect(mapStateToProps)(RingSize);
