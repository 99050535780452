import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import Loader from "../../common/spinner"

function UserDetails(props) {

  const [loadingDetails, setLoadingDetails] = useState(false);
  const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  const [validationTrace, setValidationTrace] = useState({});
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  })

  useEffect(()=>{
    // console.log("User Details Got Change in validationTrace",props.validationTrace)
    setValidationTrace(props.validationTrace);
  },[props.validationTrace])

  useEffect(() => {
    // console.log("User Details Changed", userDetails)
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [userDetails])

  useEffect(() => {
    // console.log("VOData Changed", props.VOData)
    if (props.VOData.order)
      if (props.VOData.order.user_details)
        setUserDetails({
          first_name: props.VOData.order.user_details.virtual_order_profile_first_name,
          last_name: props.VOData.order.user_details.virtual_order_profile_last_name,
          email: props.VOData.order.user_details.virtual_order_profile_email,
          phone: props.VOData.order.user_details.virtual_order_profile_phone,
        })
  }, [props.VOData])

  // console.log("Render User Details ", userDetails)

  const userDetailSync = () => {
    SDClient.endpoint("virtual_order_set_user_details", { ...userDetails }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({});
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          }
        }
      },
      console.log
    )
  }

  const debounce = () => {
    if (debounceLock) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      // console.log("DEBOUNCE CALLBACK!")
      userDetailSync();
      setDebounceLock(false);
    }, 2000));
    setDebounceLock(true);
  }

  const showUserDetailsForm = () => {

    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">First Name</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={userDetails.first_name}
            onChange={(e) => { setUserDetails({ ...userDetails, first_name: e.target.value }) }}
            onBlur={() => props.validator.current.showMessageFor('first_name')}
          />
          {validationTrace.first_name ? (<small className="text-uppercase text-danger"> Please enter your first name. </small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Last Name</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={userDetails.last_name}
            onChange={(e) => { setUserDetails({ ...userDetails, last_name: e.target.value }) }}
            onBlur={() => props.validator.current.showMessageFor('last_name')}
          />
          {validationTrace.last_name ? (<small className="text-uppercase text-danger"> Please enter your last name. </small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Email Address</div>
          <input
            type="email"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={userDetails.email}
            onChange={(e) => { setUserDetails({ ...userDetails, email: e.target.value }) }}
            onBlur={() => props.validator.current.showMessageFor('email')}
          />
          {validationTrace.email ? (<small className="text-uppercase text-danger"> Please use a valid email address. </small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Phone Number</div>
          <input
            type="tel"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={userDetails.phone}
            onChange={(e) => { setUserDetails({ ...userDetails, phone: e.target.value }) }}
            onBlur={() => props.validator.current.showMessageFor('phone')}
          />
          {validationTrace.phone ? (<small className="text-uppercase text-danger"> Please use a valid phone number. </small>) : null}
        </div>
      </div>
    )
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">First Name</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Last Name</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Email Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Phone Number</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white border p-4 mb-4">
      {/* {props.userDetails && props.userDetails.errors && props.userDetails.errors
                .map((error, i) => (
                    < div className="alert alert-danger" role="alert">
                        {error.text}
                    </div>
                ))
            } */}
      <div className="d-flex justify-content-between">
        <p className="text-secondary text-uppercase">User Details</p>
        <div className="mt-1">
          {debounceLock || !props.VOData.order ? (<Loader />) : ""}
        </div>
      </div>
      {props.VOData.order ? showUserDetailsForm() : showLoadingInputs()}
      {/* <div className="form-group ">
        <div className="text-uppercase field-label">Email</div>
        <input
          type="email"
          name="virtual_order_profile_email"
          className="form-control"
          value={props.userDetails.virtual_order_profile_email}
          // onChange={(e) => props.handleChange(e, 1)}
          onBlur={() => props.validator.current.showMessageFor('email')}
        />
        {props.validator.current.message("virtual_order_profile_email", props.userDetails.email, "required|email")}
      </div>
      <div className="form-group ">
        <div className="text-uppercase field-label">Phone</div>
        <input
          type="tel"
          name="virtual_order_profile_phone"
          className="form-control"
          value={props.userDetails.virtual_order_profile_phone}
          // onChange={(e) => props.handleChange(e, 1)}
          onBlur={() => props.validator.current.showMessageFor('phone')}
        />
        {props.validator.current.message("virtual_order_profile_phone", props.userDetails.phone, "required|phone|min:10|max:10")}
      </div> */}
    </div >
  );
}

export default UserDetails;
