import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import ReactPixel from 'react-facebook-pixel';
import { getContact, getCategory } from "../../../services/index";
import { connect } from "react-redux";
import Seo from "../common/seo";
import { Link } from "react-router-dom";
import Banner from "../common/banner";
import SimpleReactValidator from "simple-react-validator";
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";

import {
  postAllCustomerServices,
  // postAccessZohoToken,
  postZohoDetailsSubmit
} from "../../../actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Promise from "../../new_home_page/promise";

class CustomerService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Phone: "",
      First_Name: "",
      Last_Name: "",
      Email: "",
      Category: "",
      Notes: "",
    };
    this._reCaptchaRef = React.createRef();
    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  // componentDidMount() {
  //   window.fbq('track', 'Contact');
  // }

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  onRecaptcha = (e) => {
    ReactGA.event({
      category: 'ecommerce',
      action: 'Contact',
      label: 'Contact'
    })

    e.preventDefault();
    if (this.validator.allValid()) {
      this._reCaptchaRef.current.executeAsync();
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSubmit = (code) => {
    var customer_data = {
      First_Name: this.state.First_Name,
      Last_Name: this.state.Last_Name,
      Phone: this.state.Phone,
      Email: this.state.Email,
      Category: this.state.Category,
      Notes: this.state.Notes
    }

    if (code) {
      // this.props.postZohoDetailsSubmit(details, module);
      this.props.postAllCustomerServices(customer_data);
      toast.success("Thank you We Will Get Back To You Soon");
      this.setState({
        Phone: "",
        First_Name: "",
        Last_Name: "",
        Email: "",
        Category: "",
        Notes: ""
      });
      this.validator.hideMessages();
      this._reCaptchaRef.current.reset();
    } else {
      // Invalid session
    }
  };

  render() {
    const { contact, head } = this.props;

    if (head.length > 0) {
      return (
        <div>
          {/* seo section  */}
          <Seo type="customer-service-head" />
          <Breadcrumb title={head[0].banner.title} />
          <section className=" contact-page">
            <div className="uk-section sd-inner-content uk-padding-small">
              <div className="uk-container uk-container-large">

                <div data-uk-grid>

                  {/* banner section  */}
                  <div className="mt-2">
                    <Banner type="customer-service-head" move="false" text_color="#722257" title_background={true} />
                  </div>

                  {/* Store locator section */}
                  <div className="uk-width-1-3@m">
                    <div className="contact-right">
                      <ul>
                        <li>
                          <div className="contact-icon ">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="tel:+919961870099"
                            >
                              <span data-uk-icon="receiver"></span>
                              <h6 className="d-none d-md-block">Contact Us</h6>
                            </a>
                          </div>
                          <div className="media-body pt-md-2 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="tel:+919961870099"
                            >
                              <p className="para-one">{contact[0].phone1}</p>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <span data-uk-icon="location" style={{color: "#722257"}}></span>
                            <h6 className="d-none d-md-block">Address</h6>
                          </div>
                          <div className="media-body">
                            <p className="para-one">{contact[0].address}</p>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="mailto:customerservice@sunnydiamonds.com"
                            >
                              <span data-uk-icon="mail"></span>
                              <h6 className="d-none d-md-block">Email</h6>
                            </a>
                          </div>
                          <div className="media-body pt-md-3 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="mailto:customerservice@sunnydiamonds.com"
                            >
                              <p className="para-one">{contact[0].email1}</p>
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="contact-icon">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://api.whatsapp.com/send?phone=919961870099"
                            >
                              <span data-uk-icon="whatsapp"></span>
                              <h6 className="d-none d-md-block">WhatsApp</h6>
                            </a>
                          </div>
                          <div className="media-body pt-md-3 contact">
                            <a
                              className="media-body"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://api.whatsapp.com/send?phone=919961870099"
                            >
                              <p className="para-one">{contact[0].whatsapp1}</p>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <br />
                    <div className="col-md-12 pb-3 text-center">
                      <Link
                        to={`${process.env.PUBLIC_URL}/locations`}
                        data-lng="en"
                        className="btn btn-solid "
                      >
                        Store locator
                      </Link>
                    </div>
                  </div>

                  {/* Form section  */}
                  <div className="uk-width-expand@m store-form">
                    <form
                      className="theme-form p-4 border bg-white"
                      // onSubmit={this.handleSubmit}
                      onSubmit={this.onRecaptcha}
                    // method="post"
                    >
                      <div className="mb-4">
                        <h2>Send a Message</h2>
                        <p className="normal-para">
                          All questions will be answered within one business day.
                        </p>
                      </div>
                      <div className="form-row">
                        <div className="col-md-6">
                          <label htmlFor="First_Name" className="uk-form-label">First Name</label>
                          <input
                            type="text"
                            className="uk-input"
                            name="First_Name"
                            placeholder="First name"
                            value={this.state.First_Name}
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "First_Name",
                            this.state.First_Name,
                            "required|alpha_space"
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Last_Name" className="uk-form-label">Last Name</label>
                          <input
                            type="text"
                            name="Last_Name"
                            className="uk-input"
                            placeholder="Last name"
                            value={this.state.Last_Name}
                            onChange={this.setStateFromInput}
                          />
                          {this.validator.message(
                            "Last_Name",
                            this.state.Last_Name,
                            "required|alpha_space"
                          )}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="email" className="uk-form-label">Email</label>
                          <input
                            type="email"
                            name="Email"
                            className="uk-input"
                            placeholder="Email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            title="Enter a valid mail id"
                            value={this.state.Email}
                            onChange={this.setStateFromInput}
                          // onBlur={() => this.validator.showMessageFor('Email')}
                          />
                          {this.validator.message(
                            "Email",
                            this.state.Email,
                            "required|email"
                          )}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="Phone" className="uk-form-label">Phone Number</label>
                          <input
                            type="tel"
                            className="uk-input"
                            name="Phone"
                            id="Phone"
                            placeholder="Phone Number"
                            pattern="[0-9]{10}"
                            title="Enter a valid phone number(10 digit number)"
                            value={this.state.Phone}
                            onChange={this.setStateFromInput}
                          // onBlur={() => this.validator.showMessageFor('Phone')} 
                          />
                          {this.validator.message(
                            "Phone",
                            this.state.Phone,
                            "required|phone|min:10|max:12"
                          )}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="subject" className="uk-form-label">Enquiry Type</label>
                          <select
                            className="uk-select"
                            name="Category"
                            value={this.state.Category}
                            onChange={this.setStateFromInput}
                          // onBlur={() => this.validator.showMessageFor('Category')} 
                          >
                            <option value="">Select type</option>
                            <option value="jewellery_repair">
                              Jewellery Repair
                            </option>
                            <option value="customization_of_jewellery">
                              Customization of Jewellery
                            </option>
                            <option value="order_and_shipping">
                              Order And Shipping
                            </option>
                            <option value="any_other_enquiries">
                              Any other enquiries
                            </option>
                          </select>
                          {this.validator.message(
                            "Category",
                            this.state.Category,
                            "required"
                          )}
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="review">Write Your Message</label>
                          <textarea
                            className="uk-textarea"
                            name="Notes"
                            placeholder="Write Your Message"
                            rows="6"
                            maxLength="150"
                            value={this.state.Notes}
                            onChange={this.setStateFromInput}
                          />
                        </div>

                        <ReCAPTCHA
                          ref={this._reCaptchaRef}
                          size="invisible"
                          sitekey={global.reCaptcha}
                          onChange={this.handleSubmit}
                        />

                        <div className="col-md-12">
                          <button type="submit" className="btn btn-solid">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section >
          {/*Sunny diamonds features section*/}
          < div className="mt-5 pt-2" >
            <Promise />
          </div >
        </div >
      );
    } else return null;
  }
}

const mapStateToProps = state => ({
  contact: getContact(state.data.products, "kochi"),
  head: getCategory(state.data.products, "customer-service-head")
});

export default connect(
  mapStateToProps,
  {
    postAllCustomerServices,
    postZohoDetailsSubmit
    // postAccessZohoToken,
  }
)(CustomerService);
