import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { actUserLogout } from "../../../actions";



const ActivePlan = (props) => {


    const [navClose, setNavClose] = useState({ left: "-410px" })

    const openNav = () => {
        setNavClose({ navClose: { left: "0px" } });
    }
    const closeNav = () => {
        setNavClose({ navClose: { left: "-410px" } });
    }

    const logoutSubmit = () => {
        props.actUserLogout();
    };

    return (
        <section className="blog-detail-page section-b-space ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        <div
                            className="account-sidebar"
                            onClick={openNav.bind(this)}
                        >
                            <a className="popup-btn">my account</a>
                        </div>
                        <div
                            className="dashboard-left mt-2"
                            style={navClose}
                        >
                            <div
                                className="collection-mobile-back"
                                onClick={closeNav.bind(this)}
                            >
                                <span className="filter-back">
                                    <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                                    back
                                </span>
                            </div>
                            <div className="block-content">
                                <ul>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                                            My Orders
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                                            Edit Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                                            DFE Active Plan
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/change-password`}
                                        >
                                            Change Password
                                        </Link>
                                    </li>
                                    <li className="last">
                                        <a onClick={logoutSubmit}>Log Out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-10">
                        <div className="dashboard-right mt-2 bg-white">
                            <div className="dashboard">
                                <div className="page-title">
                                    <h2>Active Plan</h2>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Paln id: SDDFE-AB123</th>
                                            <th scope="col"></th>
                                            <th scope="col">Total Balance : ₹ 1,000</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>₹ 1000</td>
                                            <td>Paid online on Jun 25, 2022</td>
                                            <td className="d-flex align-items-center">
                                                PAID
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                    width="20" height="20"
                                                    viewBox="0 0 48 48"
                                                    style={{ fill: "#000000" }}>
                                                    <linearGradient id="I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1"
                                                        x1="9.858" x2="38.142" y1="9.858" y2="38.142"
                                                        gradientUnits="userSpaceOnUse">
                                                        <stop offset="0" stopColor="#21ad64"></stop>
                                                        <stop offset="1" stopColor="#088242"></stop>
                                                    </linearGradient>
                                                    <path fill="url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)"
                                                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z">
                                                    </path>
                                                    <path d="M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z" opacity=".05">
                                                    </path>
                                                    <path d="M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z" opacity=".07">
                                                    </path>
                                                    <path fill="#fff" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z">
                                                    </path>
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Jul 25, 2022</td>
                                            <td>
                                            <button
                                                    type="button"
                                                    className="btn btn-solid">
                                                    PAY NOW
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Aug 25, 2022</td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">4</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Sep 25, 2022</td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Oct 25, 2022</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">6</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Nov 25, 2022</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">7</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Dec 25, 2022</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">8</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Jan 25, 2023</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">9</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Feb 25, 2023</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Mar 25, 2023</td>
                                            <td>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">11</th>
                                            <td>₹ 1000</td>
                                            <td>Due on Apr 25, 2023</td>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { actUserLogout })(ActivePlan);