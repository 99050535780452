import { THEME_LAYOUT } from "../constants/ActionTypes";


const initialState = {
    themeColor: 'light'
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case THEME_LAYOUT:
            return { ...state,
                themeColor: action.themeColor };
        default:
            return state;
    }
};
export default themeReducer;