import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actUserLogout, actOrderTracking, actUserLogin } from "../../actions";
import TimeOut from "../../sessions/timeOut";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClose: { left: "-410px" }
    };
  }
  componentDidMount() {
    if (
      sessionStorage.getItem("user_id") === "" ||
      sessionStorage.getItem("token") === "" ||
      sessionStorage.getItem("user_type") !== "CUSTOMER"
    ) {
      this.props.history.push("/login");
    }
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { left: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { left: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { left: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { left: "-410px" } });
  }

  logoutSubmit = event => {
    this.props.actUserLogout();
  };

  render() {

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />
        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3">
                <div
                  className="account-sidebar"
                  onClick={this.openNav.bind(this)}
                >
                  <a className="popup-btn btn btn-solid  ">my account</a>
                </div>
                <div
                  className="dashboard-left mt-2"
                  style={this.state.navClose}
                >
                  <div
                    className="collection-mobile-back"
                    onClick={this.closeNav.bind(this)}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="block-content">
                    <ul>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                          Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                          My Orders
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                          Edit Profile
                        </Link>
                      </li>
                      {/* <li>
                          <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                            DFE Active Plan
                          </Link>
                        </li> */}
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/change-password`}>
                          Change Password
                        </Link>
                      </li>
                      <li className="last">
                        <a onClick={this.logoutSubmit}>Log Out</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-md-9">
                <div className="dashboard-right my-4 ">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>My Dashboard</h2>
                    </div>
                    <div className="welcome-msg">
                      <p className="normal-para">Hello, {sessionStorage.getItem("user_name")} </p>
                      <TimeOut logout={this.logoutSubmit} msg={null} />
                      <p className="normal-para">
                        From your My Account Dashboard you have the ability to
                        view a snapshot of your recent account activity and
                        update your account information. Select a link to
                        view or edit information.
                      </p>
                    </div>
                    <div className="account-image mt-3">
                      <img
                        src="/assets/images/sunny/dashboard/dashboard_ad.png"
                        className=" img-fluid d-md-block d-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  type_user: state.data.user_type
});

export default connect(
  mapStateToProps,
  { actUserLogout, actOrderTracking, actUserLogin }
)(Dashboard);
