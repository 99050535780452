import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import { getAboutus, serCms } from "../../../services/index";
import {getCms} from "../../../actions";
import Features from "./features";
import store from "../../../store";
import { v4 as uuidv4 } from "uuid";
import Seo from "../common/seo";
import Banner from "./cms/banner";
import FeatureIn from "../common/featurein";
import CompanyValues from "./cms/companyValues";
import Leadership from "./cms/leadership";
import HTMLLoader from "./cms/html-loader"
import Layout from "./cms/layout.json"

const Components = {
  HTMLLoader,
  Seo,
  Breadcrumb,
  Banner,
  Features,
  FeatureIn,
  CompanyValues,
  Leadership,
};

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeVal: 0
    };
  }

  componentDidMount() {
   this.props.getCms()
  }

  render() {
    const { about_json } = this.props;
    return (
      <>
        {Layout.map((widget, i) => { return React.createElement(Components[widget.component], {...widget.props,'key':i}) })}
      </>
    );
  }
}

const mapStateToProps = state => ({
  about_json: serCms(state.data.products)
});

export default connect(mapStateToProps,{getCms})(AboutUs);
