import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

class VariantOptions extends Component {
    constructor(props) {
        super(props);
        //this.loading = false;
        this.state = {
            color: '',
            size: '',
        };
    }

    componentDidMount() {
        this.setState({ color: this.props.variants[0].color })
        this.setState({ size: this.props.variants[0].default_size })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.color !== prevState.color) {
            const nColor = this.state.color;
            const nSize = this.state.size;
            var sizeArray = this.props.variants.filter(function (vari) { return vari.color == nColor; });

            // sizeArray.filter((vari) => (nArray = vari.size == nSize ? true : false));

            const nArray = sizeArray.some(vari => vari.size == nSize);
            if (!nArray) {
                this.setState({ size: sizeArray[0].default_size })
            }
        }
    }

    colorHandle(event, vari) {
        this.setState({ color: vari.color })

        let nSize = this.state.size;
        var sizeArray = this.props.variants.filter(function (vari) { return vari.color == vari.color; });
        const nArray = sizeArray.some(vari => vari.size == nSize);
        if (!nArray) {
            this.setState({ size: sizeArray[0].default_size })
            nSize = sizeArray[0].default_size;
        }
        const selectedItem = this.props.variants.filter(item => (item.color == vari.color && item.size == nSize))
        if (selectedItem[0]) {
            this.props.changeVariant(selectedItem[0]);
        } else {
            this.props.changeVariant(vari);
        }
        var elems = document.querySelectorAll(".color-variant ul li");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
        event.target.classList.add("active");
    }

    sizeHandle(event) {
        this.setState({ size: event.target.value })
        const selectedItem = this.props.variants.filter(item => (item.color == this.state.color && item.size == event.target.value))
        this.props.changeVariant(selectedItem[0]);
    }



    render() {
        const { category, variants } = this.props;
        const { color, size } = this.state;
        const { compare } = Intl.Collator('en-US');

        // const colorArray = [...new Set(variants.map(item => item.color))];
        const mainArray = [...new Map(variants.filter(item => (item.size == item.default_size)).map(item => [item.color, item])).values()];
        const allArray = [...new Map(variants.map(item => [item.color, item])).values()];

        var colorArray = mainArray.concat(allArray.filter(({ color }) => !mainArray.find(vari => vari.color == color)));

        // const colorArray = [...new Map(variant.filter(item => (item.size == item.default_size)).map(item => [item.color, item])).values()];

        return (
            <>
                <div className="col-md-12 col-sm-12 p-0">
                    <div className="row py-2 mb-1 mx-2 mx-md-0">
                        <div className="col-md-6 col-12">
                            <div className="row">
                                <div className="col-12 d-flex pb-2 justify-content-center">
                                    <span className="instock-cls">Color</span>
                                </div>
                                <div className="col-12 d-flex pt-3 product-description justify-content-center">
                                    <div className="color-variant color-tab">
                                        <ul>
                                            {colorArray.map((vari, i) => {
                                                if (vari.color) {
                                                    return (
                                                        <li
                                                            className={(i == 0) ? vari.color + " active" : vari.color}
                                                            key={i}
                                                            title={vari.color}
                                                            onClick={(e) => this.colorHandle(e, vari)}
                                                        />

                                                    );
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {variants[0].size ?
                            <div className="col-md-6 col-12 mt-md-0 mt-3">
                                <div className="row">
                                    <div className="col-12 d-flex pb-2 justify-content-between">
                                        <span className="instock-cls">Size</span>
                                        <span>
                                            {
                                                (category == 'rings') ? <span className="title-font"><Link to="/education/size-chart/ring-size-guide" target="_blank" rel="noopener noreferrer"><u>Don't Know Your Rings Size?</u></Link></span>
                                                    : (category == 'bangles') ? <span className="title-font"><Link to="/education/size-chart/bangle-and-bracelet-size-guide" target="_blank" rel="noopener noreferrer"><u>Don't Know Your Bangles Size?</u></Link></span>
                                                        : (category == 'bracelets') ? <span className="title-font"><Link to="/education/size-chart/bangle-and-bracelet-size-guide" target="_blank" rel="noopener noreferrer"><u>Don't Know Your Bracelets Size?</u></Link></span>
                                                            : <span className="title-font"><Link to="/education/size-chart/ring-size-guide" target="_blank" rel="noopener noreferrer"><u>View Jewellery Size Chart</u></Link></span>
                                            }

                                        </span>
                                    </div>
                                    <div className="col-12 d-flex pt-3 d-flex flex-column">
                                        {variants[0].size ?
                                            <div className="d-flex flex-column align-items-center">
                                                <select
                                                    // style={{ width: '244px' }}
                                                    value={size}
                                                    // defaultValue={size}
                                                    className="form-control"
                                                    name="size"
                                                    onChange={(e) => this.sizeHandle(e)}
                                                // onBlur={() => this.validator.showMessageFor('Category')}
                                                >
                                                    {/* <option value="" data-vari="">Select a Size</option> */}
                                                    {variants.filter(function (o) { return o.color == color; }).sort((a, b) => compare(a.size, b.size)).map((vari, i) => (
                                                        <option key={i} value={vari.size}>{vari.size} mm</option>
                                                    ))}
                                                </select>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div> : ''}
                    </div>
                </div>
            </>
        );
    }
}

export default VariantOptions;
