import React, { Component } from "react";
import Marquee from "react-easy-marquee";
import { connect } from "react-redux";
import { getAboutus } from "../../../services/index";


class FeatureIn extends Component {
    render(){
        const { featurein_data } = this.props;

        return(
            <section className="testimonial-feature small-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 mt-1">
                  <h2 className="feature-heading2">Featured In</h2>
                </div>
                <div className="col-12">
                  <Marquee
                    duration={10000}
                    background="#fff"
                    height="5rem"
                    width="100%"
                    axis="X"
                    align="center"
                    reverse={true}
                    pauseOnHover={true}
                  >
                    {featurein_data.map((data, i) => (
                      <a
                        href={data.links}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={i}
                      >
                        <img
                          src={data.image}
                          className="featuredin"
                          alt={data.titles}
                        />
                      </a>
                    ))}
                  </Marquee>
                </div>
              </div>
            </div>
          </section>
        )
    }
}
const mapStateToProps = state => ({

    featurein_data: getAboutus(state.data.products, "about-us-feature"),
  });
  
  export default connect(mapStateToProps)(FeatureIn);