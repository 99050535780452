import React, { useEffect, useState } from "react";
import { connect } from "react-redux";


import { getNews } from "../../../services/index";


import Breadcrumb from "../../common/breadcrumb";
import Banner from "../../layouts/common/banner";
import Seo from "../../layouts/common/seo";
import LatestNews from "./latest-news";
import Promise from "../../new_home_page/promise";
import NewsList from "./news-list";
import MobileLatestNews from "./mobile-latest-news";


const NewsPage = (props) => {

  const { mainNews, popularNews, bannerNews } = props;

  if (mainNews.length > 0) {
    return (
      <div>
        {/* banner section  */}

        <Seo type="news-head" />
        <Breadcrumb title={bannerNews[0].name} />

        <div className="uk-section sd-inner-content uk-padding-small">
          <div className="uk-container uk-container-large">
            {/* banner section  */}
            <Banner type="news-head" move="false" title_background={true} />


            <MobileLatestNews
              popularNews={popularNews}
            />
            <div data-uk-grid>
              {/* Popular Blog  */}
              <LatestNews
                popularNews={popularNews}
              />
              <NewsList
                mainNews={mainNews}
              />
            </div>

          </div>
        </div>

        {/*Sunny diamonds features section*/}
        < div className="mt-5 pt-2" >
          <Promise />
        </div >
        {/* end of sunny diamonds features section */}
      </div >
    );
  } else {
    return 0;
  }
}

const mapStateToProps = state => ({
  mainNews: getNews(state.data.products, "news"),
  popularNews: getNews(state.data.products, "sidenews"),
  bannerNews: getNews(state.data.products, "new_banner")
});

export default connect(mapStateToProps)(NewsPage);
