import React, { Component } from "react";

class Leadership extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <section className="leadership small-section-lead">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="ldshp-head">Leadership Team</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                      <a
                        href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          className="img-fluid"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/sunny/about_us/sunny _new.webp`}
                          alt="P.P. Sunny"
                        />
                      </a>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5 p-0 mt-5">
                      <a
                        href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <h4 className="leadership_text">P.P. Sunny</h4>
                        <p className="leadership_text">
                          Founder & CMD
                          <br />
                          Graduate from GIA
                          <br />
                          <b>Linked</b>
                          <i className="fab fa-linkedin" aria-hidden="true" />
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-5 p-0 mt-5">
                      <a
                        href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <h4 className="leadership_text" style={{textAlign:'right'}}>Prem Sunny</h4>
                        <p className="leadership_text" style={{textAlign:'right'}}>
                          Director of Operations
                          <br />
                          Graduate from GIA
                          <br />
                          <b>Linked</b>
                          <i className="fab fa-linkedin" />
                        </p>
                      </a>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                      <a
                        href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          className="img-fluid"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/images/sunny/about_us/prem_new.webp`}
                          alt="Prem Sunny"
                        />
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default (Leadership);
