import React from "react";
import { Link } from "react-router-dom";

import './css/navigation.css'
// import './css/navbar.css'

const Navbar = (props) => {

    const handleDropDownNavBar = (list, i) => {
        return (
            <li key={i} className="dropdown">
                <Link to={`${process.env.PUBLIC_URL}/jewellery`} className="dropbtn">
                    {list.heading}
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <polyline
                            fill="none"
                            stroke="#000"
                            strokeWidth="1.5"
                            points="16 7 10 13 4 7"
                        />
                    </svg>
                </Link>
                <div className="dropdown-content dropdown-content_links">
                    <div className="dropdown-expand">
                        <div className="menu-list-row">
                            <ul className="menu__list-links-title">
                                {list.links.map((list, i) => (
                                    <li key={i} className="menu__list-links-title-link content-active">
                                        <Link to={`${process.env.PUBLIC_URL}/` + list.link} className="togglebtn">{list.name}</Link>
                                        <div className="dropdown-content">
                                            <div className="dropdown-expand">
                                                <div className="menu__list-expand">
                                                    <div className="menu__list-expand-col menu__list-expand-col-1">
                                                        <ul>
                                                            {list.subCategoryCol1.map((list, i) => (
                                                                <li><Link to={`${process.env.PUBLIC_URL}/` + list.link}> {list.category}</Link></li>
                                                            ))}
                                                        </ul >
                                                    </div >
                                                    <div className="menu__list-expand-col menu__list-expand-col-2">
                                                        <ul>
                                                            {list.subCategoryCol2.map((list, i) => (
                                                                <li><Link to={`${process.env.PUBLIC_URL}/` + list.link}> {list.category}</Link></li>
                                                            ))}
                                                        </ul >
                                                        <Link to={`${process.env.PUBLIC_URL}/jewellery/earrings`} className="menu__list-view-all Earring">Shop All Earrings</Link>
                                                    </div >
                                                    <div className="menu__list-expand-col menu__list-expand-col-3">
                                                        <div className="img">
                                                            <img src={`${process.env.PUBLIC_URL}/` + list.src} alt="" />
                                                        </div>
                                                        <p>Some thing in life always fit perfectly.</p>
                                                        <Link to={`${process.env.PUBLIC_URL}/jewellery`} className="menu__list-view-all Jewellery">View All Jewellery</Link>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >
                                    </li >
                                ))}
                            </ul >
                        </div >
                    </div >
                </div >
            </li >
        )
    }
    const handleLinkNavBar = (list, i) => {
        return (
            <li key={i} className="navlist">
                <Link to={`${process.env.PUBLIC_URL}/` + list.link} className={list.tag == "animate" && "ribbon"}>{list.heading}</Link>
            </li>
        )
    }
    const handleAnchorNavBar = (list, i) => {
        return (
            <li key={i}>
                <a href={list.href} target="_blank" rel="noopener noreferrer" className="dropdown">
                    {list.heading}
                </a>
            </li>
        )
    }

    return (
        <div className="navigation topnav p-0 pb-md-2" id="myTopnav">
            <ul>
                {props.navBar.map((list, i) => {
                    if (list.type == "dropdown")
                        return (
                            handleDropDownNavBar(list, i)
                        )
                    else if (list.type == "link")
                        return (
                            handleLinkNavBar(list, i)
                        )
                    else if (list.type == "anchor")
                        return (
                            handleAnchorNavBar(list, i)
                        )
                })}
            </ul>

            {/* Mobile sidebar button  */}

            <a
                className="icon"
                uk-navbar-toggle-icon=""
                uk-toggle="target: #offcanvas-nav-primary"
                aria-expanded="false"
            />
        </div>
    )
}

export default Navbar