import React from "react";

const Banner = (props) => {
    return (
        <div
            className="uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-visible@s"
            data-src={props.bannerImage}
            data-uk-img="">
        </div>
    )
}
export default Banner