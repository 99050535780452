import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getCategory } from "../../../../services";

import { v4 as uuidv4 } from "uuid";
import DOMPurify from 'dompurify';

import Accordion from "react-bootstrap/Accordion";


const DfeFaq = (props) => {

    const { dfe_faq } = props;

    return (
        <>
            <section>
                <div className="container px-3">
                    <div className="row">
                        <div className="col-12">
                            <Accordion defaultActiveKey="0" alwaysOpen={false}>
                                {dfe_faq.map((item, i) => (
                                    <div
                                        key={uuidv4()}
                                        className="col-xl-12"
                                        id={item.heading}
                                    >
                                        {item.heading ? (
                                            <h3 className="accord-head">{item.heading}</h3>
                                        ) : (
                                            " "
                                        )}
                                        <Accordion.Item eventKey={item.id}>
                                            <Accordion.Header>
                                                {
                                                    <h4 className="accordian-question">
                                                        {item.question}
                                                    </h4>
                                                }
                                            </Accordion.Header>
                                            <Accordion.Body className="dark-accordian">
                                                <p className="accordian-answer" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.answer) }}></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = state => ({
    dfe_faq: getCategory(state.data.products, "dfe"),
});

export default connect(mapStateToProps)(DfeFaq);