import React from "react";



import Breadcrumb from "../../common/breadcrumb";
import Seo from "../../layouts/common/seo";
import Banner from "../../layouts/common/banner";
import TimeLine from "./timeline";
import FeaturedIn from "../../new_home_page/featured_in/index";
import ServiceLayout from "./Serivce_layout";
import Leadership from "./Leadership";
import Promise from "../../new_home_page/promise/index";

const NewAboutUs = (props) => {

    const { themeColor, about_data, service_data } = props;

    return (
        <div>
            {/* seo section  */}
            <Seo type="about-us-head" />

            <Breadcrumb title="About us" />

            {/*about section*/}

            <section>
                <div className="uk-section sd-inner-content uk-padding-small">
                    <div className="uk-container uk-container-large">


                        <Banner type="about-us-head" move="false" />

                        {/* Timeline */}
                        <TimeLine />

                        {/* Features in */}
                        <FeaturedIn />

                        {/* ServiceLayout */}
                        <ServiceLayout />

                        {/*Leadership*/}
                        <Leadership />
                    </div>
                </div>

                {/*Sunny diamonds features section*/}
                <Promise />

            </section>
        </div>
    );
}



export default NewAboutUs;
