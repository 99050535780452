import React from "react";

import { Link } from "react-scroll";

import { v4 as uuidv4 } from "uuid";

const FaqSidepanel = (props) => {

    const validateURL = (url) => {
        const parsed = new URL(global.publicUrl + url)
        return ['https:', 'http:'].includes(parsed.protocol)
      }

    return (
        <div className="uk-width-auto@m uk-visible@l">
            <div data-uk-sticky="start: 500; end: 4100" className="uk-position-z-index">
                <h2>FAQ</h2>
                <div>
                    <ul className="uk-tab-left" data-uk-tab>
                        {props.card3
                            .filter(data => data.heading)
                            .map((item, i) => (
                                <Link
                                    key={uuidv4()}
                                    to={validateURL(`${item.heading}`) ? `${item.heading}` : ''}
                                    spy={true}
                                    smooth={true}
                                    offset={-275}
                                    activeClass="active"
                                >
                                    <li className="uk-active uk-padding-small">
                                        <p className="normal-para">{item.heading}</p>
                                    </li>
                                </Link>

                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FaqSidepanel;