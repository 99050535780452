import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { postAllOrderdetails } from "../../actions";
import Breadcrumb from "../common/breadcrumb";
import store from "../../store";

class OrderResponse extends Component {

  componentDidMount() {
    if (!this.props.orderData) { this.props.history.push("/trending") }
    else {
      if (this.props.match.params.id == "success") {
        if (this.props.orderData.items) {
          const products = []
          for (let i = 0; i < this.props.orderData.items.length; i++) {
            products.push({
              'name': this.props.orderData.items[i].name,
              'id': this.props.orderData.items[i].sku,
              'price': this.props.orderData.items[i].sum,
              'brand': 'Sunny Diamonds',
              'category': this.props.orderData.items[i].variants[0].color,
              'variant': this.props.orderData.items[i].color,
              'quantity': this.props.orderData.items[i].qty,
              'coupon': ''
            });
          }
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'transaction',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': this.props.orderData.order_id,
                  'affiliation': 'Online Store',
                  'revenue': this.props.orderData.amount / 100,
                  'tax': '0.00',
                  'shipping': '0.00',
                },
                'products': products,
              }
            }
          });

          const content_name = []
          const content_ids = []
          for (let i = 0; i < this.props.orderData.items.length; i++) {
            content_ids.push(
              this.props.orderData.items[i].sku
            );
            content_name.push(
              this.props.orderData.items[i].name
            )
          }
          // window.fbq('track', 'Purchase', { content_ids: content_ids, value: this.props.orderData.amount / 100, content_name: content_name, currency: "INR", event_time: new Date().getTime() });


          store.dispatch({ type: "RECEIVE_ORDER_ID", payload: "" });
          store.dispatch({ type: "REMOVE_ALL_CART" });
        } else {
          this.props.history.push("/trending")
        }
      }
    }
  }

  render() {
    let response = this.props.match.params.id;
    return (
      <div>
        <Breadcrumb title={this.props.match.params.id} />
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {response === "success" ?
                  <div className="success-text my-5 py-4">
                    <i className="fa fa-check-circle" aria-hidden="true" />
                    <h2 className="mt-4">Order Placed Successfully</h2>
                    {/* <h3>Transaction no. {this.props.orderData.order.id}</h3> */}
                    <p className="text-center mt-4">Hooray! You have Completed your order</p>
                    <p className="text-center mb-4">Thank you for Shopping with us...</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/trending/`}
                      data-lng="en"
                      className="btn btn-solid mt-2"
                      rel="noopener noreferrer"
                    >
                      <div>continue shopping</div>
                    </Link>
                  </div> :
                  <div className="danger-text my-5 py-4">
                    <i className="fa fa-times-circle" aria-hidden="true" />
                    <h2 className="mt-4">Your Order has been failed !</h2>
                    <p className="text-center mb-4">Please try agian later</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/checkout`}
                      className="btn btn-solid mt-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Try again
                    </Link>
                  </div>}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderData: state.data.orderid_data,
});

export default connect(
  mapStateToProps,
  { postAllOrderdetails }
)(OrderResponse);
