import React from "react";

import './core_values.css'

const CoreValues = () => {
    return (

        <section class="corevalues-in">
            <div class="container">
                <div class="heading-section">
                    <h2 class="heading-title title-normal p-0">Our Core Values</h2>
                    <div class="heading-icon">
                        <span class="hr-line">&#160;</span>
                        <span class="diamond-icon">&#160;</span>
                        <span class="hr-line">&#160;</span>
                    </div>
                </div>
                <div class="corevalues-contents">
                    <div class="corevalues-item">
                        <h3 className="corevalues-subhead">Integrity</h3>
                        <p>
                        Acting with strong ethics is a priority for everyone representing the organization 
                        as well as the company’s behavior as a whole. 
                        </p>
                    </div>
                    <div class="corevalues-item item1">
                        <h3 className="corevalues-subhead">Fairness</h3>
                        <p>
                        Treating everyone with the common decency we all deserve and expect.
                        </p>
                    </div>
                    <div class="corevalues-item item2">
                        <h3 className="corevalues-subhead">Promise to Customers</h3>
                        <p>
                        Creating a great customer experience begins with staying true to 
                        the words we speak and the bonds we make. 
                        </p>
                    </div>
                    <div class="corevalues-item item2">
                        <h3 className="corevalues-subhead">Learning</h3>
                        <p>
                        No one has all the answers. A culture of humility and continuous 
                        learning is a bedrock principle of successful companies. 
                        </p>
                    </div>
                    <div class="corevalues-item item4">
                        <h3 className="corevalues-subhead">Passion</h3>
                        <p>
                        Having a joy not just for the work itself but also the people 
                        around us, so that everyone can be bold, innovative, and creative. 
                        </p>
                    </div>
                    <div class="corevalues-item item5">
                        <h3 className="corevalues-subhead">Quality</h3>
                        <p>
                        Companies are judged by the craftsmanship of their products and 
                        services, so the highest standards must be maintained. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CoreValues