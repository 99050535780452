import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ImageSlider from './image-slider';
import SDFeatures from "./sd-features";
import Breadcrumbs from "../../new_template_common/breadcrumb/BreadCrumb";

import '../css/product-details.css'
import PriceBreakUp from "./price-breakup";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quickQty: 1,
      inStock: "",
      pincode: "",
      pinStatus: "",
      pintext: "",
      pincode_loading: false,
      images: [],
      variants: [],
    };
  }

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    if (event.target.value.length == 6)
      this.checkPin(event)
    this.setState({ pinStatus: "" });
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const variant_id = queryParams.get('variant_id')
    let selectedVariant = []
    let list = this.props.item.variants.map((item) => (item.id == variant_id))
    if (list.includes(true))
      selectedVariant = this.props.item.variants.filter(item => (item.id == variant_id))[0]
    else {
      window.history.replaceState({}, "", window.location.href);
      selectedVariant = this.props.item.variants[0]
    }

    this.setState({
      variants: selectedVariant,
      images: selectedVariant.image.split(","),
      inStock: this.props.item.made_to_order == "true" ? "Made to order" : "In Stock",
    });
    if (selectedVariant.offerprice >= 49000)
      this.setState({
        pinStatus: "Available for products Below ₹49k",
        pintext: "text-info"
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartItems != prevProps.cartItems)
      this.props.addCartToServer(this.props.cartItems)
  }

  minusQty = () => {
    if (this.state.quickQty > 1)
      this.setState({ quickQty: this.state.quickQty - 1 });
    else
      this.setState({ quickQty: 1 });
  };

  plusQty = (item) => {

    if (item.made_to_order == "false") {
      if (item.stock > this.state.quickQty) {
        this.setState({
          quickQty: this.state.quickQty + 1,
        });
      } else
        this.setState({ inStock: "Out of Stock !" });
    } else
      this.setState({ quickQty: this.state.quickQty + 1 })
  };

  addToCart = item => {
    this.props.addToCartClicked(item, this.state.quickQty, item.selectedVariant);
    this.props.addCartToServer(this.props.cartItems)
    this.setState({ quickQty: 1 });
  };

  addToWishlist = item => {
    this.props.addToWishlistClicked(item, item.selectedVariant)
  }

  addToCompare = item => {
    this.props.addToCompareClicked(item, item.selectedVariant)
  }

  changeVariant = vari => {
    this.setState({
      variants: vari,
      images: vari.image.split(","),
    })

    window.history.replaceState({}, "", window.location.pathname + "?variant_id=" + vari.id);
    this.props.setVariant(vari);
  }

  /*Color selector variant function call*/

  colorHandle = (event) => {
    // let nSize = this.state.variants.size;
    // let sizeArray = this.props.item.variants.filter((vari) => vari.color == event.target.value);
    // const nArray = sizeArray.some(vari => vari.size == nSize);
    // if (!nArray)
    //   nSize = sizeArray[0].size;
    const selectedItem = this.props.item.variants.filter(item => (item.color == event.target.value && item.size == this.state.variants.size))
    this.changeVariant(selectedItem[0]);
  }

  /*Size selector variant function call*/

  sizeHandle(event) {
    const selectedItem = this.props.item.variants.filter(item => (item.color == this.state.variants.color && item.size == event.target.value))
    this.changeVariant(selectedItem[0])
  }


  /* Offer Section showing function*/
  offerSectionShowing = () => {
    if (this.props.item.variants[0].offerprice >= window.rc.pdp_offer.min_price) {
      return (
        <>
          <div>
            <button className="red-discount uk-button-secondary">
              <span uk-icon="icon: credit-card" className="uk-margin-small-right"></span>
              <span className="normal-para">{window.rc.pdp_offer.offer_text}</span>
            </button>
          </div>
          <hr />
        </>
      )
    }
    else return null
  }


  checkPin = event => {
    event.preventDefault();
    if (this.state.pincode_loading) return;
    this.setState({ pincode_loading: true });
    if (this.props.item.variants[0].offerprice * (this.state.quickQty) <= 49000) {
      this.checkPinAPI(event.target.value)
    }
    else {
      this.setState({
        pinStatus: "COD not available for this product",
        pintext: "text-danger",
        pincode_loading: false
      });
    }
  };

  checkPinAPI = async pin => {
    await axios
      .get("https://api.postalpincode.in/pincode/" + pin)
      .then(response => {
        response = response.data
        this.setState({ pincode_loading: false });
        if (response[0].Status == "Success") {
          this.setState({
            pinStatus: "Whoo, Cash on delivery available in your pincode",
            pintext: "text-success"
          });
        }
        else {
          this.setState({
            pinStatus: "Oops, Only available in selected pincodes",
            pintext: "text-danger"
          });
        }
      })
      .catch(error => {
        this.setState({ pincode_loading: false });
        if (error) {
          this.setState({
            pinStatus: " Error cannot find. Please try again",
            pintext: "text-danger"
          });
        }
      });
  }

  scrollToProductDetails = () => {
    var element = document.getElementById("sd-product-information")
    element.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { item, BuynowClicked, symbol } = this.props;
    const { variants, images } = this.state;

    // const { compare } = Intl.Collator('en-US');

    // const mainArray = [...new Map(item.variants.filter(item => (item.size == item.default_size)).map(item => [item.color, item])).values()];
    // const colorArray = [...new Map(item.variants.map(item => [item.color, item])).values()];
    // const colorArray = mainArray.concat(variantArray.filter(({ color }) => !mainArray.find(vari => vari.color == variants.color)));

    return (
      <div className="uk-section sd-inner-content uk-padding-remove product-detail-main">
        <div className="uk-container uk-container-xlarge">
          <Breadcrumbs
            ancestor={item.name}
            currentPage=""
          />
          <div data-uk-grid>
            {variants ? (
              <ImageSlider imageArr={images} itemName={item.name} />
            ) : (
              <ImageSlider imageArr={item.pictures} itemName={item.name} />
            )}
            {/* <ProductDetails item={item} /> */}
            <div className="uk-width-1-1 uk-width-1-2@l side-bar-listing ">
              <div className="uk-width-1-1">
                <h2>{item.name.toUpperCase()}</h2>
                <p className="normal-para">SKU: {item.sku.toUpperCase()}</p>

                <hr />

                <div className="uk-child-width-1-1 uk-child-width-1-2@s uk-padding-small-top" data-uk-grid>
                  <div className="uk-padding-remove">
                    <i className={"fa fa-star" + (variants.rating >= 1 ? "" : variants.rating > 0 && variants.rating < 1 ? "-half-o" : "-o")} color="#722257" aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 2 ? "" : variants.rating > 1 && variants.rating < 2 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 3 ? "" : variants.rating > 2 && variants.rating < 3 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 4 ? "" : variants.rating > 3 && variants.rating < 4 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 5 ? "" : variants.rating > 4 && variants.rating < 5 ? "-half-o" : "-o")} aria-hidden="true" />
                  </div>

                  {/* not using in current version  */}

                  {/* <div className="uk-padding-remove">
                    <span className="small-review">
                      <a href="javascript:void(0)">34 Reviews</a>
                    </span>
                  </div> */}
                  <div className="uk-padding-remove">
                    <a href="javascript:void(0)" onClick={this.scrollToProductDetails} className="caps-text pro-dt-mob">
                      <u>Product Details</u>
                    </a>
                  </div>
                </div>

                <hr />

                <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                  <div className="uk-padding-remove">
                    <article>
                      <small>Price</small>
                      <span className="big-price">{symbol} {Number(variants.offerprice).toFixed(2)}</span>
                      {/* <del>₹ {price}</del> */}
                    </article>
                  </div>

                  <PriceBreakUp symbol={symbol} product={variants} />

                </div>

                <hr />

                {this.offerSectionShowing()}

                <div className="uk-width-1-1@l form-select-size">
                  <form>
                    <fieldset className="uk-fieldset">
                      <div className="form-input">
                        <div className="uk-child-width-1-1 uk-child-width-1-2@l" data-uk-grid>
                          <div className="uk-padding-small uk-padding-remove-left uk-form-box">
                            <label className="uk-form-label" htmlFor="form-stacked-select">Metal Color</label>
                            <select
                              className="uk-select"
                              name="color"
                              onChange={(e) => this.colorHandle(e)}
                              value={variants.color}
                            >
                              {item.variants.filter((item) => item.size == variants.size).map((vari, i) => (
                                <option key={i} value={vari.color}>
                                  {vari.color.replaceAll("-", " ").toUpperCase()}
                                </option>
                              ))
                              }
                            </select>
                          </div>

                          {item.category == 'rings' || item.category == 'bangles' || item.category == 'bracelets' ?
                            <div className="uk-padding-small uk-padding-remove-left uk-form-box">
                              <label className="uk-form-label" htmlFor="form-stacked-select">Select Size</label>
                              <select
                                className="uk-select"
                                name="size"
                                onChange={(e) => this.sizeHandle(e)}
                                value={variants.size}
                              >
                                {item.variants.filter((item) => item.color == variants.color).map((vari, i) => (
                                  <option key={i} value={vari.size}>{vari.size}</option>
                                ))
                                }
                              </select>
                            </div> : ""}

                          {/* <div className="uk-padding-remove uk-form-box">
                            <label className="uk-form-label" htmlFor="form-stacked-select">Select Diamond Clarity</label>
                            <select
                              className="uk-select"
                              value={variants.diamond_clarity}
                            >
                              <option>
                                {variants.diamond_clarity}
                              </option>
                            </select>
                          </div> */}

                          {/* cod checking */}

                          <div className="uk-padding-small uk-padding-remove-left uk-form-box">
                            <label className="uk-form-label" htmlFor="form-stacked-select">Check Cash On Delivery</label>
                            <div className="uk-child-width-1-1">
                              <div className="uk-inline">
                                <span className="uk-form-icon" data-uk-icon="icon: location"></span>
                                <input
                                  type="Pincode"
                                  className="uk-input"
                                  placeholder="Pin Code"
                                  minLength="6"
                                  maxLength="6"
                                  name="pincode"
                                  value={this.state.pincode}
                                  onChange={this.setStateFromInput}
                                />
                              </div>
                              {this.state.pincode_loading && <div data-uk-spinner="ratio-1" className="uk-padding-small-top"></div>}
                              <h6 className={"product-title my-2 " + this.state.pintext}>{this.state.pinStatus}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>

                <hr />

                <div className="cart-button">
                  <div className="uk-child-width-1-1@s uk-child-width-1-3@m" data-uk-grid>
                    <div className="uk-padding-remove">
                      <div className="quantity buttons_added">
                        <input
                          type="button"
                          value="-"
                          className="minus"
                          onClick={this.minusQty}
                        />
                        <input
                          type="number"
                          step="1"
                          min="1"
                          max=""
                          name="quantity"
                          value={this.state.quickQty}
                          title="Qty"
                          className="input-text qty text" size="4"
                          pattern=""
                          inputMode=""
                          readOnly
                        />
                        <input
                          type="button"
                          value="+"
                          className="plus"
                          onClick={() => this.plusQty(item)}
                        />
                      </div>
                    </div>
                    <div className="uk-visible@m">
                      <Link
                        to={`${process.env.PUBLIC_URL}/checkout`}
                        className="uk-button uk-button-primary buy-now uk-width-1-1"
                        onClick={() => {
                          item.selectedVariant = variants
                          BuynowClicked(item, this.state.quickQty, item.selectedVariant)
                        }}
                      >
                        buy now
                      </Link>
                    </div>
                    <div className="uk-visible@m">
                      <button
                        className="uk-button uk-button-secondary add-cart uk-width-1-1"
                        onClick={() => {
                          item.selectedVariant = variants
                          this.addToCart(item)
                        }}
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              {/* Not using in this version  */}

              {/* <div className="uk-width-1-1">
                <div className="uk-grid">
                  <div className="uk-iconnav uk-padding-small uk-padding-remove-vertical">
                    <p className="uk-margin-remove">Engrave this product</p>
                    <span className="engrave-offcanvas uk-text-middle">
                      <button className="uk-button uk-button-default" type="button" data-uk-toggle="target: #offcanvas-flip">
                        <span uk-icon="pencil"></span>
                        Add Engraving
                      </button>

                      <div className="uk-flex">
                        <p className="uk-text-small">Inner Engraving:<span>asdsad</span></p>
                        <p className="uk-flex-middle uk-margin-left uk-margin-remove-top">Remove</p>
                      </div>

                      <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true">
                        <div className="uk-offcanvas-bar">
                          <button className="uk-offcanvas-close uk-icon uk-close" type="button" aria-label="Close" data-uk-close>
                          </button>
                          <dl className="uk-description-list">
                            <dt>Engrave</dt>
                            <hr className="uk-divider-icon uk-margin-remove"></hr>
                            <dd>Note:Text can only contain upto 7 English/alphanumeric (A-Z, a-z, and/or 0-9) and special characters (heart and infinity)</dd>
                          </dl>
                          <div>
                            <p>Choose a font</p>
                            <div className="uk-flex uk-flex-wrap uk-flex-wrap-between">
                              <button className="uk-button uk-button-primary uk-button-large">Aa</button>
                              <button className="uk-button uk-button-secondary uk-button-large">Aa</button>
                              <button className="uk-button uk-button-secondary uk-button-large">Aa</button>
                              <button className="uk-button uk-button-primary uk-button-large">Aa</button>
                            </div>
                          </div>
                          <div>
                            <p>Inner Engraving</p>
                            <div className="uk-margin">
                              <input className="input-text" type="text" placeholder="Input" aria-label="Input" />
                            </div>
                          </div>
                        </div>
                      </div>

                    </span>
                  </div>
                </div>
              </div>

              <hr /> */}

              <div className="engraveproductwith">
                <div className="uk-grid uk-padding-small uk-padding-remove-top ">
                  <div className="uk-iconnav uk-padding-small uk-padding-remove-vertical">
                    <a
                      href="javascript:void(0)"
                      className="wishlist-btn uk-flex-inline uk-flex-middle"
                      onClick={() => {
                        item.selectedVariant = variants
                        this.addToWishlist(item, item.selectedVariant)
                      }}
                    >
                      <span data-uk-icon="icon: heart"></span>
                      <span className="uk-text-middle">
                        <p className="uk-margin-remove">Add To Wishlist</p>
                      </span>
                    </a>
                  </div>
                  <div className="uk-iconnav uk-padding-small uk-padding-remove-vertical">
                    <a
                      href="javascript:void(0)"
                      className="wishlist-btn uk-flex-inline uk-flex-middle"
                      onClick={() => {
                        item.selectedVariant = variants
                        this.addToCompare(item, item.selectedVariant)
                      }}
                    >
                      <span data-uk-icon="icon: settings"></span>
                      <span className="uk-text-middle">
                        <p className="uk-margin-remove">Compare</p>
                      </span>
                    </a>
                  </div>
                  <div className="uk-iconnav uk-padding-small uk-padding-remove-vertical">
                    <a
                      href={"whatsapp://send?text=https://sunnydiamonds.com/" + item.value + "?variant_id=" + variants.id}
                      className="uk-flex-inline uk-flex-middle"
                      data-action="share/whatsapp/share"
                      target="_blank">
                      <span data-uk-icon="icon: social" />
                      <span className="uk-text-middle">
                        <p className="uk-margin-remove">Share</p>
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              {variants.price !== 0 ? (
                <div className="fair-pricing">
                  <div className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" data-uk-grid>
                    <div className="uk-text-left uk-cover/-container">
                      <div className="uk-card-body">
                        <h3 className="uk-card-title">Fair Pricing</h3>
                        <p className="para-one">{symbol} {variants.offerprice} Sunny Diamonds</p>
                      </div>
                    </div>
                    <div className="uk-text-right@l estimated-box">
                      <div className="uk-card-body">
                        <a href={`${process.env.PUBLIC_URL}/fair-pricing`} target="_blank" className="learn-more">Learn More</a>
                        <p className="para-one">{symbol} {variants.price} Estimated Retail</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <SDFeatures />

              <div className="need-help">
                <div className="uk-card uk-card-default uk-grid-collapse uk-margin uk-padding-small" data-uk-grid>
                  <div className="uk-text-center uk-cover/-container uk-width-1-1@s">
                    <div className="uk-card-body help-need">
                      <p className="">Need Help?</p>
                    </div>
                  </div>
                  <div className="uk-text-right@m uk-width-1-1 need-help-ico">
                    <div className="uk-card-body help-need">
                      <a
                        href="tel:+919961870099"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span data-uk-icon="icon: receiver"></span>
                      </a>
                      <a
                        href="https://api.whatsapp.com/send?phone=919961870099"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "rgb(114, 34, 87)" }}
                      >
                        <span data-uk-icon="icon: whatsapp"></span>
                      </a>
                      <a
                        href="mailto:customerservice@sunnydiamonds.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span data-uk-icon="icon: mail"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sd-product-information"></div>
            </div>
          </div >
        </div>

        {/* <!-- mob-cart-button --> */}

        <div className="uk-grid-match uk-child-width-expand@s uk-text-center uk-hidden@m uk-position-relative" data-uk-grid>
          <div className="uk-child-width-1-2 buy-cart-static">
            <Link
              to={`${process.env.PUBLIC_URL}/checkout`}
              className="uk-button uk-button-primary buy-now"
              // onClick={() => BuynowClicked(item, this.state.quickQty, item.selectedVariant)}
              onClick={() => {
                item.selectedVariant = variants
                BuynowClicked(item, this.state.quickQty, item.selectedVariant)
              }}
            >
              Buy Now
            </Link>
          </div>
          <div className="uk-child-width-1-2 buy-cart-static">
            <button
              className="uk-button uk-button-secondary add-cart"
              onClick={() => {
                item.selectedVariant = variants
                this.addToCart(item)
              }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductDetails;
