import React from "react";

// import Loader from "../../common/spinner"

const ShippingMethod = (props) => {

    let shippingMethods = {
        DOMESTIC: () => (
            <div className="form-check mb-3">
                <input
                    className="form-check-input mt-2"
                    type="radio"
                    name="virtual_order_shipping_method"
                    id="domestic_shipping"
                    value="domestic_shipping"
                    onClick={() =>
                        props.handleChange("domestic_shipping", 5)
                    }
                />
                <div className="d-flex justify-content-between">
                    <label className="form-check-label" htmlFor="domestic_shipping">
                        <p className="mb-0">Domestic delivery</p>
                    </label>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        height="1.5rem"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                        />
                    </svg>
                </div>
            </div>
        ),
        INTERNATIONAL: () => (
            <div className="form-check">
                <input
                    className="form-check-input mt-2"
                    type="radio"
                    name="virtual_order_shipping_method"
                    id="inernational_shipping"
                    value="inernational_shipping"
                    onClick={() =>
                        props.handleChange("inernational_shipping", 5)
                    }
                />
                <div className="d-flex justify-content-between">
                    <label className="form-check-label" htmlFor="inernational_shipping">
                        <p className="mb-0">International delivery</p>
                    </label>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        height="1.5rem"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                        />
                    </svg>
                </div>
            </div>
        )
    }


    if (props.options)

        return (
            <div className="bg-white border p-4 mb-4">
                {props.shippingMethod && props.shippingMethod.errors 
                    .map((error, i) => (
                        < div className="alert alert-danger" role="alert">
                            {error.text}
                        </div>
                    ))
                }
                {props.options && props.options.shipping.errors.length > 0 && props.options.shipping.errors
                    .map((error, i) => (
                        <div className="alert alert-danger" role="alert">
                            {error.text}
                        </div>
                    ))
                }
                <div className="d-flex justify-content-between">
                    <p className="text-secondary text-uppercase">Shipping methods</p>
                    {/* <div className="mt-1">
                    <Loader />
                </div> */}
                </div>
                {Object.keys(props.options.shipping).map((elements, i) => {
                    if (Object.values(props.options.shipping)[i] === true)
                        return shippingMethods[elements]()
                })
                }
            </div>
        )
    else return null
}

export default ShippingMethod