import * as types from "../constants/ActionTypes";

const filtersReducerDefaultState = {
  placeholder: [],
  color: [],
  value: { min: 0, max: 100000 },
  sortBy: "LowToHigh"
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case types.FILTER_CAT:
      return {
        ...state,
        placeholder: {
          ...state.placeholder,
          [action.placeholder]: action.brand
        }
      };
    case types.FILTER_CAT_NULL:
      return {
        ...state,
        placeholder: {
          // ...state.placeholder,
          [action.placeholder]: ""
        }
      };
    case types.FILTER_COLOR:
      return {
        ...state,
        color: action.color
      };
    case types.FILTER_PRICE:
      return {
        ...state,
        value: { min: action.value.value.min, max: action.value.value.max }
      };
    case types.FILTER_PRICE_RANGE:
      return {
        ...state,
        value: { min: action.value.min, max: action.value.max }
      };
    case types.SORT_BY:
      return {
        ...state,
        sortBy: action.sort_by
      };
    default:
      return state;
  }
};

export default filtersReducer;
