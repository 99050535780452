import React, { Component } from "react";
import { Link } from "react-router-dom";

class Success extends Component {
    render() {
        if (sessionStorage.getItem("user_type") === "CUSTOMER")
            return (
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text my-5 py-4">
                                    <i className="fa fa-check-circle" aria-hidden="true" />
                                    <h2>Thank You for registering !</h2>
                                    <p className="text-center">Welcome To Sunny Diamonds Family.</p>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}/jewellery/`}
                                        data-lng="en"
                                        className="btn btn-solid mt-2"
                                        rel="noopener noreferrer"
                                    >
                                        <div>continue shopping</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        else
            return (
                <div className="height">
                    <img
                        src={`${process.env.PUBLIC_URL
                            }/assets/images/sunny/loader/loader.gif`}
                        alt=""
                        className="loader"
                    />
                </div>
            );
    }
}

export default (Success);
