import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import Loader from "../../common/spinner"

function BillingAddressDetails(props) {
  const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  const [validationTrace, setValidationTrace] = useState({});
  const [billingDetails, setbillingDetails] = useState({
    'address_country': "",
    'address_zipcode': "",
    'address_state': "",
    'address_city': "",
    'address_address': "",
  })

  useEffect(() => {
    console.log("billing Details Changed", billingDetails)
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [billingDetails])

  useEffect(() => {
    console.log("[billing] VOData Changed", props.VOData)
    if (props.VOData.order)
      if (props.VOData.order.billing_address)
        setbillingDetails({
          address_country: props.VOData.order.billing_address.virtual_order_address_country,
          address_zipcode: props.VOData.order.billing_address.virtual_order_address_zipcode,
          address_state: props.VOData.order.billing_address.virtual_order_address_state,
          address_city: props.VOData.order.billing_address.virtual_order_address_city,
          address_address: props.VOData.order.billing_address.virtual_order_address_address,
        })
  }, [props.VOData])

  console.log("Render billing Details ", billingDetails)

  const billingDetailsync = () => {
    SDClient.endpoint("virtual_order_set_billing_address", { ...billingDetails }).then(
      (data) => {
        if (data.message.action) {
          setValidationTrace({});
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            setValidationTrace(data.message.trace);
          }
        }
      },
      console.log
    )
  }

  const debounce = () => {
    if (debounceLock) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      console.log("DEBOUNCE CALLBACK!")
      billingDetailsync();
      setDebounceLock(false);
    }, 1000));
    setDebounceLock(true);
  }

  const showbillingDetailsForm = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">Country</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={billingDetails.address_country}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_country: e.target.value }) }}
          />
          {validationTrace.address_country ? (<small className="text-uppercase text-danger"> Please specify a valid country name. </small>) : null}
        </div>
        <div className="form-group ">
          <label className="text-uppercase field-label">Address</label>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={billingDetails.address_address}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_address: e.target.value }) }}
          />
          {validationTrace.address_address ? (<small className="text-uppercase text-danger"> Please specify an address for billing address.</small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">City </div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={billingDetails.address_city}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_city: e.target.value }) }}
          />
          {validationTrace.address_city ? (<small className="text-uppercase text-danger"> Please specify a valid city for billing address.</small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">State</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={billingDetails.address_state}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_state: e.target.value }) }}
          />
          {validationTrace.address_state ? (<small className="text-uppercase text-danger"> Please specify a valid state for billing address. </small>) : null}
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Postal code</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="form-control"
            value={billingDetails.address_zipcode}
            onChange={(e) => { setbillingDetails({ ...billingDetails, address_zipcode: e.target.value }) }}
          />
          {validationTrace.address_zipcode ? (<small className="text-uppercase text-danger"> Please specify a valid pin code for billing address. </small>) : null}
        </div>
      </div>
    )
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className="text-uppercase field-label">Country</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">City </div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">State</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
        <div className="form-group ">
          <div className="text-uppercase field-label">Postal code</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="form-control"
          />
        </div>
      </div>
    )
  }

  return (
    <div id="CheckoutBillingAddressBlock" className="bg-white border p-4 mb-4">
      <div className="d-flex justify-content-between">
        <p className="text-secondary text-uppercase">Shipping Details</p>
        <div className="mt-1">
          {debounceLock ? (<Loader />) : ""}
        </div>
      </div>
      {props.VOData.order ? showbillingDetailsForm() : showLoadingInputs()}
    </div>
  )
}


export default BillingAddressDetails;
