import React, { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";

const PayApp = props => {

  const Razorpay = useRazorpay();
  const [Data, setData] = useState([]);

  useEffect(
    () => {
      if (props.orderData) {
        if (props.orderData.order_id !== "0") setData(props.orderData);
      }
    },
    [props.orderData]
  );

  useEffect(
    () => {
      if (Data) {
        if (Data.order_id) handlePayment();
      }
    },
    [Data]
  );

  const handlePayment = () => {
    const options = {
      key: global.rzpKey,
      amount: Data.amount * 100,
      currency: Data.currency,
      order_id: Data.order_id,
      name: "Sunny Diamonds",
      description: "Sunny Diamonds Payment",
      image: "https://sunnydiamonds.com/assets/images/sunny/logo/razorpay/logo.webp",
      callback_url: props.mode == "gift" ? global.rzpGiftCallbk : global.rzpCallbk,
      cancel_url: props.mode == "gift" ? global.rzpGiftCancel : global.rzpCancel,
      redirect: true,
      handler: res => {
        var values = {
          razorpay_signature: res.razorpay_signature,
          razorpay_order_id: res.razorpay_order_id,
          razorpay_payment_id: res.razorpay_payment_id
        };
      },
      prefill: {
        name: props.Order.first_name + " " + props.Order.last_name,
        email: props.Order.email,
        contact: props.Order.phone
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#722257"
      }
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  };
  return null
};

export default PayApp;