import React from "react";

import './google-review.css'

const GoogleReview = () => {

    return (
        <section class="people-section">
            <div class="people-container">
                <div class="heading-section">
                    <h2 class="heading-title title-normal">What People Says</h2>
                    <div class="heading-icon">
                        <span class="hr-line">&nbsp;</span>
                        <span class="diamond-icon">&nbsp;</span>
                        <span class="hr-line">&nbsp;</span>
                    </div>
                </div>


                <div
                    className="tagembed-container"
                    style={{ width: "100%", height: "100%", overflow: "auto" }}
                >
                    <div
                        className="tagembed-socialwall"
                        data-wall-id={window.rc.googlewidget.data_wall_id}
                        view-url={window.rc.googlewidget.view_url}
                    />
                </div>


            </div>
        </section>
    )
}

export default GoogleReview