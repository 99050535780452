import React from "react";

export const MainLoader = () => {
    return (
        <div className="sd-loader">
            <img src="/assets/images/sunny/loader/loader.gif" alt="" style={{ height: "200px" }} />
        </div>
    )
}

export const ErrorMessage = (props) => {
    return (
        <div className="sd-loader">
            <div className="row d-flex justify-content-center">
                <div className="col-12 bg-white p-4 dialog" style={{ borderRadius: "5px" }}>
                    <h4>{props.heading}</h4>
                    <br />
                    <p>{props.description}</p>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-promo-code-outline" onClick={props.rejectAction}>{props.reject}</button>
                        <button className="btn btn-promo-code ml-2" onClick={props.resolveAction}>{props.resolve}</button>
                    </div>
                </div>
            </div>
        </div>)
}