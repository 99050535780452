import React, { useEffect } from "react";
import { connect } from "react-redux";

import Topbar from "./topbar";
import HeaderBody from "./header-body";
import { postUserLog } from "../../actions";

import './css/sunny-diamond.css'
import './css/header.css'


const Header = (props) => {

    useEffect(() => {
        getLocationData()
    }, [props.location])

    const getLocationData = () => {
        fetch("https://geolocation-db.com/json/")
            .then((response) => {
                response.json()
                    .then((data) => {
                        var userLog = {
                            visited_page: window.location.pathname,
                            ip_address: data.IPv4,
                            address: data.country_name,
                            latitude: data.latitude == "Not found" ? 0 : data.latitude,
                            longitude: data.longitude == "Not found" ? 0 : data.longitude,
                            user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                        };
                        props.postUserLog(userLog);
                    })
                    .catch(() => {
                        var userLog = {
                            visited_page: window.location.pathname,
                            user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                        };
                        props.postUserLog(userLog);
                    });
            })
            .catch(() => {
                var userLog = {
                    visited_page: window.location.pathname,
                    user_id: navigator.cookieEnabled && sessionStorage.getItem("user_id"),
                };
                props.postUserLog(userLog);
            });
    };
    return (
        <div>
            <Topbar topbarText={window.navLinksTop.topBarText} />
            <HeaderBody
                navBar={window.navLinksTop.navBar}
                wishListItems={props.wishListItems}
                compareItems={props.compareItems}
            />
        </div>
    )
}


const mapStateToProps = state => ({
    wishListItems: state.wishlist.list,
    compareItems: state.compare.items
});

export default connect(mapStateToProps, { postUserLog })(Header);