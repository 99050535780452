import React, { Component } from "react";

// import './assets/css/imageSlider.css'

class ImageSlider extends Component {
    render() {
        let imgeSrc = [];
        let vidSrc = [];
        let imgI = 0;
        const exts = [".png", ".jpg", ".webp", ".gif"];

        this.props.imageArr.forEach(function (img) {
            let ext = img.substring(img.lastIndexOf("."));
            if (exts.includes(ext)) {
                imgeSrc[imgI] = img;
                imgI++;
            } else {
                vidSrc[imgI] = img;
                imgI++;
            }
        });

        if (this.props.imageArr)
            return (
                <div className="uk-width-1-1 uk-width-1-2@l uk-padding-remove">
                    <div className="sticky-image" data-uk-slideshow="animation: pull">
                        <ul className="uk-slideshow-items">
                            {imgeSrc.map((image, index) => (
                                <li key={index}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}` + image}
                                        alt={this.props.itemName}
                                        onError={e => { e.target.src = `${process.env.PUBLIC_URL}/assets/images/sunny/no_image/no_images.webp`; }}
                                        data-uk-cover
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className="uk-position-small uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
                            <ul className="uk-thumbnav uk-slider-items uk-child-width-1-4 uk-child-width-1-6@m uk-grid">
                                {imgeSrc.map((image, index) => (
                                    <li key={index} data-uk-slideshow-item={index}>
                                        <div className="uk-panel">
                                            <img
                                                src={`${process.env.PUBLIC_URL}` + image}
                                                alt={this.props.itemName}
                                                onError={e => {
                                                    e.target.src = `${process.env.PUBLIC_URL
                                                        }/assets/images/sunny/no_image/no_images.webp`;
                                                }}
                                                width="120" height="67"
                                            />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        else return null;
    }
}

export default ImageSlider;
