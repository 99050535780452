import React from "react";

const Occasion = (props) => {


    return (
        <div>
            <div className="container gift-index-visited  px-lg-5 px-0 mx-lg-5 mx-0">
                <div className="row justify-content-center my-3">
                    <div className="row mx-lg-3 mx-5">
                        {props.cardList && props.cardList.map((data, i) => (
                            <div key={i}
                                className="col-md-3 col-12 my-1 p-0">
                                <img className={
                                    props.occasion.title == data.title ? "img-fluid card-type active" : "img-fluid card-type"
                                }
                                    src={`${process.env.PUBLIC_URL}` + data.picture}
                                    alt={data.title}
                                    width="97%"
                                    onClick={
                                        () => {
                                            props.handleOccationData(data)
                                            props.handleIndexData(3)
                                            if(window.outerWidth >= 1024) {
                                                window.scrollTo(0, 435);
                                            }else{
                                                window.scrollTo(0, 0);
                                            }
                                        }
                                    }
                                />
                                <h3
                                    className="mt-2 text-center font-weight-bold cursor-pointer"
                                    onClick={() => {
                                        props.handleOccationData(data)
                                        props.handleIndexData(3)
                                    }}
                                >
                                    {
                                        data.title.replaceAll("_", " ")
                                    }
                                </h3>
                            </div>
                        ))
                        } </div>
                </div>
            </div>
        </div>
    )
}

export default Occasion;

