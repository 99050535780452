import React, { Component } from "react";

class HTMLLoader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rootID:Math.random().toString(36).slice(2, 7)
        };
    }

    propertise(element, properties) {
        for (let key in properties) {
            element[key] = properties[key];
            if (properties[key] instanceof Object && key != 'elements')
                this.propertise(element[key], properties[key])
        }
    }

    drender(element, template) {
        for (let rep of template) {
            let elm = document.createElement(rep.tag);
            element.appendChild(elm);
            this.propertise(elm, rep.props)
            this.drender(elm, rep.childs)
        }
    }

    componentDidMount() {

        this.drender(document.getElementById(this.state.rootID),this.props.template);
    }

    render() {
        return (
            <div id={this.state.rootID}>
            </div>
        );
    }
}

export default HTMLLoader;
