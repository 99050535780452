import React, { Component } from 'react';
// import { withTranslate } from 'react-redux-multilingual'
import ReactGA from 'react-ga';

// Custom Components
import Header from './new_header'
import HeaderSix from './common/headers/header-six';
import FooterFive from "./common/footers/footer-five";
import { MainLoader } from '../loader';

// ThemeSettings
import ThemeSettings from "./common/theme-settings"
import { connect } from 'react-redux';
import Footer from './new_footer';
class App extends Component {

    showLoader() {
        if (this.props.loader) return (<MainLoader />)
        else return null
    }

    render() {
        return (
            <div>

                {this.showLoader()}
                <Header location={this.props.location} />
                {this.props.children}
                <Footer />
                {/* <ToastContainer autoClose={1000} className="toast-container" /> */}
                <ThemeSettings />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loader: state.data.loader
})

export default connect(mapStateToProps)(App);