import React from "react";


import './leadership.css'

const Leadership = () => {

    return (
        <section className="leadership-section">
            <div className="leadership-container">
                <div class="heading-section">
                    <h2 class="heading-title title-normal p-0">Leadership Team</h2>
                    <div class="heading-icon m-0">
                        <span class="hr-line">&#160;</span>
                        <span class="diamond-icon">&#160;</span>
                        <span class="hr-line">&#160;</span>
                    </div>
                </div>


                <div className="d-flex flex-md-row flex-column justify-content-center mt-5">
                    <div className="d-flex flex-row px-5">
                        <a
                            href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                className="img-fluid"
                                src={`${process.env.PUBLIC_URL
                                    }/assets/images/sunny/about_us/sunny _new.webp`}
                                alt="P.P. Sunny"
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/p-p-sunny-49834b8b/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <h4 className="para-one">P.P. Sunny</h4>
                            <p className="para-one">
                                Founder & CMD
                                <br />
                                Graduate from GIA
                                <br />
                                <b>Linked</b>
                                <i className="fab fa-linkedin" aria-hidden="true" />
                            </p>
                        </a>
                    </div>

                    <div className="d-flex flex-row px-5 mt-md-0 mt-5">
                        <a
                            href="https://www.linkedin.com/in/prem-sunny/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <h4 className="para-one">Prem Sunny</h4>
                            <p className="para-one">
                                Director of Operations
                                <br />
                                Graduate from GIA
                                <br />
                                <b>Linked</b>
                                <i className="fab fa-linkedin" />
                            </p>
                        </a>
                        <a
                            href="https://www.linkedin.com/in/prem-sunny/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                className="img-fluid"
                                src={`${process.env.PUBLIC_URL
                                    }/assets/images/sunny/about_us/prem_new.webp`}
                                alt="Prem Sunny"
                            />
                        </a>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default Leadership;