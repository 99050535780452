/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Trending from "./trending";
import HeaderSix from "../../common/headers/header-six";
import FooterFive from "../../common/footers/footer-five";
import ThemeSettings from "../../common/theme-settings";
import Features from "./features";
import FeatureIn from "../common/featurein";
import ShopCategory from "./shop-by-category";
import Seo from "../common/seo";
import Slider from "react-slick";
import { Slider1 } from "../../../services/script";
import GoogleTagEmbeded from "../common/google-tagembed";
import { MainLoader } from "../../../loader";

import Header from "../../new_header"
import Footer from "../../new_footer";

class Sunny extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }

  componentDidMount() {
    if (window.ApplicationTagEmbed)
      window.ApplicationTagEmbed.init()
    setTimeout(() => {
      this.setState({ load: true })
    }, [4000])
  }

  componentWillUnmount() {
    this.setState({ load: false })
  }

  render() {
    return (
      <div>
        {/* seo  */}
        <Seo type="sunny-head" />

        {!this.state.load && <MainLoader />}

        {/* Header section */}
        {/* <HeaderSix logoName={"logo_sunny.png"} /> */}
        <Header />


        {/* Carousel section */}
        <div className="p-0 banner_home">
          <Slider {...Slider1} className="d-md-block d-none">
            {window.banners.desktop.map((data, i) => (
              <div key={i} className="container_ban">
                <img
                  id="banner_container"
                  className="img-fluid blur-up lazyload image_category"
                  onLoad={() => { if (window.innerWidth > 767) this.setState({ load: true }) }}
                  src={`${process.env.PUBLIC_URL}` + data.image}
                  alt={data.alt}
                />
              </div>
            ))}
          </Slider>
          <Slider {...Slider1} className="d-md-none d-block">
            {window.banners.mobile.map((data, i) => (
              <div key={i} className="container_ban">
                <img
                  id="banner_container"
                  className="img-fluid blur-up lazyload image_category"
                  onLoad={() => { if (window.innerWidth <= 767) this.setState({ load: true }) }}
                  src={`${process.env.PUBLIC_URL}` + data.image}
                  alt={data.alt}
                />
              </div>
            ))}
          </Slider>
        </div >

        {/* category section */}
        <div className="mt-4">
          <ShopCategory type={"sunny-category"} />
        </div>

        {/* product section Start */}
        <div className="my-4">
          <Trending />
        </div>

        {/* instagram widget  */}
        <div className="tagembed-container" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <div
            className="tagembed-socialwall"
            data-wall-id={window.rc.pinterest.data_wall_id}
            view-url={window.rc.pinterest.view_url}
          />
        </div>

        <ThemeSettings />

        {/* Features in */}
        <div className="my-4">
          <FeatureIn />
        </div>

        {/* Google Review  section */}
        <GoogleTagEmbeded />

        {/*Sunny diamonds features section*/}
        <div className="mt-3 pt-4">
          <Features />
        </div>

        {/* footer section */}
        {/* <FooterFive logoName={"logo.png"} /> */}
        <Footer/>
      </div>
    );
  }
}

export default Sunny;
