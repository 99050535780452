import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-bootstrap-accordion/dist/index.css";
import { connect } from "react-redux";
import { getPolicy } from "../../services";
import DOMPurify from 'dompurify';
import Seo from "../layouts/common/seo";
import Slider from "react-slick";
import Promise from "../new_home_page/promise";

class FairPricing extends Component {
  render() {
    const { listPolicy } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: true,
      autoplay:true,
      autoplaySpeed:3000
    };

    return (
      <div>
        {/* seo section  */}
        <Seo type="fair-price-head" />

        <Breadcrumb title={listPolicy[0].head} />

        {/*Dashboard section*/}
        <section className="section-b-space  blog-page">
          <div className="container mt-3">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 ">
                <div className="row blog-media">
                  <div className="col-md-10 careers">
                    <div className="row">
                      <div className="col-xl-12 mb-4 policy-para">
                        <h1 className="text-center pt-3">
                          {listPolicy[0].name1}
                        </h1>
                        <p className="text-center" dangerouslySetInnerHTML = {{__html: DOMPurify.sanitize(listPolicy[0].content)}}></p>
                      </div>
                    </div>

                    <div className="row pl-md-5">
                      <ul className="fair-pricing">
                        {listPolicy[0].list.map((items, index) => (
                          <li className="col-md-6 fair-pricing" key={index}>
                            {items}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="mt-4 mb-4 text-center">
                      <img
                        className="img-fluid fair"
                        src={`${process.env.PUBLIC_URL}` + listPolicy[0].image1}
                        alt="fair pricing"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-white py-5 text-center">
          <div className="container">
            <h2 className="text-center">{listPolicy[0].name2}</h2>

            <Slider {...products} className=" fair product-slick mx-4">
              <div className="text-center">
                <img
                  className="img-fluid mx-auto mb-2"
                  src={`${process.env.PUBLIC_URL}` + listPolicy[0].image2}
                  alt="fair pricing"
                />
                <div className="row">
                  <div className="col-md-6 col-sm-12 v-divider text-md-right">
                    <h2>₹ 2,93,181</h2>
                    <h2>Estimated Other Jewellers</h2>
                    <h3>
                      {listPolicy[0].percent} more than the <br />
                      {listPolicy[0].title} price
                    </h3>
                  </div>
                  <div className="col-md-6 col-sm-12  text-md-left">
                    <h2>₹ 2,09,415</h2>
                    <h2>{listPolicy[0].title}</h2>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <img
                  className="img-fluid mx-auto mb-2"
                  src={`${process.env.PUBLIC_URL}` + listPolicy[0].image2}
                  alt="fair pricing"
                />
                <div className="row">
                  <div className="col-md-6 col-sm-12 v-divider text-md-right">
                    <h2>₹ {listPolicy[0].price1}</h2>
                    <h2>Estimated Other Jewellers</h2>
                    <h3>
                      {listPolicy[0].percent} more than the <br />
                      {listPolicy[0].title} price
                    </h3>
                  </div>
                  <div className="col-md-6 col-sm-12  text-md-left">
                    <h2>₹ {listPolicy[0].price2}</h2>
                    <h2>{listPolicy[0].title}</h2>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        {/*Sunny diamonds features section*/}
        <div className="mt-4 pt-3">
          <Promise />
        </div>
        {/* end of sunny diamonds features section */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  card: getPolicy(state.data.products, "card-2"),
  listPolicy: getPolicy(state.data.products, "fair-pricing")
});

export default connect(mapStateToProps)(FairPricing);
