import React from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./home-banner.css";

const HomeBanner = (props) => {

  const Slider1 = {
    dots: true,
    dotsClass: "slick-dots",
    initialSlide: 0,
    arrows: false,
    infinite: true,
    lazyLoad: true,
    fade: true,
    swipeToSlide: true,
    speed: 5000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (

    <section className="homepage-banner">
      <div className="homepage-banner-wrapper">
        <Slider {...Slider1} >
          {window.banners.desktop.map((item, index) => (
            <div key={index} className="banner-left banner-home">
              <Link key={index} to={`${process.env.PUBLIC_URL}/` + item.link}>
                <img src={item.image} alt={item.alt} />
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      {/* Mobile Banner */}
      <div className="mobile-banner">
        <Slider {...Slider1} >
          {window.banners.mobile.map((item, index) => (
            <div key={index}>
              <Link key={index} to={`${process.env.PUBLIC_URL}/` + item.link}>
                <img src={item.image} alt={item.alt} />
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>

  );
};

export default HomeBanner;
