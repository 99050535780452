import React, { useState } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';

import Item from './item';
import { getCategoryProducts } from "../../../services";
import { addToCart, addToWishlist, addToCompare, filterSort } from "../../../actions";
import CategoryDescription from './catagory-description';
import AdSpace from './ad-space';
// import UIkit from 'uikit';

const ListingPanel = (props) => {

    const [limit, setLimit] = useState(24)
    const [hasMoreItems, setHasMoreItems] = useState(true)

    const {
        products,
        symbol,
        addToCart,
        addToWishlist,
        addToCompare,
        filterSort
    } = props;

    console.log(products[0])

    const filterTag = props.filters

    const fetchMoreItems = () => {
        // UIkit.update()
        if (limit >= props.products.length) {
            setHasMoreItems(false)
            return;
        }
        // a fake async api call
        setLimit(limit + 12)
    }

    return (
        <div>

            {/* Category description  */}

            <CategoryDescription
                productsLength={products.length}
                categoryTitle={props.title}
                categoryDescription={props.description}
                filterSort={filterSort}
                history={props.history}
            />

            <div className="uk-width-1-1 listing-box-main">
                <div className="uk-width-1-1 uk-margin-medium-top fillter-select-button">
                    <div data-uk-grid className="uk-padding-remove">
                        {Object.keys(Object.values(filterTag)[0]).map(filter => (
                            Object.values(filterTag)[0][filter] && Object.values(filterTag)[0][filter].map((values, i) => (
                                <div className="uk-padding-remove uk-width-auto uk-margin-remove">
                                    <div data-uk-alert>
                                        <p>{values.replace("_", " ")}</p>
                                    </div>
                                </div>
                            ))
                        ))
                        }
                    </div>
                </div>

                {/* product listing section  */}

                <h3>Select Your Style</h3>
                {products !== undefined && products.length > 0 ? (
                    <InfiniteScroll
                        dataLength={limit} //This is important field to render the next data
                        next={() => fetchMoreItems(products)}
                        hasMore={hasMoreItems}
                        loader={<div className="loading-cls" />}
                    >
                        <div className="uk-grid uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl uk-text-center uk-padding-small" data-uk-grid>
                            {products.slice(0, limit).map((product, index) => (
                                <Item
                                    product={product}
                                    symbol={symbol}
                                    onAddToCompareClicked={addToCompare}
                                    onAddToWishlistClicked={addToWishlist}
                                    onAddToCartClicked={addToCart}
                                    key={index}
                                />
                            ))}
                        </div>
                    </InfiniteScroll>
                ) : (
                    <div className="row">
                        <div className="col-sm-12 text-center section-b-space my-5 no-found">
                            <h2>
                                Sorry! Couldn't find the product you were looking For!!!
                            </h2>
                            <p className="category_paragraph">
                                Please check if you have misspelt something or try searching
                                with other words.
                            </p>
                            <Link
                                to={`${process.env.PUBLIC_URL}/trending`}
                                className="btn btn-solid"
                            >
                                continue shopping
                            </Link>
                        </div>
                    </div>
                )}
                <div className="uk-width-1-1 uk-inline uk-text-center">
                    <hr className="uk-divider-icon" />
                </div>
            </div>

            {/* Ad Space  */}

            <AdSpace categoryTitle={props.title} bannerImage={props.bannerImage} />

        </div >
    )
}

const mapStateToProps = state => ({
    products: getCategoryProducts(state.data.category.products, state.filters),
    symbol: state.data.symbol,
    filters: state.filters
});

export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare, filterSort })(ListingPanel);