import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { postAllOrderdetails } from "../../../actions";

import Breadcrumb from "../../common/breadcrumb";
import store from "../../../store";

class GiftPurchaseResponse extends Component {

  constructor(props) {
    super(props);
    this.state = {
      transaction_Id: this.props.orderData.order_id,
    };
  }

  componentDidMount() {
    if (this.props.orderData) {
      if (this.props.match.params.id == "success") {
        if (this.props.orderData.purchase) {
          window.dataLayer = window.dataLayer || [];
          try {
            window.dataLayer.push({
              "event": 'transaction',
              'ecommerce': {
                'currencyCode': 'INR',
                'purchase': {
                  'actionField': {
                    'id': this.props.orderData.order_id,
                    'affiliation': 'Online Store',
                    'revenue': this.props.orderData.order.amount / 100,
                    'tax': '0.00',
                    'shipping': '0.00',
                    'coupon': 'NULL'
                  },
                  'products': {
                    'name': this.props.orderData.purchase.purchase_occasion_name,
                    'id': this.props.orderData.purchase.purchase_card_code,
                    'price': this.props.orderData.purchase.purchase_card_amount,
                    'brand': 'Sunny Diamonds',
                    'category': 'Digital Gift Card',
                    'quantity': 1,
                    'coupon': ''
                  },
                }
              }
            });
          }
          catch (err) {
            if (window.dataLayer.filter(function (obj) {
              return obj.errorMsg === err.message;
            }).length == 0) {
              window.dataLayer.push({
                'event': 'variable error',
                'errorMsg': err.message
              });
            }
          }
          const content_name = []
          const content_ids = []
          for (let i = 0; i < this.props.orderData.purchase.length; i++) {
            content_ids.push(
              this.props.orderData.purchase[i].purchase_card_code
            );
            content_name.push(
              this.props.orderData.purchase[i].purchase_occasion_name
            )
          }
          // window.fbq('track', 'Purchase',
          //   {
          //     content_ids: content_ids,
          //     value: this.props.orderData.order.amount / 100,
          //     content_name: content_name,
          //     currency: "INR",
          //     event_time: new Date().getTime()
          //   });
          store.dispatch({ type: "RECIEVE_GIFTCARD_CREATE_DETAILS", payload: "" });
        }
      }
    }
    else
      this.props.history.push("/trending")
  }

  render() {
    let response = this.props.match.params.id;

    let transaction_Id = this.props.orderData.order_id


    return (
      <div>
        <Breadcrumb title={this.props.match.params.id} />
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {response == "success" ?
                  <div className="success-text my-5 py-4">
                    <i className="fa fa-check-circle" aria-hidden="true" />
                    <h2 className="mt-4">Thank You for the purchase !</h2>
                    <h3>Transaction no: <span className="h3">{this.state.transaction_Id}</span></h3>
                    <p className="text-center mt-4">Confirmation mail has been sent to your mail id</p>
                    <p className="text-center mb-4">We hope you enjoy your purchase</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/jewellery/`}
                      data-lng="en"
                      className="btn btn-solid mt-2"
                      rel="noopener noreferrer"
                    >
                      <div>continue shopping</div>
                    </Link>
                  </div> :
                  <div className="danger-text my-5 py-4">
                    <i className="fa fa-times-circle" aria-hidden="true" />
                    <h2 className="mt-4">Your Purchase has been failed !</h2>
                    <p className="text-center mb-4">Please try agian later</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/gifts`}
                      className="btn btn-solid mt-3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Try again
                    </Link>
                  </div>}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orderData: state.gift.card_create,
});

export default connect(
  mapStateToProps,
  { postAllOrderdetails }
)(GiftPurchaseResponse);


