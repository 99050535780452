import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../common/index.scss";
import Breadcrumb from "../common/breadcrumb";
import store from "../../store";
// import Seo from "../layouts/common/seo";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { actProfile, actProfileEdit, actUserLogout } from "../../actions";
import TimeOut from "../../sessions/timeOut";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      address: "",
      city: "",
      state: "",
      country: "India",
      phone: "",
      email: "",
      pincode: "",
      user_id: "",
      ship_address: "",
      ship_city: "",
      ship_state: "",
      ship_pincode: "",
      button_disable: true,
      same_address: false,
      navClose: { left: "-410px" }
    };
    this.validator = new SimpleReactValidator({ locale: 'en' });
  }

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  componentDidMount() {
    store.dispatch({ type: "RECEIVE_PROFILE", payload: "" });
    if (
      sessionStorage.getItem("user_id") === "" ||
      sessionStorage.getItem("token") === "" ||
      sessionStorage.getItem("user_type") !== "CUSTOMER"
    ) {
      this.props.history.push("/login");
    } else {
      this.props.actProfile();
    }
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { left: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { left: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { left: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { left: "-410px" } });
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile_data !== prevProps.profile_data) {
      if (
        sessionStorage.getItem("user_id") === "" ||
        sessionStorage.getItem("token") === "" ||
        sessionStorage.getItem("user_type") !== "CUSTOMER"
      ) {
        this.props.history.push("/login");
      } else {
        this.setState({
          user_id: this.props.profile_data.user_id,
          first_name: this.props.profile_data.first_name,
          last_name: this.props.profile_data.last_name,
          phone: this.props.profile_data.phone,
          email: this.props.profile_data.email,
          address: this.props.profile_data.address,
          city: this.props.profile_data.city,
          state: this.props.profile_data.state,
          pincode: this.props.profile_data.pincode,
          ship_address: this.props.profile_data.ship_address,
          ship_city: this.props.profile_data.ship_city,
          ship_state: this.props.profile_data.ship_state,
          ship_pincode: this.props.profile_data.ship_pincode
        });
      }
    }
  }

  sameBillingAddress = event => {
    this.setState({
      same_address: event.target.checked
    });
    if (event.target.checked) {
      this.setState({
        ship_address: this.state.address,
        ship_city: this.state.city,
        ship_state: this.state.state,
        ship_pincode: this.state.pincode
      });
    }
    else {
      this.setState({
        ship_address: "",
        ship_city: "",
        ship_state: "",
        ship_pincode: ""
      });
    }
  };

  enableButton = () => {
    this.setState({
      button_disable: false
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      var profile_details = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        country: "India",
        phone: this.state.phone,
        email: this.state.email,
        pincode: this.state.pincode,
        user_id: this.state.user_id,
        ship_address: this.state.ship_address,
        ship_city: this.state.ship_city,
        ship_state: this.state.ship_state,
        ship_pincode: this.state.ship_pincode
      };
      this.props.actProfileEdit(profile_details);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  logoutSubmit = () => {
    this.props.actUserLogout();
  };

  render() {

    if (this.props.profile_data)
      return (
        <div>
          <Helmet>
            <title>
              Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds |
              Profile Update
            </title>
            <meta
              name="description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally
          Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
            <meta
              name="keywords"
              content="Buy Diamonds online, Online diamonds shopping, Diamond Jewellery Store, Buy Diamond Rings Online, 
          diamond earrings online, diamond pendants online kerala, buy diamond bangles online, buy diamond necklace,  
          Buy wedding rings online,  online diamond jewellery India"
            />
            <meta property="og:site_name" content="Sunny Diamonds" />
            <meta property="og:type" content="Summary" />
            <meta
              property="og:title"
              content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
            />
            <meta
              property="og:description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
            <meta property="og:url" content="https://sunnydiamonds.com/" />
            <meta name="twitter:card" content="Summary" />
            <meta name="twitter:site" content="@SunnyDiamond_4c" />
            <meta name="twitter:creator" content="Sunny Diamonds" />
            <meta
              name="twitter:title"
              content="Buy Diamonds Online | Diamond Jewellery Store - Sunny Diamonds"
            />
            <meta
              name="twitter:description"
              content="Sunny diamonds one of the finest diamond jewellery store in India. Shop Internally Flawless diamond and platinum jewellery online at best price. Buy diamonds online!"
            />
          </Helmet>
          <Breadcrumb title={"Profile"} />
          <section className="blog-detail-page section-b-space ">
            <div className="container">
              {/* <div className="row">
                <img src="/assets/images/sunny/dashboard/dashboard_ad.png"/>
              </div> */}
              <div className="row">
                <div className="col-lg-2 col-md-3 ">
                  <div
                    className="account-sidebar"
                    onClick={this.openNav.bind(this)}
                  >
                    <a className="popup-btn btn btn-solid">my account</a>
                  </div>
                  <div
                    className="dashboard-left mt-2"
                    style={this.state.navClose}
                  >
                    <div
                      className="collection-mobile-back"
                      onClick={this.closeNav.bind(this)}
                    >
                      <span className="filter-back">
                        <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                        back
                      </span>
                    </div>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/my-orders`}>
                            My Orders
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/user/profile`}>
                            Edit Profile
                          </Link>
                        </li>
                        {/* <li>
                          <Link to={`${process.env.PUBLIC_URL}/dfe-active-plan`}>
                            DFE Active Plan
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/change-password`}
                          >
                            Change Password
                          </Link>
                        </li>
                        <li className="last">
                          <a onClick={this.logoutSubmit}>Log Out</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-10 col-md-9">
                  <div className="dashboard-right mt-2 bg-white">
                    <div className="dashboard">
                      <div className="page-title">
                        <h2>Profile Edit</h2>
                      </div>
                      <TimeOut logout={this.logoutSubmit} msg={null} />

                      <form
                        onSubmit={this.handleSubmit.bind(this)}
                        onChange={this.enableButton}
                      >
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <h3>User Details</h3>
                            <br />
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <div className="field-label">First Name</div>
                                <input
                                  type="text"
                                  name="first_name"
                                  className="form-control"
                                  value={this.state.first_name}
                                  onChange={this.setStateFromInput}
                                />
                                {this.validator.message(
                                  "first_name",
                                  this.state.first_name,
                                  "required|alpha_space"
                                )}
                              </div>
                              <div className="form-group col-md-6 col-12">
                                <div className="field-label">Last Name</div>
                                <input
                                  type="text"
                                  name="last_name"
                                  className="form-control"
                                  value={this.state.last_name}
                                  onChange={this.setStateFromInput}
                                />
                                {this.validator.message(
                                  "last_name",
                                  this.state.last_name,
                                  "required|alpha_space"
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <div className="field-label">Phone</div>
                                <input
                                  type="tel"
                                  name="phone"
                                  className="form-control"
                                  value={this.state.phone}
                                  onChange={this.setStateFromInput}
                                />
                                {this.validator.message(
                                  "phone",
                                  this.state.phone,
                                  "required|phone|max:10"
                                )}
                              </div>
                              <div className="form-group col-md-6 col-12">
                                <div className="field-label">Email Address</div>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  title="enter a valid mail id"
                                  value={this.state.email}
                                  onChange={this.setStateFromInput}
                                />
                                {this.validator.message(
                                  "email",
                                  this.state.email,
                                  "required|email"
                                )}
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-lg-3 mt-0">
                              <div className="col-lg-6 col-md-6 col-12">
                                <h3>Billing Address</h3>
                                <div className="row mt-5">
                                  <div className="form-group col-12">
                                    <div className="field-label">
                                      Address
                                    </div>
                                    <input
                                      type="text"
                                      name="address"
                                      className="form-control"
                                      value={this.state.address}
                                      onChange={this.setStateFromInput}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Town/City</div>
                                    <input
                                      type="text"
                                      name="city"
                                      className="form-control"
                                      value={this.state.city}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "city",
                                      this.state.city,
                                      "required|alpha_space|min:3|max:125"
                                    )}
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">State</div>
                                    <input
                                      type="text"
                                      name="state"
                                      className="form-control"
                                      value={this.state.state}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "state",
                                      this.state.state,
                                      "required|alpha_space|min:3|max:125"
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Postal Code</div>
                                    <input
                                      type="text"
                                      name="pincode"
                                      className="form-control"
                                      value={this.state.pincode}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "pincode",
                                      this.state.pincode,
                                      "required|min:6|max:6"
                                    )}
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Country</div>
                                    <select
                                      name="country"
                                      className="form-control"
                                      value={this.state.country}
                                      onChange={this.setStateFromInput}
                                    >
                                      <option>India</option>
                                    </select>
                                    {this.validator.message(
                                      "country",
                                      this.state.country,
                                      "required"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 ">
                                <h3>Shipping Address</h3>
                                <div class="form-check py-2 mb-1">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={this.sameBillingAddress.bind(this)} />
                                  <label class="form-check-label" for="flexCheckDefault">
                                    Same as billing address
                                  </label>
                                </div>
                                <div className="row">
                                  <div className="form-group col-12">
                                    <div className="field-label">
                                      Address
                                    </div>
                                    <input
                                      type="text"
                                      name="ship_address"
                                      className="form-control"
                                      value={this.state.ship_address}
                                      onChange={this.setStateFromInput}
                                    />
                                    {/* {this.validator.message(
                                        "address",
                                        this.state.ship_address,
                                        "required|alpha_num_dash_space|min:5|max:125"
                                      )} */}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Town/City</div>
                                    <input
                                      type="text"
                                      name="ship_city"
                                      className="form-control"
                                      value={this.state.ship_city}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "city",
                                      this.state.ship_city,
                                      "required|alpha_space|min:3|max:125"
                                    )}
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">State</div>
                                    <input
                                      type="text"
                                      name="ship_state"
                                      className="form-control"
                                      value={this.state.ship_state}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "state",
                                      this.state.ship_state,
                                      "required|alpha_space|min:3|max:125"
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Postal Code</div>
                                    <input
                                      type="text"
                                      name="ship_pincode"
                                      className="form-control"
                                      value={this.state.ship_pincode}
                                      onChange={this.setStateFromInput}
                                    />
                                    {this.validator.message(
                                      "pincode",
                                      this.state.ship_pincode,
                                      "required|min:6|max:6"
                                    )}
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12 col-12">
                                    <div className="field-label">Country</div>
                                    <select
                                      name="country"
                                      className="form-control"
                                      value={this.state.country}
                                      onChange={this.setStateFromInput}
                                    >
                                      <option>India</option>
                                    </select>
                                    {this.validator.message(
                                      "country",
                                      this.state.country,
                                      "required"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-solid"
                              disabled={this.state.button_disable}
                            >
                              Update
                            </button>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <div className="row mt-5 ">
                  <div className="col-md-12">
                    <h2 className="text-center">Need Help?</h2>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-md-4">
                    <a
                      href="tel:+919961870099"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fas fa-phone fa-2x"
                        style={{ transform: "rotateY(180deg)" }}
                      />
                      <div className="profile-help">+91 9961 870 099</div>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a
                      href="mailto:customerservice@sunnydiamonds.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-envelope fa-2x" />
                      <div className="profile-help">
                        customerservice@sunnydiamonds.com
                      </div>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a
                      href="https://api.whatsapp.com/send?phone=+919961870099"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "rgb(114, 34, 87)" }}
                    >
                      <i className="fab fa-whatsapp fa-2x" />
                      <div className="profile-help">+91 9961 870 099</div>
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </section>
        </div>
      );
    else
      return (
        <div className="height">
          <img
            src={`${process.env.PUBLIC_URL
              }/assets/images/sunny/loader/loader.gif`}
            alt=""
            className="loader"
          />
        </div>
      );
  }
}

const mapStateToProps = state => ({
  profile_data: state.data.profile_data
});

export default connect(
  mapStateToProps,
  { actProfile, actProfileEdit, actUserLogout }
)(Profile);
